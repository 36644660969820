import React from "react";

export default function IconAsacp() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="11" viewBox="0 0 49 11" fill="none">
      <path
        d="M11.9203 10.1047L8.91623 10.1047L8.37003 8.32957H4.81973L4.27353 10.1047H0.996338L4.95628 3.12538e-05H8.23348L11.9203 10.1047ZM7.68728 6.28132L6.45833 2.59448L5.50248 6.28132H7.68728Z"
        fill="#717171"
      />
      <path
        d="M30.218 10.1047L27.2139 10.1047L26.6677 8.32954H23.1174L22.5712 10.1047H19.294L23.254 0H26.5312L30.218 10.1047ZM25.985 6.28129L24.756 2.59445L23.8002 6.28129H25.985Z"
        fill="#717171"
      />
      <path
        d="M14.6514 6.10352e-05H23.254L22.7078 1.63866H15.6072C15.6072 1.63866 13.8321 1.50211 13.8321 2.32141C13.8321 3.19533 15.1783 3.46957 15.7438 3.55036C16.6996 3.68691 19.7037 4.23311 19.7037 6.82755C19.7037 9.01661 17.6555 10.1047 16.8362 10.1047H11.9204L11.1011 8.19305H15.1976C15.7438 8.19305 16.9727 8.19305 16.8362 7.10065C16.7007 6.01669 14.9245 5.8717 13.8321 5.5986C12.8762 5.3255 11.2376 4.64276 11.2376 2.73106C11.2376 0.6555 13.5135 6.10352e-05 14.6514 6.10352e-05Z"
        fill="#717171"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.218 10.1048L29.3987 8.05656L30.4911 8.05655C30.2952 7.81251 29.8083 6.2814 29.8083 4.6428C29.8083 1.50216 32.1297 0.136658 33.4952 0.136658H46.1943C47.0591 0.136658 48.7888 0.764787 48.7888 3.2773C48.7888 5.78982 46.9681 6.41795 46.0578 6.41795H43.5999V10.1048H40.7323V2.32146C39.873 2.29077 38.248 2.30147 36.6945 2.31169C35.944 2.31663 35.2103 2.32146 34.5876 2.32146C32.949 2.32146 32.6759 4.18765 32.6759 5.18901C32.6759 6.19038 33.0855 8.1931 34.5876 8.1931C36.2262 8.1931 36.6813 6.73658 36.6358 6.00831L39.2303 6.82761C38.4656 9.55861 36.3627 10.1048 35.5434 10.1048H30.218ZM43.5999 2.18489V4.36969H44.9654C45.2385 4.36969 45.9212 4.15801 45.9212 3.27729C45.9212 2.45799 45.3295 2.18489 44.9654 2.18489H43.5999Z"
        fill="#717171"
      />
    </svg>
  );
}
