import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import SignUpModal from "../common/SignUpModal";
import HomeFilters from "../home/HomeFilters";
import SortBySelect from "./SortBySelect";
import useDeviceSize from "../../hooks/useDeviceSize";
import ChoseLang from "../mui/ChoseLang";
import { useTranslation } from "react-i18next";

export default function HeaderMobileMenu({ isOpen, closeModal }) {
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const { mobileHeader } = useDeviceSize();
  const { t } = useTranslation();

  useEffect(() => {
    if (!mobileHeader) closeModal();
  }, [closeModal, mobileHeader]);

  useEffect(() => {
    if (openLoginModal) {
      closeModal();
    }
  }, [closeModal, openLoginModal]);

  return (
    <>
      <Modal
        overlayClassName={"menu-overlay"}
        ariaHideApp={false}
        className={`menu-modal ${t("currentLang")}`}
        isOpen={isOpen}
        onRequestClose={closeModal}
      >
        <div className="menu-modal__content">
          {/* <div className="menu-modal__content__buttons">
            <CusButton text={"Login"} onClick={() => setOpenLoginModal(LOGIN)} />
            <CusButton
              onClick={() => setOpenLoginModal(SIGN_UP)}
              className="custom-button__red"
              text={"Sign-up"}
            />
          </div> */}
          <div className="header__lang">
            <ChoseLang classNameUl="ul-for-mobile" />
          </div>
          <SortBySelect />
          <HomeFilters setOpenLoginModal />
        </div>
      </Modal>
      <SignUpModal isOpen={!!openLoginModal} type={openLoginModal} setOpen={setOpenLoginModal} />
    </>
  );
}
