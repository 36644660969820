import React, { useMemo } from "react";
import Like from "./components/Like";
import SelectModeChat from "./components/SelectModeChat";
import PrivateButton from "./components/PrivateButton";
import { ENUM_STATUS_WINDOW } from "../../../../store/chatSlice";
import { useSelector } from "react-redux";

export default function ControlsBottomMobile() {
  const status = useSelector((state) => state.chat.statusWindow);
  const available = useMemo(() => status === ENUM_STATUS_WINDOW.stream, [status]);

  return (
    <div className="chat__window__controls__bottom-mobile">
      <Like />
      <div className="controls__buttons">
        <PrivateButton alwaysShown={available} />
        <SelectModeChat />
      </div>
    </div>
  );
}
