import { useLayoutEffect, useMemo, useState } from "react";

export const EnumDeviceModes = {
  mobile: "mobile",
  desktop: "desktop",
};
export const ENUM_DEVICE_ORIENTATION = {
  portrait: "portrait",
  landscape: "landscape",
};

export default function useDeviceSize() {
  const [appMode, setAppMode] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [fullWidth, setFullWidth] = useState(window.outerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [orientation, setOrientation] = useState(ENUM_DEVICE_ORIENTATION.portrait);

  const checkSizeWindow = () => {
    const angle = screen?.orientation?.angle;
    if (angle === 90) setOrientation(ENUM_DEVICE_ORIENTATION.landscape);
    if (angle === 0) setOrientation(ENUM_DEVICE_ORIENTATION.portrait);
    if (!window.innerWidth) return;
    setAppMode(window.innerWidth <= 670 ? EnumDeviceModes.mobile : EnumDeviceModes.desktop);
    if (screen?.orientation?.angle == 90) {
      setAppMode(window.innerWidth <= 599 ? EnumDeviceModes.mobile : EnumDeviceModes.desktop);
    }
    setWidth(window.innerWidth);
    setFullWidth(window.outerWidth);
    setHeight(window.innerHeight);
  };

  useLayoutEffect(() => {
    checkSizeWindow();
    window.addEventListener("resize", checkSizeWindow);
    return () => window.removeEventListener("resize", checkSizeWindow);
  }, []);

  const isMobile = useMemo(() => appMode === EnumDeviceModes.mobile, [appMode]);
  const mobileHeader = useMemo(() => width < 1150, [width]);

  const isIos = useMemo(() => /iPad|iPhone|iPod/.test(navigator.userAgent), []);
  const isSafari = useMemo(() => /^((?!chrome|android).)*safari/i.test(navigator.userAgent), []);
  const isWebRTC = useMemo(
    () =>
      !!(
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia
      ),

    []
  );

  const memoObj = useMemo(
    () => ({
      isSafari,
      isIos,
      appMode,
      width,
      fullWidth,
      height,
      isMobile,
      mobileHeader,
      orientation,
      isWebRTC,
    }),
    [
      appMode,
      width,
      isSafari,
      isIos,
      fullWidth,
      isMobile,
      height,
      mobileHeader,
      orientation,
      isWebRTC,
    ]
  );

  return memoObj;
}
