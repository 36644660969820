import React, { useEffect, useState } from "react";
import { ENUM_CHAT_TAB, setChatActiveTab } from "../../../../../store/chatSlice";
import IconChat from "../../../../../images/jsIcons/IconChat";
import IconChatTips from "../../../../../images/jsIcons/IconChatTips";
import IconVibrator from "../../../../../images/jsIcons/IconVibrator";
import CusSelect from "../../../../mui/CusSelect";
import { useDispatch, useSelector } from "react-redux";

const FREE = ENUM_CHAT_TAB.free;
const TIPS = ENUM_CHAT_TAB.tips;
const LOVENSE = ENUM_CHAT_TAB.lovense;

export default function SelectModeChat() {
  const dispatch = useDispatch();
  const chatActiveTab = useSelector((state) => state.chat.chatActiveTab);

  const [currentMode, setCurrentMode] = useState(FREE);

  const tabs = {
    [FREE]: { icon: <IconChat />, label: "Free chat", value: FREE },
    [TIPS]: { icon: <IconChatTips />, label: "Tips menu", value: TIPS },
    // [LOVENSE]: { icon: <IconVibrator />, label: "Lovense Toys", value: LOVENSE },
  };

  const changeSelect = (value) => {
    setCurrentMode(value);
    dispatch(setChatActiveTab(value));
  };

  useEffect(() => {
    setCurrentMode(chatActiveTab);
  }, [chatActiveTab]);

  return (
    <div className="select-chat">
      <CusSelect
        valueProps={currentMode}
        options={Object.values(tabs)}
        title={
          <span>
            {tabs[currentMode].icon} {tabs[currentMode].text}
          </span>
        }
        onChange={changeSelect}
      />
    </div>
  );
}
