import React from "react";

export default function IconPlay(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_857_17751)">
        <g filter="url(#filter0_di_857_17751)">
          <path
            d="M22 0C9.84883 0 0 9.84883 0 22C0 34.151 9.84883 44 22 44C34.151 44 44 34.151 44 22C44 9.84883 34.151 0 22 0ZM32.5271 23.7663L19.4043 32.7941C19.0392 33.0441 18.6143 33.172 18.1882 33.172C17.8453 33.172 17.5048 33.0905 17.1913 32.9267C16.4867 32.554 16.0442 31.8233 16.0442 31.028V12.9722C16.0442 12.1769 16.4867 11.4462 17.1913 11.0734C17.8959 10.7038 18.7473 10.7547 19.4043 11.206L32.5271 20.2337C33.1077 20.6335 33.4557 21.2949 33.4557 22C33.4557 22.7051 33.1079 23.3665 32.5271 23.7663Z"
            fill="#485154"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_di_857_17751"
          x="-58"
          y="-29"
          width="160"
          height="160"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_857_17751" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_857_17751"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_857_17751" />
        </filter>
        <clipPath id="clip0_857_17751">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
