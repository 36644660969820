import React from "react";

export default function IconChat() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8933 8.26667V10.98C10.8933 11.22 10.8666 11.4467 10.8066 11.6533C10.5599 12.6333 9.74659 13.2467 8.62659 13.2467H6.81325L4.79992 14.5867C4.49992 14.7933 4.09992 14.5733 4.09992 14.2133V13.2467C3.41992 13.2467 2.85325 13.02 2.45992 12.6267C2.05992 12.2267 1.83325 11.66 1.83325 10.98V8.26667C1.83325 7 2.61992 6.12667 3.83325 6.01333C3.91992 6.00667 4.00659 6 4.09992 6H8.62659C9.98659 6 10.8933 6.90667 10.8933 8.26667Z"
        fill="white"
        fillOpacity={0.6}
      />
      <path
        d="M12.3333 10.3997C13.1799 10.3997 13.8933 10.1197 14.3866 9.61967C14.8866 9.12634 15.1666 8.41301 15.1666 7.56634V4.16634C15.1666 2.59967 13.8999 1.33301 12.3333 1.33301H6.66659C5.09992 1.33301 3.83325 2.59967 3.83325 4.16634V4.66634C3.83325 4.85301 3.97992 4.99967 4.16659 4.99967H8.62658C10.4333 4.99967 11.8933 6.45967 11.8933 8.26634V10.0663C11.8933 10.253 12.0399 10.3997 12.2266 10.3997H12.3333Z"
        fill="white"
        fillOpacity={0.6}
      />
    </svg>
  );
}
