import React from "react";

export default function IconChatOff() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.333 2.33301H4.66634C2.82634 2.33301 1.33301 3.81967 1.33301 5.65301V9.63967V10.3063C1.33301 12.1397 2.82634 13.6263 4.66634 13.6263H5.66634C5.84634 13.6263 6.08634 13.7463 6.19967 13.893L7.19967 15.2197C7.63967 15.8063 8.35967 15.8063 8.79967 15.2197L9.79967 13.893C9.92634 13.7263 10.1263 13.6263 10.333 13.6263H11.333C13.173 13.6263 14.6663 12.1397 14.6663 10.3063V5.65301C14.6663 3.81967 13.173 2.33301 11.333 2.33301ZM5.33301 8.99967C4.95967 8.99967 4.66634 8.69967 4.66634 8.33301C4.66634 7.96634 4.96634 7.66634 5.33301 7.66634C5.69967 7.66634 5.99967 7.96634 5.99967 8.33301C5.99967 8.69967 5.70634 8.99967 5.33301 8.99967ZM7.99967 8.99967C7.62634 8.99967 7.33301 8.69967 7.33301 8.33301C7.33301 7.96634 7.63301 7.66634 7.99967 7.66634C8.36634 7.66634 8.66634 7.96634 8.66634 8.33301C8.66634 8.69967 8.37301 8.99967 7.99967 8.99967ZM10.6663 8.99967C10.293 8.99967 9.99967 8.69967 9.99967 8.33301C9.99967 7.96634 10.2997 7.66634 10.6663 7.66634C11.033 7.66634 11.333 7.96634 11.333 8.33301C11.333 8.69967 11.0397 8.99967 10.6663 8.99967Z"
        fill="url(#paint0_linear_0_1)"
      />
      <path d="M1 1L14.5 15" stroke="#FB9A9A" strokeLinecap="round" />
      <defs>
        <linearGradient
          id="paint0_linear_0_1"
          x1="7.99967"
          y1="2.33301"
          x2="7.99967"
          y2="16.7702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
      </defs>
    </svg>
  );
}
