import React from "react";

export default function IconFoolScreen() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_889_15078)">
        <path
          d="M2.34375 2.34375H6.69324V0H0V6.69324H2.34375V2.34375Z"
          fill="url(#paint0_linear_889_15078)"
        />
        <path
          d="M13.3086 0V2.34375H17.6581V6.69324H20.0018V0L13.3086 0Z"
          fill="url(#paint1_linear_889_15078)"
        />
        <path
          d="M2.34375 13.3086H0V20.0019H6.69324V17.6581H2.34375V13.3086Z"
          fill="url(#paint2_linear_889_15078)"
        />
        <path
          d="M17.6581 17.6581H13.3086V20.0019H20.0018V13.3086H17.6581V17.6581Z"
          fill="url(#paint3_linear_889_15078)"
        />
        <path
          d="M5.05078 5.05078H14.9498V14.9498H5.05078V5.05078Z"
          fill="url(#paint4_linear_889_15078)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_889_15078"
          x1="3.34662"
          y1="0"
          x2="3.34662"
          y2="7.25101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_889_15078"
          x1="16.6552"
          y1="0"
          x2="16.6552"
          y2="7.25101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_889_15078"
          x1="3.34662"
          y1="13.3086"
          x2="3.34662"
          y2="20.5596"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_889_15078"
          x1="16.6552"
          y1="13.3086"
          x2="16.6552"
          y2="20.5596"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_889_15078"
          x1="10.0003"
          y1="5.05078"
          x2="10.0003"
          y2="15.7747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <clipPath id="clip0_889_15078">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
