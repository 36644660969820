import React from "react";
import PageTextContainer from "../components/common/PageTextContainer";
import { getLang } from "../utils/utils";
import { useReadLocalStorage } from "usehooks-ts";
import SupportContentEn from "../components/mui/support/SupportContentEn";
import SupportContentRu from "../components/mui/support/SupportContentRu";

export default function Support() {
  const chosenLang = getLang();
  const selectLang = useReadLocalStorage("lang") || chosenLang;
  return (
    <PageTextContainer>
      {selectLang === "ru" ? <SupportContentRu /> : <SupportContentEn />}
    </PageTextContainer>
  );
}
