import React, { useRef, useState } from "react";
import ProfileHeader from "./profile/ProfileHeader";
import { useEffect } from "react";
import { TEST_MODE } from "../common/consts";

import photo1 from "../../images/template/image1289.png";
import photo2 from "../../images/template/image2.png";
import defaultVideo from "../../images/template/default-video.mp4";
import IconPlay from "../../images/jsIcons/IconPlay";
import Pagination from "../mui/Pagination";

const PER_PAGE = 28;

const VideoItem = ({ el }) => {
  const [isPlaying, setPlaying] = useState(false);
  const videoRef = useRef();

  const playingClass = isPlaying ? "playing" : "";

  const onVideoPlay = () => {
    // if (isPlaying) {
    //   console.log(videoRef);
    //   return;
    // }

    videoRef.current.play();
    setPlaying(true);
  };

  return (
    <div className={`chat__video__content__items video ${playingClass}`} onClick={onVideoPlay}>
      {!isPlaying && <IconPlay className="chat__video__content__items__play" />}
      <img className="chat__photo__content__items__bg" src={el.preview} />
      <video ref={videoRef} poster={el.preview} controls={isPlaying}>
        <source src={defaultVideo} />
      </video>
    </div>
  );
};

export default function ChatTabVideo() {
  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    if (TEST_MODE && true) console.log("f");
    // if (TEST_MODE && true)
    setVideos([
      { video: "", preview: photo1 },
      { video: "", preview: photo2 },
    ]);
  }, []);

  return (
    <div className="chat__video">
      <ProfileHeader />
      <div className="chat__video__content">
        {videos.map((el, indx) => (
          <VideoItem el={el} key={indx} />
        ))}
      </div>

      <Pagination
        MAX_PAGE={Math.ceil(totalPage / PER_PAGE)}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
