import React, { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import useJanus from "../../../hooks/useJanus";
import { useSelector } from "react-redux";
import { TEST_MODE } from "../../common/consts";
import { ENUM_CHAT_MODE, ENUM_STATUS_WINDOW } from "../../../store/chatSlice";
import { errorToast } from "../../mui/Toaster";
import { useTranslation } from "react-i18next";

export default function Autostart({ setMyStream }) {
  const { offerStream } = useJanus({});
  const chatActiveMode = useSelector((state) => state.chat.chatActiveMode);
  const status = useSelector((state) => state.chat.statusWindow);

  const { t } = useTranslation();
  const [autostart] = useLocalStorage("autostart", false);
  const [camera] = useLocalStorage("localCamera", false);
  const [micro] = useLocalStorage("localMicro", false);
  const [resolution] = useLocalStorage("localResolution", undefined);

  useEffect(() => {
    if (!autostart) return;
    if (chatActiveMode !== ENUM_CHAT_MODE.exclusive) return;
    if (status !== ENUM_STATUS_WINDOW.stream) return;

    const constraints = {
      audio: { deviceId: micro ? { exact: micro } : undefined },
      video: {
        deviceId: camera ? { exact: camera } : undefined,
        width: resolution ? { ideal: +resolution.split("x")[0] } : undefined,
        height: resolution ? { ideal: +resolution.split("x")[1] } : undefined,
      },
    };

    if (!camera && !micro) {
      errorToast(
        t("Autostart is impossible, the microphone and camera are turned off in the settings")
      );
      return;
    }

    navigator.mediaDevices.getUserMedia(constraints).then(function (mediaStream) {
      setMyStream(mediaStream);
      console.log("stream", mediaStream);
      const newDevices = { videoId: camera, microId: micro, resolution: resolution };
      if (!TEST_MODE) offerStream(newDevices, !!camera, !!micro);
    });
  }, [autostart, chatActiveMode, status]);

  return <></>;
}
