import React, { useState } from "react";
import IconCross from "../../images/jsIcons/IconCross";
import IconMove from "../../images/jsIcons/IconMove";

import Draggable from "./Draggable";

export default function ControlsTop({
  children,
  style = {},
  className,
  controlsStyle = () => ({}),
  onClose,
  id,
}) {
  const [hide, setHide] = useState(false);

  return (
    <Draggable id={id} style={style} className={className}>
      {" "}
      <div className={"absolute__controls"} style={controlsStyle(hide)}>
        <button className={"absolute__controls__move"}>
          <IconMove />
        </button>
        <button className={"absolute__controls__hide"} onClick={() => setHide((prev) => !prev)}>
          <div className="icon-hide" />
        </button>
        {onClose && (
          <button className={"absolute__controls__off"} onClick={onClose}>
            <IconCross />
          </button>
        )}
      </div>
      <div style={{ display: hide ? "none" : "block" }}>{children}</div>
    </Draggable>
  );
}
