import React from "react";

export default function IconChatTips() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1133 11.1667C14.8933 12.9401 13.44 14.3934 11.6667 14.6134C10.5933 14.7467 9.59332 14.4534 8.81332 13.8801C8.36666 13.5534 8.47332 12.8601 9.00666 12.7001C11.0133 12.0934 12.5933 10.5067 13.2067 8.50006C13.3667 7.9734 14.06 7.86673 14.3867 8.30673C14.9533 9.0934 15.2467 10.0934 15.1133 11.1667Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M7.15943 1.33398C4.21943 1.33398 1.83276 3.72065 1.83276 6.66065C1.83276 9.60065 4.21943 11.9873 7.15943 11.9873C10.0994 11.9873 12.4861 9.60065 12.4861 6.66065C12.4794 3.72065 10.0994 1.33398 7.15943 1.33398ZM6.53276 5.91398L8.13943 6.47398C8.71943 6.68065 8.99943 7.08732 8.99943 7.71398C8.99943 8.43398 8.4261 9.02732 7.7261 9.02732H7.6661V9.06065C7.6661 9.33398 7.43943 9.56065 7.1661 9.56065C6.89276 9.56065 6.6661 9.33398 6.6661 9.06065V9.02065C5.9261 8.98732 5.33276 8.36732 5.33276 7.59398C5.33276 7.32065 5.55943 7.09398 5.83276 7.09398C6.1061 7.09398 6.33276 7.32065 6.33276 7.59398C6.33276 7.83398 6.5061 8.02732 6.71943 8.02732H7.71943C7.87276 8.02732 7.99276 7.88732 7.99276 7.71398C7.99276 7.48065 7.95276 7.46732 7.79943 7.41398L6.19276 6.85398C5.61943 6.65398 5.33276 6.24732 5.33276 5.61398C5.33276 4.89398 5.9061 4.30065 6.6061 4.30065H6.6661V4.27398C6.6661 4.00065 6.89276 3.77398 7.1661 3.77398C7.43943 3.77398 7.6661 4.00065 7.6661 4.27398V4.31398C8.4061 4.34732 8.99943 4.96732 8.99943 5.74065C8.99943 6.01398 8.77276 6.24065 8.49943 6.24065C8.2261 6.24065 7.99943 6.01398 7.99943 5.74065C7.99943 5.50065 7.8261 5.30732 7.61276 5.30732H6.61276C6.45943 5.30732 6.33943 5.44732 6.33943 5.62065C6.33276 5.84732 6.37276 5.86065 6.53276 5.91398Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  );
}
