import { useEffect } from "react";
import { getCHPYPapamByName } from "../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  ENUM_MODALS_VALUE,
  setFilter,
  setLoader,
  setModals,
  setShown,
  setSortModel,
  setTypeModel,
} from "../store/commonSlice";
import useUtilsBroadcaster from "./useUtilsBroadcaster";
import { setBroadcaster } from "../store/chatSlice";
import { useNavigate } from "react-router-dom";
import { errorToast } from "../components/mui/Toaster";
import { useTranslation } from "react-i18next";

const ENUM_LINK = {
  HOME: "home",
  RANDOM: "random",
  MODEL: "model",
  POPULAR: "popular5",
  GROWING: "growing",
};

const ENUM_OFFLINE = {
  home: "home",
  profile: "profile",
};

export default function useLinkParse() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { getBroadcasters } = useUtilsBroadcaster();

  const optionsSex = useSelector((state) => state.siteInfo.commonSiteInfo.options?.sex);
  const optionsShown = useSelector((state) => state.siteInfo.commonSiteInfo.options?.shown);
  const optionsFilters = useSelector((state) => state.siteInfo.commonSiteInfo.options?.filter);
  const currentSex = useSelector((state) => state.common.typeModel);
  const shown = useSelector((state) => state.common.shown);
  const filtersCategory = useSelector((state) => state.common.filter);

  const getRandomModel = async (filter, offline = true) => {
    const broadcastersAll = await getBroadcasters(filter);
    const broadcasters = broadcastersAll.filter((el) => el.online);
    const length = broadcasters.length;

    if (length) {
      const random = +(Math.random() * (length - 1)).toFixed();
      const broadcastersRandom = broadcasters[random];
      console.log("broadcastersRandom", broadcastersRandom);
      if (broadcastersRandom) {
        console.log(broadcastersRandom.name);
        const name = broadcastersRandom.name;
        setBroadcaster(broadcastersRandom);
        navigate("/chat/" + name);
        return;
      }
    }

    if (offline) {
      const length = broadcastersAll.length;
      const random = +(Math.random() * length).toFixed();
      const broadcastersRandom = broadcastersAll[random];
      if (broadcastersRandom) {
        console.log(broadcastersRandom.name);
        const name = broadcastersRandom.name;
        setBroadcaster(broadcastersRandom);
        navigate("/chat/" + name);
      }
    }
  };

  const logicByLink = async (link) => {
    const section = getCHPYPapamByName("section");
    const offline = getCHPYPapamByName("offline");
    const sectionValue = optionsSex.find((el) => el.url === section)?.value;

    if ([ENUM_LINK.POPULAR, ENUM_LINK.GROWING].includes(link)) {
      if (sectionValue) dispatch(setTypeModel(sectionValue));
    }

    if (link === ENUM_LINK.POPULAR) {
      console.log("popular");
    }
    if (link === ENUM_LINK.GROWING) {
      console.log("GROWING");
    }
    if (link === ENUM_LINK.RANDOM) {
      getRandomModel({ categories: [sectionValue] });
    }

    console.log("link", link);

    if (link === ENUM_LINK.MODEL) {
      const model = getCHPYPapamByName("model");
      console.log("offline", offline);
      if (offline === ENUM_OFFLINE.profile) {
        if (model) {
          errorToast(t("No model nick"));
          return;
        }
        navigate("/chat/" + model);
      }
    }
  };

  const logicByShown = async (showString) => {
    const shownNameList = showString.split(",");
    const values = optionsShown.filter((el) => shownNameList.includes(el.url)).map((el) => el.id);
    if (values.length) dispatch(setShown(values));
  };

  const logicByFilters = async () => {
    const popularStringList = getCHPYPapamByName("filters:popular")?.split(",");
    const ageStringList = getCHPYPapamByName("filters:age")?.split(",");
    const regionStringList = getCHPYPapamByName("filters:region")?.split(",");
    const languageStringList = getCHPYPapamByName("filters:language")?.split(",");
    const appearanceStringList = getCHPYPapamByName("filters:appearance")?.split(",");

    const popularList = optionsFilters.find((el) => el.url === "popular").children;
    const ageList = optionsFilters.find((el) => el.url === "age").children;
    const regionList = optionsFilters.find((el) => el.url === "region").children;
    const languageList = optionsFilters.find((el) => el.url === "language").children;
    const appearanceList = optionsFilters.find((el) => el.url === "appearance").children;

    const popular = popularList
      .filter((el) => popularStringList?.includes(el.url))
      .map((el) => el.value);

    const age = ageList.filter((el) => ageStringList?.includes(el.url)).map((el) => el.value);

    const region = regionList
      .filter((el) => regionStringList?.includes(el.url))
      .map((el) => el.value);

    const language = languageList
      .filter((el) => languageStringList?.includes(el.url))
      .map((el) => el.value);

    const appearance = appearanceList
      .filter((el) => appearanceStringList?.includes(el.url))
      .map((el) => el.value);

    const commonFilters = [...popular, ...age, ...region, ...language, ...appearance];

    console.log("commonFilters", commonFilters);

    if (commonFilters.length) {
      dispatch(setFilter(commonFilters));
    }
  };

  const logicBySort = async (sortName) => dispatch(setSortModel(sortName));

  const checkSignUp = async () => {
    const pathname = window.location.pathname;
    if (pathname.search("signup") < 0) return;

    dispatch(setModals({ name: ENUM_MODALS_VALUE.register, value: true }));
  };

  const checkToHome = async (pathname) => {
    if (pathname.search("to:home") < 0) return;
    // navigate("/");
  };

  const checkToRandom = async (pathname) => {
    if (pathname.search("to:chat:random") < 0) return;
    dispatch(setLoader(true));
    await getRandomModel({ categories: [currentSex, ...shown, ...filtersCategory] });
    dispatch(setLoader(false));
  };

  // сначала берём весь список моделей и выбираем кто из них в офлайне, на оставшихся делаем запрос и переходим к ней, если все в офлайне то рандом
  const goToModelList = async (chatStrings) => {
    const modelListId = decodeURIComponent(chatStrings).split(",");
    const broadcastersAll = await getBroadcasters({});

    const offlineList = broadcastersAll.filter(
      (el) => !el.online && (modelListId.includes("" + el.id) || modelListId.includes(el.name))
    );

    const offlineId = offlineList.map((el) => "" + el.id);
    const offlineName = offlineList.map((el) => "" + el.name);

    for (let i = 0; i < modelListId.length; i++) {
      const modelId = modelListId[i];
      if (offlineId.includes(modelId) || offlineName.includes(modelId)) {
        continue;
      }

      const filter = +modelId ? { broadcaster_id: +modelId } : { nickname: modelId };

      const broadcaster = (await getBroadcasters(filter))?.[0];
      if (!broadcaster?.online) continue;
      navigate("/chat/" + modelId);
      return;
    }

    getRandomModel({ categories: [currentSex, ...shown, ...filtersCategory] });
  };

  const checkToLink = async () => {
    checkToHome(window.location.pathname);
    checkToRandom(window.location.pathname);
    const chatStrings = getCHPYPapamByName("to:chat");
    if (chatStrings) {
      dispatch(setLoader(true));
      await goToModelList(chatStrings);
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    const campaignId = getCHPYPapamByName("campaign");
    const link = getCHPYPapamByName("link");
    const show = getCHPYPapamByName("show");
    const sort = getCHPYPapamByName("sort-by");

    if (link) logicByLink(link);
    if (show) logicByShown(show);
    if (sort) logicBySort(sort);

    checkSignUp();
    checkToLink();
    logicByFilters();

    localStorage.setItem("campaignId", campaignId);
  }, [optionsFilters]);
}
