import React from "react";
import IconVibrator from "../../../../images/jsIcons/IconVibrator";

export default function MesVibro({ message }) {
  return (
    <div className="chat__free__message__vibro">
      <span className="chat__free__message__vibro__green">
        <IconVibrator />
        {message.power}
      </span>
      vibratotion
      <span className="chat__free__message__vibro__delay">3 sec</span>
      by {message.nickName}
    </div>
  );
}
