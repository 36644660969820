import React from "react";

export default function IconCameraOff2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
    >
      <path
        d="M102.243 96.1742L76.8316 70.7633C77.3809 68.8535 77.75 66.8965 77.75 64.8751V39.1251C77.75 27.293 68.1238 17.6668 56.2917 17.6668H23.7351L7.83017 1.75755C6.15213 0.079513 3.4355 0.079513 1.75746 1.75755C0.0794167 3.4356 0.0837084 6.14793 1.75746 7.82597L18.9241 24.9926L96.1741 102.243C97.011 103.08 98.1097 103.5 99.2083 103.5C100.307 103.5 101.406 103.08 102.243 102.243C103.921 100.565 103.921 97.8523 102.243 96.1742ZM65.8106 84.0159C62.9309 85.4536 59.7293 86.3334 56.2917 86.3334H21.9583C10.109 86.3334 0.5 76.7244 0.5 64.8751V39.1251C0.5 33.4515 2.73167 28.3358 6.29804 24.5034L65.8063 84.0159H65.8106ZM103.5 36.1767V67.5874C103.5 70.8362 101.698 73.7546 98.792 75.2051C97.5775 75.8103 96.2728 76.1107 94.9768 76.1107C93.17 76.1107 91.3846 75.5313 89.874 74.3983C89.7152 74.2781 86.3333 70.9221 86.3333 70.9221V32.8764C86.3333 32.8764 89.7066 29.4903 89.874 29.3658C92.4661 27.4174 95.878 27.1041 98.792 28.559C101.706 30.0139 103.5 32.9279 103.5 36.1767Z"
        fill="url(#paint0_linear_740_18226)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_740_18226"
          x1="52.0005"
          y1="0.499023"
          x2="49.9544"
          y2="133.555"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
