import React from "react";

export default function IconPen() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <path
        d="M28.1751 10.9889L24.2668 7.08058C23.7567 6.60145 23.0883 6.32653 22.3887 6.30812C21.6891 6.28971 21.0072 6.52909 20.4726 6.98073L7.63503 19.8183C7.17397 20.2832 6.88689 20.8927 6.82198 21.5442L6.20863 27.4923C6.18942 27.7012 6.21653 27.9118 6.28803 28.1091C6.35953 28.3063 6.47366 28.4853 6.62229 28.6334C6.75557 28.7656 6.91364 28.8702 7.08743 28.9412C7.26121 29.0122 7.4473 29.0482 7.63503 29.0471H7.7634L13.7115 28.505C14.363 28.4401 14.9725 28.1531 15.4374 27.692L28.275 14.8544C28.7732 14.3281 29.0425 13.6256 29.0238 12.901C29.0051 12.1765 28.6999 11.4889 28.1751 10.9889ZM23.3254 15.7531L19.5026 11.9303L22.2841 9.07753L26.1782 12.9716L23.3254 15.7531Z"
        fill="white"
      />
    </svg>
  );
}
