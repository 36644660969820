export const SIGN_UP = "Sign up";
export const LOGIN = "Login";
export const SITE_NAME = "RichCams";
export const MAX_SCALE = 6;
export const isBonusForNewUsers = false;

export const TEST_MODE =
  window.location.hostname === "localhost" ||
  window.location.hostname.startsWith("192.168.") ||
  window.location.hostname.startsWith("10.");

const startConfig = window.startConfig;
export const configSetting = startConfig?.settings;

export const IS_SKIP_INTO = configSetting?.url?.find((el) => el.path.includes("skip-intro"))?.value;
export const CAMPAIGN_ID = configSetting?.url?.find((el) => el.path.includes("campaign_id"))?.value;
export const CAPTCHA = startConfig?.settings?.captcha;
export const SITE = startConfig?.site || {
  // export const SITE = {
  enabled: true,
  title: "dev.cam4fan.com",
  categories: [
    {
      title: "Gender",
      id: 1,
      url: "gender",
      subcategories: [
        {
          title: "All models",
          id: 15,
          url: "all",
        },
        {
          title: "Girls",
          id: 11,
          url: "girls",
        },
        {
          title: "Guys",
          id: 12,
          url: "guys",
        },
        {
          title: "Trans",
          id: 16,
          url: "trans",
        },
      ],
    },
    {
      id: 4,
      url: "show",
      subcategories: [
        {
          title: "Outdoors",
          keywords: "Outdoors",
          id: 8,
          url: "outdoors",
        },
        {
          title: "Vibrator",
          keywords: "Vibrator",
          id: 17,
          url: "vibrator",
        },
        {
          title: "Dildo",
          keywords: "Dildo",
          id: 18,
          url: "dildo",
        },
        {
          title: "Couples",
          keywords: "Couples",
          id: 19,
          url: "couples",
        },
        {
          title: "Spy Private",
          keywords: "Spy Private",
          id: 20,
          url: "spy-private",
        },
      ],
    },
    {
      title: "Filters",
      id: 5,
      url: "filters",
      subcategories: [
        {
          title: "Popular",
          keywords: "Popular",
          id: 9,
          url: "popular",
          subcategories: [
            {
              title: "Group Sex",
              keywords: "Group Sex",
              id: 24,
              url: "group-sex",
            },
            {
              title: "Big tits",
              keywords: "Big tits",
              id: 25,
              url: "big-tits",
            },
            {
              title: "Anal",
              keywords: "Anal",
              id: 32,
              url: "anal",
            },
            {
              title: "Big Ass",
              keywords: "Big Ass",
              id: 33,
              url: "big-ass",
            },
            {
              title: "Lesbians",
              keywords: "Lesbians",
              id: 34,
              url: "lesbians",
            },
            {
              title: "Pregnant",
              keywords: "Pregnant",
              id: 35,
              url: "pregnant",
            },
            {
              title: "Squirt",
              keywords: "Squirt",
              id: 36,
              url: "squirt",
            },
            {
              title: "Hardcore",
              keywords: "Hardcore",
              id: 37,
              url: "hardcore",
            },
            {
              title: "Blowjob",
              keywords: "Blowjob",
              id: 38,
              url: "blowjob",
            },
            {
              title: "BDSM",
              keywords: "BDSM",
              id: 39,
              url: "bdsm",
            },
          ],
        },
        {
          title: "Age",
          id: 78,
          url: "age",
          subcategories: [
            {
              title: "18-22",
              keywords: "18-22",
              id: 79,
              url: "18-22",
            },
            {
              title: "23-30",
              keywords: "23-30",
              id: 80,
              url: "23-30",
            },
            {
              title: "31-45",
              keywords: "31-45",
              id: 81,
              url: "31-45",
            },
            {
              title: "45+",
              keywords: "45+",
              id: 82,
              url: "46-99",
            },
          ],
        },
        {
          title: "Region",
          keywords: "Region",
          id: 10,
          url: "region",
          subcategories: [
            {
              title: "North America / UK / Australia",
              keywords: "USA, Canada, UK, Australia",
              id: 40,
              url: "usa-uk",
            },
            {
              title: "Europe",
              keywords: "Europe",
              id: 41,
              url: "eu",
            },
            {
              title: "Russian / Ukraine",
              keywords: "Russian / Ukraine",
              id: 42,
              url: "cis",
            },
            {
              title: "Latin America",
              keywords: "Latin America",
              id: 43,
              url: "latin",
            },
            {
              title: "Asia",
              keywords: "Asia",
              id: 44,
              url: "asia",
            },
            {
              title: "Other",
              keywords: "Other",
              id: 45,
              url: "other",
            },
          ],
        },
        {
          title: "Language",
          keywords: "Language",
          id: 22,
          url: "language",
          subcategories: [
            {
              title: "English",
              keywords: "English",
              id: 46,
              url: "en",
            },
            {
              title: "French",
              keywords: "French",
              id: 88,
              url: "fr",
            },
            {
              title: "Spanish",
              keywords: "Spanish",
              id: 89,
              url: "es",
            },
            {
              title: "Russian",
              keywords: "Russian",
              id: 90,
              url: "ru",
            },
            {
              title: "German",
              keywords: "German",
              id: 91,
              url: "de",
            },
            {
              title: "Italian",
              keywords: "Italian",
              id: 92,
              url: "it",
            },
            {
              title: "Swedish",
              keywords: "Swedish",
              id: 93,
              url: "sw",
            },
            {
              title: "Portuguese",
              keywords: "Portuguese",
              id: 94,
              url: "pt",
            },
            {
              title: "Dutch",
              keywords: "Dutch",
              id: 95,
              url: "da",
            },
            {
              title: "Other",
              keywords: "Other",
              id: 96,
              url: "other",
            },
          ],
        },
        {
          title: "Appearance",
          keywords: "Appearance",
          id: 23,
          url: "appearance",
          subcategories: [
            {
              title: "Body",
              keywords: "Body",
              id: 47,
              url: "body",
              subcategories: [
                {
                  title: "Thin",
                  keywords: "Thin",
                  id: 48,
                  url: "thin",
                },
                {
                  title: "Regular",
                  keywords: "Regular",
                  id: 49,
                  url: "regular",
                },
                {
                  title: "Athletic",
                  keywords: "Athletic",
                  id: 50,
                  url: "athletic",
                },
                {
                  title: "A Few Extra Pounds",
                  keywords: "A Few Extra Pounds",
                  id: 51,
                  url: "extra",
                },
                {
                  title: "Big & Tall / BBW",
                  keywords: "Big & Tall / BBW",
                  id: 52,
                  url: "bbw",
                },
              ],
            },
            {
              title: "Breasts",
              keywords: "Breasts",
              id: 53,
              url: "breasts",
              subcategories: [
                {
                  title: "Small",
                  keywords: "Small",
                  id: 54,
                  url: "small",
                },
                {
                  title: "Medium",
                  keywords: "Medium",
                  id: 55,
                  url: "medium",
                },
                {
                  title: "Large",
                  keywords: "Large",
                  id: 56,
                  url: "large",
                },
                {
                  title: "Huge",
                  keywords: "Huge",
                  id: 57,
                  url: "huge",
                },
              ],
            },
            {
              title: "Butt size",
              keywords: "Butt size",
              id: 58,
              url: "butt",
              subcategories: [
                {
                  title: "Small",
                  keywords: "Small",
                  id: 59,
                  url: "small",
                },
                {
                  title: "Medium",
                  keywords: "Medium",
                  id: 60,
                  url: "medium",
                },
                {
                  title: "Big ass",
                  keywords: "Big ass",
                  id: 61,
                  url: "big",
                },
              ],
            },
            {
              title: "Ethnicity",
              keywords: "Ethnicity",
              id: 62,
              url: "ethnicity",
              subcategories: [
                {
                  title: "Asia",
                  keywords: "Asia",
                  id: 63,
                  url: "asia",
                },
                {
                  title: "White / Caucasian",
                  keywords: "White / Caucasian",
                  id: 64,
                  url: "caucasian",
                },
                {
                  title: "Ebony",
                  keywords: "Ebony",
                  id: 65,
                  url: "ebony",
                },
                {
                  title: "Latino / Hispanic",
                  keywords: "Latino / Hispanic",
                  id: 66,
                  url: "latino",
                },
                {
                  title: "Indian",
                  keywords: "Indian",
                  id: 67,
                  url: "indian",
                },
                {
                  title: "Arab",
                  keywords: "Arab",
                  id: 68,
                  url: "arab",
                },
              ],
            },
            {
              title: "Hair",
              keywords: "Hair",
              id: 69,
              url: "hair",
              subcategories: [
                {
                  title: "Blonde",
                  keywords: "Blonde",
                  id: 70,
                  url: "blonde",
                },
                {
                  title: "Brunette",
                  keywords: "Brunette",
                  id: 71,
                  url: "brunette",
                },
                {
                  title: "Redhead",
                  keywords: "Redhead",
                  id: 72,
                  url: "redhead",
                },
                {
                  title: "Bald",
                  keywords: "Bald",
                  id: 73,
                  url: "bald",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Sort by",
      keywords: "Sort by",
      id: 21,
      url: "sort-by",
      subcategories: [
        {
          name: "Popular rooms",
          title: "Most popular chat rooms",
          keywords: "Most popular chat rooms",
          id: 26,
          url: "popular-chat-rooms",
        },
        {
          name: "Highest score",
          title: "Highest score",
          keywords: "Highest score",
          id: 74,
          url: "highest",
        },
        {
          name: "New models",
          title: "New models",
          keywords: "New models",
          id: 75,
          url: "new-models",
        },
        {
          name: "New Rooms",
          title: "New Rooms",
          keywords: "New Rooms",
          id: 76,
          url: "new-rooms",
        },
        {
          name: "My Favorites",
          title: "My Favorites",
          keywords: "My Favorites",
          id: 77,
          url: "favorites",
        },
      ],
    },
  ],
  headTags: "\n",
  metaTags: [],
  ogImage: "",
  theme: {
    frontend: {
      name: "default",
      color: "green",
    },
    client: {
      name: "default",
      color: "",
    },
    broadcaster: {
      name: "default",
      color: "",
    },
  },
};

const TEST_USER = {
  id: 180,
  // id: 0,
  registered: "2023-10-28 20:10:29",
  nickname: "dirahi4449",
  avatar: "/var/www/vhosts/dev.cam4fan.com/httpdocs/gallery/181/avatar/",
  type: "client",
  favorites: [6, 6, 6, 6, 6, 6, 6, 6, 4, 5, 6],
  premium: false,
  group: {
    id: 2,
    group_id: 1,
    enabled: true,
    title: "Webcam Sites",
    balance: 0,
    rights: [],
  },
  profile: [],
  status: "unconfirmed",
  balance: 9999999990,
  email: "dirahi4449@wanbeiz.com",
  email_confirmed: true,
  confirmed: true,
  personal_information_confirmed: false,
  approve_status: "unconfirmed",
  rights: [],
};

export const USER = TEST_MODE ? TEST_USER : startConfig?.user || {};
export const SITE_KEY = CAPTCHA?.["site-key"];
export const debugLog = true;
