import { API_URL_PAYMENT } from "./main";
import { cusAxios } from "./main";

const providers = async () => {
  let response = await cusAxios({
    method: "POST",
    url: `${API_URL_PAYMENT}/providers`,
  });
  return response;
};

const buy = async (id) => {
  let response = await cusAxios({
    method: "POST",
    url: `/api/user/payment/package/${id}/buy`,
    data: {},
  });
  return response;
};

export const API_PAYMENT = {
  providers: providers,
  buy: buy,
};
