import React from "react";
import { useTranslation } from "react-i18next";
import IconCircleArrow from "../../images/jsIcons/IconCircleArrow";

export default function NewMessagePlug({ onClick }) {
  const { t } = useTranslation();

  return (
    <div className="new-message" onClick={onClick}>
      <span className="new-message__text">{t("New Messages")}</span>
      <IconCircleArrow />
    </div>
  );
}
