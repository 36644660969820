"use client";

import { Segmented } from "antd";
import React, { useEffect, useState } from "react";
import ChoseLang from "../mui/ChoseLang";
import CusFind from "../mui/CusFind";
import SignUpModal from "../common/SignUpModal";
import { useDispatch, useSelector } from "react-redux";
import { setNickName, setTypeModel } from "../../store/commonSlice";
import useDeviceSize from "../../hooks/useDeviceSize";
import IconFilters from "../../images/jsIcons/IconFilters";
import HeaderMobileMenu from "./HeaderMobileMenu";
import IconCross from "../../images/jsIcons/IconCross";
import HeaderButton from "./HeaderButton";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Logo from "../mui/Logo";

export default function Header() {
  const navigate = useNavigate();
  const modelTypes = useSelector((state) => state.common.typeModel);
  const nickname = useSelector((state) => state.common.nickname);
  const user = useSelector((state) => state.user.user?.id);
  const optionsSex = useSelector((state) => state.siteInfo.commonSiteInfo.options?.sex);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { mobileHeader, width } = useDeviceSize();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const createTemplateSegment = (icon, title = "") => (
    <div className="header__segment__label">
      {icon}
      <span>{title}</span>
    </div>
  );

  const onSegmentedChange = (e) => {
    dispatch(setTypeModel(e));
  };

  useEffect(() => {
    const value = optionsSex.find((el) => el.selected)?.value;
    if (value) dispatch(setTypeModel(value));
  }, [optionsSex]);

  const segmentType = useMemo(
    () =>
      optionsSex.map((el) => ({
        label: createTemplateSegment(
          el.icon,
          width < 820 && width > 640 ? el.titleShort : el.title
        ),
        value: "" + el.value,
      })),
    [optionsSex, width]
  );

  const findPlaceholder = user && width < 1480 ? "By nickname" : "Sort a model by nickname";

  return (
    <>
      <header className={`header ${user ? "authorized" : ""}`}>
        <div className={"header__content"}>
          <Logo className="header__logo" />

          <div className={`header__segment ${mobileHeader ? "top-segment" : ""} `}>
            <Segmented
              onChange={onSegmentedChange}
              options={segmentType}
              value={"" + modelTypes}
              rootClassName="header__segment__root"
              classID="header__segment__id"
              onClick={() => setTimeout(() => navigate("/"), 500)}
              className="header__segment__some"
            />
          </div>
          {!mobileHeader && (
            <div className="header__find" onClick={() => navigate("/")}>
              <CusFind
                value={nickname}
                // TODO добавить оптимизацию
                onChange={(e) => dispatch(setNickName(e.target.value))}
                placeholder={t(findPlaceholder)}
              />
            </div>
          )}
          {!mobileHeader ? (
            <>
              <div className="header__lang">
                <ChoseLang />
              </div>
              <HeaderButton setOpenLoginModal={setOpenLoginModal} />
            </>
          ) : (
            // блок кнопки на десктопе для авторизированного или нет
            <>
              <HeaderButton setOpenLoginModal={setOpenLoginModal} />
              <button
                title="filters"
                className="button-filters"
                onClick={() => setOpenMobileMenu((prev) => !prev)}
              >
                {openMobileMenu ? <IconCross /> : <IconFilters />}
              </button>
            </>
          )}
        </div>
        {mobileHeader && (
          <div className="header__content-two">
            <div className="header__segment mobile-segment">
              <Segmented
                onChange={(e) => dispatch(setTypeModel(e))}
                value={"" + modelTypes}
                rootClassName="header__segment__root"
                classID="header__segment__id"
                className="header__segment__some"
                options={segmentType}
              />
            </div>
          </div>
        )}
      </header>
      <SignUpModal isOpen={!!openLoginModal} type={openLoginModal} setOpen={setOpenLoginModal} />
      <HeaderMobileMenu isOpen={openMobileMenu} closeModal={() => setOpenMobileMenu(false)} />
    </>
  );
}
