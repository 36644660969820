import axios from "axios";
import { API_URL_USER } from "./main";
import { getCHPYPapamByName } from "../utils/utils";

const register = async (data) => {
  const campaignId = +getCHPYPapamByName("campaign_id") || +localStorage.getItem("campaignId");
  const campaignOptions = JSON.parse(localStorage.getItem("campaignOptions"));

  const tempData = { ...data, options: campaignOptions };
  if (campaignId) {
    data.campaign_id = campaignId;
  }

  let response = await axios({
    method: "POST",
    url: `${API_URL_USER}/join`,
    data: tempData,
  });
  return response;
};

const login = async (data) => {
  let response = await axios({
    method: "POST",
    url: `${API_URL_USER}/login`,
    data: data,
  });
  return response;
};

const confirm = async (data) => {
  let response = await axios({
    method: "POST",
    url: `${API_URL_USER}/email/confirm`,
    data: data,
  });
  return response;
};

const logout = async (data) => {
  let response = await axios({
    method: "GET",
    url: `${API_URL_USER}/logout`,
    data: data,
  });
  return response;
};

const resetPas = async (data) => {
  const URL = `${API_URL_USER}/password/reset`;

  let response = await axios({
    method: "POST",
    url: URL,
    data: data,
  });
  return response;
};
const changePas = async (data) => {
  const URL = `${API_URL_USER}/password`;

  let response = await axios({
    method: "POST",
    url: URL,
    data: data,
  });
  return response;
};

export const changeEmail = async (data) => {
  const URL = `${API_URL_USER}/email/change`;

  let response = await axios({
    method: "POST",
    url: URL,
    data: data,
  });
  return response;
};
export const API_LOGIN = {
  login: login,
  register: register,
  confirm: confirm,
  logout: logout,
  resetPas: resetPas,
  changePas: changePas,
  changeEmail: changeEmail,
};
