import React, { useEffect, useMemo, useState } from "react";
import profileDefault from "../../images/mui/profile.svg";
import genderDefault from "../../images/template/no-img.svg";
import useAvatar from "../../hooks/useAvatar";

export const ENUM_PLUG = {
  gender: "gender",
  rabbit: "rabbit",
};
export const NONE_AVATAR = "none";

const plugToId = {
  [ENUM_PLUG.gender]: genderDefault,
  [ENUM_PLUG.rabbit]: profileDefault,
};

const ImgAvatar = ({
  className = "avatar__btn",
  avatarProps,
  autoWidthImg,
  plug = ENUM_PLUG.rabbit,
  id,
}) => {
  const { avatar, updateAvatar } = useAvatar({ id, url: avatarProps });
  const defaultSource = useMemo(() => avatar || plugToId[plug], [plug, avatar]);
  const [imgSource, setImgSource] = useState(defaultSource);

  useEffect(() => {
    setImgSource(avatar);
  }, [avatar]);

  useEffect(() => {
    updateAvatar();
  }, [id, avatarProps]);

  return (
    <div className={`${className}  avatar-rect`}>
      <div className="avatar-rect__img-container">
        <img
          width={autoWidthImg ? "auto" : "100%"}
          src={imgSource}
          onError={() => setImgSource(profileDefault)}
        />
      </div>
    </div>
  );
};

export default React.memo(ImgAvatar);
