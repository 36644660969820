import React from "react";

export default function IconDerails() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_207_32702)">
        <path
          d="M6.60938 11.9897C6.60938 11.2593 6.75572 10.5624 7.02014 9.92649L1.26877 6.60596C0.438984 8.256 0 10.0891 0 11.9897C0 15.1951 1.24823 18.2085 3.51469 20.475C4.09678 21.0571 4.72852 21.5716 5.39977 22.0155L8.72006 16.2645C7.43756 15.2782 6.60938 13.729 6.60938 11.9897Z"
          fill="url(#paint0_linear_207_32702)"
        />
        <path
          d="M3.51477 3.50452C2.93268 4.08662 2.41827 4.71835 1.97437 5.3896L7.72527 8.70985C8.57647 7.60309 9.84688 6.83476 11.297 6.64501V0.0107422C8.35607 0.179586 5.61477 1.40462 3.51477 3.50452Z"
          fill="url(#paint1_linear_207_32702)"
        />
        <path
          d="M20.4853 3.50452C18.3853 1.40462 15.644 0.179586 12.7031 0.0107422V6.64501C15.3445 6.99062 17.3906 9.25566 17.3906 11.9898C17.3906 14.9622 14.9724 17.3804 12 17.3804C11.2695 17.3804 10.5726 17.2341 9.93674 16.9696L6.61621 22.721C8.26621 23.5509 10.0994 23.9898 12 23.9898C15.2053 23.9898 18.2188 22.7416 20.4853 20.4751C22.7518 18.2086 24 15.1951 24 11.9898C24 8.78448 22.7518 5.77102 20.4853 3.50452Z"
          fill="url(#paint2_linear_207_32702)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_207_32702"
          x1="4.36003"
          y1="6.60596"
          x2="4.36003"
          y2="23.2996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_207_32702"
          x1="6.63566"
          y1="0.0107422"
          x2="6.63566"
          y2="9.43478"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_207_32702"
          x1="15.3081"
          y1="0.0107422"
          x2="15.3081"
          y2="25.988"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_207_32702">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
