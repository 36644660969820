import React from "react";

export default function IconProfile() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M7 0C3.13441 0 0 3.13393 0 7C0 10.8661 3.1341 14 7 14C10.8662 14 14 10.8661 14 7C14 3.13393 10.8662 0 7 0ZM7 2.09308C8.2791 2.09308 9.3156 3.12994 9.3156 4.40848C9.3156 5.68733 8.2791 6.72387 7 6.72387C5.72151 6.72387 4.68501 5.68733 4.68501 4.40848C4.68501 3.12994 5.72151 2.09308 7 2.09308ZM6.99846 12.1698C5.72274 12.1698 4.55434 11.7052 3.65312 10.9362C3.43358 10.7489 3.3069 10.4743 3.3069 10.1862C3.3069 8.88952 4.35632 7.85175 5.65325 7.85175H8.34736C9.6446 7.85175 10.69 8.88952 10.69 10.1862C10.69 10.4746 10.564 10.7486 10.3441 10.9359C9.4432 11.7052 8.27449 12.1698 6.99846 12.1698Z"
        fill="url(#paint0_linear_207_32425)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_207_32425"
          x1="7"
          y1="0"
          x2="7"
          y2="15.1667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
