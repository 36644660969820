import { DatePicker } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { subtractYears } from "../../utils/utils";

export default function CusDatePicker({ label, onChange, setDate, defaultValue, value }) {
  const { t } = useTranslation();

  return (
    <div className="container-date-picker">
      <label className="container-date-picker__label">{t(label)}</label>
      <DatePicker
        disabledDate={(d) => d.isAfter(dayjs(subtractYears(18)))}
        className="cus-date-picker"
        popupClassName="popup_cus-date-picker"
        defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
        value={value ? dayjs(value) : undefined}
        onChange={(e) => {
          const date = e?.format("YYYY-MM-DD");
          if (setDate) setDate(date);
          if (onChange) onChange(e);
        }}
      />
    </div>
  );
}
