import React, { useMemo } from "react";
import CusButton from "../../../../mui/CusButton";
import IconChatTips from "../../../../../images/jsIcons/IconChatTips";
import { useSelector } from "react-redux";
import { useLocalStorage } from "usehooks-ts";

import usePaidAction from "../../../../../hooks/usePaidAction";
import ShownControl from "../../../../mui/ShownControl";
import useJanus from "../../../../../hooks/useJanus";
import { useTranslation } from "react-i18next";
import { ENUM_STATUS_WINDOW } from "../../../../../store/chatSlice";

export default function SendTips() {
  const { postTips } = useJanus({});
  const [lastAmountFull] = useLocalStorage("lastAmount", {});
  const { onPaidActionClick } = usePaidAction();
  const { t } = useTranslation();

  const { nickname } = useSelector((state) => state.user.user);
  const statusWindow = useSelector((state) => state.chat.statusWindow);

  const lastAmount = useMemo(() => lastAmountFull.value, [lastAmountFull]);
  const btnText = useMemo(
    () => `${t("Send")} ${lastAmount ? lastAmount + ` ${t("tk")}` : ""} `,
    [lastAmount]
  );
  const isOffline = useMemo(() => statusWindow === ENUM_STATUS_WINDOW.offline, [statusWindow]);

  const onPostTip = () => {
    postTips({ ...lastAmountFull, nickname: nickname });
  };

  return (
    <ShownControl>
      {!isOffline && (
        <CusButton
          text={btnText}
          icon={<IconChatTips />}
          color="red"
          onClick={() => onPaidActionClick(lastAmount, onPostTip)}
          className="send-tips"
          disabled={!lastAmount}
        />
      )}
    </ShownControl>
  );
}
