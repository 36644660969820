import React from "react";

export default function IconGallery() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
      <g clipPath="url(#clip0_207_33206)">
        <path
          d="M13.0199 38.6068C9.68459 38.6068 6.71783 36.4713 5.64053 33.292L5.56767 33.0525C5.3136 32.2105 5.20717 31.5025 5.20717 30.7941V16.5894L0.152955 33.4606C-0.497086 35.9422 0.984198 38.5149 3.46953 39.2004L35.6851 47.8279C36.0871 47.9321 36.4892 47.982 36.8852 47.982C38.9601 47.982 40.856 46.6049 41.3874 44.5754L43.2643 38.6068H13.0199Z"
          fill="#CAADAD"
        />
        <path
          d="M18.7499 16.7322C21.0479 16.7322 22.9164 14.8634 22.9164 12.5653C22.9164 10.2673 21.0479 8.39844 18.7499 8.39844C16.4519 8.39844 14.583 10.2673 14.583 12.5653C14.583 14.8634 16.4519 16.7322 18.7499 16.7322Z"
          fill="#CAADAD"
        />
        <path
          d="M44.7921 2.14844H13.5413C10.6707 2.14844 8.33301 4.48614 8.33301 7.35715V30.2741C8.33301 33.1451 10.6707 35.4828 13.5413 35.4828H44.7921C47.6631 35.4828 50.0008 33.1451 50.0008 30.2741V7.35715C50.0008 4.48614 47.6631 2.14844 44.7921 2.14844ZM13.5413 6.31533H44.7921C45.3674 6.31533 45.8339 6.78188 45.8339 7.35715V22.1471L39.2527 14.4675C38.5545 13.6489 37.544 13.2113 36.4587 13.1865C35.3795 13.1926 34.367 13.6718 33.6754 14.5011L25.9375 23.7886L23.4167 21.2739C21.9919 19.8491 19.6729 19.8491 18.2499 21.2739L12.4999 27.022V7.35715C12.4999 6.78188 12.9665 6.31533 13.5413 6.31533Z"
          fill="#CAADAD"
        />
      </g>
      <defs>
        <clipPath id="clip0_207_33206">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
