import React from "react";

export default function IconDepositCheck() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3H6C3.8 3 2 4.8 2 7C2 8.7 3 10.1 4.5 10.7V7C4.5 6.6 4.7 6.2 4.9 5.9C5.1 5.6 5.6 5.5 6 5.5H18C18.4 5.5 18.8 5.7 19.1 5.9C19.4 6.1 19.5 6.6 19.5 7V10.7C21 10.1 22 8.7 22 7C22 4.8 20.2 3 18 3ZM18 19V7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19ZM11.2 12.4L10.5 13.1C10.2 13.4 9.7 13.4 9.4 13.1C9.1 12.8 9.1 12.3 9.4 12L10.7 10.7C11.4 10 12.5 10 13.2 10.7L14.5 12C14.8 12.3 14.8 12.8 14.5 13.1C14.2 13.4 13.7 13.4 13.4 13.1L12.7 12.4V16.6C12.7 17 12.4 17.4 11.9 17.4C11.4 17.4 11.1 17.1 11.1 16.6V12.4H11.2Z"
        fill="url(#paint0_linear_288_8157)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_288_8157"
          x1="12"
          y1="3"
          x2="12"
          y2="22.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
