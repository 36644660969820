import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ENUM_STATUS_WINDOW, setLoadingBroadcaster } from "../store/chatSlice";
import { TEST_MODE } from "../components/common/consts";

const MAX_ARTIFICiAL_TIME = 15 * 1000;

export default function useListenerJanusMode() {
  const statusWindow = useSelector((state) => state.chat.statusWindow);
  const isLoading = useSelector((state) => state.chat.loadingBroadcaster);

  const dispatch = useDispatch();
  const prevStatus = useRef(statusWindow);

  const timeoutLoadingRef = useRef();

  const setLoading = () => {
    console.log("setLoadingBroadcaster", statusWindow === ENUM_STATUS_WINDOW.loading);
    dispatch(setLoadingBroadcaster(statusWindow === ENUM_STATUS_WINDOW.loading));
  };

  useEffect(() => {
    if (TEST_MODE) return;

    const isLoading =
      prevStatus.current !== ENUM_STATUS_WINDOW.stream &&
      statusWindow === ENUM_STATUS_WINDOW.stream;

    clearTimeout(timeoutLoadingRef.current);
    console.log("isLoading", prevStatus.current, statusWindow);
    if (isLoading) {
      console.log("setLoadingBroadcaster", true);
      dispatch(setLoadingBroadcaster(true));
      timeoutLoadingRef.current = setTimeout(() => {
        console.log("end loader by timeout");
        setLoading();
      }, MAX_ARTIFICiAL_TIME);
    } else {
      setLoading();
    }

    prevStatus.current = statusWindow;
    return () => setLoading();
  }, [statusWindow]);

  useEffect(() => {
    if (!isLoading) {
      // setLoading();

      clearTimeout(timeoutLoadingRef.current);
    }
  }, [isLoading]);
}
