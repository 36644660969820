import React from "react";
import CusButton from "../mui/CusButton";
import ControlsTop from "../mui/ControlsTop";
import { useTranslation } from "react-i18next";

/**
 * @param {Object} props
 * @param {String} props.title
 * @param {Boolean} props.isOpen
 * @param {String} props.text
 * @param {React.JSX.Element} props.icon
 * @param {Object} props.confirm
 * @param {String} props.confirm[].text
 * @param {Function} props.confirm[].onClick
 * @param {Object} props.cancel
 * @param {String} props.cancel[].text
 * @param {Function} props.cancel[].onClick
 */
export default function NotifyModal({ isOpen, title, text, icon, confirm, cancel, closeModal }) {
  const { text: confirmText, onClick: onConfirm } = confirm || {};
  const { text: cancelText, onClick: onCancel } = cancel || {};
  const { t } = useTranslation();

  return (
    isOpen && (
      <ControlsTop id={"notify"} className={"notify-modal"} onClose={closeModal}>
        <div className="notify-modal__content">
          <div className="notify-modal__icon">{icon}</div>
          <h1>{t(title)}</h1>
          <p>{t(text)}</p>
          <div className="notify-modal__buttons">
            {confirm && <CusButton text={confirmText} color="red" onClick={onConfirm} />}
            {cancel && <CusButton text={cancelText} onClick={onCancel} />}
          </div>
        </div>
      </ControlsTop>
    )
  );
}
