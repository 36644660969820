import React from "react";

export default function IconCircleArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
      <path
        d="M9.99967 1.58301C5.62967 1.58301 2.08301 5.12967 2.08301 9.49967C2.08301 13.8697 5.62967 17.4163 9.99967 17.4163C14.3697 17.4163 17.9163 13.8697 17.9163 9.49967C17.9163 5.12967 14.3697 1.58301 9.99967 1.58301ZM12.7943 10.3151L10.4193 12.6901C10.3005 12.8088 10.1501 12.8643 9.99967 12.8643C9.84926 12.8643 9.69884 12.8088 9.58009 12.6901L7.20509 10.3151C6.97551 10.0855 6.97551 9.70551 7.20509 9.47592C7.43467 9.24634 7.81467 9.24634 8.04426 9.47592L9.40592 10.8376V6.72884C9.40592 6.40426 9.67509 6.13509 9.99967 6.13509C10.3243 6.13509 10.5934 6.40426 10.5934 6.72884V10.8376L11.9551 9.47592C12.1847 9.24634 12.5647 9.24634 12.7943 9.47592C13.0238 9.70551 13.0238 10.0855 12.7943 10.3151Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
}
