import React from "react";

export default function IconLike() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <g clipPath="url(#clip0_26_13434)">
        <path
          d="M8.85881 5.49439L5.10915 9.17076C5.07994 9.19939 5.04067 9.21543 4.99977 9.21543C4.95888 9.21543 4.91961 9.19939 4.8904 9.17076L1.14074 5.49439C0.75504 5.11561 0.489041 4.63192 0.375751 4.10333C0.262462 3.57475 0.306869 3.02453 0.503463 2.52095C0.67701 2.07191 0.966169 1.67673 1.34164 1.37544C1.71711 1.07416 2.16556 0.877473 2.64153 0.805325C3.53051 0.685497 4.36071 1.044 4.99977 1.81664C5.63949 1.04278 6.46943 0.684372 7.3549 0.802919C7.85995 0.881755 8.33312 1.0995 8.72153 1.4318C9.10993 1.76411 9.39827 2.19789 9.55431 2.68465C9.71035 3.17142 9.72794 3.69199 9.60511 4.18818C9.48229 4.68437 9.22389 5.13662 8.85881 5.49439Z"
          fill="#767676"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_13434">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
