import React from "react";

export default function IconCameraOff() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="153"
      height="152"
      viewBox="0 0 153 152"
      fill="none"
    >
      <path
        d="M129.826 56.9997C128.94 56.9364 127.8 56.873 126.533 56.873C124.063 56.873 122.1 58.8364 122.1 61.3064V90.7564C122.1 93.2264 124.063 95.1897 126.533 95.1897C127.8 95.1897 128.876 95.1264 129.953 95.063C139.833 93.923 139.833 86.9564 139.833 81.8897V70.1097C139.833 65.043 139.833 58.0764 129.826 56.9997Z"
        fill="#292D32"
      />
      <path
        d="M129.826 56.9997C128.94 56.9364 127.8 56.873 126.533 56.873C124.063 56.873 122.1 58.8364 122.1 61.3064V90.7564C122.1 93.2264 124.063 95.1897 126.533 95.1897C127.8 95.1897 128.876 95.1264 129.953 95.063C139.833 93.923 139.833 86.9564 139.833 81.8897V70.1097C139.833 65.043 139.833 58.0764 129.826 56.9997Z"
        fill="#CA0C0D"
      />
      <path
        d="M129.826 56.9997C128.94 56.9364 127.8 56.873 126.533 56.873C124.063 56.873 122.1 58.8364 122.1 61.3064V90.7564C122.1 93.2264 124.063 95.1897 126.533 95.1897C127.8 95.1897 128.876 95.1264 129.953 95.063C139.833 93.923 139.833 86.9564 139.833 81.8897V70.1097C139.833 65.043 139.833 58.0764 129.826 56.9997Z"
        fill="url(#paint0_linear_778_8937)"
      />
      <path
        d="M116.274 64.2196V87.7796C116.274 106.463 101.074 121.663 82.3909 121.663H59.1476C54.1442 121.663 51.1042 116.153 53.7642 111.973L99.7442 39.2663C102.024 35.6563 107.154 35.3396 110.004 38.5696C114.564 43.8263 116.274 51.9963 116.274 64.2196Z"
        fill="#CA0C0D"
      />
      <path
        d="M116.274 64.2196V87.7796C116.274 106.463 101.074 121.663 82.3909 121.663H59.1476C54.1442 121.663 51.1042 116.153 53.7642 111.973L99.7442 39.2663C102.024 35.6563 107.154 35.3396 110.004 38.5696C114.564 43.8263 116.274 51.9963 116.274 64.2196Z"
        fill="url(#paint1_linear_778_8937)"
      />
      <path
        d="M104.366 11.843C102.149 10.4497 99.236 11.083 97.8427 13.2997L87.0127 30.463C85.556 30.3997 84.036 30.3363 82.3894 30.3363H47.0494C21.0827 30.3363 13.166 38.253 13.166 64.2197V87.7797C13.166 107.096 17.5993 116.343 30.5193 119.826L21.8427 133.633C20.4493 135.85 21.0827 138.763 23.2993 140.156C24.0593 140.663 24.946 140.916 25.8327 140.916C27.416 140.916 28.936 140.156 29.8227 138.7L105.823 18.3663C107.279 16.1497 106.583 13.2363 104.366 11.843Z"
        fill="#CA0C0D"
      />
      <path
        d="M104.366 11.843C102.149 10.4497 99.236 11.083 97.8427 13.2997L87.0127 30.463C85.556 30.3997 84.036 30.3363 82.3894 30.3363H47.0494C21.0827 30.3363 13.166 38.253 13.166 64.2197V87.7797C13.166 107.096 17.5993 116.343 30.5193 119.826L21.8427 133.633C20.4493 135.85 21.0827 138.763 23.2993 140.156C24.0593 140.663 24.946 140.916 25.8327 140.916C27.416 140.916 28.936 140.156 29.8227 138.7L105.823 18.3663C107.279 16.1497 106.583 13.2363 104.366 11.843Z"
        fill="url(#paint2_linear_778_8937)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_778_8937"
          x1="130.966"
          y1="56.873"
          x2="130.966"
          y2="98.3828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_778_8937"
          x1="84.5268"
          y1="36.3389"
          x2="84.5268"
          y2="128.773"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_778_8937"
          x1="59.8771"
          y1="11.1055"
          x2="59.8771"
          y2="151.734"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
