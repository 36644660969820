import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_PROFILE } from "../api/profile";
import useLoginAction from "./useLoginAction";
import { setFavorites } from "../store/userSlice";

export default function useLikeController(cardId) {
  const dispatch = useDispatch();
  const favorites = useSelector((state) => state.user.user.favorites);
  const { onLoginAction } = useLoginAction();
  const [like, setLike] = useState(favorites?.includes(cardId));

  useEffect(() => {
    setLike(favorites?.includes(cardId));
  }, [favorites, cardId]);

  const removeLike = useCallback(async (id) => {
    API_PROFILE.personal.favorites
      .remove([id])
      .then((e) => {
        console.log(e);
        dispatch(setFavorites(favorites.filter((el) => el != id)));
      })
      .catch((e) => console.log(e));
  }, []);

  const addLike = useCallback(async (id) => {
    API_PROFILE.personal.favorites
      .set([id])
      .then((e) => {
        console.log(e);
        dispatch(setFavorites([...favorites, id]));
      })
      .catch((e) => console.log(e));
    return;
  }, []);

  const onLikeClick = useCallback(
    async (id) => {
      const likeAction = async () => {
        if (!like) return await addLike(id);
        await removeLike(id);
      };

      await onLoginAction(likeAction);
    },
    [like]
  );

  return { onLikeClick, like };
}
