import React from "react";

export default function IconGroupChat() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14.7317 0.000889524H7.85611C7.53544 -0.00928974 7.22371 0.104698 6.98926 0.31786C6.75482 0.531022 6.6168 0.82596 6.60547 1.13801V5.36362C6.61695 5.67562 6.75502 5.97046 6.98943 6.18359C7.22384 6.39672 7.53548 6.51076 7.85611 6.50074H12.1365C12.1923 6.51788 12.2422 6.53502 12.295 6.55502L14.1416 7.96356C14.169 7.98396 14.2017 7.9964 14.2361 7.99945C14.2704 8.0025 14.3049 7.99603 14.3356 7.98079C14.3663 7.96555 14.392 7.94215 14.4096 7.91332C14.4272 7.88449 14.4361 7.85139 14.4352 7.81785V6.58359C14.4356 6.57233 14.4382 6.56126 14.443 6.551C14.4478 6.54074 14.4546 6.5315 14.463 6.52381C14.4715 6.51611 14.4814 6.51011 14.4923 6.50616C14.5031 6.5022 14.5146 6.50036 14.5262 6.50074H14.7317C14.8917 6.50725 15.0515 6.48299 15.2019 6.42934C15.3523 6.3757 15.4903 6.29372 15.6081 6.18811C15.7259 6.08249 15.8211 5.95531 15.8884 5.81383C15.9556 5.67236 15.9935 5.51937 16 5.36362V1.13801C15.986 0.823792 15.8447 0.527752 15.607 0.31465C15.3694 0.101548 15.0546 -0.0112653 14.7317 0.000889524Z"
        fill="#767676"
      />
      <path
        d="M11.5022 8.00089H9.6879C9.65296 11.5208 6.06838 13.4256 3.37598 12.8179V13.3636C3.38109 13.5183 3.41753 13.6705 3.48321 13.8114C3.54888 13.9523 3.6425 14.0792 3.75869 14.1849C3.87488 14.2905 4.01136 14.3728 4.1603 14.427C4.30924 14.4812 4.46771 14.5063 4.62662 14.5007H8.90698C8.96276 14.5179 9.01267 14.535 9.06552 14.555L10.9121 15.9636C10.9395 15.984 10.9722 15.9964 11.0066 15.9995C11.0409 16.0025 11.0754 15.996 11.1061 15.9808C11.1368 15.9655 11.1625 15.9422 11.1801 15.9133C11.1977 15.8845 11.2066 15.8514 11.2057 15.8178V14.5836C11.2061 14.5723 11.2087 14.5613 11.2135 14.551C11.2183 14.5407 11.2251 14.5315 11.2336 14.5238C11.242 14.5161 11.2519 14.5101 11.2628 14.5062C11.2736 14.5022 11.2851 14.5004 11.2967 14.5007H11.5022C11.6622 14.5072 11.822 14.483 11.9724 14.4293C12.1228 14.3757 12.2608 14.2937 12.3786 14.1881C12.4964 14.0825 12.5917 13.9553 12.6589 13.8138C12.7261 13.6724 12.764 13.5194 12.7705 13.3636V9.13801C12.7565 8.82379 12.6152 8.52775 12.3775 8.31465C12.1399 8.10155 11.8251 7.98873 11.5022 8.00089Z"
        fill="#767676"
      />
      <path
        d="M9.01285 7.07233H7.85615C7.37985 7.08223 6.91894 6.90794 6.57445 6.58768C6.22995 6.26742 6.02998 5.82731 6.01835 5.3638V3.81527C5.30711 3.57428 4.54516 3.51051 3.80208 3.62979C3.059 3.74907 2.35846 4.04759 1.76441 4.49811C-0.246596 5.99807 -0.437421 8.72372 0.68111 10.2065C0.824963 10.3951 0.860193 11.4979 0.073404 12.2265C0.0560284 12.2434 0.0442898 12.265 0.0397209 12.2885C0.0351519 12.312 0.0379649 12.3362 0.0477925 12.3581C0.0576201 12.38 0.0740056 12.3986 0.09481 12.4113C0.115614 12.424 0.139871 12.4303 0.164413 12.4293C1.32698 12.4236 2.07854 12.1636 2.40148 11.9608C2.44227 11.9361 2.48915 11.9225 2.53718 11.9215C2.58522 11.9205 2.63265 11.9321 2.6745 11.9551C2.89638 12.0733 3.13192 12.1653 3.37615 12.2294C5.63993 12.8293 9.06305 11.2691 9.10092 8.00088V7.94946C9.10186 7.65495 9.07236 7.36109 9.01285 7.07233Z"
        fill="#767676"
      />
    </svg>
  );
}
