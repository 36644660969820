import React from "react";
import ProfileHeader from "./profile/ProfileHeader";
import ProfileModelData from "./profile/ProfileModelData";
import ProfileAbout from "./profile/ProfileAbout";

export default function ChatTabProfile() {
  return (
    <div className="chat__profile">
      <ProfileHeader />
      <div className="chat__profile__content">
        <ProfileModelData />
        <ProfileAbout />
      </div>
    </div>
  );
}
