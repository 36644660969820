import React, { useEffect, useState } from "react";
import ChatSend from "./ChatSend";
import { useTranslation } from "react-i18next";
import { ENUM_CHAT_TAB, setChatActiveTab, setNewMessages } from "../../../store/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import CusButton from "../../mui/CusButton";
import { ENUM_MODALS_VALUE, setModals } from "../../../store/commonSlice";
import TypeLoginModal from "../../common/TypeLoginModal";
import { API_CHAT } from "../../../api/chat";
import { errorToast, successToast } from "../../mui/Toaster";
import { TYPE_ENUM } from "./ChatFree";
import usePaidAction from "../../../hooks/usePaidAction";

const DEFAULT_TIPS = [
  { id: 0, text: "3 sec 0", vibro: "Low", type: "low", total: "10" },
  { id: 1, text: "3 sec 2", vibro: "Medium", type: "medium", total: "10" },
  { id: 2, text: "3 sec 2", vibro: "Medium", type: "medium", total: "10" },
  { id: 3, text: "3 sec 3", vibro: "High", type: "high", total: "10" },
  { id: 4, text: "3 sec 4", vibro: "Medium", type: "medium", total: "10" },
  { id: 5, text: "3 sec 5", vibro: "Medium", type: "medium", total: "10" },
  { id: 6, text: "3 sec 6", vibro: "Ultra High", type: "ultra-high", total: "10" },
  { id: 7, text: "3 sec 7", vibro: "Medium", type: "medium", total: "10" },
  { id: 8, text: "3 sec 8", vibro: "Medium", type: "medium", total: "10" },
  { id: 9, text: "3 sec 9", vibro: "Medium", type: "medium", total: "10" },
  { id: 10, text: "3 sec 10", vibro: "Medium", type: "medium", total: "10" },
  { id: 11, text: "3 sec 11", vibro: "Low", type: "low", total: "10" },
  { id: 12, text: "3 sec 12", vibro: "Low", type: "low", total: "10" },
  { id: 13, text: "3 sec 13", vibro: "Low", type: "low", total: "10" },
];

export default function ChatLovenseToys() {
  const dispatch = useDispatch();
  const roomId = useSelector((state) => state.chat.broadcaster.id);
  const { nickname, balance } = useSelector((state) => state.user.user);
  const { onPaidActionClick } = usePaidAction();

  const [tips, setTips] = useState([]);
  const [chooseBtn, setChooseBtn] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => setTips(DEFAULT_TIPS), []);

  const onSendMes = (mes, amount) => {
    onPaidActionClick(amount, () => {
      if (amount) {
        API_CHAT.postTips(roomId, amount)
          .then(() => {
            const message = {
              id: Math.random() * 100,
              value: amount,
              nick: nickname,
              type: TYPE_ENUM.tipped,
            };

            dispatch(setNewMessages(message));
            // setLastAmount(amount);
            successToast(t("Sent tip _ tk", { amount: amount }));
          })
          .catch(() => errorToast(t("Failed to send tip")));
      }

      dispatch(setChatActiveTab(ENUM_CHAT_TAB.free));
    });
  };

  const onBtnClick = (e, tip) => {
    e.stopPropagation();

    if (balance < tip.total) {
      dispatch(setModals({ name: ENUM_MODALS_VALUE.deposit, value: true }));
      return;
    }

    // LOGIC

    dispatch(setChatActiveTab(ENUM_CHAT_TAB.free));
  };
  return (
    <>
      <div className="chat__toys">
        <div className="chat__tips__title">
          <span className="chat__tips__title__actmty">{t("DURATION")}</span>
          <span className="chat__tips__title__tokens">{t("VIBRATION")}</span>
          <span className="chat__tips__title__tokens">{t("TOKENS")}</span>
        </div>
        <div className="chat__tips__content">
          {tips.map((tip) => (
            <div
              key={tip.id}
              className="chat__tips__item"
              onClick={() => {
                setChooseBtn((prev) => (prev === tip.id ? false : tip.id));
              }}
            >
              <span className="chat__tips__item__actmty">{t(tip.text)}</span>
              <span className={`chat__tips__item__vibro ${tip.type}`}>{t(tip.vibro)}</span>

              {chooseBtn === tip.id ? (
                <CusButton
                  color="red"
                  text={balance < tip.total ? "Buy tokens" : "Send"}
                  onClick={(e) => onBtnClick(e, tip)}
                />
              ) : (
                <span className="chat__tips__item__tokens">{t(tip.total)}</span>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="chat__toys__send">
        <ChatSend sendPlug onSendMes={onSendMes} />
      </div>
      <TypeLoginModal isOpen={!!openLoginModal} setOpen={setOpenLoginModal} />
    </>
  );
}
