import React from "react";
import IconGroup from "../../../images/jsIcons/IconGroup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CusButton from "../../mui/CusButton";
import { ENUM_CHAT_MODE } from "../../../store/chatSlice";
import useJanus from "../../../hooks/useJanus";
import usePaidAction, { ENUM_PAID_ACTION } from "../../../hooks/usePaidAction";
import FreeSec from "../../mui/FreeSec";
export default function GroupPlug() {
  const name = useSelector((state) => state.chat.broadcaster.name);
  const { t } = useTranslation();

  const { changeChatMode } = useJanus({});
  const { onPaidActionClick } = usePaidAction();

  const onShowClick = () => {
    onPaidActionClick(ENUM_PAID_ACTION.price, () => changeChatMode(ENUM_CHAT_MODE.paid));
  };

  return (
    <>
      <div className="chat__model__private">
        <IconGroup />
        <h3 className="chat__model__private__title">
          @{name} {t("is having fun in Group show.")}
        </h3>
        <p className="chat__model__private__p">
          {t("Want to reveal what’s happening behind the curtain?")}
        </p>
        <CusButton
          color="red"
          className="chat__model__private__button"
          text={`${t("Join a group show")} @${name}`}
          onClick={onShowClick}
        />
        <FreeSec />
      </div>
    </>
  );
}
