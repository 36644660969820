import React from "react";
import { useTranslation } from "react-i18next";

export default function MesTyped({ message }) {
  const { t } = useTranslation();
  return (
    <div className="chat__free__message__change">
      <span className="chat__free__message__change__text">
        {message.nick}{" "}
        <span className="mode">
          {t("Tipped")} {message.value} {t("tokens_NUM")}
        </span>
      </span>
    </div>
  );
}
