import React from "react";
import { useSelector } from "react-redux";
import { ENUM_TAB } from "../../../store/chatSlice";
import ChatTabVideo from "../ChatTabVideo";
import ChatTabProfile from "../ChatTabProfile";
import ChatTabPhoto from "../tabs/ChatTabPhoto";
import ChatTabSnapshot from "../tabs/ChatTabSnapshot";

export default function UnderChat() {
  const activeTab = useSelector((state) => state.chat.pageActiveTab);

  const compByTab = {};
  compByTab[ENUM_TAB.video] = <ChatTabVideo />;
  compByTab[ENUM_TAB.profile] = <ChatTabProfile />;
  compByTab[ENUM_TAB.photo] = <ChatTabPhoto />;
  compByTab[ENUM_TAB.snapshot] = <ChatTabSnapshot />;

  return (
    <div>
      <section name="section-bottom">{compByTab[activeTab]}</section>
    </div>
  );
}
