import React, { useEffect, useRef, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import CusButton from "../mui/CusButton";
import CusCheckbox from "../mui/CusCheckbox";
import IconArrow from "../../images/jsIcons/IconArrow";
import MenuForFreeBanner from "./MenuForFreeBanner";
import { useDispatch, useSelector } from "react-redux";
import { toggleElemInList } from "../../utils/utils";
import { setFilter } from "../../store/commonSlice";
import { useEventListener } from "usehooks-ts";
import { isBonusForNewUsers } from "../common/consts";

export default function HomeFilters({ collapsed }) {
  const filterMenu = useSelector((state) => state.siteInfo.commonSiteInfo.options?.filter);

  const filtersCategory = useSelector((state) => state.common.filter);
  const dispatch = useDispatch();

  const [filterOptions, setFilterOptions] = useState(filtersCategory);

  const buttonRef = useRef();
  const filterMenuRef = useRef();

  useEffect(() => {
    setFilterOptions(filtersCategory);
  }, [filtersCategory]);

  const onChangeItem = (value) => setFilterOptions(toggleElemInList(filterOptions, value));
  const onSearch = () => dispatch(setFilter(filterOptions));
  const onClear = () => {
    setFilterOptions([]);
    dispatch(setFilter([]));
  };

  const changeSizeFilterMenu = () => {
    const filterMenu = filterMenuRef.current;
    if (!filterMenu) return;
    const buttons = buttonRef.current;
    const bottomBorder = filterMenu.offsetTop + filterMenu.offsetHeight;

    if (bottomBorder > window.innerHeight - buttons.offsetHeight * 2 + window.scrollY) {
      buttons.classList.add("fixed-button");
    } else {
      buttons.classList.remove("fixed-button");
    }
  };

  useEventListener("scroll", () => {
    changeSizeFilterMenu();
  });

  const defaultOpen = (arr1) => {
    function checkChildrenValues(arr, valuesToCheck) {
      for (let obj of arr) {
        if (obj.children) {
          for (let childObj of obj.children) {
            if (valuesToCheck.includes(childObj.value)) {
              return true;
            }
          }
        }
      }
      return false;
    }

    try {
      let res = arr1.map((el) => el.value).some((el2) => filtersCategory.includes(el2));

      if (res) return true;

      if (arr1[0].children) return checkChildrenValues(arr1, filtersCategory);

      return false;
    } catch (e) {
      return false;
    }
  };

  return (
    !collapsed && (
      <div className="home__filter__container">
        <ul
          ref={filterMenuRef}
          className="home__filter__menu"
          id="home-filter-menu"
          onClick={() => setTimeout(changeSizeFilterMenu, 450)}
        >
          {filterMenu.map((el, indx) => (
            <li className="home__filter__menu__li" key={indx}>
              <Accordion
                className="home__filter__menu__accordion"
                defaultExpanded={defaultOpen(el.children)}
              >
                <AccordionSummary className="home__filter__menu__accordion__summary">
                  <div className="container__icon">{el.icon}</div>
                  <span>{el.title}</span>
                  <IconArrow className={"arrow"} />
                </AccordionSummary>

                {el.children.map((elCHild, indx) => (
                  <AccordionDetails className="home__filter__menu__accordion__details" key={indx}>
                    {elCHild.type === "checkbox" && (
                      <CusCheckbox
                        checked={filterOptions.includes(elCHild.value)}
                        title={elCHild.title}
                        id={elCHild.title}
                        count={elCHild.count}
                        onChange={() => onChangeItem(elCHild.value)}
                      />
                    )}

                    {elCHild.type === "container" && (
                      <div className="home__filter__type__container">
                        <span>{elCHild.title}</span>
                        {elCHild.children.map((child2, indx2) => (
                          <div key={indx2} className="home__filter__type__container__child">
                            {child2.type === "checkbox" && (
                              <CusCheckbox
                                checked={filterOptions.includes(child2.value)}
                                title={child2.title}
                                id={child2.title}
                                count={child2.count}
                                onChange={() => onChangeItem(child2.value)}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </AccordionDetails>
                ))}
              </Accordion>
            </li>
          ))}
        </ul>
        {
          <div ref={buttonRef} className="home__filter__menu__item__buttons">
            <CusButton onClick={onSearch} color={"red"} text={"Search"} />
            <CusButton onClick={onClear} text={"Reset"} />
          </div>
        }

        {isBonusForNewUsers && <MenuForFreeBanner />}
      </div>
    )
  );
}
