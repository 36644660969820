import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CusInput from "../mui/CusInput";
import CusButton from "../mui/CusButton";

import { CAPTCHA, SITE_KEY } from "./consts";
import ReCAPTCHA from "react-google-recaptcha";
import { API_LOGIN } from "../../api/login";
import { catchMessageServer, thenMessageServer } from "../../api/main";
import CusForm from "../mui/CusForm";

export default function ResetPas({ closeModal }) {
  const { t } = useTranslation();
  const [btnLoading, setBtnLoading] = useState(false);
  const LangToHl = { english: "EN", russian: "RU" };
  const HL = LangToHl[t("currentLang")] || "EN";
  const captchaRef = useRef();

  const onPassReset = (e, fields) => {
    setBtnLoading(true);
    e.preventDefault();
    API_LOGIN.resetPas({
      captcha: captchaRef.current?.getValue?.(),
      email: fields.email,
    })
      .then(thenMessageServer)
      .catch(catchMessageServer)
      .finally(() => {
        setBtnLoading(false);
        captchaRef.current?.reset();
        closeModal();
      });
  };

  return (
    <div className="reset-password">
      <h1 className="reset-password__title">{t("Password Recovery")}</h1>
      <CusForm onSubmit={onPassReset} className="reset-password__content">
        <CusInput name={"email"} required text={"Type your email"} />

        {!!CAPTCHA?.["protect-reset-password-page"] && (
          <div className="re-captcha">
            <ReCAPTCHA hl={HL} sitekey={SITE_KEY} ref={captchaRef} theme="dark" />
          </div>
        )}

        <CusButton
          type="submit"
          loading={btnLoading}
          className="cus-button__outline res-pas__button"
          text={"Get code"}
        />
        <p>
          {t(
            "After clicking on the 'Get code' button, an email with a confirmation code will be sent to the entered E-mail. If the letter did not come, check the 'SPAM' folder."
          )}
        </p>
      </CusForm>
    </div>
  );
}
