import React from "react";
import IconLike from "../../../../../images/jsIcons/IconLike";
import { useSelector } from "react-redux";
import useLikeController from "../../../../../hooks/useLikeController";
import useUtilsBroadcaster from "../../../../../hooks/useUtilsBroadcaster";

export default function Like() {
  const { id: roomId, favorites: likes } = useSelector((state) => state.chat.broadcaster);
  const { updateBroadcaster } = useUtilsBroadcaster();
  const { onLikeClick, like } = useLikeController(roomId);

  return (
    <>
      <button
        className={`controls__favorite ${like ? "active" : ""}`}
        onClick={async () => {
          await onLikeClick(roomId);
          updateBroadcaster();
        }}
      >
        <IconLike />
      </button>
      <div className="controls__comment">{likes}</div>
    </>
  );
}
