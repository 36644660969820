import React, { useRef, useState } from "react";
import Hone from "./Home";
import Modal from "react-modal";
import CloseModal from "../components/mui/CloseModal";
import { useTranslation } from "react-i18next";
import CusForm from "../components/mui/CusForm";
import CusInput from "../components/mui/CusInput";
import { CAPTCHA, SITE_KEY } from "../components/common/consts";
import ReCAPTCHA from "react-google-recaptcha";
import { catchMessageServer, thenMessageServer } from "../api/main";
import { API_LOGIN } from "../api/login";
import CusButton from "../components/mui/CusButton";
import { useSearchParams } from "react-router-dom";

export default function ResetPassword() {
  const { t } = useTranslation();
  const LangToHl = { english: "EN", russian: "RU" };
  const HL = LangToHl[t("currentLang")] || "EN";
  const [query] = useSearchParams();
  const code = query.get("code");

  const [isOpen, setIsOpen] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const captchaRef = useRef();

  const closeModal = () => {
    setIsOpen(false);
    window.history.pushState("", "", "/");
  };

  const onPassReset = (e, fields) => {
    setBtnLoading(true);
    e.preventDefault();
    API_LOGIN.resetPas({
      captcha: captchaRef.current?.getValue?.(),
      password: fields.newPas,
      code: code,
    })
      .then(thenMessageServer)
      .catch(catchMessageServer)
      .finally(() => {
        setBtnLoading(false);
        captchaRef.current?.reset();
        closeModal();
      });
  };

  return (
    <>
      <Modal
        ariaHideApp={false}
        className={"login-modal"}
        isOpen={isOpen}
        onRequestClose={closeModal}
      >
        <CloseModal close={closeModal} />

        <h1 className="reset-password__title">{t("Your new password")}</h1>
        <CusForm onSubmit={onPassReset} className="reset-password__content">
          <CusInput
            type={"password"}
            minLength={8}
            name={"newPas"}
            required
            text={"Type your new password"}
          />

          {!!CAPTCHA?.["protect-reset-password-page"] && (
            <div className="re-captcha">
              <ReCAPTCHA hl={HL} sitekey={SITE_KEY} ref={captchaRef} theme="dark" />
            </div>
          )}

          <CusButton
            type="submit"
            loading={btnLoading}
            className="cus-button__outline res-pas__button"
            text={"Change password"}
          />
          <p>{t("Enter a new password.")}</p>
        </CusForm>
      </Modal>

      <Hone />
    </>
  );
}
