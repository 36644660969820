import React from "react";

export default function IconAbout() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
      <g clipPath="url(#clip0_857_15695)">
        <path
          d="M24.7917 4H3.20833C1.4385 4 0 5.4385 0 7.20833V21.7917C0 23.5615 1.4385 25 3.20833 25H24.7917C26.5615 25 28 23.5615 28 21.7917V7.20833C28 5.4385 26.5615 4 24.7917 4ZM8.75 8.66667C10.3577 8.66667 11.6667 9.97567 11.6667 11.5833C11.6667 13.191 10.3577 14.5 8.75 14.5C7.14233 14.5 5.83333 13.191 5.83333 11.5833C5.83333 9.97567 7.14233 8.66667 8.75 8.66667ZM14 19.4583C14 19.9413 13.608 20.3333 13.125 20.3333H4.375C3.892 20.3333 3.5 19.9413 3.5 19.4583V18.875C3.5 17.1052 4.9385 15.6667 6.70833 15.6667H10.7917C12.5615 15.6667 14 17.1052 14 18.875V19.4583ZM23.625 20.3333H17.2083C16.7253 20.3333 16.3333 19.9413 16.3333 19.4583C16.3333 18.9753 16.7253 18.5833 17.2083 18.5833H23.625C24.108 18.5833 24.5 18.9753 24.5 19.4583C24.5 19.9413 24.108 20.3333 23.625 20.3333ZM23.625 15.6667H17.2083C16.7253 15.6667 16.3333 15.2747 16.3333 14.7917C16.3333 14.3087 16.7253 13.9167 17.2083 13.9167H23.625C24.108 13.9167 24.5 14.3087 24.5 14.7917C24.5 15.2747 24.108 15.6667 23.625 15.6667ZM23.625 11H17.2083C16.7253 11 16.3333 10.608 16.3333 10.125C16.3333 9.642 16.7253 9.25 17.2083 9.25H23.625C24.108 9.25 24.5 9.642 24.5 10.125C24.5 10.608 24.108 11 23.625 11Z"
          fill="url(#paint0_linear_857_15695)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_857_15695"
          x1="14"
          y1="4"
          x2="14"
          y2="26.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_857_15695">
          <rect width="28" height="28" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
