import React, { useState } from "react";
import Modal from "react-modal";
import CusButton from "../mui/CusButton";

import imgAllModal from "../../images/confirm-age/all-models.png";
import imgAllModalSelect from "../../images/confirm-age/all-models-select.png";
import imgGirl from "../../images/confirm-age/girls.png";
import imgGirlSelect from "../../images/confirm-age/girls-select.png";
import imgGays from "../../images/confirm-age/guys.png";
import imgGaysSelect from "../../images/confirm-age/guys-select.png";
import imgTrans from "../../images/confirm-age/trans.png";
import imgTransSelect from "../../images/confirm-age/trans-select.png";
import { setTypeModel } from "../../store/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { SEX_ALL_MODELS, SEX_GAYS, SEX_GIRLS, SEX_TRANS } from "../../store/siteInfoSlice";
import { SITE_NAME } from "./consts";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ConfirmAge({ isOpen, closeModal }) {
  const optionsSex = useSelector((state) => state.siteInfo.commonSiteInfo.options?.sex);

  const imgById = {};
  imgById[SEX_ALL_MODELS] = { img: imgAllModal, selectImg: imgAllModalSelect };
  imgById[SEX_GIRLS] = { img: imgGirl, selectImg: imgGirlSelect };
  imgById[SEX_GAYS] = { img: imgGays, selectImg: imgGaysSelect };
  imgById[SEX_TRANS] = { img: imgTrans, selectImg: imgTransSelect };

  const categories = optionsSex?.map((el) => ({
    selectImg: imgById[el.id].selectImg,
    img: imgById[el.id].img,
    text: el.title,
    value: el.value,
    selected: el.selected,
    className: el.className,
  }));

  const defaultSelected = categories?.find((el) => el.selected) || categories?.[0];
  const [selectedCategory, setSelectedCategory] = useState(defaultSelected?.value);

  const getImgSource = (category) =>
    selectedCategory === category.value ? category.selectImg : category.img;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Modal
      ariaHideApp={false}
      className={"confirm-age-modal"}
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className="confirm-age-modal__content">
        <h1 className="confirm-age-modal__title">{SITE_NAME}</h1>
        <p className="confirm-age-modal__text">
          {t(
            "We are creating a better experience for 18+ LIVE entertainment. Join our community & start interacting now for FREE."
          )}
        </p>
        <div className="confirm-age-modal__categories">
          <span className="confirm-age-modal__categories__title">{t("Select a category")}:</span>
          <div className="confirm-age-modal__categories__content">
            {categories?.map((category) => (
              <div
                key={category.text}
                onClick={() => {
                  setSelectedCategory(category.value);
                }}
                className={`confirm-age-modal__category ${
                  selectedCategory === category.value ? "select" : ""
                } ${category.className}`}
              >
                <img src={getImgSource(category)} alt="icon" />
                <span>{category.text}</span>
              </div>
            ))}
          </div>
          <CusButton
            onClick={() => {
              dispatch(setTypeModel(selectedCategory));

              closeModal();
            }}
            className="custom-button__red"
            text={"I'm over 18 years old"}
          />
          <p className="confirm-age-modal__plug__text">
            <span>
              {t(
                "By visiting and using the site, you confirm that you are over 18 years of age and agree to the"
              )}
            </span>{" "}
            <Link to={"/terms"}> {t("Terms of Use")}</Link> {t("and")}{" "}
            <Link to={"/policy"}>{t("Privacy Policy")} </Link>{" "}
            <Link to={"/2257"}>{t("18 U.S.C. 2257")}</Link>{" "}
            {t("Record-Keeping Requirements Compliance Statement.")}
          </p>
        </div>
        <a href="https://www.google.com/">{t("Exit")}</a>
      </div>
      <div className="confirm-age-modal__plug">
        <p className="confirm-age-modal__plug__text">
          {t(
            "If you provide personal data, by visiting our website you expressly consent to our processing in order to optimize the site according to your preferences."
          )}
        </p>
        <p className="confirm-age-modal__plug__text__accent">
          {t("THE WEBSITE CONTAINS SEXUALLY EXPLICIT MATERIALS.")}
        </p>
        <p className="confirm-age-modal__plug__text">
          {t(
            "You must be at least eighteen (18) years of age to use this website. If the age of majority in your country is after eighteen (18) years of age, then your age must be no less than the age of majority in your country. Use of this website is prohibited where prohibited by law."
          )}
        </p>
        <p className="confirm-age-modal__plug__text">
          {t(
            "The website also requires the use of cookies. Find out more about our cookies in our Privacy Policy."
          )}
          <span className="confirm-age-modal__plug__text__accent">
            {" "}
            {t("BY ACCESSING AND USING THIS WEBSITE, YOU CONSENT TO THE")}{" "}
            <Link to={"/cookie"}>{t("USE OF COOKIES")}</Link> <br />
            {t("AND AGREE TO THE")} <Link to={"/policy"}>{t("PRIVACY POLICY")}</Link>
            <br />
          </span>
          {t("All models are of legal age at the time of creating an account on this website.")}
        </p>
      </div>
    </Modal>
  );
}
