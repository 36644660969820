import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconICircle from "../../../images/jsIcons/IconICircle";
import FlagImg from "../../mui/FlagImg";
import { useSelector } from "react-redux";

export default function ProfileModelData() {
  const { t } = useTranslation();
  const [fields, setFields] = useState({});
  const profile = useSelector((state) => state.chat.broadcasterProfile);

  useEffect(() => {
    const fields = {
      Name: profile?.general?.nickname || "",
    };
    const location = profile?.about?.location;
    const age = profile?.about?.age;
    if (location) fields.From = location;
    if (age) fields.Age = age;

    const extra = profile?.about?.extra;

    if (Array.isArray(extra) && extra.length) {
      extra.forEach((el) => {
        fields[el.name] = el.value;
      });
    }

    setFields(fields);
  }, [profile]);

  return (
    <div className="chat__profile__model-data">
      <div className="chat__profile__model-data__header">
        <IconICircle /> <h3>{t("Profile")}</h3>
      </div>
      <div className="chat__profile__model-data__info">
        {Object.keys(fields).map((key) => (
          <div key={key}>
            <span className="chat__profile__model-data__keys">{t(key)}</span>
            {key === "Languages" ? (
              <div className="chat__profile__model-data__languages">
                {fields[key].map((el, indx) => (
                  <span key={indx}>
                    <span>{el}</span>
                    <FlagImg flagCode={"us"} />
                  </span>
                ))}
              </div>
            ) : (
              <span className="chat__profile__model-data__value">{fields[key]}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
