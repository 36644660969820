import React from "react";
import PageTextContainer from "../components/common/PageTextContainer";
import TermsContent from "../components/mui/terms/TermsContent";

export default function Terms() {
  return (
    <PageTextContainer>
      <TermsContent />
    </PageTextContainer>
  );
}
