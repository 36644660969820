import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ChatSend from "./ChatSend";
import { useSelector } from "react-redux";

import { ENUM_STATUS_WINDOW } from "../../../store/chatSlice";
import useJanus from "../../../hooks/useJanus";
import ChatPin from "./ChatPin";
import NewMessagePlug from "../../mui/NewMessagePlug";
import ChatFreeMessages from "./ChatFreeMessages";
import useDeviceSize from "../../../hooks/useDeviceSize";

export const TYPE_ENUM = {
  date: "date",
  someonesMessage: "someonesMessage",
  myMessage: "myMessage",
  modelMessage: "modelMessage",
  tipped: "tipped",
  lovense: "lovense",
  vibratotion: "vibratotion",
  switched: "switched",
};

// ChatFree  - главный компонент бесплатного чата
export default function ChatFree({ maxHeight, hideOther }) {
  const { onSendMessage } = useJanus({});
  const { t } = useTranslation();
  const { isMobile } = useDeviceSize();

  const userId = useSelector((state) => state.user.user.id);
  const broadcasterId = useSelector((state) => state.chat.broadcaster.id);
  const messages = useSelector((state) => state.chat.messages);
  const status = useSelector((state) => state.chat.statusWindow);

  const [showNewMes, setShowNewMes] = useState(false);

  const freeMessages = useRef();

  const makeScrollBottom = (ref) => {
    // ref.scrollIntoView({ behavior: "smooth" });

    if (!ref) return;

    ref.scrollTop = ref.scrollHeight;
    setShowNewMes(false);
  };

  const onSendMes = (text, _, onlyBroadcaster) => {
    onSendMessage(text, onlyBroadcaster);
    makeScrollBottom(freeMessages.current);
  };

  const messagesJSX = useMemo(() => {
    const curMess = messages?.filter((el) => el.type !== "pin");
    const messageJSX = !hideOther
      ? curMess
      : curMess?.filter(
          (el) =>
            el.user_id === userId ||
            el.from?.user_id === userId ||
            el.user_id === broadcasterId ||
            el.from?.user_id === broadcasterId
        );
    return messageJSX;
  }, [messages, hideOther]);

  const isAvailable = useMemo(() => status !== ENUM_STATUS_WINDOW.offline, [status, isMobile]);
  // const isAvailable = useMemo(() => true, [status]);

  // return JSX
  return (
    <div className="chat__free">
      <ChatPin message={messages?.find?.((el) => el.type === "pin")} />
      {!isAvailable ? (
        <div className="chat__free__empty">
          {t("messages are only available when the broadcasting")}
        </div>
      ) : (
        <>
          {messagesJSX?.length ? (
            <ChatFreeMessages
              maxHeight={maxHeight}
              messages={messagesJSX}
              messagesAll={messages}
              freeMessages={freeMessages}
              makeScrollBottom={makeScrollBottom}
              setShowNewMes={setShowNewMes}
            />
          ) : (
            <div className="chat__free__empty">{t("There have been no messages...")}</div>
          )}
          {showNewMes && (
            <NewMessagePlug
              onClick={() => {
                makeScrollBottom(freeMessages.current);
              }}
            />
          )}
        </>
      )}
      <ChatSend tabFree onSendMes={onSendMes} />
    </div>
  );
}
