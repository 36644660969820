import React, { useEffect, useMemo, useRef, useState } from "react";
import UnavailableModal from "./window/UnavailableModal";
import { useDispatch, useSelector } from "react-redux";
import { ENUM_STATUS_WINDOW } from "../../store/chatSlice";
import ChatControls from "./window/controls/ChatControls";
import useJanus from "../../hooks/useJanus";
import BroadcasterVideo from "./window/BroadcasterVideo";
import useDeviceSize from "../../hooks/useDeviceSize";
import { getMaxHeight, scrollToElement } from "../../utils/utils";
import { setShownControls } from "../../store/commonSlice";
import { TEST_MODE } from "../common/consts";
import useChangeModeByUrl from "../../hooks/useChangeModeByUrl";
import { useEventListener } from "usehooks-ts";
import MyVideo from "./window/MyVideo";
import Autostart from "./window/Autostart";

const DELAY_CLEAR_VIDEO = 5 * 1000;

export default function BroadcasterWindow({ setMaxHeight }) {
  const dispatch = useDispatch();
  const { isMobile } = useDeviceSize();

  const broadcasterId = useSelector((state) => state.chat.broadcaster.id);
  const broadcasterChatId = useSelector((state) => state.chat.broadcaster?.chat?.id);
  const status = useSelector((state) => state.chat.statusWindow);
  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);

  const videoRef = useRef();
  const audioRef = useRef();
  const canvasRef = useRef();
  const divZoomRef = useRef();
  const chatWindowRef = useRef();
  const clearVideoIdRef = useRef();

  const janusOptions = {
    broadcasterId: broadcasterId,
    chatId: broadcasterChatId,
    videoRef: videoRef,
    audioRef: audioRef,
  };

  const { changeChatMode } = useJanus(janusOptions);
  useChangeModeByUrl(changeChatMode);

  const available = useMemo(() => status === ENUM_STATUS_WINDOW.stream, [status]);

  const [shownControls, setControls] = useState(!isMobile);
  const [myStream, setMyStream] = useState({});
  const [politicMute, setPoliticMute] = useState(false);

  useEffect(() => {
    clearVideoIdRef.current = setTimeout(() => setControls(false), DELAY_CLEAR_VIDEO);

    if (TEST_MODE) setPoliticMute(true);
    return () => clearTimeout(clearVideoIdRef.current);
  }, [clearVideoIdRef]);

  useEffect(() => {
    const isShown = shownControls || !available || politicMute;
    dispatch(setShownControls(isShown));
  }, [shownControls, available, politicMute]);

  const onUnMute = () => {
    setPoliticMute(false);
    audioRef.current?.play();
  };

  useEventListener("orientationchange", () => {
    if (!isMobile) return;
    console.log("orientationchange angle", screen?.orientation?.angle);
    if (screen?.orientation?.angle == 90) {
      scrollToElement("chat-window");
    }
  });

  return (
    <>
      <div
        className={"chat__window"}
        name="chat-window"
        ref={chatWindowRef}
        onMouseEnter={() => !isMobile && setControls(true)}
        onMouseLeave={() => !isMobile && setControls(false)}
        onTouchStart={() => {
          setControls(true);
          clearTimeout(clearVideoIdRef.current);
          clearVideoIdRef.current = setTimeout(() => setControls(false), DELAY_CLEAR_VIDEO);
        }}
        style={{ maxHeight: getMaxHeight(isFullScreen, "80svh") }}
        onClick={onUnMute}
      >
        <audio
          ref={audioRef}
          autoPlay
          controls
          style={{ opacity: 0, position: "absolute" }}
          onLoadedData={(e) => {
            e.target.play().catch(() => setPoliticMute(true));
          }}
        />
        <BroadcasterVideo
          videoRef={videoRef}
          canvasRef={canvasRef}
          divZoomRef={divZoomRef}
          setMaxHeight={setMaxHeight}
          available={available}
        />
        {!available && <UnavailableModal />}
        {
          <ChatControls
            myStream={myStream}
            setMyStream={setMyStream}
            chatWindowRef={chatWindowRef}
            canvasRef={canvasRef}
            divZoomRef={divZoomRef}
            videoRef={videoRef}
            audioRef={audioRef}
            politicMute={politicMute}
            onUnMute={onUnMute}
          />
        }
      </div>

      <Autostart setMyStream={setMyStream} />

      <MyVideo myStream={myStream} setMyStream={setMyStream} />
    </>
  );
}
