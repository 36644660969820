import React from "react";

export default function IconQuestionCircle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <g clipPath="url(#clip0_740_20653)">
        <path
          d="M7.45508 4.53803C8.14893 4.53803 8.56577 4.90814 8.56577 5.53245C8.56577 6.66631 6.54179 6.97884 6.54179 8.35495C6.54179 8.72506 6.78404 9.12955 7.28162 9.12955C8.04501 9.12955 7.95267 8.56281 8.23043 8.15756C8.60016 7.61437 10.3239 7.03604 10.3239 5.53208C10.3239 3.90176 8.86708 3.21875 7.3863 3.21875C5.97579 3.21875 4.67969 4.22513 4.67969 5.35899C4.67969 5.82143 5.02699 6.06443 5.43186 6.06443C6.55301 6.0648 5.97505 4.53803 7.45508 4.53803Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M8.27578 10.8312C8.27578 10.3105 7.84773 9.88281 7.32734 9.88281C6.80695 9.88281 6.37891 10.3112 6.37891 10.8312C6.37891 11.352 6.80695 11.7797 7.32734 11.7797C7.84773 11.7797 8.27578 11.352 8.27578 10.8312Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M15 7.5C15 3.36457 11.6354 0 7.5 0C3.36457 0 0 3.36457 0 7.5C0 11.6354 3.36457 15 7.5 15C11.6354 15 15 11.6358 15 7.5ZM0.747682 7.5C0.747682 3.77692 3.77692 0.747682 7.5 0.747682C11.2231 0.747682 14.2523 3.77692 14.2523 7.5C14.2523 11.2231 11.2231 14.2523 7.5 14.2523C3.77692 14.2523 0.747682 11.2231 0.747682 7.5Z"
          fill="white"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_20653">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
