import React from "react";

export default function IconStatistic() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_207_32431)">
        <path
          d="M6.58984 7.41016V1.66797H6.17969C2.78709 1.66797 0 4.42772 0 7.82031C0 11.2129 2.78709 14 6.17969 14C9.57228 14 12.332 11.2129 12.332 7.82031V7.41016H6.58984Z"
          fill="url(#paint0_linear_207_32431)"
        />
        <path
          d="M7.82031 0H7.41016V6.58984H14V6.17969C14 2.78709 11.2129 0 7.82031 0Z"
          fill="url(#paint1_linear_207_32431)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_207_32431"
          x1="6.16602"
          y1="1.66797"
          x2="6.16602"
          y2="15.0277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_207_32431"
          x1="10.7051"
          y1="0"
          x2="10.7051"
          y2="7.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_207_32431">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
