import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import CloseModal from "../mui/CloseModal";
import CusSelect from "../mui/CusSelect";
import CusButton from "../mui/CusButton";
import { useTranslation } from "react-i18next";
import IconCameraOff from "../../images/jsIcons/IconCameraOff2";
import Spinner from "../mui/Spinner";
import { getOptionsVideo, makeResolution } from "../../utils/utils";
import { useSelector } from "react-redux";
import { errorToast } from "../mui/Toaster";
import useDeviceSize from "../../hooks/useDeviceSize";
import { useLocalStorage } from "usehooks-ts";
import useJanus from "../../hooks/useJanus";
// import { ENUM_CHAT_MODE, setMyStream } from "../../store/chatSlice";
import { TEST_MODE } from "../common/consts";
import { ENUM_CHAT_MODE } from "../../store/chatSlice";

export const ENUM_CAMERA_STATE = { off: false };
export const ENUM_MICRO_STATE = { off: false };

export default function CameraSetting({
  isOpen,
  closeModal,
  isOfferStream,
  setMyStream,
  onSaveClick,
  buttonText = "Save",
}) {
  const { offerStream } = useJanus({});

  const [, setCameraId] = useLocalStorage("cameraId", undefined);
  const [, setDevices] = useLocalStorage("devices", {});
  const { t } = useTranslation();
  const { isMobile } = useDeviceSize();

  const { id: userId } = useSelector((state) => state.user.user);
  const chatActiveMode = useSelector((state) => state.chat.chatActiveMode);

  const isDisabled = TEST_MODE
    ? false
    : isOfferStream && chatActiveMode !== ENUM_CHAT_MODE.exclusive;
  // const isDisabled = myStream?.id;

  // options
  const [cameraOptions, setCameraOptions] = useState([]);
  const [resolutions, setResolutions] = useState([]);
  const [microphones, setMicrophones] = useState([]);

  // value
  const [camera, setCamera] = useLocalStorage("localCamera", ENUM_CAMERA_STATE.off);
  const [micro, setMicro] = useLocalStorage("localMicro", ENUM_MICRO_STATE.off);
  const [resolution, setResolution] = useLocalStorage("localResolution", undefined);
  const keepStreamRef = useRef(false);

  // other
  const [loader, setLoader] = useState(true);

  // refs
  const videoRef = useRef(null);
  const videoRef2 = useRef(null);
  const mediaStreamRef = useRef();

  const closeConnect = () => {
    if (keepStreamRef.current) return;
    mediaStreamRef.current?.getTracks?.()?.forEach((t) => t?.stop?.());
  };

  const handleCLose = () => {
    closeConnect();
    closeModal();
  };

  const onButtonCLick = () => {
    keepStreamRef.current = true;
    console.log("btnClick");

    if (typeof onSaveClick === "function") {
      onSaveClick(mediaStreamRef.current);
    }

    const fn = async () => {
      setCameraId(camera);
      const newDevices = { videoId: camera, microId: micro, resolution: resolution };
      setDevices(newDevices);
      console.log(mediaStreamRef.current);
      handleCLose();
      if (isOfferStream) {
        if (!TEST_MODE) offerStream(newDevices, !!camera, !!micro);
        setMyStream?.(mediaStreamRef.current);
      }
    };

    setTimeout(() => fn(), 100);
  };

  const onDisableClick = () => {
    let messageText = userId
      ? "Broadcast your camera only in a Private Exclusive chat"
      : "Camera broadcasting is available only to authorized users";

    errorToast(messageText);
  };

  const getFillOptions = async () => {
    const { videoOptions, audioOptions } = await getOptionsVideo();

    setCameraOptions([...videoOptions, { label: t("Do not use"), value: ENUM_CAMERA_STATE.off }]);
    setMicrophones([...audioOptions, { label: t("Do not use"), value: ENUM_MICRO_STATE.off }]);
  };

  useEffect(() => {
    getFillOptions();
  }, []);

  useEffect(() => {
    if (!isOpen) return;
    const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);

    try {
      if (!localStorage.getItem("cameraPerm") || isIos) {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((devs) => {
            devs?.getTracks?.()?.forEach((t) => t?.stop?.());
            getFillOptions();
            localStorage.setItem("cameraPerm", true);
          })

          .catch((e) => {
            errorToast(t("Grant access to the camera and microphone to enable them."));
            localStorage.removeItem("cameraPerm");
            console.log(e);
          });
      }
    } catch (e) {
      console.log(e);
    }
  }, [isOpen]);

  useEffect(() => {
    const getVideo = () => {
      const video = videoRef.current;

      const constraints = {
        audio: { deviceId: micro ? { exact: micro } : undefined },
        video: {
          deviceId: camera ? { exact: camera } : undefined,
          width: resolution ? { ideal: +resolution.split("x")[0] } : undefined,
          height: resolution ? { ideal: +resolution.split("x")[1] } : undefined,
        },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (mediaStream) {
          mediaStreamRef.current = mediaStream;

          const curResolutions = makeResolution(mediaStream);
          setResolutions(curResolutions);

          video.srcObject = mediaStream;
          videoRef2.current.srcObject = mediaStream;
          video.onloadedmetadata = () => video.play();
          videoRef2.current.onloadedmetadata = () => videoRef2.current.play();
        })
        .catch(function (err) {
          console.log(err);
          // setCamera(ENUM_CAMERA_STATE.off);
        })
        .finally(() => setLoader(false));
    };

    setLoader(true);

    if (isOpen && (camera !== ENUM_CAMERA_STATE.off || micro !== ENUM_MICRO_STATE.off)) {
      closeConnect();
      setTimeout(getVideo, 100);
    } else {
      setLoader(false);
    }
  }, [isOpen, camera, micro, resolution]);

  return (
    <Modal
      ariaHideApp={false}
      className={`camera-settings-modal ${t("currentLang")}`}
      isOpen={isOpen}
      onRequestClose={handleCLose}
    >
      <CloseModal close={handleCLose} />

      <h1 className="camera-settings__title">{t("Camera settings")}</h1>
      <div className="camera-settings__user">
        {camera !== ENUM_CAMERA_STATE.off ? (
          <>
            <video
              playsInline
              muted
              ref={videoRef2}
              autoPlay
              className="camera-settings__video__bg"
            />
            <video
              muted
              playsInline
              ref={videoRef}
              autoPlay
              className="camera-settings__video"
              width={isMobile ? "100%" : 520}
            />
          </>
        ) : (
          <div className="camera-settings__no-camera">
            <IconCameraOff />
            <p>{t("No video from camera")}</p>
          </div>
        )}
      </div>
      <CusSelect
        label={"Camera"}
        options={cameraOptions}
        valueProps={camera}
        onChange={setCamera}
      />
      <CusSelect
        label={"Camera resolution"}
        options={resolutions}
        valueProps={resolution}
        onChange={setResolution}
      />
      <CusSelect
        label={"Microphone"}
        valueProps={micro}
        options={microphones}
        onChange={setMicro}
      />
      <div className="button-container" onClick={isDisabled ? onDisableClick : undefined}>
        <CusButton
          className={isDisabled ? "disabled" : ""}
          text={buttonText}
          onClick={!isDisabled ? onButtonCLick : undefined}
          color="red"
          disabled={camera === false && micro === false}
        />
      </div>

      {!!loader && (
        <div className="camera-settings__loader">
          <Spinner />
        </div>
      )}
    </Modal>
  );
}
