import React from "react";

export default function SupportContentEn() {
  return (
    <>
      <h1 className="center">Support / Contact Us</h1>

      <h2>Need help?</h2>
      <p>
        In order to provide you the best assistance, please contact us through any of the choices
        below. We are available 24 hours a day, 7 days a week
        <span>
          Email: <a href="mailto:support@richcams.com">support@richcams.com</a>
        </span>
      </p>

      <h2>Complaints: </h2>
      <p>
        For all complaints, please contact us through{" "}
        <a href="mailto:support@richcams.com">support@richcams.com</a> Complaints will be reviewed
        and resolved within seven (7) business days. That resolution will then be communicated to
        the Complainant. Appeals or requests for any decision made should be presented, again, to{" "}
        <a href="mailto:support@richcams.com">support@richcams.com</a>.
      </p>

      <h2>Appeals:</h2>
      <p>
        If you have been depicted in any content and would like to appeal removal of such content,
        please notify us by email <a href="mailto:support@richcams.com">support@richcams.com</a>. If
        there should be disagreement regarding an appeal, we will allow the disagreement to be
        resolved by a neutral party.
      </p>

      <h2>Billing Issues CCBill:</h2>
      <p>
        If you'd like to cancel your subscription and not to be billed again, or for any billing
        questions including refunds on a case-by-case basis, please contact our billing partner,
        CCBill via phone 1.888.596.9279, or email at{" "}
        <a href="mailto:consumersupport@ccbill.com">consumersupport@ccbill.com</a>
      </p>
    </>
  );
}
