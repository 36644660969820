import React from "react";
import CloseModal from "../mui/CloseModal";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import balanceCoin from "../../images/mui/tokens.svg";
import CusButton from "../mui/CusButton";
import IconPremiumCrown from "../../images/jsIcons/IconPremiumCrown";
import checkCircle from "../../images/common/check-circle.svg";
import LifetimePremium from "../mui/LifetimePremium";
import { useDispatch } from "react-redux";
import { ENUM_MODALS_VALUE, setModals } from "../../store/commonSlice";

export default function PremiumModal({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const countTokens = 100;
  const titleList = [
    "Private messages in chat",
    "Your messages will be highlighted",
    "The model spends more time with Premium users",
    "Access to all photos of the model",
    "Gallery of snapshots from the model show",
  ];

  return (
    <>
      <Modal
        ariaHideApp={false}
        className={"premium-modal"}
        isOpen={isOpen}
        onRequestClose={closeModal}
      >
        <CloseModal close={closeModal} />
        <div className="premium-modal__content">
          {/* modal__banner */}
          <div className="premium-modal__banner">
            <h2>
              {t("Lifetime")} <br /> {t("Premium Status")}
            </h2>
            {/* <p>
              <span>{t("And get bonus")}</span> {countTokens} <img src={balanceCoin} />{" "}
              {t("right now")}
            </p> */}
            <div className="crown-red">
              <IconPremiumCrown />
            </div>
          </div>
          {/* modal__info */}
          <div className="premium-modal__info">
            <h1 className="premium-modal__info__title">
              {t("Get a")} <LifetimePremium />
              <br /> {t("status for just $20")}
            </h1>
            <h4>{t("You will have access:")}</h4>

            <ul>
              {titleList.map((el, indx) => (
                <li key={indx}>
                  <span className="check__list__span">
                    <img src={checkCircle} /> <span>{el}</span>
                  </span>
                </li>
              ))}
            </ul>
            <CusButton
              // text={"Get a Lifetime Premium Status + from 100 tokens"}
              text={"Get a Lifetime Premium Status "}
              icon={<IconPremiumCrown />}
              color="red"
              onClick={() => {
                closeModal();
                dispatch(setModals({ name: ENUM_MODALS_VALUE.deposit, value: true }));
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
