import React, { useEffect, useMemo, useState } from "react";
import IconCross from "../../images/jsIcons/IconCross";
import useDeviceSize from "../../hooks/useDeviceSize";
import { Carousel } from "antd";
import { setShown } from "../../store/commonSlice";
import { toggleElemInList } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";

export default function CategoriesCarousel({ className, categories }) {
  const { width } = useDeviceSize();
  const dispatch = useDispatch();
  const shown = useSelector((state) => state.common.shown);

  const [selectedIdList, setSelectedIdList] = useState(shown);
  const slidesItemToShow = useMemo(() => Math.floor((width - 50) / 150), [width]);
  const isCarousel = useMemo(
    () => slidesItemToShow < categories.length,
    [slidesItemToShow, categories]
  );

  const isSelectedCLass = (title) => (selectedIdList.includes(title) ? "selected" : "");

  useEffect(() => {
    setSelectedIdList(shown);
  }, [shown]);

  const onCategoryClick = (id) => {
    const choiceIdList = toggleElemInList(selectedIdList, id);

    setSelectedIdList(choiceIdList);
    dispatch(setShown(choiceIdList));
  };

  const ItemCategory = ({ el }) => (
    <li
      className={`item ${className}__category ${isSelectedCLass(el.id)}`}
      onClick={() => onCategoryClick(el.id)}
    >
      {el.icon}
      <span className={`${className}__categories__title`}>{el.title}</span>
      <button className={`${className}__category__cross`}>
        <IconCross />
      </button>
    </li>
  );

  return (
    <nav className={`${className}__nav mobile-header`}>
      {isCarousel ? (
        <Carousel
          style={{ color: "white" }}
          className="carousel"
          slidesToShow={Math.min(slidesItemToShow, categories.length)}
          autoplay
          draggable
          dots={false}
          autoplaySpeed={5000}
        >
          {categories.map((el, indx) => (
            <ItemCategory el={el} key={indx} />
          ))}
        </Carousel>
      ) : (
        <ul className="home__unheader__categories">
          {categories.map((el, indx) => (
            <ItemCategory el={el} key={indx} />
          ))}
        </ul>
      )}
    </nav>
  );
}
