import React from "react";
import { Link } from "react-router-dom";

export default function TermsContent() {
  return (
    <>
      <h1 className="center">Terms of Use</h1>
      <p>
        Please review these terms and conditions of use carefully before using 's websites,
        including, without limitation, the following websites:
      </p>
      <Link to={"/"}>RichCams.com</Link>
      <p>
        This document states the terms and conditions (“Terms”) upon which , DBA RichCams, of (“we”
        or “us”) will provide service to you on its websites, including, without limitation, the
        above listed websites (the “Website”). These Terms constitute a contractual agreement
        between you and us. By visiting, accessing, using, and/or joining (collectively “using”) the
        Website, you express your understanding and acceptance of these Terms. As used in this
        document, the terms “you” or “your” refers to you, any entity you represent, your or its
        representatives, successors, assigns and affiliates, and any of your or their devices. If
        you do not agree to be bound by these Terms, navigate away from the Website and cease using
        it.
      </p>

      <h2>Eligibility</h2>
      <p>
        You must be at least eighteen (18) years of age to use the Website, unless the age of
        majority in your jurisdiction is greater than eighteen (18) years of age, in which case you
        must be at least the age of majority in your jurisdiction. Use of the Website is not
        permitted where prohibited by law.
      </p>
      <p>
        The provisions of the The Content Reporting and Appeal Procedure and the related definitions
        are incorporated by reference herein and shall be deemed to have the same force and effect
        as if set forth in full herein.
      </p>

      <h2>Grant of Use</h2>
      <p>
        We grant you a non-exclusive, non-transferable and limited right to access, non-publicly
        display, and use the Website, including all content available therein (the “Content”) on
        your computer or mobile device consistent with these Terms.
      </p>
      <p>
        This grant is terminable by us at will for any reason and at our sole discretion, with or
        without prior notice. Upon termination, we may, but shall not be obligated to: (i) delete or
        deactivate your account, (ii) block your email and/or IP addresses or otherwise terminate
        your use of and ability to use the Website, and/or (iii) remove and/or delete any of your
        User Submissions (defined below). You agree not to use or attempt to use the Website after
        said termination. Upon termination, the grant of your right to use the Website shall
        terminate, but all other portions of these Terms shall survive. You acknowledge that we are
        not responsible to you or any third party for the termination of your grant of use.
      </p>

      <h2>Intellectual Property</h2>
      <p>
        The Content on the Website, excluding User Submissions and Third Party Content (defined
        below), but including other text, graphical images, photographs, music, video, software,
        scripts and trademarks, service marks and logos contained therein (collectively “Proprietary
        Materials”), are owned by and/or licensed to us. All Proprietary Materials are subject to
        copyright, trademark and/or other rights under the laws of applicable jurisdictions,
        including domestic laws, foreign laws, and international conventions. We reserve all our
        rights over our Proprietary Materials.
        <br />
        <br />
        Except as otherwise explicitly permitted, you agree not to copy, modify, publish, transmit,
        distribute, participate in the transfer or sale of, create derivative works of, or in any
        other way exploit, in whole or in part, any Content.
      </p>

      <h2>User Identity Verification</h2>
      <p>
        This verification is mandatory for all Users who wish to upload any content (other than an
        Avatar or a Background Photo) on the Website. We at our sole discretion may request from a
        User to verify its identity at any time and for any reason we reasonably believe it is
        necessary. Any content attempted to be uploaded by a non-verified User will not be published
        on the Website until the verification process is completed. Once the User completes the
        account verification procedure, the content will be moderated by our moderation team and if
        it complies with the relevant rules and regulations will be published on the User’s account
        automatically. Users can start the account verification procedure either through their
        profile or by attempting to upload any content on the Website. Users must provide a clear
        copy of a valid government issued identification document with a photo and a selfie holding
        the identification document. Failure to comply with a verification request, providing false
        or misleading information during the verification process or attempting to circumvent our
        verification review systems, may lead to a permanent block of the User’s account and the
        access to the Website.
      </p>

      <h2>User Submissions</h2>
      <p>
        You are entirely responsible for any and all materials you stream, upload, submit or
        otherwise make available via the Website, including videos or any other communications or
        profile information (collectively, “User Submissions”). For the purpose of these Terms, any
        reference to User Submissions includes any submissions/content made available via the
        Website from models. User Submissions cannot always be withdrawn. You acknowledge that any
        disclosure of personal information in User Submissions may make you personally identifiable
        and that we do not guarantee any confidentiality with respect to User Submissions.
      </p>
      <p>
        You shall be solely responsible for any and all of your own User Submissions and any and all
        consequences of positing, uploading, publishing or otherwise making them available. For any
        of your User Submissions, you affirm, represent and/or warrant that:
      </p>
      <ol>
        <li>
          You own or have the necessary licenses, permissions, rights or consents to use and
          authorize us to use all trademarks, copyrights, trade secrets or other proprietary rights
          in and to User Submissions for any and all uses contemplated by the Website and these
          Terms;
        </li>
        <li>
          You will not post, or allow anyone else to post, any material that depicts any individual
          under the age of eighteen (18) years and that you have inspected and are maintaining
          written documentation, pursuant to United States law18 U.S.C. § 2257, which we voluntarily
          comply with (and whether you are subject to United States law or not) and other analogous,
          relevant and/or applicable laws, to confirm that all individuals in your User Submission
          are, in fact, over the age of eighteen (18) years; and
        </li>
        <li>
          You have written consent, release, and/or permission from each and every identifiable
          individual in the User Submission to use the name and/or likeness of each and every such
          identifiable individual to enable use of the User Submission for any and all uses
          contemplated by the Websites and these Terms.
        </li>
      </ol>
      <ol>
        <li>
          Is copyrighted, protected by trade secret or trademark laws, or otherwise subject to third
          party proprietary rights, including privacy and publicity rights, unless you are the owner
          of such rights or have explicit permission from the rightful owner to submit the material
          and to grant us all of the license rights granted herein;
        </li>
        <li>
          Is obscene, vulgar, illegal, unlawful, defamatory, fraudulent, libelous, harmful,
          harassing, abusive, threatening, invasive of privacy or publicity rights, hateful,
          racially or ethnically offensive, inflammatory, or otherwise inappropriate as decided by
          us in our sole discretion;
        </li>
        <li>
          Depicts illegal activities, promotes or depicts physical harm or injury against any group
          or individual, or promotes or depicts any act of cruelty to animals;
        </li>
        <li>
          Impersonates any person or entity or otherwise misrepresents you in any way, including
          creating a false identity;
        </li>
        <li>
          Would constitute, encourage or provide instructions for a criminal offense, a violation of
          the rights of any party, or that would otherwise create liability or violate any local,
          state, national or international law; or
        </li>
        <li>
          Is unsolicited or unauthorized advertising, promotion, “spam” or any other form of
          solicitation.
        </li>
      </ol>
      <p>
        We claim no ownership or control over User Submissions or Third Party Content. You or a
        third party licensor, as appropriate, retain all copyrights to User Submissions and you are
        responsible for protecting those rights as appropriate. You irrevocably grant us a
        world-wide, non-exclusive, royalty-free, perpetual, non-cancelable, sub-licenseable license
        to reproduce, publicly perform, publicly display, distribute, adapt, modify, publish,
        translate, create derivative works of and otherwise exploit User Submissions for any
        purpose, including without limitation any purpose contemplated by the Website and these
        Terms. Furthermore, you also grant other users of the Website a right and license to
        display, stream and download User Submissions in connection with their use of the Website
        and for other personal use. You also irrevocably waive and cause to be waived against us and
        any of our users any claims and assertions of moral rights or attribution with respect to
        User Submissions.
      </p>
      <p>
        You represent and warrant that you have all the rights, power and authority necessary to
        grant the rights granted herein to User Submissions. Specifically, you represent and warrant
        that you own the title to the User Submissions, that you have the right to upload the User
        Submissions to the Website, and that uploading the User Submissions will not infringe upon
        any other party’s rights or your contractual obligations to other parties.
      </p>
      <p>
        You acknowledge that we may at our sole discretion refuse to publish, remove, or block
        access to any User Submission for any reason, or for no reason at all, with or without
        notice.
      </p>
      <p>
        Without limiting the other indemnification provisions herein, you agree to defend us against
        any claim, demand, suit or proceeding made or brought against us by a third-party alleging
        that your User Submissions or your use of the Website in violation of these Terms infringes
        or misappropriates the intellectual property rights of any third-party or violates
        applicable law and you shall indemnify us for any and all damages against us and for
        reasonable attorney’s fees and other costs incurred by us in connection with any such claim,
        demand, suit or proceeding.
      </p>

      <h2>Content on the Website</h2>
      <p>
        You understand and acknowledge that, when using the Website, you will be exposed to content
        from a variety of sources including content made available on the Website by other users and
        through automated or other means (collectively, “Third Party Content”) and that we do not
        control and are not responsible for any Third Party Content. You understand and acknowledge
        that you may be exposed to content that is inaccurate, offensive, indecent or otherwise
        objectionable or may cause harm to your computer systems and, without limiting the other
        limitation of liability provisions herein, you agree to waive, and hereby do waive, any
        legal or equitable rights or remedies you may have against us with respect thereto.
      </p>
      <p>
        We claim no ownership or control over Third Party Content. Third parties retain all rights
        to Third Party Content and they are responsible for protecting their rights as appropriate.
      </p>
      <p>
        You understand and acknowledge that we assume no responsibility whatsoever for monitoring
        the Websites for inappropriate content or conduct. If at any time we choose, in our sole
        discretion, to monitor such content, we assume no responsibility for such content, have no
        obligation to modify or remove any such content (including User Submissions and Third Party
        Content), and assume no responsibility for the conduct of others submitting any such content
        (including User Submissions and Third Party Content).
      </p>
      <p>
        Without limiting the provisions below on limitations of liability and disclaimers of
        warranties, all Content (including User Submissions and Third Party Content) on the Website
        is provided to you “AS-IS” for your information and personal use only and you shall not use,
        copy, reproduce, distribute, transmit, broadcast, display, sell, license or otherwise
        exploit for any other purpose whatsoever the Content without the prior written consent of
        the respective owners/licensors of the Content.
      </p>
      <p>
        You acknowledge that we may at our sole discretion refuse to publish, remove, or block
        access to any Content for any reason, or for no reason at all, with or without notice.
      </p>
      <p>
        By uploading content on the Website (including content uploaded by models), you are freely
        consent and grant a non-revocable license to, its affiliates and agents, to use the
        username, videos, pictures, and voice uploaded on the Website as they see fit. You further
        understand and consent that the content will appear publicly as part of’s website as well as
        third party websites and/or other marketing materials. The content will be subject to’s
        privacy policy and terms of use as well as the terms of use of the third-party websites.
      </p>

      <h2>User/Model Conduct</h2>
      <p>
        You represent and warrant that all the information and content provided by you to us is
        accurate and current and that you have all necessary rights, power and authority to (i)
        agree to these Terms, (ii) provide the User Submissions to us, and (iii) perform the acts
        required of you under these Terms.
      </p>
      <p>
        You hereby expressly authorize us to monitor, record and log any of your activities on the
        Website, including your chats, streams, messages, interactions with other users and User
        Submissions.
      </p>
      <p>As a condition of your use of the Website:</p>
      <ol>
        <li>
          You agree not to use the Websites for any unlawful purpose or in any way that is
          prohibited by these Terms;
        </li>
        <li>
          You agree to abide by all applicable local, state, national and international laws and
          regulations;
        </li>
        <li>
          You agree not to use the Websites in any way that exposes us to criminal or civil
          liability;
        </li>
        <li>
          You agree that you are solely responsible for all acts and omissions that occur as a
          result of your use of the Website;
        </li>
        <li>
          You agree that all your User Submissions that you provide to us belongs to you and that
          you have the right and authority to provide it to us;
        </li>
        <li>
          You agree to maintain the security of your login password and to be fully responsible for
          any and all use of your account;
        </li>
        <li>
          You agree not to use or attempt to use any other party’s account on the Websites without
          authorization;
        </li>
        <li>
          You agree not to use any automated means, including robots, crawlers or data mining tools,
          to download, monitor or use data or Content from the Website;
        </li>
        <li>
          You agree not to use the Website to collect usernames and/or email addresses for sending
          unsolicited messages of any kind;
        </li>
        <li>
          You agree not to take any action that imposes, or may impose, in our sole discretion, an
          unreasonable or disproportionately large load on our technology infrastructure or
          otherwise make excessive demands on it;
        </li>
        <li>You agree not to “stalk” or otherwise harass anyone on or through the Website;</li>
        <li>
          You agree not to forge headers or otherwise manipulate identifiers in order to disguise
          the origin of any information you transmit;
        </li>
        <li>
          You agree not to disable, circumvent, or otherwise interfere with security related
          features of the Website or features that prevent or restrict use or copying of any content
          or which enforce limitations on the use of the Website or the content therein;
        </li>
        <li>
          You agree not to post, link to, or otherwise make available on the Website any material
          that contains software viruses or any computer code, file or program designed to
          interrupt, destroy, limit or monitor the functionality of any computer software or
          hardware or any telecommunications equipment;
        </li>
        <li>
          You agree not to license, sublicense, sell, resell, transfer, assign, distribute or
          otherwise in any way commercially exploit or make available the Website or any Content to
          any third party;
        </li>
        <li>You agree not to “frame” or “mirror” the Website; and</li>
        <li>You agree not to reverse engineer any portion of the Website.</li>
        <li>
          You agree not to use a username of a hateful, racist, defamatory, ethnically offensive
          nature or any username related to children, terrorist organization and/or any other sites.
        </li>
        <p>
          We reserve the right to take appropriate action against any user for any unauthorized use
          of the Website, including civil, criminal and injunctive redress and the termination of
          any user’s use of the Website. Any use of the Website and our computer systems not
          authorized by these Terms is a violation of these Terms and certain international, foreign
          and domestic criminal and civil laws.
        </p>
      </ol>

      <h2>Tokens and Payments</h2>
      <p>
        You must purchase “tokens” which you can then use to give you full use of additional
        features of the Website. Prices for tokens will be posted on the Website and may change from
        time to time in our sole discretion. Prices are subject to change and may vary due to
        various reasons, including special and limited promotional offers. All payments, including
        for token purchases, must be made with your valid credit card or debit card or other
        available payment method, the information of which may be kept on file by us or by our
        payment processors (“Filed Payment Details”). Fees for tokens must be paid in advance of you
        using them.
      </p>
      <p>
        In order to make a purchase, you may be asked to supply certain information to allow us to
        process and authorize your purchase, including, without limitation, your name, address, card
        number, card expiration date, card security number and other information. You represent and
        warrant that (i) you have the legal right to use form of payment that you use and that (ii)
        the information that you are providing with that form of payment is true and correct. You
        acknowledge that we may use a third party for the purposes of processing or facilitating any
        payment and that by submitting your information to us you grant us the right to provide this
        information to such third parties.
      </p>
      <p>
        We reserve the right to refuse, cancel or terminate your order for any time and for any
        reason in our sole discretion. Without limiting the foregoing, we reserve the right to
        refuse, cancel or terminate your order because of product or service unavailability, errors
        in the description or price of our product or service and errors in your order.
      </p>
      <p>
        YOUR FILED PAYMENT DETAILS MAY BE AUTOMATICALLY CHARGED FOR YOUR FUTURE PURCHASES OR FOR ANY
        RECURRING PURCHASES WHICH YOU HAVE AUTHORISED. YOU HEREBY AUTHORIZE US AND OUR AGENTS
        (INCLUDING PAYMENT PROCESSORS WE MAY USE) TO CHARGE YOUR FILED PAYMENT DETAILS FOR SUCH
        PAYMENTS ON YOUR BEHALF.
      </p>
      <p>
        By enabling the auto-refill feature on the Website, you hereby expressly give your
        permission for us to automatically charge your last payment details on file, with an amount
        equivalent to your last purchase, as soon as the tokens in your account get below a chosen
        threshold. You can always opt-out from the auto-refill feature at any time, by going to your
        account's "Settings".
      </p>
      <p>
        You agree not to report as fraudulent, lost or stolen any form of payment which you have
        used in conjunction with payment to us, for which you do not have a good faith reason to
        believe is in fact fraudulent, lost or stolen. You agree not to report as unauthorized any
        charge by us for any goods or services, including subscriptions, for which you do not have a
        good faith reason to believe is in fact unauthorized. You agree that, absent good faith, in
        the event of any such report you shall be liable to us for such charge or obligation plus an
        additional $100 administrative fee. The liability specified in this paragraph will not limit
        our rights or any other liability you may have for any other reason, including a breach of
        any other provision of these Terms.
      </p>
      <p>
        <em>Refunds:</em>
      </p>
      <p>
        If at any time we terminate your rights to use the Website because of a breach of these
        Terms, you shall not be entitled to a refund of any portion of your unused tokens. In all
        other respects, such fees shall be governed by additional rules, terms, conditions or
        agreements posted on the Website and/or imposed by any sales agent or payment processing
        company, as may be amended from time to time.
      </p>
      <p>
        Without limiting the foregoing, we may in our discretion refund you tokens when there are
        technical problems with the Website (i.e., when the Website malfunctions and takes your
        tokens) or when you have had a provably unsatisfactory private show (in which case, you must
        end the show as soon as possible). However, we will never refund tokens when you have given
        a tip (as tips are gifts and cannot be reversed) or when you are spying on another person’s
        private show.
      </p>
      <p>
        <em>Inactive accounts:</em>
      </p>
      <p>
        If a period of inactivity is detected (over 12 months) in your account ie you neither use
        your account nor purchase any tokens, then we reserve the right and at our sole discretion
        to terminate your rights to use the Website.
      </p>
      <p>
        In such a case, you hereby expressly accept, agree and acknowledge that any portion of your
        unused tokens is forfeited by you and cannot subsequently be recovered.
      </p>

      <h2>Services on Website</h2>
      <p>
        We offer you and other users many ways to use the Website (which may change in our
        discretion). We may offer chat sessions where you can chat with other users and models on
        the website while viewing the model’s video stream. You may “tip” a model your purchased
        tokens. We may offer group shows where you can view a model’s video stream and chat with the
        model along with other users, for a per-second or per-minute fee billed against your
        purchased tokens in accordance with the rates posted on the Website. We may offer private
        shows where you can view a model’s video stream and chat with the model privately (although
        other users may spy without interacting), for a per-second or per-minute fee billed against
        your purchased tokens in accordance with the rates posted on the Website. We may offer to
        permit you to spy on another user’s private show with a model, for a per-second or
        per-minute fee billed against your purchased tokens in accordance with the rates posted on
        the Website. You acknowledge that there may be further services added or that some of the
        forgoing services may be modified or removed in our discretion and all such services will be
        in accordance with the rules that we may post on the Website, as they may be modified from
        time to time.
      </p>
      <p>
        You acknowledge that if you are engaged in any activity that bills you per-second or
        per-minute, you must exit such activity to stop the billing of the same. You also
        acknowledge that if you run out of purchased tokens while engaged in such activity, your
        ability to engage in such activity will halt. We reserve the right to round prices.
      </p>
      <p>
        You acknowledge that all models and other users may engage in any activities in their
        discretion while in accordance with the terms of this Agreement and other rules and
        regulations posted on the Website and pursuant to the functionality of the Website. You
        acknowledge that a model may decline to engage in any activity in his or her discretion.
        Without limiting the general release provisions below, if you have any disputes with any
        models, you acknowledge that your dispute is solely with said model and you release us from
        any liability.
      </p>
      <h2>Trust & Safety</h2>
      <p>
        We care deeply about maintaining a healthy community at our Website. We have some of the
        most creative and expressive people and we want to create the safest environment for them
        where they can communicate and interact safely. We ensure the safety of all content through
        strong pre-moderation processes for unpublished content.
      </p>
      <p>
        Types of Content We strive to ensure that all content is harm-free. This includes but not
        limited to
        <ul>
          <li>Text</li>
          <li>Images</li>
          <li>Videos</li>
          <li>Live broadcasts</li>
        </ul>
      </p>
      <h4>Our Definition of Harm</h4>
      <p>
        We consider content harmful if it harms the owner of the content, another person on or
        outside the website, or our Website overall. We consider any type of harm including but not
        limited to legal, physical, psychological, financial. We use specific guidelines explained
        in this page to define our own perception of such harm.
      </p>
      <h4>Our Tools & Procedures</h4>
      <p>
        We employ multiple mechanisms in place that limit the possibility of harmful content
        reaching our Website (before publishing) as well as detect possibly harmful content after
        publishing. Our Website over and above the requirements and standards set, only accepts
        content depicting persons registered and verified in its Website. Our Website does not rely
        on third parties to collect the consent of the persons depicted in the content available in
        its Website. By applying this rule, the Website aims to eliminate any possible distribution
        of non-consensual content through its website.
      </p>
      <p>
        A dedicated in-house moderation team manually monitors and screens the content which has
        been submitted for publication to our Website on a 24/7 basis. The moderators follow the
        Website’s rules and guidelines to protect online users by keeping incompatible with the
        terms of use, illegal and inappropriate content off the site.
      </p>
      <h4>Verification System for Models</h4>
      <p>
        To fight fraud and ensure that all our models (Live broadcasters) can broadcast legally,
        they must be verified by our registration system first. We use both a third party KYC
        solution as well manual moderation to verify the legitimacy of all models and the documents
        they provide.
      </p>
      <h4>Pre-Recorded Content Moderation</h4>
      <p>
        We perform a pre-moderation of all the pre-recorded content including images and videos that
        our users and models upload, through both automated and human moderation, to ensure that the
        content is not illegal, violates third party industry standards, violates our internal rules
        and that the person depicted in it, consents to the distribution of the content, before such
        content is published on our Website. We refuse any content that we deem harmful as specified
        in our guidelines.
      </p>
      <h4>Suspicious Messages Pre-Moderation</h4>
      <p>
        We use an automated system to flag possible harmful messages to our team, in some cases if
        the system has sufficient reason to categorise a message as high risk, it will not be
        visible on our website until it is properly reviewed by a human Moderation Specialist.
      </p>
      <h4>Third Party Reports</h4>
      <p>
        We also investigate all reports sent our way through our different reporting mechanisms
        available within the Website. We prioritise and handle reports based on the severity of
        violation reported.
      </p>
      <h2>Privacy Policy</h2>
      <p>
        We retain a separate Privacy Policy and your assent to these Terms also signifies your
        assent to the Privacy Policy. We reserve the right to amend the{" "}
        <Link to={"/policy"}> Privacy Policy</Link> at any time by posting such amendments to the
        Website. No other notification may be made to you about any amendments. Your continued use
        of the Website following such amendments will constitute your acceptance of such amendments,
        regardless of whether you have actually read them.
      </p>
      <h2>Copyright and Intellectual Property Claims</h2>
      <p>
        We respect the intellectual property rights of others. You may not infringe the copyright,
        trademark or other proprietary informational rights of any party. We may in our sole
        discretion remove any Content we have reason to believe violates any of the intellectual
        property rights of others and may terminate your use of the Website if you submit any such
        Content.
      </p>
      <p>
        REPEAT INFRINGER POLICY. AS PART OF OUR REPEAT-INFRINGEMENT POLICY, ANY USER FOR WHOSE
        MATERIAL WE RECEIVE THREE GOOD-FAITH AND EFFECTIVE COMPLAINTS WITHIN ANY CONTIGUOUS
        SIX-MONTH PERIOD WILL HAVE HIS GRANT OF USE OF THE WEBSITES TERMINATED.
      </p>
      <h2>Modification of These Terms</h2>
      <p>
        We reserve the right to amend these Terms at any time by posting such amended Terms to the
        Website. No other notification may be made to you about any amendments. YOU ACKNOWLEDGE THAT
        YOUR CONTINUED USE OF THE WEBSITE FOLLOWING SUCH AMENDMENTS WILL CONSTITUTE YOUR ACCEPTANCE
        OF SUCH AMENDMENTS, REGARDLESS OF WHETHER YOU HAVE ACTUALLY READ THEM.
      </p>
      <h2>Indemnification and Release</h2>
      <p>
        You hereby agree to indemnify us and hold us harmless from any and all damages and
        third-party claims and expenses, including attorney’s fees, arising from your use of the
        Website or from your breach of these Terms.
        <br />
        In the event that you have a dispute with one of more other users (including models) or any
        third parties, you hereby release us, our officers, employees, agents and
        successors-in-right from claims, demands and damages (actual and consequential) of every
        kind or nature, known and unknown, suspected and unsuspected, disclosed and undisclosed,
        arising out of or in any way related to such disputes and/or the Website.
      </p>
      <h2> Disclaimer of Warranties and Limitations of Liabilities </h2>
      <p>
        READ THIS SECTION CAREFULLY AS IT LIMITS OUR LIABILITY TO THE MAXIMUM EXTENT PERMITTED UNDER
        APPLICABLE LAW.
      </p>
      <p>
        The Website may contain links to third-party websites which are independent of us. We assume
        no responsibility for the content, privacy policies, or practices of and make no
        representation or warranty as to the accuracy, completeness or authenticity of information
        contained in any third party websites. We have no right or ability to edit the content of
        any third party websites. You acknowledge that we shall not be liable for any and all
        liability arising from your use of any third party websites.
      </p>
      <p>
        The Website is provided “AS-IS” and without any warranty or condition, express, implied or
        statutory. We specifically disclaim to the fullest extent any implied warranties of
        merchantability, fitness for a particular purpose, non-infringement, information accuracy,
        integration, interoperability or quiet enjoyment. We disclaim any warranties for viruses or
        other harmful components in connection with the Websites. Some jurisdictions do not allow
        the disclaimer of implied warranties, therefore in such jurisdictions, some of the foregoing
        disclaimers may not apply to you insofar as they relate to such implied warranties.
      </p>
      <p>
        UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR DIRECT, INDIRECT INCIDENTAL, SPECIAL,
        CONSEQUENTIAL OR EXEMPLARY DAMAGES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES) RESULTING FROM ANY ASPECT OF YOUR USE OF THE WEBSITE, WHETHER, WITHOUT LIMITATION,
        SUCH DAMAGES ARISE FROM (i) YOUR USE, MISUSE OR INABILITY TO USE THE WEBSITE, (ii) YOUR
        RELIANCE ON ANY CONTENT ON THE WEBSITE, (iii) THE INTERRUPTION, SUSPENSION, MODIFICATION,
        ALTERATION OR COMPLETE DISCONTINUANCE OF THE WEBSITE OR (iv) THE TERMINATION OF SERVICE BY
        US. THESE LIMITATIONS ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF OTHER
        SERVICES OR PRODUCTS RECEIVED OR ADVERTISED IN CONNECTION WITH THE WEBSITE. SOME
        JURISDICTIONS DO NOT ALLOW SOME LIMITATIONS OF LIABILITY, THEREFORE, IN SUCH JURISDICTIONS,
        SOME OF THE FOREGOING LIMITATIONS MAY NOT APPLY TO YOU.
      </p>
      <p>
        WE DO NOT WARRANT THAT (i) THE WEBSITE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (ii) THE
        WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE
        OBTAINED FROM YOUR USE OF THE WEBSITE WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY
        PRODUCTS, SERVICES, INFORMATION, CONTENT OR OTHER MATERIAL OBTAINED THROUGH THE WEBSITE WILL
        MEET YOUR REQUIREMENTS OR EXPECTATIONS, OR (v) ANY ERRORS IN CONTENT WILL BE CORRECTED.
      </p>
      <p>
        ANY CONTENT OBTAINED THROUGH THE USE OF THE WEBSITE IS OBTAINED AT YOUR OWN DISCRETION AND
        RISK. YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR
        LOSS OF DATA THAT RESULTS FROM SUCH CONTENT.
        <br />
        <br />
        YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE WEBSITE OR ANY
        OTHER GRIEVANCE SHALL BE THE TERMINATION OF YOUR USE OF THE WEBSITE. WITHOUT LIMITING THE
        FOREGOING, IN NO CASE SHALL THE MAXIMUM LIABILITY OF US ARISING FROM OR RELATING TO YOUR USE
        OF THE WEBSITES EXCEED $100.
      </p>
      <h2>General Terms</h2>
      <ul>
        <li>
          These Terms, as amended from time to time, constitute the entire agreement between you and
          us and supersede all prior agreements between you and us and may not be modified without
          our written consent.
        </li>
        <li>
          Our failure to enforce any provision of these Terms will not be construed as a waiver of
          any provision or right.
        </li>
        <li>
          If any part of these Terms is determined to be invalid or unenforceable pursuant to
          applicable law, then the invalid and unenforceable provision will be deemed superseded by
          a valid, enforceable provision that most closely matches the intent of the original
          provision and the remainder of the agreement shall continue in effect.
        </li>
        <li>
          Nothing herein is intended, nor will be deemed, to confer rights or remedies upon any
          third party.
        </li>
        <li>
          These Terms are not assignable, transferable or sub-licensable by you except with our
          prior written consent, but may be assigned or transferred by us without restriction.
        </li>
        <li>
          You agree that we may provide you with notices by email, regular mail, or postings to the
          Website.
        </li>
        <li>
          The section titles in these Terms are for convenience only and have no legal or
          contractual effect.
        </li>
        <li>As used in these Terms, the term “including” is illustrative and not limitative.</li>
        <li>
          If these Terms or any other documents between you and us are translated and executed in
          any language other than English and there is any conflict as between the translation and
          the English version, the English version shall control.
        </li>
      </ul>
    </>
  );
}
