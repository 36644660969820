import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import imgChrome from "../../images/template/Chrome.png";

export default function SupportWebRTC() {
  const { t } = useTranslation();

  const isWebRTC = useMemo(
    () =>
      !!(
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia
      ),

    []
  );

  return (
    !isWebRTC && (
      <div className="np-support-webrtc">
        <span>{t("Your browser is outdated and does not support WebRTC.")}</span>
        <span>
          <img src={imgChrome} />
          <a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer">
            {t("Install Chrome Browser.")}
          </a>
        </span>
      </div>
    )
  );
}
