import React from "react";
import FulScreen from "./components/FulScreen";
import Eye from "./components/Eye";
import Share from "./components/Share";
// import Screenshot from "./components/Screenshot";
import BalanceInfo from "../../../user/BalanceInfo";
import Live from "./components/Live";
import Volume from "./components/Volume";
import CusButton from "../../../mui/CusButton";
import IconChat from "../../../../images/jsIcons/IconChatOne";
import IconChatOff from "../../../../images/jsIcons/IconChatOff";
import ChatFree from "../../chat/ChatFree";
import useDeviceSize from "../../../../hooks/useDeviceSize";
import Camera from "./components/Camera";
import Screenshot from "./components/Screenshot";
import SendTips from "./components/SendTips";
import PrivateButton from "./components/PrivateButton";
import { useDispatch, useSelector } from "react-redux";
import { setFullscreenChat } from "../../../../store/commonSlice";
import ShownControl from "../../../mui/ShownControl";
import { useEventListener } from "usehooks-ts";

export default function ControlsFs({
  realVideoRef,
  chatWindowRef,
  videoRef,
  audioRef,
  setOpenCamera,
}) {
  const { isMobile } = useDeviceSize();
  const dispatch = useDispatch();
  const openChat = useSelector((state) => state.common.controls.isFullscreenChat);

  const isRow = isMobile ? "" : "row";

  const hiddenChatClass = openChat ? "" : "hidden";

  useEventListener("click", (e) => {
    if (isMobile) return;
    if (e.target.id !== "zoomist-wrapper") return;

    dispatch(setFullscreenChat(!openChat));
  });

  return (
    <>
      <div className="chat__window__controls__top">
        <div className="chat__window__controls__top__left">
          <Live />
          <Eye />
          <ShownControl>{isMobile && <BalanceInfo />}</ShownControl>
        </div>
        <div className="chat__window__controls__top__right">
          <>
            <ShownControl>{!isMobile && <BalanceInfo />}</ShownControl>
            <Screenshot videoRef={videoRef} />
            <Camera setOpenCamera={setOpenCamera} />
            {!isMobile && <Volume audioRef={audioRef} />}
            <ShownControl>
              <CusButton
                id={"chat-button"}
                icon={openChat ? <IconChat /> : <IconChatOff />}
                onClick={() => dispatch(setFullscreenChat(!openChat))}
              />
            </ShownControl>
            <Share />
            <FulScreen
              chatWindowRef={chatWindowRef}
              videoRef={videoRef}
              realVideoRef={realVideoRef}
            />
          </>
        </div>
      </div>

      <div className={`chat__window__controls__left ${hiddenChatClass}`}>
        <ChatFree />
      </div>

      <div className={`chat__window__controls__right ${isRow}`}>
        <PrivateButton />
        <SendTips />
      </div>
    </>
  );
}
