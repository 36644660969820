import React from "react";

export default function IconDiamond() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
      <path d="M0 4.77783L5.55025 12.917L3.42314 4.77783H0Z" fill="#767676" />
      <path
        d="M4.54799 0.499512H3.01113L0.126953 3.88317H3.44262L4.54799 0.499512Z"
        fill="#767676"
      />
      <path d="M12.8727 3.88317L9.98849 0.499512H8.45166L9.557 3.88317H12.8727Z" fill="#767676" />
      <path d="M7.44922 12.917L12.9995 4.77783H9.57633L7.44922 12.917Z" fill="#767676" />
      <path d="M8.74083 3.88317L7.63546 0.499512H5.36467L4.25928 3.88317H8.74083Z" fill="#767676" />
      <path d="M4.22021 4.77783L6.49972 13.5001L8.77922 4.77783H4.22021Z" fill="#767676" />
    </svg>
  );
}
