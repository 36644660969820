import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../components/mui/Spinner";
import { API_LOGIN } from "../api/login";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function EmailConfirm() {
  const { t } = useTranslation();

  const [query] = useSearchParams();
  const key = query.get("key");
  const email = query.get("email");
  const navigate = useNavigate();

  useEffect(() => {
    const data = { key: key, email: email };

    API_LOGIN.confirm(data)
      .then((e) => {
        navigate({
          pathname: "/",
          search: `confirmEmail=${
            e.data?.message || t("Your email has been successfully confirmed")
          }`,
        });
      })
      .catch((e) => {
        navigate({
          pathname: "/",
          search: `errorEmail=${e.data?.message || t("An unknown error was received")}`,
        });
      });
  }, []);

  return (
    <main
      className={`home ${t("currentLang")}`}
      style={{
        display: "flex",
        minHeight: "100svh",
        background: "black",
      }}
    >
      <Spinner style={{ margin: "auto" }} />
    </main>
  );
}
