import React, { useMemo, useState } from "react";
import ControlsBottom from "./ControlsBottom";
import ControlsTop from "./ControlsTop";
import ControlsLeft from "./ControlsLeft";
import ControlsRight from "./ControlsRight.jsx";
import { useSelector } from "react-redux";
import ControlsFs from "./ControlsFs.jsx";
import useDeviceSize from "../../../../hooks/useDeviceSize.js";
import DrugSize from "./components/DrugSize.jsx";
import { ENUM_STATUS_WINDOW } from "../../../../store/chatSlice.js";
import CameraSetting from "../../CameraSetting.jsx";
import SocialIcons from "./components/social_icons/SocialIcons.jsx";

export default function ChatControls({
  videoRef,
  canvasRef,
  divZoomRef,
  chatWindowRef,
  setMyStream,
  myStream,
  audioRef,
  politicMute,
  onUnMute,
}) {
  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);
  const status = useSelector((state) => state.chat.statusWindow);

  const { isMobile } = useDeviceSize();
  const isFullScreenClass = isFullScreen ? "fool-screen" : "";

  const [openCamera, setOpenCamera] = useState(false);

  const available = useMemo(() => status === ENUM_STATUS_WINDOW.stream, [status]);

  return (
    <>
      <div className={`chat__window__controls ${isFullScreenClass} `} onClick={onUnMute}>
        {isFullScreen ? (
          <ControlsFs
            setOpenCamera={setOpenCamera}
            realVideoRef={videoRef}
            videoRef={canvasRef}
            audioRef={audioRef}
          />
        ) : (
          <>
            <ControlsTop
              className={"user-video"}
              setOpenCamera={setOpenCamera}
              chatWindowRef={chatWindowRef}
              videoRef={canvasRef}
              politicMute={politicMute}
              onUnMute={onUnMute}
              realVideoRef={videoRef}
            />
            <ControlsLeft videoRef={canvasRef} />
            <ControlsRight audioRef={audioRef} politicMute={politicMute} onUnMute={onUnMute} />
          </>
        )}
        {available && <DrugSize videoRef={videoRef} canvasRef={divZoomRef} />}
        {isMobile ? (
          <div className="social-icons__container__mobile">
            <SocialIcons />
          </div>
        ) : (
          <ControlsBottom />
        )}
      </div>
      <CameraSetting
        myStream={myStream}
        setMyStream={setMyStream}
        isOfferStream
        isOpen={openCamera}
        closeModal={() => setOpenCamera(false)}
        buttonText={myStream?.id ? "Change setting" : "Turn on my camera"}
      />{" "}
    </>
  );
}
