import React, { useEffect, useRef, useState } from "react";
import IconArrow from "../../images/jsIcons/IconArrow";
import IconClick from "../../images/jsIcons/IconClick";
import IconGallery from "../../images/jsIcons/IconGallery";
import IconCamera from "../../images/jsIcons/IconCamera";
import CusButton from "../mui/CusButton";
import { getBase64 } from "../../utils/utils";
import { API_PROFILE } from "../../api/profile";
import { errorToast } from "../mui/Toaster";
import { setAvatarUser } from "../../store/userSlice";
import { useDispatch } from "react-redux";
import IconSetting from "../../images/jsIcons/IconSetting";
import CameraSetting from "../chat/CameraSetting";
import { useLocalStorage } from "usehooks-ts";
import { useTranslation } from "react-i18next";

const DELAY_GET_PHOTO = 5 * 1000;

export default function NewAvatar({ setChangePhoto, isOpen }) {
  const [cameraId] = useLocalStorage("cameraId", undefined);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [uploadImg, setUploadImg] = useState("");
  const [beginCapture, setBeginCapture] = useState(false);
  const [finishedPhoto, setFinishedPhoto] = useState(false);
  const [timer, setTimer] = useState(false);
  const [openCamSetting, setOpenCamSetting] = useState(false);

  const captureOrPhoto = beginCapture || finishedPhoto;

  const uploadRef = useRef();
  const setProfileRef = useRef();
  const base64FullRef = useRef();
  const mediaStreamRef = useRef();
  const canvasRef = useRef();
  const videoRef = useRef();
  const photoRef = useRef();
  const getPhotoTimerIdRef = useRef();
  const timerRef = useRef(0);

  const onClickBack = () => {
    setTimer(0);
    setChangePhoto(false);
    setBeginCapture(false);
    setFinishedPhoto(false);
  };

  const onUploadClick = () => {
    uploadRef.current?.click?.();
    onCancelPhoto();
  };

  const onClickReset = () => {
    setUploadImg("");
    onCancelPhoto();

    if (!captureOrPhoto && !uploadImg) {
      onClickBack();
    }
  };

  const closeStream = async () => {
    if (mediaStreamRef.current) {
      const tracks = mediaStreamRef.current.getTracks();

      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        await track.stop();
      }
    }
  };

  const getPhoto = async () => {
    setTimer(0);
    clearTimeout(getPhotoTimerIdRef.current);
    const head = "data:image/png;base64,";
    const nowDate = new Date().getDate() + "." + (new Date().getMonth() + 1);

    const photo = photoRef.current;
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (!canvas) return;

    const context = canvas.getContext("2d");

    context.drawImage(video, 0, 0, 300, 300);
    photo.setAttribute("src", canvas.toDataURL());

    const dataUrl = canvas.toDataURL();
    const base64 = dataUrl.split(",")[1];
    const imgFileSize = Math.round(((dataUrl.length - head.length) * 3) / 4);

    const dataAvatar = {
      data: base64,
      filename: `camera-shot-${nowDate}.png`,
      size: imgFileSize,
      type: "image/png",
    };

    setProfileRef.current = dataAvatar;

    closeStream();
    setUploadImg("");
    setFinishedPhoto(true);
    setBeginCapture(false);
  };

  const onCancelPhoto = async () => {
    setTimer(0);
    setBeginCapture(false);
    setFinishedPhoto(false);
    clearTimeout(getPhotoTimerIdRef.current);
    await closeStream();
  };

  const onCameraSettingClick = async () => {
    await onCancelPhoto();
    setOpenCamSetting(true);
  };

  const onBeginClick = () => {
    if (!cameraId) {
      setOpenCamSetting(true);
      return;
    }

    setTimer(0);
    setBeginCapture(true);
    setFinishedPhoto(false);

    const video = videoRef.current;
    var constraints = {
      audio: false,
      video: { width: 300, height: 300, deviceId: { exact: cameraId } },
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (mediaStream) {
        mediaStreamRef.current = mediaStream;

        getPhotoTimerIdRef.current = setTimeout(getPhoto, DELAY_GET_PHOTO);
        setTimer(DELAY_GET_PHOTO / 1000);

        video.srcObject = mediaStream;
        video.onloadedmetadata = () => video.play();
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });
  };

  const onContinueClick = () => {
    API_PROFILE.personal
      .setAvatar(setProfileRef.current)
      .then(() => {
        API_PROFILE.personal
          .getProfile()
          .then((e) => {
            const avatar = e.data?.profile?.avatar;
            if (avatar) dispatch(setAvatarUser(avatar));
          })
          .catch((e) => console.log(e))
          .finally(() => setChangePhoto(false));
      })
      .catch((e) => {
        console.log(e);
        errorToast(e);
        setChangePhoto(false);
      });
  };

  const onUploadChange = async (e) => {
    const file = e.target.files[0];
    const base64Full = await getBase64(file, true);
    base64FullRef.current = base64Full;
    const base64 = base64Full.split(",")[1];

    const dataAvatar = {
      data: base64,
      filename: file.name,
      size: file.size,
      type: file.type,
    };

    setProfileRef.current = dataAvatar;
    setUploadImg(base64Full);
  };

  const onSaveClick = async (tracks) => {
    await closeStream();
    tracks?.getTracks?.()?.forEach((t) => t?.stop?.());
  };

  useEffect(() => {
    if (timer < 1) return;
    setTimeout(() => {
      timerRef.current = setTimer((prev) => prev - 1);
    }, 1000);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [timer]);

  useEffect(() => {
    if (!isOpen) onCancelPhoto();
  }, [isOpen]);

  return (
    <>
      <div className="new-avatar">
        <div className="new-avatar__back" onClick={onClickBack}>
          <IconArrow className={"rotate90"} />
          <span>{t("Back to your account details")}</span>
        </div>
        <div className="new-avatar__description">
          <IconClick />
          <span className="new-avatar__description__text">
            {t("Choose one of the proposed options")}
          </span>

          <CusButton
            className="new-avatar__camera-setting"
            icon={<IconSetting />}
            text={"Camera setting"}
            onClick={onCameraSettingClick}
          />
        </div>
        <div className="new-avatar__content">
          {!captureOrPhoto && (
            <div className="new-avatar__block">
              {uploadImg ? (
                <div className="new-avatar__block__img">
                  <img src={uploadImg} alt="upload-img" />
                </div>
              ) : (
                <div className="new-avatar__block__img" onClick={onUploadClick}>
                  <div className="new-avatar__block__img__circle-1" />
                  <div className="new-avatar__block__img__circle-2" />
                  <IconGallery />
                </div>
              )}
              <span className="new-avatar__block__text">{t("Upload photo from gallery")}</span>
              <CusButton text={"Select a file"} onClick={onUploadClick} />
              <input
                onChange={onUploadChange}
                ref={uploadRef}
                type="file"
                className="hidden"
                accept="image/png, image/gif, image/jpeg"
              />
            </div>
          )}

          {!uploadImg && (
            <div
              className={`new-avatar__block ${captureOrPhoto ? "capture-take" : ""} ${
                finishedPhoto ? "take-photo" : ""
              } `}
            >
              <div className={`new-avatar__block__booth ${captureOrPhoto ? "" : "hidden"}`}>
                {timer > 0 && <span className="new-avatar__block__booth__timer">{timer}</span>}

                <img
                  ref={photoRef}
                  src="http://goo.gl/qgUfzX"
                  id="photo"
                  alt="Ваша фотография"
                  className={finishedPhoto ? "" : "hidden"}
                />

                <video
                  ref={videoRef}
                  id="video"
                  autoPlay
                  className={`new-avatar__block__video ${finishedPhoto ? "hidden" : ""}`}
                />

                <canvas
                  ref={canvasRef}
                  id="canvas"
                  className="new-avatar__canvas"
                  width="300"
                  height="300"
                />
              </div>
              {!captureOrPhoto && (
                <>
                  <div className="new-avatar__block__img" onClick={onBeginClick}>
                    <div className="new-avatar__block__img__circle-1" />
                    <div className="new-avatar__block__img__circle-2" />
                    <IconCamera />
                  </div>
                  <span className="new-avatar__block__text">{t("Take a photo now")}</span>
                  {!beginCapture && <CusButton text={"Begin"} onClick={onBeginClick} />}
                </>
              )}
            </div>
          )}
        </div>

        <div className="new-avatar__buttons">
          {beginCapture ? (
            <>
              <CusButton color="red" text={"Take a photo"} onClick={getPhoto} />
              <CusButton text={"Cancel"} onClick={onCancelPhoto} />
            </>
          ) : (
            <>
              <CusButton color={"red"} text={"Continue"} onClick={onContinueClick} />

              {captureOrPhoto ? (
                <CusButton text={"Try again"} onClick={onBeginClick} />
              ) : (
                <CusButton text={"Cancel"} onClick={onClickReset} />
              )}
            </>
          )}
        </div>
      </div>
      <CameraSetting
        isOpen={openCamSetting}
        closeModal={() => setOpenCamSetting(false)}
        onSaveClick={onSaveClick}
      />
    </>
  );
}
