import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import CloseModal from "../mui/CloseModal";
import ImgAvatar from "../mui/ImgAvatar";
import { useSelector } from "react-redux";
import CusInput from "../mui/CusInput";

export default function ShareModal({ isOpen, closeModal }) {
  const { imgSource, name, id: roomId } = useSelector((state) => state.chat.broadcaster);

  const { t } = useTranslation();

  return (
    <Modal
      ariaHideApp={false}
      className={`share-modal ${t("currentLang")}`}
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className="share__content">
        <CloseModal close={closeModal} />
        <div className="share__header">
          <ImgAvatar id={roomId} avatarProps={imgSource} />
          <h1 className="share__title">@{name}</h1>
        </div>
        <div className="share__fields">
          <CusInput
            defaultValue={window.location.href}
            isCopy
            text={"Link to this chat room"}
            readOnly
          />
          <CusInput defaultValue={roomId} isCopy text={"Model ID"} readOnly />
        </div>
      </div>
    </Modal>
  );
}
