import React from "react";

export default function IconLock() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M16 23.1338C17.2003 23.1338 18.1733 22.1607 18.1733 20.9604C18.1733 19.7601 17.2003 18.7871 16 18.7871C14.7997 18.7871 13.8267 19.7601 13.8267 20.9604C13.8267 22.1607 14.7997 23.1338 16 23.1338Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M24.3733 12.707V11.0403C24.3733 7.44032 23.5066 2.66699 16 2.66699C8.49329 2.66699 7.62663 7.44032 7.62663 11.0403V12.707C3.89329 13.1737 2.66663 15.067 2.66663 19.7203V22.2003C2.66663 27.667 4.33329 29.3337 9.79996 29.3337H22.2C27.6666 29.3337 29.3333 27.667 29.3333 22.2003V19.7203C29.3333 15.067 28.1066 13.1737 24.3733 12.707ZM16 24.987C13.7733 24.987 11.9733 23.1737 11.9733 20.9603C11.9733 18.7337 13.7866 16.9337 16 16.9337C18.2133 16.9337 20.0266 18.747 20.0266 20.9603C20.0266 23.187 18.2266 24.987 16 24.987ZM9.79996 12.587C9.69329 12.587 9.59996 12.587 9.49329 12.587V11.0403C9.49329 7.13366 10.6 4.53366 16 4.53366C21.4 4.53366 22.5066 7.13366 22.5066 11.0403V12.6003C22.4 12.6003 22.3066 12.6003 22.2 12.6003H9.79996V12.587Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
}
