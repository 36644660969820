import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import "./style/vars.scss";
import "./style/base.scss";
import "./style/materials/materials.scss";
import "zoomist/css";
import "zoomist/css";
import EmailConfirm from "./pages/EmailConfirm";
import ResetPassword from "./pages/ResetPassword";
import Toaster from "./components/mui/Toaster";
import useCommonHooks from "./hooks/useCommonHooks";
import P18usc2257 from "./pages/P18usc2257";
import CommonModals from "./components/common/CommonModals";
import CurrentModel from "./pages/CurrentModel";
import DMCA from "./pages/DMCA";
import Cookie from "./pages/Cookie";
import Policy from "./pages/Policy";
import Support from "./pages/Support";
import About from "./pages/About";
import Terms from "./pages/Terms";

function App() {
  useCommonHooks();

  return (
    <>
      <CommonModals />
      <Router basename="/">
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/client/email/confirm" exact element={<EmailConfirm />}></Route>
          <Route path="/client/email/change" exact element={<EmailConfirm />}></Route>
          <Route path="/client/password/reset" exact element={<ResetPassword />}></Route>
          <Route path="/2257" exact element={<P18usc2257 />}></Route>
          <Route path="/dmca" exact element={<DMCA />}></Route>
          <Route path="/contact" exact element={<Support />}></Route>
          <Route path="/cookie" exact element={<Cookie />}></Route>
          <Route path="/policy" exact element={<Policy />}></Route>
          <Route path="/about" exact element={<About />}></Route>
          <Route path="/terms" exact element={<Terms />}></Route>
          <Route path="/chat/:id" exact element={<CurrentModel />}></Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="/*" element={<Home />}></Route>
        </Routes>
      </Router>
      <Toaster />
    </>
  );
}

export default App;
