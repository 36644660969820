import React from "react";
import CusButton from "./CusButton";
import { useTranslation } from "react-i18next";

const ErrorPlug = () => {
  const { t } = useTranslation();

  return (
    <div className="error-block-plug">
      <span>{t("Sorry, failed to load data. Refresh the page or click “Refresh”")}</span>
      <CusButton color="red" text={"Refresh"} onClick={() => window.location.reload()} />
    </div>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    // const { children, fallbackComponent: FallbackComponent } = this.props;

    if (hasError) {
      // return <FallbackComponent />;
      return <ErrorPlug />;
    }

    return children;
  }
}

export default ErrorBoundary;
