import React from "react";

export default function IconOut() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.947754 7.00016C0.947754 7.24179 1.14363 7.43766 1.38525 7.43766L7.78591 7.43766L6.6422 8.41799C6.45874 8.57524 6.4375 8.85143 6.59475 9.03488C6.75199 9.21834 7.02819 9.23958 7.21164 9.08234L9.25331 7.33234C9.35028 7.24922 9.40609 7.12788 9.40609 7.00016C9.40609 6.87245 9.35028 6.75111 9.25331 6.66799L7.21164 4.91799C7.02819 4.76074 6.75199 4.78199 6.59475 4.96544C6.4375 5.1489 6.45874 5.42509 6.6422 5.58234L7.78591 6.56266L1.38525 6.56266C1.14363 6.56266 0.947754 6.75854 0.947754 7.00016Z"
        fill="url(#paint0_linear_207_32447)"
      />
      <path
        d="M5.46859 5.68769L5.68925 5.68769C5.53843 5.25976 5.6139 4.76527 5.9304 4.39602C6.40214 3.84566 7.23072 3.78192 7.78108 4.25366L9.82275 6.00366C10.1137 6.25301 10.2811 6.61704 10.2811 7.00019C10.2811 7.38334 10.1137 7.74736 9.82275 7.99671L7.78108 9.74671C7.23072 10.2185 6.40214 10.1547 5.9304 9.60435C5.6139 9.2351 5.53843 8.74062 5.68925 8.31269L5.46859 8.31269L5.46859 9.3335C5.46859 10.9834 5.46859 11.8084 5.98115 12.3209C6.49371 12.8335 7.31867 12.8335 8.96859 12.8335L9.55192 12.8335C11.2018 12.8335 12.0268 12.8335 12.5394 12.3209C13.0519 11.8084 13.0519 10.9834 13.0519 9.3335L13.0519 4.66683C13.0519 3.01692 13.0519 2.19195 12.5394 1.67939C12.0268 1.16683 11.2018 1.16683 9.55192 1.16683L8.96859 1.16683C7.31867 1.16683 6.49371 1.16683 5.98115 1.67939C5.46859 2.19196 5.46859 3.01691 5.46859 4.66683L5.46859 5.68769Z"
        fill="url(#paint1_linear_207_32447)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_207_32447"
          x1="0.947754"
          y1="7.00016"
          x2="14.0606"
          y2="7.00016"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_207_32447"
          x1="0.947754"
          y1="7.00016"
          x2="14.0606"
          y2="7.00016"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
