import React from "react";
import CloseModal from "../mui/CloseModal";
import CusForm from "../mui/CusForm";
import CusInput from "../mui/CusInput";
import CusButton from "../mui/CusButton";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA, SITE_KEY } from "./consts";
import IconPresent from "../../images/jsIcons/IconPresent";

export default function RegisterBannerApp({ fields, onRegister, closeModal, captchaRef }) {
  const { t } = useTranslation();
  const LangToHl = { english: "EN", russian: "RU" };
  const HL = LangToHl[t("currentLang")] || "EN";

  return (
    <>
      <div className="banner-register__content">
        <div className="banner-register__img">
          <h2>
            <span className="accent">{t("Extra bonuses")}</span> {t("in the app")}
            <span className="custom-button__red">
              <IconPresent />
            </span>
          </h2>
          <p>
            {t("Sign Up and receive a link to the web app for")}{" "}
            <span className="accent">{"Android & iOS"}</span>
          </p>
        </div>
        <div className="banner-register__fields">
          <CloseModal close={closeModal} />

          <h1>{t("Sing Up to RichCams")}</h1>

          <CusForm className="banner-register__form" onSubmit={onRegister}>
            {fields?.map((option, indx) => (
              <CusInput key={indx} {...option} />
            ))}
            {!!CAPTCHA?.["protect-login-page"] && (
              <ReCAPTCHA hl={HL} sitekey={SITE_KEY} ref={captchaRef} theme="dark" />
            )}
            <CusButton
              text={"Create an account"}
              className="custom-button__red"
              type="Create an account"
            />
          </CusForm>
        </div>
      </div>
    </>
  );
}
