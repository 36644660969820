import React from "react";
import { useSelector } from "react-redux";
import { TYPE_ENUM } from "./ChatFree";

import MesSwitched from "./messagesType/MesSwitched";
import MesTyped from "./messagesType/MesTyped";
import MesVibro from "./messagesType/MesVibro";
import MesLovense from "./messagesType/MesLovense";
import MessageText from "./messagesType/MessageText";
import MessageDate from "./messagesType/MessageDate";
import { ENUM_CHAT_MODE } from "../../../store/chatSlice";

const TYPE_ENUM_CSS = {
  my: "my",
  model: "model",
  center: "center",
  join: "join",
  vibro: "vibratotion",
  lovense: "lovense",
};

export default function Message({ message }) {
  const userId = useSelector((state) => state.user.user.id);
  const broadcasterId = useSelector((state) => state.chat.broadcaster.id);
  const isAvailableSpy = message.mode === ENUM_CHAT_MODE.private;
  const isJoin = false;

  const getMeClass = (message) => {
    const from = message?.from;
    if (message.type === TYPE_ENUM.switched && isJoin) return TYPE_ENUM_CSS.join;
    if (message.type === TYPE_ENUM.switched) return TYPE_ENUM_CSS.center;
    if (message.type === TYPE_ENUM.tipped) return TYPE_ENUM_CSS.center;
    if (from?.user_id === userId || from?.id === userId) return TYPE_ENUM_CSS.my;
    if (from?.user_id === broadcasterId || from?.id === broadcasterId) return TYPE_ENUM_CSS.model;
    if (from?.type === TYPE_ENUM.vibratotion) return TYPE_ENUM_CSS.vibro;
    if (from?.type === TYPE_ENUM.lovense) return TYPE_ENUM_CSS.lovense;

    return "";
  };

  const isPrivateClass = (mes) => (mes.private ? "private" : "");

  return (
    <>
      <MessageDate />

      <div className={`chat__free__message ${isPrivateClass(message)}  ${getMeClass(message)}`}>
        {message.type == TYPE_ENUM.switched ? (
          <MesSwitched message={message} isJoin={isJoin} spying={isAvailableSpy} />
        ) : message.type == TYPE_ENUM.tipped ? (
          <MesTyped message={message} />
        ) : message.type == TYPE_ENUM.vibratotion ? (
          <MesVibro message={message} />
        ) : message.type == TYPE_ENUM.lovense ? (
          <MesLovense />
        ) : (
          <MessageText message={message} />
        )}
      </div>
    </>
  );
}
