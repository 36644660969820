import React from "react";
import CusButton from "../../../../mui/CusButton";
import IconVideo from "../../../../../images/jsIcons/IconVideo";
import { useSelector } from "react-redux";
import useLoginAction from "../../../../../hooks/useLoginAction";
import ShownControl from "../../../../mui/ShownControl";

export default function Camera({ setOpenCamera }) {
  const { onLoginAction } = useLoginAction();
  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);
  const availavleCamera = useSelector(
    (state) => state.chat.settingInfo?.["exclusive-chat"]?.["client-can-enable-webcam"]
  );

  const onCameraClick = async () => {
    setOpenCamera(true);
    if (isFullScreen) await document?.exitFullscreen?.();
  };

  return (
    availavleCamera && (
      <>
        <ShownControl>
          <CusButton
            id={"camera-button"}
            icon={<IconVideo />}
            onClick={() => onLoginAction(onCameraClick)}
          />
        </ShownControl>
      </>
    )
  );
}
