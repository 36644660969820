import React from "react";
import CloseModal from "../mui/CloseModal";
import CusForm from "../mui/CusForm";
import CusInput from "../mui/CusInput";
import CusButton from "../mui/CusButton";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA, SITE_KEY } from "./consts";
import imgModel from "../../images/banner-register/register2-bg.png";
import imgModelMobile from "../../images/banner-register/register2-bg-mobile.png";
import useDeviceSize from "../../hooks/useDeviceSize";

export default function RegisterBanner2({ fields, onRegister, closeModal, captchaRef }) {
  const { t } = useTranslation();
  const LangToHl = { english: "EN", russian: "RU" };
  const HL = LangToHl[t("currentLang")] || "EN";
  const { mobileHeader } = useDeviceSize();

  return (
    <div className="banner-register__content">
      <div className="banner-register__img">
        <h2 className="left">{t("Sing Up now")}</h2>
        <img src={mobileHeader ? imgModelMobile : imgModel} alt="model" />
        <h2 className="right">{t("It's free")}</h2>
      </div>{" "}
      <div className="banner-register__fields">
        <CloseModal close={closeModal} />

        <h1>{t("Sing Up to RichCams")}</h1>

        <CusForm className="banner-register__form" onSubmit={onRegister}>
          {fields?.map((option, indx) => (
            <CusInput key={indx} {...option} />
          ))}
          {!!CAPTCHA?.["protect-login-page"] && (
            <ReCAPTCHA hl={HL} sitekey={SITE_KEY} ref={captchaRef} theme="dark" />
          )}
          <CusButton
            text={"Create an account"}
            className="custom-button__red"
            type="Create an account"
          />
        </CusForm>
      </div>
    </div>
  );
}
