import React, { useEffect, useState } from "react";
import IconMagnifierPlus from "../../../../../images/jsIcons/IconMagnifierPlus";
import IconMagnifier from "../../../../../images/jsIcons/IconMagnifier";
import { Slider } from "@mui/material";
import { MAX_SCALE } from "../../../../common/consts";
import { useDispatch, useSelector } from "react-redux";
import { setZoom } from "../../../../../store/commonSlice";
import ShownControl from "../../../../mui/ShownControl";

export default function Magnifier() {
  const [openValue, setOpenValue] = useState(false);
  const zoom = useSelector((state) => state.common.controls.zoom);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.zoomist?.transform?.scale) window.zoomist.transform.scale = zoom;
  }, [zoom]);

  const onMagnifierClick = () => {
    console.log(window.zoomist);
    if (!window.zoomist.transform) return;
    window.zoomist.transform.translateX = 0;
    window.zoomist.transform.translateY = 0;

    dispatch(setZoom(1));
  };

  return (
    <ShownControl>
      <div
        className={"chat__window__controls__magnifier-container "}
        onMouseEnter={() => setOpenValue(true)}
        onMouseLeave={() => setOpenValue(false)}
      >
        <button className="chat__window__controls__mixer custom-button" onClick={onMagnifierClick}>
          {zoom === 1 ? <IconMagnifierPlus /> : <IconMagnifier />}
        </button>

        {openValue && (
          <div className="chat__window__controls__value">
            <Slider
              step={0.1}
              max={MAX_SCALE}
              min={1}
              value={zoom}
              valueLabelDisplay="off"
              onChange={(e) => dispatch(setZoom(e.target.value))}
            />
          </div>
        )}
      </div>
    </ShownControl>
  );
}
