import React from "react";
import IconEye from "../../../../../images/jsIcons/IconEye";
import ShownControl from "../../../../mui/ShownControl";
import { useSelector } from "react-redux";

export default function Eye() {
  const { chat } = useSelector((state) => state.chat.broadcaster);

  const eyeView = chat?.participants || 1;

  return (
    <ShownControl>
      <div className="controls__eye">
        <IconEye />
        <span>{eyeView}</span>
      </div>
    </ShownControl>
  );
}
