import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ENUM_STATUS_WINDOW, setStatusWindow } from "../../../store/chatSlice";
import { TEST_MODE } from "../../common/consts";
import { useEventListener } from "usehooks-ts";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { getImgById, getMaxHeight } from "../../../utils/utils";
import CameraOff from "./CameraOff";
import useCheckCamera from "../../../hooks/useCheckCamera";
import useInitZoom from "../../../hooks/useInitZoom";
import useVideoPlay from "../../../hooks/useVideoPlay";
import SupportWebRTC from "../../mui/SupportWebRTC";

const MAX_AREA_HEIGHT = 450;

export default function BroadcasterVideo({
  videoRef,
  canvasRef,
  divZoomRef,
  setMaxHeight,
  available,
}) {
  const { isMobile } = useDeviceSize();
  const dispatch = useDispatch();

  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);
  const chatActiveTab = useSelector((state) => state.chat.chatActiveTab);
  const broadcasterId = useSelector((state) => state.chat.broadcaster.id);

  const [noCamera, setNoCamera] = useState(false);

  useInitZoom({ available, divZoomRef });
  useVideoPlay({ videoRef, canvasRef });
  const { onPlaying, onPause } = useCheckCamera({ videoRef, setNoCamera });

  const video = videoRef.current;
  const canvas = canvasRef.current;
  const mobileMaxHeight = isFullScreen ? "100vh" : "60vh";

  const makeMaxHeight = () => {
    if (!available || !canvasRef.current || !video) {
      setMaxHeight(undefined);
      return;
    }

    setMaxHeight(Math.max(video.clientHeight, MAX_AREA_HEIGHT));
  };

  useEffect(() => {
    makeMaxHeight();
  }, [chatActiveTab, available, canvasRef, canvas]);

  useEventListener("resize", () => {
    makeMaxHeight();
  });

  //TEST TEST_MODE
  useEffect(() => {
    if (!TEST_MODE) return;

    // setTimeout(() => dispatch(setStatusWindow(ENUM_STATUS_WINDOW.stream)), 900);
    console.log("status stream");
    setTimeout(() => dispatch(setStatusWindow(ENUM_STATUS_WINDOW.stream)), 900);

    setTimeout(() => {
      try {
        navigator.mediaDevices

          .getUserMedia({ audio: false, video: true })
          .then(function (mediaStream) {
            videoRef.current.srcObject = mediaStream;
          })
          .catch(function (err) {
            console.log(err.name + ": " + err.message);
          });
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }, []);

  return (
    <>
      <video
        autoPlay
        muted="muted"
        playsInline
        ref={videoRef}
        style={{ position: "absolute", opacity: 0, maxWidth: "100svw", width: "100%" }}
        onPlaying={onPlaying}
        onPause={onPause}
      />

      <div
        className="zoomist-container"
        id="zoomist-container"
        ref={divZoomRef}
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          maxWidth: "100svw",
          maxHeight: isMobile ? mobileMaxHeight : getMaxHeight(isFullScreen, "80svh"),
          cursor: "grab",
          display: available && !noCamera ? undefined : "none",
        }}
      >
        <div
          className="bg-blur"
          style={{
            position: "absolute",
            top: isMobile ? "0" : "28px",
            left: "0",
            width: "100%",
            height: isMobile ? "100%" : "calc(100% - 56px)",
            backgroundImage: `url(${getImgById(broadcasterId)}), url('img/bg-white.jpg')`,
          }}
        />

        <div
          className="zoomist-wrapper"
          id="zoomist-wrapper"
          style={{ background: "transparent", height: "100%" }}
        >
          <div className="zoomist-image" style={{ height: "100%" }}>
            <canvas
              ref={canvasRef}
              style={{
                position: "relative",
                width: "100%",
                maxWidth: "100svw",
                height: "100%",
                maxHeight: isFullScreen ? "100vh" : isMobile ? mobileMaxHeight : "80vh",
                cursor: "grab",
                display: available ? undefined : "none",
              }}
            />
          </div>
        </div>
      </div>

      {noCamera && available && (
        <div className="chat__model__offline">
          <img src={getImgById(broadcasterId)} className="chat__model__offline__bg" />
          <CameraOff />
        </div>
      )}

      <SupportWebRTC />
    </>
  );
}
