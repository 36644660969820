import React, { useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-modal";
import CloseModal from "../mui/CloseModal";
import CusInput from "../mui/CusInput";
import CusButton from "../mui/CusButton";
import CusCheckbox from "../mui/CusCheckbox";
import { CAPTCHA, LOGIN, SIGN_UP, SITE_KEY, SITE_NAME } from "./consts";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import ResetPas from "./ResetPas";
import { Segmented } from "antd";
import CusForm from "../mui/CusForm";
import { API_LOGIN } from "../../api/login";
import { catchMessageServer, thenMessageServer } from "../../api/main";
import { useDispatch } from "react-redux";
import { setMainUser } from "../../store/userSlice";
import { useLocalStorage } from "usehooks-ts";

export default function SignUpModal({ isOpen, setOpen, type = SIGN_UP }) {
  const isCaptcha = !!CAPTCHA?.["protect-login-page"];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, setHaveAccount] = useLocalStorage("haveAccount", false);

  const [modalType, setModalType] = useState(type);
  const [isResetPas, setIsResetPas] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const [emptyEmail, setEmptyEmail] = useState(true);
  const [emptyPas, setEmptyPas] = useState(true);
  const [emptyCaptcha, setEmptyCaptcha] = useState(isCaptcha);

  const captchaRef = useRef();
  const LangToHl = { english: "EN", russian: "RU" };
  const title =
    modalType === SIGN_UP ? `${t("Sign up")} ${SITE_NAME}` : `${t("Login to")} ${SITE_NAME}`;
  const textButton = modalType === SIGN_UP ? "Create an account" : "Login";

  const placeholderPas = modalType === SIGN_UP ? "Create your password" : "Your password";

  const fields = useMemo(() => {
    const nickField = {
      text: "Nickname",
      name: "nickname",
      type: "text",
      placeholder: "Nickname",
    };

    const fields = [
      {
        text: "E-mail",
        type: "text",
        name: "email",
        dataType: "email",
        placeholder: "Enter your e-mail",
        required: true,
        onChange: (e) => {
          const isEmpty = !e.target.value.length;
          setEmptyEmail(isEmpty);
        },
      },
      {
        text: "Password",
        type: "password",
        minLength: 8,
        name: "password",
        placeholder: placeholderPas,
        required: true,
        onChange: (e) => {
          const isEmpty = !e.target.value.length;
          setEmptyPas(isEmpty);
        },
      },
    ];

    // if (modalType === SIGN_UP) fields.push(confPasField);
    if (modalType === SIGN_UP) fields.push(nickField);
    return fields;
  }, [modalType, placeholderPas]);

  const HL = LangToHl[t("currentLang")] || "EN";

  const closeModal = () => {
    setIsResetPas(false);
    setOpen(false);
    if (captchaRef.current) {
      captchaRef.current.reset?.();
      setEmptyCaptcha(true);
    }
  };

  //onFormSubmit
  const onFormSubmit = async (e, fields) => {
    e.preventDefault();
    setBtnLoading(true);

    // API_LOGIN
    const methodLogin = modalType === SIGN_UP ? API_LOGIN.register : API_LOGIN.login;
    const remember = modalType === SIGN_UP ? true : fields.remember;

    const data = {
      email: fields.email,
      password: fields.password,
      login_after_join: remember,
      nickname: fields.nickname,
      captcha: captchaRef.current?.getValue?.(),
    };

    methodLogin(data)
      .then((e) => {
        thenMessageServer(e);
        setHaveAccount(true);
        if (e.data.user) {
          dispatch(setMainUser(e.data.user));
        }

        if (e.data.ok) {
          closeModal();
        }
      })
      .catch(catchMessageServer)
      .finally(() => {
        setBtnLoading(false);
        captchaRef.current?.reset?.();
        setEmptyCaptcha(true);
      });
  };

  useEffect(() => {
    setModalType(type);
  }, [type]);

  return (
    <>
      <Modal
        ariaHideApp={false}
        className={"login-modal"}
        isOpen={isOpen}
        onRequestClose={closeModal}
      >
        <CloseModal close={closeModal} />

        {!isResetPas ? (
          <>
            <h1 className="login-modal__title">{title}</h1>
            <Segmented
              rootClassName="header__segment__root"
              classID="header__segment__id"
              className="header__segment__some"
              options={[
                { value: LOGIN, label: t(LOGIN) },
                { value: SIGN_UP, label: t(SIGN_UP) },
              ]}
              defaultValue={type}
              onChange={(e) => {
                setModalType(e);
              }}
            />

            <CusForm onSubmit={onFormSubmit} className="login-modal__form">
              {fields?.map((option, indx) => (
                <CusInput key={indx} {...option} />
              ))}
              {modalType === LOGIN && (
                <div className="login-modal__action">
                  <span className="login-modal__action__remember">
                    <CusCheckbox name={"remember"} id={"input-remember"} />
                    <label htmlFor="input-remember">{t("Remember me")}</label>
                  </span>
                  <button
                    type="button"
                    className="login-modal__reset-pas"
                    onClick={() => setIsResetPas(true)}
                  >
                    {t("Forgot password")}
                  </button>
                </div>
              )}

              {isCaptcha && (
                <div className="re-captcha">
                  <ReCAPTCHA
                    onChange={(e) => setEmptyCaptcha(!e)}
                    onExpired={() => setEmptyCaptcha(true)}
                    hl={HL}
                    sitekey={SITE_KEY}
                    ref={captchaRef}
                    theme="dark"
                  />
                </div>
              )}

              <CusButton
                disabled={emptyEmail || emptyPas || emptyCaptcha}
                type="submit"
                loading={btnLoading}
                text={textButton}
                className="login-modal__button"
              />
            </CusForm>
          </>
        ) : (
          <ResetPas closeModal={closeModal} />
        )}
      </Modal>
    </>
  );
}
