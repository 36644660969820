import React from "react";

import IconRTA from "../../images/jsIcons/IconRTA";
import IconAsacp from "../../images/jsIcons/IconAsacp";
import { SITE_NAME } from "../common/consts";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HomeUpperFooter from "./HomeUpperFooter";

export default function Footer() {
  const { t } = useTranslation();

  const links = {
    becomeModal: "https://start.richcams.com/",
    studioRegister: "https://start.richcams.com/",
    affiliate: "https://camsoffer.com/",
  };

  return (
    <>
      <HomeUpperFooter />
      <div className="footer">
        <div className="footer__content">
          <div className="footer__top">
            <div className="footer__top__action">
              <h4 className="footer__top__action__title"> {t("Work with us")}</h4>
              <nav>
                <a href={links.becomeModal} target="_blank" rel="noreferrer">
                  {t("Become a Model")}
                </a>
                <a href={links.studioRegister} target="_blank" rel="noreferrer">
                  {t("Studio Registration")}
                </a>
                <a href={links.affiliate} target="_blank" rel="noreferrer">
                  {t("Affiliate program")}
                </a>
              </nav>
            </div>
            <div className="footer__top__action">
              <h4 className="footer__top__action__title right">{t("Help and support")}</h4>
              <nav>
                <Link to={"/about"}>
                  {t("About $SITE")} {SITE_NAME}
                </Link>
                <Link to={"/terms"}> {t("Terms & Conditions")} </Link>
                <Link to="/policy"> {t("Privacy Policy")} </Link>
                <Link to={"/cookie"}> {t("Cookies")} </Link>
                <Link to={"/dmca"}>{t("DMCA / Remove Content")}</Link>
                <Link to={"/contact"}> {t("Support/Contact Us")} </Link>
              </nav>
            </div>
            <div className="footer__bottom-line" />
          </div>
          <div className="footer__bottom">
            <div className="footer__bottom__content">
              <span className="footer__bottom__copyright__up-row">
                © 2023 {SITE_NAME}
                <div className="footer__bottom__copyright__img-container">
                  <IconRTA />
                  <IconAsacp />
                </div>
              </span>
              <br />
              <span className="footer__bottom__copyright">
                <p>
                  {t(
                    "RichCams is an 18+ LIVE sex & entertainment community. You can watch streams from amateur & professional models for absolutely free. Browse through thousands of open-minded people: naked girls, guys, transsexuals and couples performing live sex shows. Besides watching the shows, you can also control performers with interactive toys, select what desire you want to explore from the tokens menu, go for Private shows and use your camera to connect more intimately. The purpose of all of that to create an ultimate destination for adult entertainment filled with good vibes and happy aftertaste. Besides watching the shows, you can also control performers with interactive toys, select what desire you want to explore from the tokens menu, go for Private shows and use your camera to connect more intimately. The purpose of all of that to create an ultimate destination for adult entertainment filled with good vibes and happy aftertaste."
                  )}
                </p>
              </span>
              <div className="footer__bottom-line" />
            </div>
            <span className="footer__bottom__container">
              <Link className="footer__bottom__bottom" to={"/2257"}>
                {t("18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement")}
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
