import axios from "axios";
import { cusAxios } from "./main";
import { API_URL_USER } from "./main";

const transactionList = async ({ filter, paging }) => {
  const data = { filter, paging };
  let response = await cusAxios({
    method: "POST",
    url: `${API_URL_USER}/payment/list`,
    data: data,
  });
  return response;
};

const commonInfo = async () => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_USER}/personal/information`,
  });
  return response;
};

const setCommonInfo = async (personal_information) => {
  let response = await cusAxios({
    method: "POST",
    url: `${API_URL_USER}/personal/information`,
    data: { ...personal_information },
  });
  return response;
};

const setAvatar = async (avatar) => {
  const id = window.startConfig?.user?.id;

  let response = await cusAxios({
    method: "POST",
    url: `${API_URL_USER}/${id}/avatar`,
    data: avatar,
  });
  return response;
};
const getProfile = async () => {
  const id = window.startConfig?.user?.id;

  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_USER}/${id}/profile`,
  });
  return response;
};

const getFavorites = async () => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_USER}/favorites`,
  });
  return response;
};

const setFavorites = async (favorites) => {
  let response = await cusAxios({
    method: "POST",
    url: `${API_URL_USER}/favorites/add`,
    data: { id: favorites },
  });
  return response;
};

const removeFavorites = async (favorites) => {
  let response = await cusAxios({
    method: "POST",
    url: `${API_URL_USER}/favorites/remove`,
    data: { id: favorites },
  });
  return response;
};
const getPhotoIdList = async (userId, page = 1, length = 99) => {
  let response = await cusAxios({
    method: "POST",
    url: `${API_URL_USER}/${userId}/gallery/photo/`,
    data: { paging: { length: length, page: page } },
  });
  return response;
};
const getSnapshotIdList = async (userId, page = 1, length = 99) => {
  let response = await cusAxios({
    method: "POST",
    url: `${API_URL_USER}/${userId}/gallery/snapshot/`,
    data: { paging: { length: length, page: page } },
  });
  return response;
};
const getPhotoPreview = async (userId, photoId) => {
  let response = await axios({
    method: "GET",
    url: `${API_URL_USER}/${userId}/gallery/photo/${photoId}/preview/180`,
  });
  return response;
};
const getSnapshotPreview = async (userId, photoId) => {
  let response = await axios({
    method: "GET",
    url: `${API_URL_USER}/${userId}/gallery/snapshot/${photoId}/preview/180`,
  });
  return response;
};
const getPhotoFull = async (userId, photoId) => {
  let response = await axios({
    method: "GET",
    url: `${API_URL_USER}/${userId}/gallery/photo/${photoId}/view`,
  });
  return response;
};
const getSnapshotFull = async (userId, photoId) => {
  let response = await axios({
    method: "GET",
    url: `${API_URL_USER}/${userId}/gallery/snapshot/${photoId}/view`,
  });
  return response;
};

export const API_PROFILE = {
  transaction: {
    list: transactionList,
  },
  personal: {
    getInfo: commonInfo,
    setInfo: setCommonInfo,
    setAvatar: setAvatar,
    getProfile: getProfile,
    favorites: {
      get: getFavorites,
      set: setFavorites,
      remove: removeFavorites,
    },
  },
  photos: {
    getPhotoIdList: getPhotoIdList,
    getSnapshotIdList: getSnapshotIdList,
    getPhotoPreview: getPhotoPreview,
    getSnapshotPreview: getSnapshotPreview,
    getPhotoFull: getPhotoFull,
    getSnapshotFull: getSnapshotFull,
  },
};
