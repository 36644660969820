import React from "react";
import IconBroadcastOver from "../../../images/jsIcons/IconBroadcastOver";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Pause() {
  const nickname = useSelector((state) => state.chat.broadcaster.name);
  const { t } = useTranslation();

  return (
    <div className="chat__model__over">
      <IconBroadcastOver />
      <h3>
        {t("Model")} @{nickname} {t("in pause mode.")}
      </h3>
      <h3>{t("The stream will start soon.")}</h3>
    </div>
  );
}
