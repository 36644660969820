import React from "react";
import PageTextContainer from "../components/common/PageTextContainer";
import PolicyContentRU from "../components/mui/policy_content/PolicyContentRU";
import PolicyContentEN from "../components/mui/policy_content/PolicyContentEN";
import { getLang } from "../utils/utils";
import { useLocalStorage } from "usehooks-ts";

export default function Policy() {
  const chosenLang = getLang();
  const [selectLang] = useLocalStorage("lang", chosenLang);

  return (
    <PageTextContainer>
      {selectLang === "ru" ? <PolicyContentRU /> : <PolicyContentEN />}
    </PageTextContainer>
  );
}
