import { useRef } from "react";
import { API_BROADCASTER } from "../api/broadcasters";
import { TEST_MODE } from "../components/common/consts";
import { ENUM_STATUS_WINDOW, setBroadcaster } from "../store/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { getBroadcasterByPathname } from "../utils/broadcasters";
import JanusState from "../controls/janus/site/JanusState";

export default function useUtilsBroadcaster() {
  const dispatch = useDispatch();
  const broadcasters = useSelector((state) => state.common.broadcasters);

  const changeStatusRef = useRef(0);

  const changeStatusWindow = async (mode) => {
    clearTimeout(changeStatusRef.current);

    // awaitRef
    if (!JanusState.isSuccessInit) {
      changeStatusRef.current = setTimeout(() => changeStatusWindow(mode), 3 * 1000);
      return;
    }

    // if (mode === ENUM_STATUS_WINDOW.stream) {
    //   console.log("status", ENUM_STATUS_WINDOW.stream);
    //   dispatch(setStatusWindow(ENUM_STATUS_WINDOW.stream));
    // }

    // const alreadyPrivate =
    //   JanusState.statusWindow !== ENUM_STATUS_WINDOW.stream &&
    //   (mode === ENUM_STATUS_WINDOW.someonePrivate || ENUM_STATUS_WINDOW.someoneExclusivePrivate);

    // if (mode && alreadyPrivate) {
    //   console.log("status c", mode);
    //   dispatch(setStatusWindow(mode));
    // }
  };

  const updateBroadcaster = async () => {
    try {
      if (TEST_MODE) {
        dispatch(setBroadcaster(broadcasters[0]));
        return;
      }

      const newBroadcaster = await getBroadcasterByPathname();
      if (!newBroadcaster) return { ok: false, message: "no such model found" };

      dispatch(setBroadcaster(newBroadcaster));
      const mode = newBroadcaster?.chat?.mode || ENUM_STATUS_WINDOW.offline;
      JanusState.modelMode = mode;

      clearTimeout(changeStatusRef.current);
      // changeStatusRef.current = setTimeout(() => changeStatusWindow(modeByStatus[mode]), 1000);

      return { ok: true };
    } catch (e) {
      console.error(e);
      return { ok: false, message: "unknown error" };
      // return { ok: true, message: "unknown error" };
    }
  };

  const getBroadcasters = async (filter) => {
    const payload = { filter: filter };
    const resBroadcaster = await API_BROADCASTER.list(payload);
    const newBroadcaster = API_BROADCASTER.utils.getModals(resBroadcaster);

    return newBroadcaster;
  };

  return { updateBroadcaster, changeStatusWindow, getBroadcasters };
}
