import React from "react";

export default function IconVideo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12.93 4.78906H7.35C3.25 4.78906 2 6.03906 2 10.1391V13.8591C2 17.9591 3.25 19.2091 7.35 19.2091H12.93C17.03 19.2091 18.28 17.9591 18.28 13.8591V10.1391C18.28 6.03906 17.03 4.78906 12.93 4.78906Z"
        fill="url(#paint0_linear_968_8710)"
      />
      <path
        d="M20.4202 9.00047C20.2802 8.99047 20.1002 8.98047 19.9002 8.98047C19.5102 8.98047 19.2002 9.29047 19.2002 9.68047V14.3305C19.2002 14.7205 19.5102 15.0305 19.9002 15.0305C20.1002 15.0305 20.2702 15.0205 20.4402 15.0105C22.0002 14.8305 22.0002 13.7305 22.0002 12.9305V11.0705C22.0002 10.2705 22.0002 9.17047 20.4202 9.00047Z"
        fill="url(#paint1_linear_968_8710)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_968_8710"
          x1="10.14"
          y1="4.78906"
          x2="10.14"
          y2="20.4107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_968_8710"
          x1="20.6002"
          y1="8.98047"
          x2="20.6002"
          y2="15.5346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
      </defs>
    </svg>
  );
}
