import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const errorToast = (message, dop = {}) => toast(message, { type: "error", ...dop });
export const successToast = (message, dop = {}) => toast(message, { type: "success", ...dop });
export const infoToast = (message, dop = {}) => toast(message, { type: "info", ...dop });

export default function Toaster() {
  return <ToastContainer position="bottom-right" theme="dark" />;
}
