import React from "react";

export default function IconMagnifier() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <path
        d="M11.934 21.534C6.65398 21.534 2.33398 17.214 2.33398 11.934C2.33398 6.65398 6.65398 2.33398 11.934 2.33398C17.214 2.33398 21.534 6.65398 21.534 11.934C21.534 17.214 17.214 21.534 11.934 21.534ZM11.934 4.73398C7.97398 4.73398 4.73398 7.97398 4.73398 11.934C4.73398 15.894 7.97398 19.134 11.934 19.134C15.894 19.134 19.134 15.894 19.134 11.934C19.134 7.97398 15.894 4.73398 11.934 4.73398Z"
        fill="url(#paint0_linear_1170_18421)"
      />
      <path
        d="M25.1344 26.3344C24.7744 26.3344 24.5344 26.2144 24.2944 25.9744L17.0944 18.7744C16.6144 18.2944 16.6144 17.5744 17.0944 17.0944C17.5744 16.6144 18.2944 16.6144 18.7744 17.0944L25.9744 24.2944C26.4544 24.7744 26.4544 25.4944 25.9744 25.9744C25.7344 26.2144 25.4944 26.3344 25.1344 26.3344Z"
        fill="url(#paint1_linear_1170_18421)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1170_18421"
          x1="11.934"
          y1="2.33398"
          x2="11.934"
          y2="23.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1170_18421"
          x1="21.5344"
          y1="16.7344"
          x2="21.5344"
          y2="27.1344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
      </defs>
    </svg>
  );
}
