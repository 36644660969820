import React from "react";
import ImgAvatar from "../mui/ImgAvatar";
import IconPen from "../../images/jsIcons/IconPen";
import { useSelector } from "react-redux";
import BalanceInfo from "./BalanceInfo";
import { useTranslation } from "react-i18next";

export default function ContentGeneral({ setChangePhoto, closeModal }) {
  const uerNick = useSelector((state) => state.user.user.nickname);
  const myAvatar = useSelector((state) => state.user?.user?.avatar);
  const id = useSelector((state) => state.user?.user?.id);

  const { t } = useTranslation();

  return (
    <div className="general__content">
      {/* general__img__container */}
      <div className="general__img__container" onClick={() => setChangePhoto(true)}>
        <ImgAvatar avatarProps={myAvatar} id={id} className="general__img__action" />
        <div className="general__img__pen">
          <div className="general__img__pen__circle">
            <IconPen />
          </div>
        </div>
      </div>

      {/* general__info */}
      <div className="general__info">
        <h3 className="general__info__title">{uerNick}</h3>
        {/* <div className="general__info__description">
          <div className="general__info__warn">
            <IconExclamationCircle />
          </div>
          <p className="general__info__description__text">
            {t("To turn on your webcam in private, go through verification to confirm your age in")}{" "}
            <button onClick={() => setChapter("settings")}>{t("personal settings")}</button>
          </p>
        </div> */}
        <div className="general__info__balance">
          <h4 className="general__info__balance__title"> {t("Balance")}</h4>
          <div className="general__info__balance__content">
            <BalanceInfo maxSize closeModal={closeModal} />
          </div>
        </div>
      </div>
    </div>
  );
}
