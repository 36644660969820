import React from "react";
import IconBroadcastOver from "../../../images/jsIcons/IconBroadcastOver";
import { useTranslation } from "react-i18next";

export default function Over() {
  const { t } = useTranslation();
  return (
    <div className="chat__model__over">
      <IconBroadcastOver />
      <h3>{t("Broadcast is over")}</h3>
    </div>
  );
}
