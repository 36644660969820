import React from "react";

export default function IconMixedOff() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17.9998 16.7503C17.8398 16.7503 17.6898 16.7003 17.5498 16.6003C17.2198 16.3503 17.1498 15.8803 17.3998 15.5503C18.6598 13.8703 18.9298 11.6403 18.1198 9.71032C17.9598 9.33032 18.1398 8.89032 18.5198 8.73032C18.8998 8.57032 19.3398 8.75032 19.4998 9.13032C20.5198 11.5503 20.1698 14.3603 18.5998 16.4603C18.4498 16.6503 18.2298 16.7503 17.9998 16.7503Z"
        fill="url(#paint0_linear_976_9770)"
      />
      <path
        d="M19.8299 19.2502C19.6699 19.2502 19.5199 19.2002 19.3799 19.1002C19.0499 18.8502 18.9799 18.3802 19.2299 18.0502C21.3699 15.2002 21.8399 11.3802 20.4599 8.0902C20.2999 7.7102 20.4799 7.2702 20.8599 7.1102C21.2399 6.9502 21.6799 7.1302 21.8399 7.5102C23.4299 11.2902 22.8899 15.6702 20.4299 18.9502C20.2899 19.1502 20.0599 19.2502 19.8299 19.2502Z"
        fill="url(#paint1_linear_976_9770)"
      />
      <path
        d="M14.04 12.96C14.67 12.33 15.75 12.78 15.75 13.67V16.6C15.75 18.32 15.13 19.61 14.02 20.23C13.57 20.48 13.07 20.6 12.55 20.6C11.75 20.6 10.89 20.33 10.01 19.78L9.36998 19.38C8.82998 19.04 8.73998 18.28 9.18998 17.83L14.04 12.96Z"
        fill="url(#paint2_linear_976_9770)"
      />
      <path
        d="M21.77 2.22988C21.47 1.92988 20.98 1.92988 20.68 2.22988L15.73 7.17988C15.67 5.57988 15.07 4.37988 14.01 3.78988C12.89 3.16988 11.46 3.32988 10 4.23988L7.09 6.05988C6.89 6.17988 6.66 6.24988 6.43 6.24988H5.5H5C2.58 6.24988 1.25 7.57988 1.25 9.99988V13.9999C1.25 16.4199 2.58 17.7499 5 17.7499H5.16L2.22 20.6899C1.92 20.9899 1.92 21.4799 2.22 21.7799C2.38 21.9199 2.57 21.9999 2.77 21.9999C2.97 21.9999 3.16 21.9199 3.31 21.7699L21.77 3.30988C22.08 3.00988 22.08 2.52988 21.77 2.22988Z"
        fill="url(#paint3_linear_976_9770)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_976_9770"
          x1="18.6634"
          y1="8.67188"
          x2="18.6634"
          y2="17.4235"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_976_9770"
          x1="20.9104"
          y1="7.05176"
          x2="20.9104"
          y2="20.2667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_976_9770"
          x1="12.3259"
          y1="12.666"
          x2="12.3259"
          y2="21.2612"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_976_9770"
          x1="11.6262"
          y1="2.00488"
          x2="11.6262"
          y2="23.6661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
      </defs>
    </svg>
  );
}
