import React from "react";
import { getLang } from "../utils/utils";
import { useReadLocalStorage } from "usehooks-ts";
import PageTextContainer from "../components/common/PageTextContainer";
import AboutContentRu from "../components/mui/about/AboutContentRu";
import AboutContentEn from "../components/mui/about/AboutContentEn";

export default function About() {
  const chosenLang = getLang();
  const selectLang = useReadLocalStorage("lang") || chosenLang;

  return (
    <PageTextContainer>
      {selectLang === "ru" ? <AboutContentRu /> : <AboutContentEn />}
    </PageTextContainer>
  );
}
