import React from "react";
import { useTranslation } from "react-i18next";
import PageTextContainer from "../components/common/PageTextContainer";

export default function Cookie() {
  const { t } = useTranslation();

  return (
    <PageTextContainer>
      <h1>{t("Cookies Policy")}</h1>

      <p>
        {t(
          "As you navigate through and interact with our Website, we use automatic data collection technologies to collect certain information about your equipment, browsing actions and patterns, including information such as your IP address, browser type, operating system, the referring web page, pages visited, location, your mobile carrier, device information (including device and application IDs), search terms, and cookie information, to collect information about its use by users, to distinguish between users, to help us provide you with the best possible experience and to allow us to improve the Website and the service we offer. This means that when you visit our Website, cookies or similar technologies will be placed on your computer, mobile device, tablet or other device. We also use these technologies to collect information about your online activities over time and across third-party websites or other online services (“interest-based advertising”)"
        )}
      </p>

      <h2>{t("This cookies policy explains:")}</h2>
      <p>
        {t(
          "The different types of cookies we use, why and how we use cookies and other internet tracking software; and the choices you can make about whether we can put some types of cookies on your computer, mobile device, tablet or other device. For the purposes of this cookies policy when we refer to “personal data” we mean data which identifies or may identify you as an individual, which may include for example your name or online identifiers such as your IP address."
        )}
      </p>

      <h2>{t("1. Cookies – What are they?")}</h2>
      <p>
        {t(
          "A “cookie” is a small text file that is stored on your computer, mobile device, tablet or other device when you visit a website or use an application. Cookies are then sent back to the Website on each subsequent visit, or to another website that recognizes that cookie, and allow the website to recognize the user’s device."
        )}
        <br />
        <br />
        {t(
          "Some cookies are deleted when you close down your browser. These are known as session cookies. When you restart your browser and return to the Website, the Website will not recognize you and you will have to log back in (if login is required) or select your unique preferences/settings again. Other cookies remain on your device until they expire or you delete them from your cache. Expiration dates are set within the cookies themselves, and some may expire after a few minutes while others may expire after many years. These are known as persistent cookies and enable us to remember things about you as a returning visitor."
        )}
        <br />
        <br />
        {t("Our Website uses both session and persistent cookies.")}
      </p>

      <h2>{t("2. How we use cookies")}</h2>
      <p>
        {t(
          "Cookies allow us to recognize users (where appropriate), tailor the content of our Website to fit the needs of our Website’s visitors and helps us improve the user experience. Without certain types of cookies enabled, we cannot guarantee that the Website and your experience of it are as we intended it to be."
        )}
        <br />
        <br />
        {t(
          "We use cookies to obtain information about your visits and about the device you use to access our Website."
        )}
        <br />
        <br />
        {t(
          "This includes where available, your IP address and pseudonymous identifiers, operating system and browser type and depending on the cookie, also includes the reporting of statistical data about our users’ browsing actions and patterns."
        )}
      </p>

      <h2>{t("3. Why we use cookies")}</h2>
      <p>
        {t(
          "If you visit our Website, we will deploy cookies to provide an online service more suited to the device you connect, as well as to prevent and detect fraud and keep your connection secure. When you visit our Website from any device (mobile, tablet, PC), we collect information about your use of the Website, such as information about the device or browser you use to access the site (including device type, operating system, screen resolution, etc.), the way you interact with this site, and the IP address your device connects from. You may not be able to initiate or complete some activities with our online services unless the cookies or similar technologies are installed."
        )}

        <h5>{t("We additionally use cookies (and other similar technologies) to:")}</h5>
        <ul>
          <li>
            {t(
              "provide products and services that you request and to provide a secure online environment;"
            )}
          </li>
          <li>{t("manage our marketing relationships;")}</li>
          <li>{t("give you a better online experience and track website performance;")}</li>
          <li>{t("and help us make our Website more relevant to you.")}</li>
        </ul>
      </p>

      <h2>{t("4. Cookie Categories")}</h2>
      <p>
        {t(
          "This section explains each cookie category in more detail and how you can control their use."
        )}

        <br />
        <br />
        {t(
          "The length of time a cookie stays on your device depends on its type. We use two types of cookies on our Website, session cookies and persistent cookies:"
        )}
        <br />
        <br />
        <i>{t("Session cookies")}</i>
        <br />
        <br />
        {t(
          "These are temporary cookies which only exist, during the time you use the Website (or more strictly, until you close the relevant browser). Session cookies help our Website remember what you chose on the previous page, avoiding the need to re-enter information and improve your experience whilst using the Website."
        )}
        <br />
        <br />
        <i>{t("Persistent cookies")}</i>
        <br />
        <br />
        {t(
          "These are cookies which stay on your device after you have closed your browser until they expire or you delete them. Persistent cookies help us identify you as a unique visitor."
        )}
        <br />
        <br />
        {t(
          "We also categorize our cookies to help our customers understand the specific reason for each cookie."
        )}
      </p>

      <h3>{t("Essential cookies")}</h3>
      <p>
        {t(
          "These are cookies that are essential for us to provide a service you have requested and to provide a secure online environment. Without these cookies we may unable to provide some services that you might request. Other “essential” cookies keep our Website secure. Essential cookies are used to:"
        )}

        <ul className="style-circle">
          <li>
            {t(
              "enable a user to log into our Website and recognize such user when navigating the Website (“authentication cookies”);"
            )}
          </li>
          <li>
            {t(
              "maintain online security and protect against malicious activity and/or violation of our Terms of Use (“security cookies”)"
            )}
          </li>
        </ul>

        <b className="title">
          {t("Performance cookies")} <span>{t("(tracking Website performance)")}</span>
        </b>

        {t(
          "These cookies collect aggregated information and they are not used to identify you. We use this type of cookie to understand and analyze how visitors use and explore our Website and look for ways to improve it. We use these cookies to track our sites and plugins performance across the globe. Similarly, we may also use these analytics cookies to test new advertisements, pages, or features to gauge users’ reactions. The analytics cookies we use may be our own (i.e. first-party cookies) or third-party cookies."
        )}

        <b className="title">
          {t("Functionality cookies")} <span>{t("(giving you a better online experience)")}</span>
        </b>

        {t(
          "These cookies remember your preferences and tailor the Website to provide enhanced features. Without these cookies, we cannot remember your choices or personalize your online experience. We use this type of cookies to:"
        )}
        <br />
        {t("make login faster by recognizing you if you are logged into the Website")}
        {t(
          "and remember relevant information as you browse from page to page to save you re-entering the same information repeatedly (e.g. language, volume, HD mode)"
        )}

        <b className="title">{t("Targeting cookies")}</b>
        {t(
          "These cookies are usually third-party cookies from marketing partners used to deliver advertisements relevant to you and your interests. These cookies can track your browsing history across the Website, the pages you have visited and the links you have followed. If you wish to prevent this type of cookie, you may do so, via your device’s browser security settings. The third-party companies we partner with include among others, Google Analytics."
        )}
      </p>

      <h2>{t("5. First and third party cookies")}</h2>
      <p>
        {t("Whether a cookie is first or third party depends on where it comes from.")}
        <br />
        <br />
        {t(
          "On the other hand, some content or applications, including advertisements, on the Website are served by third parties, including analytics or advertising companies, ad network and servers, content providers and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our Website over which we have no control. Unless expressly stated otherwise,"
        )}{" "}
        <b>{t("our Website does not provide any personal data to these third parties")}</b>{" "}
        {t(
          "however, they may collect information, including personal data, such as internet protocol (IP) address, browser type and version, time zone setting and location, operating system and platform and other technology on the devices you use to access this Website. They may use this information so as to provide you with interest-based advertising or other targeted content."
        )}
        <br />
        <br />
        {t(
          "We recommend that you check the privacy and cookie policies of those websites for information about the cookies they may use and the collection of personal data. We cannot accept any responsibility for any content contained in these third-party websites."
        )}
      </p>

      <h2>{t("6. Keep in mind")}</h2>
      <p>
        {t("Cookies with more than one use")}
        <br />
        <br />
        {t(
          "Some of our cookies collect data for more than one use. We will only use these cookies for their essential purposes unless you have given your consent to any other uses they have."
        )}
        <br />
        <br />
        {t("Cookies that are already on your device")}
        <br />
        <br />
        {t(
          "Turning off one or more types of cookies will not delete any that have been downloaded in the past. We may still use data we collected up to that point, but will stop collecting new data."
        )}
        <br />
        <br />
        {t("Managing cookies choices in your browser")}
        <br />
        <br />
        {t(
          "You can turn off or delete cookies in your browser. If you do this, it may affect sites that use similar cookies to us."
        )}
      </p>

      <h2>{t("7. How to control and delete cookies")}</h2>
      <p>
        {t(
          "Cookies help you get the most out of our Website. However, you can set up your browser to delete or refuse some or all of them, or to be notified when you are sent a cookie and therefore, choose whether or not to accept it. You may also delete or refuse, some or all of the cookies, on our Website at any time."
        )}
        <br />
        <br />
        {t(
          "On your first visit to the Website you will have seen a pop-up to inform you that cookies are being used when you proceed with using the Website. Although this pop-up will not usually appear on subsequent visits you may withdraw your cookie consent at any time by changing your browser or device settings."
        )}
        <br />
        <br />
        {t(
          "Please remember, if you decline to accept cookies or do delete some or all your cookies, some functionality on our Website may be disabled and as a result you may be unable to access certain parts of our Website and your experience on our Website may be affected. Unless you have adjusted your browser settings so that they will delete or refuse cookies, cookies will be issued or reissued when you direct your browser to our Website. If you do decide to delete or refuse cookies but subsequently decide that you would in fact like to allow cookies, you should adjust your browser settings and continue using our Website. Cookies will then be sent to and from our Website."
        )}

        <br />
        <br />
        {t(
          "You may also opt out of third-party cookies by following the instructions provided by each third party in its privacy policy."
        )}
      </p>
    </PageTextContainer>
  );
}
