import React from "react";

export default function IconRTA() {
  return (
    <svg width="25" height="11" viewBox="0 0 25 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.141968 10.9754V9.58823H0.731465C0.879707 9.58823 0.987261 9.60085 1.05413 9.62608C1.12162 9.65068 1.17556 9.69484 1.21593 9.75855C1.2563 9.82226 1.27649 9.89512 1.27649 9.97713C1.27649 10.0812 1.24589 10.1673 1.18471 10.2354C1.12352 10.3029 1.03205 10.3455 0.910301 10.3632C0.970859 10.3985 1.02069 10.4373 1.0598 10.4796C1.09955 10.5218 1.15285 10.5969 1.21972 10.7048L1.38909 10.9754H1.05413L0.851635 10.6735C0.779722 10.5657 0.730519 10.4979 0.704024 10.4701C0.67753 10.4417 0.649459 10.4225 0.619811 10.4124C0.590162 10.4017 0.543166 10.3963 0.478823 10.3963H0.42205V10.9754H0.141968ZM0.42205 10.1749H0.629273C0.763636 10.1749 0.847535 10.1692 0.880968 10.1579C0.914401 10.1465 0.94058 10.1269 0.959505 10.0992C0.978429 10.0714 0.987891 10.0367 0.987891 9.99511C0.987891 9.94843 0.975275 9.91089 0.950042 9.88251C0.925441 9.85349 0.89043 9.8352 0.845012 9.82763C0.822302 9.82447 0.754174 9.82289 0.640627 9.82289H0.42205V10.1749Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M1.56081 10.9754V9.58823H2.58936V9.82289H1.84089V10.1304H2.53731V10.3641H1.84089V10.7417H2.61585V10.9754H1.56081Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M2.80176 10.524L3.07428 10.4976C3.09068 10.589 3.12379 10.6562 3.17363 10.6991C3.22409 10.742 3.29191 10.7634 3.37707 10.7634C3.46727 10.7634 3.53509 10.7445 3.58051 10.7067C3.62655 10.6682 3.64958 10.6234 3.64958 10.5723C3.64958 10.5395 3.6398 10.5117 3.62025 10.489C3.60132 10.4657 3.56789 10.4455 3.51995 10.4285C3.48714 10.4171 3.41239 10.3969 3.29569 10.3679C3.14556 10.3307 3.04021 10.285 2.97965 10.2307C2.89449 10.1544 2.85191 10.0613 2.85191 9.95158C2.85191 9.88093 2.87178 9.81501 2.91153 9.75382C2.9519 9.692 3.00962 9.645 3.08468 9.61283C3.16038 9.58066 3.25153 9.56458 3.35814 9.56458C3.53225 9.56458 3.66314 9.60274 3.75083 9.67907C3.83914 9.7554 3.8855 9.85727 3.88992 9.9847L3.60984 9.997C3.59785 9.92572 3.57199 9.87462 3.53225 9.84371C3.49314 9.81217 3.43416 9.7964 3.3553 9.7964C3.27393 9.7964 3.21022 9.81312 3.16417 9.84655C3.13452 9.868 3.11969 9.8967 3.11969 9.93266C3.11969 9.96546 3.13357 9.99353 3.16133 10.0169C3.19665 10.0465 3.28244 10.0774 3.4187 10.1096C3.55496 10.1418 3.65557 10.1752 3.72055 10.2099C3.78615 10.244 3.83725 10.291 3.87383 10.3509C3.91105 10.4102 3.92966 10.4837 3.92966 10.5714C3.92966 10.6508 3.90758 10.7253 3.86343 10.7947C3.81927 10.8641 3.75682 10.9158 3.67607 10.9498C3.59533 10.9833 3.49471 11 3.37423 11C3.19886 11 3.06418 10.9596 2.97019 10.8789C2.8762 10.7975 2.82006 10.6792 2.80176 10.524Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M4.4969 10.9754V9.82289H4.0853V9.58823H5.18765V9.82289H4.77699V10.9754H4.4969Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M5.38965 10.9754V9.58823H5.97914C6.12739 9.58823 6.23494 9.60085 6.30181 9.62608C6.3693 9.65068 6.42324 9.69484 6.46361 9.75855C6.50398 9.82226 6.52417 9.89512 6.52417 9.97713C6.52417 10.0812 6.49357 10.1673 6.43238 10.2354C6.3712 10.3029 6.27973 10.3455 6.15798 10.3632C6.21854 10.3985 6.26837 10.4373 6.30748 10.4796C6.34722 10.5218 6.40053 10.5969 6.46739 10.7048L6.63677 10.9754H6.30181L6.09931 10.6735C6.0274 10.5657 5.9782 10.4979 5.9517 10.4701C5.92521 10.4417 5.89714 10.4225 5.86749 10.4124C5.83784 10.4017 5.79084 10.3963 5.7265 10.3963H5.66973V10.9754H5.38965ZM5.66973 10.1749H5.87695C6.01131 10.1749 6.09521 10.1692 6.12865 10.1579C6.16208 10.1465 6.18826 10.1269 6.20718 10.0992C6.22611 10.0714 6.23557 10.0367 6.23557 9.99511C6.23557 9.94843 6.22295 9.91089 6.19772 9.88251C6.17312 9.85349 6.13811 9.8352 6.09269 9.82763C6.06998 9.82447 6.00185 9.82289 5.88831 9.82289H5.66973V10.1749Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path d="M6.79997 10.9754V9.58823H7.08006V10.9754H6.79997Z" fill="white" fillOpacity="0.4" />
      <path
        d="M8.25477 10.4654L8.52634 10.5515C8.4847 10.7029 8.41532 10.8155 8.31817 10.8893C8.22165 10.9625 8.09896 10.9991 7.95009 10.9991C7.76589 10.9991 7.61449 10.9363 7.4959 10.8108C7.37731 10.6846 7.31801 10.5124 7.31801 10.2941C7.31801 10.0632 7.37762 9.88408 7.49685 9.75666C7.61607 9.6286 7.77283 9.56458 7.96712 9.56458C8.13681 9.56458 8.27464 9.61473 8.38062 9.71502C8.4437 9.77432 8.49101 9.85948 8.52255 9.97051L8.24531 10.0367C8.22891 9.96483 8.19453 9.90805 8.14217 9.86642C8.09045 9.82479 8.02736 9.80397 7.95293 9.80397C7.8501 9.80397 7.76652 9.84087 7.70218 9.91468C7.63847 9.98848 7.60661 10.108 7.60661 10.2733C7.60661 10.4487 7.63815 10.5736 7.70123 10.648C7.76431 10.7224 7.84632 10.7597 7.94725 10.7597C8.02169 10.7597 8.08571 10.736 8.13933 10.6887C8.19295 10.6414 8.23143 10.5669 8.25477 10.4654Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M9.09926 10.9754V9.82289H8.68765V9.58823H9.79V9.82289H9.37934V10.9754H9.09926Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M9.99105 10.9754V9.58823H11.0196V9.82289H10.2711V10.1304H10.9676V10.3641H10.2711V10.7417H11.0461V10.9754H9.99105Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M11.302 9.58823H11.8139C11.9294 9.58823 12.0174 9.59706 12.0779 9.61473C12.1593 9.6387 12.229 9.68128 12.287 9.74247C12.3451 9.80365 12.3892 9.87872 12.4195 9.96767C12.4498 10.056 12.4649 10.1651 12.4649 10.2951C12.4649 10.4092 12.4507 10.5076 12.4224 10.5903C12.3877 10.6912 12.3381 10.7729 12.2738 10.8354C12.2252 10.8827 12.1596 10.9196 12.077 10.9461C12.0152 10.9656 11.9325 10.9754 11.8291 10.9754H11.302V9.58823ZM11.5821 9.82289V10.7417H11.7912C11.8694 10.7417 11.9259 10.7373 11.9606 10.7284C12.006 10.7171 12.0436 10.6978 12.0732 10.6707C12.1035 10.6436 12.1281 10.5991 12.147 10.5373C12.1659 10.4748 12.1754 10.39 12.1754 10.2828C12.1754 10.1755 12.1659 10.0932 12.147 10.0358C12.1281 9.97839 12.1016 9.9336 12.0675 9.90143C12.0335 9.86926 11.9902 9.8475 11.9379 9.83614C11.8988 9.82731 11.8221 9.82289 11.708 9.82289H11.5821Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M13.5937 10.9754V9.82289H13.1821V9.58823H14.2845V9.82289H13.8738V10.9754H13.5937Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M14.3928 10.2903C14.3928 10.149 14.4139 10.0304 14.4562 9.93455C14.4877 9.8639 14.5306 9.8005 14.5849 9.74436C14.6398 9.68822 14.6997 9.64658 14.7647 9.61946C14.8511 9.58287 14.9508 9.56458 15.0637 9.56458C15.2681 9.56458 15.4314 9.62797 15.5538 9.75477C15.6768 9.88156 15.7383 10.0579 15.7383 10.2837C15.7383 10.5076 15.6775 10.683 15.5557 10.8098C15.434 10.936 15.2712 10.9991 15.0675 10.9991C14.8612 10.9991 14.6972 10.9363 14.5754 10.8108C14.4537 10.6846 14.3928 10.5111 14.3928 10.2903ZM14.6814 10.2809C14.6814 10.4379 14.7177 10.5572 14.7902 10.6385C14.8628 10.7193 14.9549 10.7597 15.0665 10.7597C15.1782 10.7597 15.2696 10.7196 15.3409 10.6395C15.4128 10.5587 15.4488 10.4379 15.4488 10.2771C15.4488 10.1181 15.4138 9.99952 15.3438 9.9213C15.2744 9.84308 15.182 9.80397 15.0665 9.80397C14.9511 9.80397 14.858 9.84371 14.7874 9.92319C14.7167 10.002 14.6814 10.1213 14.6814 10.2809Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M17.715 10.9754H17.4103L17.2892 10.6603H16.7347L16.6202 10.9754H16.3231L16.8634 9.58823H17.1595L17.715 10.9754ZM17.1993 10.4266L17.0081 9.91184L16.8208 10.4266H17.1993Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M17.8829 9.58823H18.3948C18.5102 9.58823 18.5982 9.59706 18.6588 9.61473C18.7402 9.6387 18.8099 9.68128 18.8679 9.74247C18.926 9.80365 18.9701 9.87872 19.0004 9.96767C19.0307 10.056 19.0458 10.1651 19.0458 10.2951C19.0458 10.4092 19.0316 10.5076 19.0032 10.5903C18.9685 10.6912 18.919 10.7729 18.8547 10.8354C18.8061 10.8827 18.7405 10.9196 18.6579 10.9461C18.596 10.9656 18.5134 10.9754 18.4099 10.9754H17.8829V9.58823ZM18.163 9.82289V10.7417H18.3721C18.4503 10.7417 18.5068 10.7373 18.5415 10.7284C18.5869 10.7171 18.6244 10.6978 18.6541 10.6707C18.6843 10.6436 18.709 10.5991 18.7279 10.5373C18.7468 10.4748 18.7563 10.39 18.7563 10.2828C18.7563 10.1755 18.7468 10.0932 18.7279 10.0358C18.709 9.97839 18.6825 9.9336 18.6484 9.90143C18.6143 9.86926 18.5711 9.8475 18.5188 9.83614C18.4796 9.82731 18.403 9.82289 18.2888 9.82289H18.163Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M19.3017 9.58823H19.5818V10.3395C19.5818 10.4588 19.5853 10.536 19.5922 10.5714C19.6042 10.6281 19.6326 10.6739 19.6774 10.7086C19.7228 10.7426 19.7846 10.7597 19.8629 10.7597C19.9423 10.7597 20.0023 10.7436 20.0426 10.7114C20.083 10.6786 20.1073 10.6385 20.1155 10.5912C20.1237 10.5439 20.1278 10.4654 20.1278 10.3556V9.58823H20.4079V10.3168C20.4079 10.4834 20.4003 10.601 20.3852 10.6698C20.37 10.7385 20.342 10.7966 20.301 10.8439C20.2606 10.8912 20.2063 10.929 20.1382 10.9574C20.0701 10.9852 19.9811 10.9991 19.8714 10.9991C19.7389 10.9991 19.6383 10.9839 19.5695 10.9536C19.5014 10.9227 19.4475 10.883 19.4077 10.8344C19.368 10.7852 19.3418 10.7338 19.3292 10.6802C19.3109 10.6007 19.3017 10.4834 19.3017 10.3282V9.58823Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M20.731 10.9754V9.59959H21.0111V10.7417H21.7075V10.9754H20.731Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M22.0959 10.9754V9.82289H21.6843V9.58823H22.7866V9.82289H22.376V10.9754H22.0959Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M22.9167 10.524L23.1892 10.4976C23.2056 10.589 23.2388 10.6562 23.2886 10.6991C23.3391 10.742 23.4069 10.7634 23.492 10.7634C23.5822 10.7634 23.6501 10.7445 23.6955 10.7067C23.7415 10.6682 23.7645 10.6234 23.7645 10.5723C23.7645 10.5395 23.7548 10.5117 23.7352 10.489C23.7163 10.4657 23.6829 10.4455 23.6349 10.4285C23.6021 10.4171 23.5274 10.3969 23.4107 10.3679C23.2605 10.3307 23.1552 10.285 23.0946 10.2307C23.0095 10.1544 22.9669 10.0613 22.9669 9.95158C22.9669 9.88093 22.9867 9.81501 23.0265 9.75382C23.0669 9.692 23.1246 9.645 23.1996 9.61283C23.2753 9.58066 23.3665 9.56458 23.4731 9.56458C23.6472 9.56458 23.7781 9.60274 23.8658 9.67907C23.9541 9.7554 24.0005 9.85727 24.0049 9.9847L23.7248 9.997C23.7128 9.92572 23.687 9.87462 23.6472 9.84371C23.6081 9.81217 23.5491 9.7964 23.4703 9.7964C23.3889 9.7964 23.3252 9.81312 23.2791 9.84655C23.2495 9.868 23.2347 9.8967 23.2347 9.93266C23.2347 9.96546 23.2485 9.99353 23.2763 10.0169C23.3116 10.0465 23.3974 10.0774 23.5337 10.1096C23.6699 10.1418 23.7705 10.1752 23.8355 10.2099C23.9011 10.244 23.9522 10.291 23.9888 10.3509C24.026 10.4102 24.0446 10.4837 24.0446 10.5714C24.0446 10.6508 24.0225 10.7253 23.9784 10.7947C23.9342 10.8641 23.8718 10.9158 23.791 10.9498C23.7103 10.9833 23.6097 11 23.4892 11C23.3138 11 23.1791 10.9596 23.0852 10.8789C22.9912 10.7975 22.935 10.6792 22.9167 10.524Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M0 8.85647V0.0151469L3.79499 0.0151449C4.75643 0.0151449 5.32913 0 5.32913 0L7.99369 2.19525C7.99369 2.8703 7.90608 3.52976 7.50923 3.97162C7.50923 3.97162 6.7647 4.88574 5.97509 5.0003C6.36785 5.22941 6.45955 5.24253 6.62104 5.40402C6.87879 5.67813 7.23704 6.19919 7.67072 6.89879L8.72039 8.85647H5.49062L4.53206 6.89879C4.19871 6.23246 4.04756 5.92801 3.87573 5.74799C3.60752 5.46062 3.20205 5.3528 2.80905 5.3449C2.78832 5.34449 2.76707 5.34427 2.74531 5.34427V8.85647H0ZM2.74531 3.56789H3.14903C3.55656 3.56789 3.87102 3.56634 4.11251 3.55715C4.49501 3.54261 4.87126 3.39969 5.0869 3.08343C5.19166 2.92978 5.17835 2.76454 5.1722 2.55153C5.17002 2.47584 5.163 2.39938 5.13453 2.32922C5.10579 2.25839 5.07017 2.19757 5.03385 2.14674C4.92887 1.99986 4.75788 1.92408 4.5866 1.86698C4.43665 1.817 4.27975 1.79152 4.12168 1.79152C3.92602 1.79152 3.62645 1.79152 3.14903 1.79152H2.74531V3.56789Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M10.6583 8.85647V2.19525H7.99369L5.32913 0L17.8445 0.0151469L17.0282 2.19525H13.4036V8.85647H10.6583Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M24.1426 8.85647H21.3165L20.8321 7.44363H17.7638L17.36 8.85647H14.534L17.8445 0.0151469H20.8321L24.1426 8.85647ZM20.3476 5.50576L19.2979 2.27599L18.329 5.50576H20.3476Z"
        fill="white"
        fillOpacity="0.4"
      />
    </svg>
  );
}
