import React, { useMemo } from "react";
import CusTable from "../mui/CusTable";
import Pagination from "../mui/Pagination";
import ImgAvatar from "../mui/ImgAvatar";
import tokens from "../../images/mui/tokens.svg";

// service
import IconDiamond from "../../images/jsIcons/IconDiamond";
import IconGroupChat from "../../images/jsIcons/IconGroupChat";
import IconSpyChat from "../../images/jsIcons/IconPrivate";
import IconTips from "../../images/jsIcons/IconTips";

// Transaction type
import chargeback from "../../images/mui/transition/chargeback.png";
import correctionMinus from "../../images/mui/transition/correction-minus.png";
import correctionPlus from "../../images/mui/transition/correction-plus.png";
import deposit from "../../images/mui/transition/deposit.png";
import refundMinus from "../../images/mui/transition/refund-minus.png";
import refundPlus from "../../images/mui/transition/refund-plus.png";
import spend from "../../images/mui/transition/spend.png";
import useDeviceSize from "../../hooks/useDeviceSize";
import CardFromTable from "../mui/CardFromTable";
import { useTranslation } from "react-i18next";
import IconCrown from "../../images/jsIcons/IconCrown";
import { textTypeByType } from "../../store/chatSlice";
import { getImgById } from "../../utils/utils";

const serviceIconById = {
  exclusive: <IconDiamond />,
  "exclusive-chat": <IconDiamond />,
  "private-chat": <IconCrown />,
  "paid-chat": <IconGroupChat />,
  "view-chat": <IconSpyChat />,
  tips: <IconTips />,
  "direct-transfer": <IconTips />,
  "balance-correction": <IconTips />,
  package: <IconTips />,
};

const transitionIconByID = {
  ...serviceIconById,
  chargeback: <img src={chargeback} alt="" />,
  "correction-minus": <img src={correctionMinus} alt="" />,
  "correction-plus": <img src={correctionPlus} alt="" />,
  deposit: <img src={deposit} alt="" />,
  "refund-minus": <img src={refundMinus} alt="" />,
  "refund-plus": <img src={refundPlus} alt="" />,
  spend: <img src={spend} alt="" />,

  "balance-correction": <img src={correctionPlus} alt="" />,
  "direct-transfer": <img src={refundMinus} alt="" />,
};

const WIDTH_FOR_CARD = 950;

export default function StatisticTable({ transactions, currentPage, setCurrentPage, countPage }) {
  const displayDate = (date) => date.replace("T", " ").split("+")?.[0] || "";
  const { t } = useTranslation();
  const { width } = useDeviceSize();

  const isCard = width < WIDTH_FOR_CARD;

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: t("Data"), dataIndex: "date", key: "date" },
    { title: t("Broadcaster"), dataIndex: "broadcaster", key: "broadcaster" },
    // { title: t("Service"), dataIndex: "service", key: "service" },
    { title: t("Transaction type"), dataIndex: "transactionType", key: "transactionType" },
    { title: t("Amount (credits)"), dataIndex: "amount", key: "amount" },
  ];

  const dataSource = useMemo(() =>
    transactions.map((el) => ({
      id: el.id,
      date: displayDate(el.created),
      broadcaster: (
        <span>
          <ImgAvatar id={el.broadcaster?.id} avatarProps={getImgById(el.broadcaster?.id)} />{" "}
          {el.broadcaster?.nickname}
        </span>
      ),
      // service: (
      //   <span className="service">
      //     {serviceIconById[el.service]} <span>{el.service}</span>
      //   </span>
      // ),
      transactionType: el.type && (
        <span className={`transition-type ${el.type}`}>
          {transitionIconByID[el.type]}
          <span>{textTypeByType[el.type]}</span>
        </span>
      ),
      amount: (
        <span className="amount">
          <img src={tokens} alt="tokens" />
          <span className="amount__value">{el.amount}</span>
          <span>{t("Tokens")}</span>
        </span>
      ),
    }))
  );

  return (
    <div className="statistic__container-table">
      {isCard ? (
        <CardFromTable columns={columns} dataSource={dataSource} />
      ) : (
        <CusTable columns={columns} dataSource={dataSource} />
      )}

      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} MAX_PAGE={countPage} />
    </div>
  );
}
