import React from "react";

export default function IconBroadcastOver() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
    >
      <path
        d="M31.1955 101.635C33.673 104.143 33.673 108.195 31.1955 110.703C29.9599 111.953 28.3378 112.582 26.7157 112.582C25.0936 112.582 23.4715 111.953 22.2359 110.703C-7.41197 80.7045 -7.41197 31.8852 22.2359 1.8805C24.7134 -0.626834 28.718 -0.626834 31.1955 1.8805C33.673 4.38784 33.673 8.44061 31.1955 10.9479C6.49636 35.9508 6.49636 76.6324 31.1955 101.635ZM129.764 1.8805C127.287 -0.626834 123.282 -0.626834 120.804 1.8805C118.327 4.38784 118.327 8.44061 120.804 10.9479C145.504 35.9508 145.504 76.6324 120.804 101.635C118.327 104.143 118.327 108.195 120.804 110.703C122.04 111.953 123.662 112.582 125.284 112.582C126.906 112.582 128.529 111.953 129.764 110.703C159.412 80.698 159.412 31.8787 129.764 1.8805ZM76 36.5728C65.5006 36.5728 56.9909 45.1849 56.9909 55.8107C56.9909 64.1855 62.2818 71.31 69.6636 73.952V145.587C69.6636 149.127 72.5023 152 76 152C79.4977 152 82.3364 149.127 82.3364 145.587V73.952C89.7182 71.31 95.0091 64.1855 95.0091 55.8107C95.0091 45.1849 86.4994 36.5728 76 36.5728ZM53.5946 24.0682C51.1171 21.5608 47.1125 21.5608 44.635 24.0682C27.343 41.5682 27.343 70.0467 44.635 87.5467C45.8706 88.7972 47.4927 89.4256 49.1148 89.4256C50.7369 89.4256 52.359 88.7972 53.5946 87.5467C56.0721 85.0394 56.0721 80.9866 53.5946 78.4793C41.245 65.9747 41.245 45.6338 53.5946 33.1356C56.0721 30.6283 56.0721 26.5755 53.5946 24.0682ZM107.359 24.0682C104.881 21.5608 100.877 21.5608 98.3991 24.0682C95.9215 26.5755 95.9215 30.6283 98.3991 33.1356C110.749 45.6402 110.749 65.9811 98.3991 78.4793C95.9215 80.9866 95.9215 85.0394 98.3991 87.5467C99.6347 88.7972 101.257 89.4256 102.879 89.4256C104.501 89.4256 106.123 88.7972 107.359 87.5467C124.651 70.0467 124.651 41.5682 107.359 24.0682Z"
        fill="#CA0C0D"
      />
      <path
        d="M31.1955 101.635C33.673 104.143 33.673 108.195 31.1955 110.703C29.9599 111.953 28.3378 112.582 26.7157 112.582C25.0936 112.582 23.4715 111.953 22.2359 110.703C-7.41197 80.7045 -7.41197 31.8852 22.2359 1.8805C24.7134 -0.626834 28.718 -0.626834 31.1955 1.8805C33.673 4.38784 33.673 8.44061 31.1955 10.9479C6.49636 35.9508 6.49636 76.6324 31.1955 101.635ZM129.764 1.8805C127.287 -0.626834 123.282 -0.626834 120.804 1.8805C118.327 4.38784 118.327 8.44061 120.804 10.9479C145.504 35.9508 145.504 76.6324 120.804 101.635C118.327 104.143 118.327 108.195 120.804 110.703C122.04 111.953 123.662 112.582 125.284 112.582C126.906 112.582 128.529 111.953 129.764 110.703C159.412 80.698 159.412 31.8787 129.764 1.8805ZM76 36.5728C65.5006 36.5728 56.9909 45.1849 56.9909 55.8107C56.9909 64.1855 62.2818 71.31 69.6636 73.952V145.587C69.6636 149.127 72.5023 152 76 152C79.4977 152 82.3364 149.127 82.3364 145.587V73.952C89.7182 71.31 95.0091 64.1855 95.0091 55.8107C95.0091 45.1849 86.4994 36.5728 76 36.5728ZM53.5946 24.0682C51.1171 21.5608 47.1125 21.5608 44.635 24.0682C27.343 41.5682 27.343 70.0467 44.635 87.5467C45.8706 88.7972 47.4927 89.4256 49.1148 89.4256C50.7369 89.4256 52.359 88.7972 53.5946 87.5467C56.0721 85.0394 56.0721 80.9866 53.5946 78.4793C41.245 65.9747 41.245 45.6338 53.5946 33.1356C56.0721 30.6283 56.0721 26.5755 53.5946 24.0682ZM107.359 24.0682C104.881 21.5608 100.877 21.5608 98.3991 24.0682C95.9215 26.5755 95.9215 30.6283 98.3991 33.1356C110.749 45.6402 110.749 65.9811 98.3991 78.4793C95.9215 80.9866 95.9215 85.0394 98.3991 87.5467C99.6347 88.7972 101.257 89.4256 102.879 89.4256C104.501 89.4256 106.123 88.7972 107.359 87.5467C124.651 70.0467 124.651 41.5682 107.359 24.0682Z"
        fill="url(#paint0_linear_740_11955)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_740_11955"
          x1="76"
          y1="0"
          x2="76"
          y2="164.667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
