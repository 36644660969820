import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DepositModal from "./DepositModal";
import { ENUM_MODALS_VALUE, setLoader, setModals } from "../../store/commonSlice";
import PremiumModal from "./PremiumModal";
import TypeLoginModal from "./TypeLoginModal";
import ShareModal from "../chat/ShareModal";
import BannerModalRegister from "./BannerModalRegister";
import LoaderOverlay from "../mui/LoaderOverlay";

export default function CommonModals() {
  const dispatch = useDispatch();
  const isDeposit = useSelector((state) => state.common.modals.deposit);
  const isPremium = useSelector((state) => state.common.modals.premium);
  const isTypeLogin = useSelector((state) => state.common.modals.typeLogin);
  const isShare = useSelector((state) => state.common.modals.share);
  const isRegister = useSelector((state) => state.common.modals.register);
  const commonLoader = useSelector((state) => state.common.loader);
  const timeoutRef = useRef(0);

  const closePremium = () => dispatch(setModals({ name: ENUM_MODALS_VALUE.premium, value: false }));
  const closeDeposit = () => dispatch(setModals({ name: ENUM_MODALS_VALUE.deposit, value: false }));
  const closeShare = () => dispatch(setModals({ name: ENUM_MODALS_VALUE.share, value: false }));
  const closeReg = () => dispatch(setModals({ name: ENUM_MODALS_VALUE.register, value: false }));

  useEffect(() => {
    console.log("commonLoader", commonLoader);
    if (commonLoader) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => dispatch(setLoader(false)), 20 * 1000);
    }
  }, [commonLoader]);

  return (
    <>
      <DepositModal isOpen={isDeposit} closeModal={closeDeposit} />
      <PremiumModal isOpen={isPremium} closeModal={closePremium} />
      <TypeLoginModal
        isOpen={isTypeLogin}
        setOpen={(value) =>
          dispatch(setModals({ name: ENUM_MODALS_VALUE.typeLogin, value: value }))
        }
      />
      <ShareModal isOpen={isShare} closeModal={closeShare} />{" "}
      <BannerModalRegister isOpen={!!isRegister} closeModal={closeReg} />
      {!!commonLoader && <LoaderOverlay />}
    </>
  );
}
