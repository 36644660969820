import React from "react";

export default function IconPin() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_740_14203)">
        <path
          d="M6.90803 16.4073L1.5657 21.7304L0.269531 20.4342L5.61186 15.1093L6.90803 16.4073ZM12.683 18.9419C13.5229 18.0986 14.1287 17.0511 14.4409 15.9025C14.753 14.7539 14.7607 13.5439 14.4632 12.3914L14.1772 11.2217L17.4955 7.88508L18.018 8.4085C18.4039 8.80738 18.9178 9.05776 19.4697 9.11575C20.0216 9.17374 20.5763 9.03565 21.0366 8.72567C21.3081 8.53248 21.5342 8.28242 21.6991 7.9929C21.8641 7.70339 21.9639 7.38139 21.9916 7.04934C22.0194 6.7173 21.9744 6.38319 21.8598 6.07032C21.7452 5.75744 21.5638 5.47332 21.3281 5.23775L16.8163 0.721332C16.4302 0.322926 15.9163 0.0728852 15.3646 0.01491C14.8128 -0.0430652 14.2582 0.0947135 13.7977 0.404165C13.526 0.59727 13.2997 0.847322 13.1346 1.13688C12.9694 1.42644 12.8695 1.74854 12.8416 2.0807C12.8138 2.41287 12.8588 2.74711 12.9734 3.06012C13.088 3.37313 13.2695 3.65736 13.5053 3.893L14.0984 4.48516L10.7791 7.82183L9.61678 7.53675C8.46333 7.23786 7.2519 7.24477 6.10193 7.55677C4.95196 7.86878 3.90316 8.47511 3.05895 9.316L2.73536 9.6405L12.3604 19.2655L12.683 18.9419Z"
          fill="white"
          fillOpacity="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_14203">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
