import React from "react";

export default function AboutContentRu() {
  return (
    <>
      <h2>О RichCams</h2>
      <p>
        RichCams — это бесплатное онлайн-сообщество, где вы можете наблюдать за выступлением
        потрясающих моделей в прямом эфире. Это 100% бесплатный сервис, предоставляет мгновенный
        доступ каждому совершеннолетнему пользователю! Наша цель — обеспечить хорошее
        времяпровождение пользователям и моделям
      </p>

      <ul>
        <li>Мерчант: RichCams</li>
        <li>Город: Tallinn </li>
        <li>Провинция: Harju maakond</li>
        <li>Страна: Estonia</li>
        <li>
          Контактная информация: <a href="mailto:support@richcams.com">support@richcams.com</a>
        </li>
      </ul>
    </>
  );
}
