import React, { useRef } from "react";

export default function MessageDate({ message }) {
  const datePlugRef = useRef("");

  const date = message?.created?.split?.("T")?.[0];
  let isDatePlug = false;

  if (date !== datePlugRef.current) {
    datePlugRef.current = date;
    isDatePlug = true;
  }
  return (
    isDatePlug &&
    date && (
      <div className="chat__free__date">
        <span>{date}</span>
      </div>
    )
  );
}
