import React from "react";
import Header from "../home/Header";
import Footer from "../home/Footer";
import useScrollUp from "../../hooks/useScrollUp";
import { useTranslation } from "react-i18next";

export default function PageTextContainer({ className = "", children }) {
  useScrollUp();
  const { t } = useTranslation();

  return (
    <div className={`page-text ${t("currentLang")} `}>
      <Header />
      <section className={`page-text__content ${className}`}>{children}</section>
      <Footer />
    </div>
  );
}
