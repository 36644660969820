import React, { useMemo, useRef } from "react";
import Modal from "react-modal";

import { API_LOGIN } from "../../api/login";
import { catchMessageServer, thenMessageServer } from "../../api/main";
import { setMainUser } from "../../store/userSlice";
import RegisterBanner1 from "./RegisterBanner1";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import RegisterBanner2 from "./RegisterBanner2";
import RegisterBannerApp from "./RegisterBannerApp";

const ENUM_TYPE_MODALS = {
  new: "newModal",
  old: "oldModal",
  app: "appModal",
};

export default function BannerModalRegister({ isOpen, closeModal, isAppModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const captchaRef = useRef("");

  const fields = [
    { text: "E-mail", type: "text", placeholder: "Your E-mail", required: true },
    {
      text: "Password",
      type: "password",
      minLength: 8,
      placeholder: "Your password",
      required: true,
    },
    { text: "Nickname", placeholder: "Your nickname" },
  ];

  const onRegister = async (e, fields) => {
    e.preventDefault();

    const data = {
      email: fields.email,
      password: fields.password,
      login_after_join: true,
      nickname: fields.nickname,
      captcha: captchaRef.current?.getValue?.(),
    };

    API_LOGIN.register(data)
      .then((e) => {
        thenMessageServer(e);
        if (e.data.user) dispatch(setMainUser(e.data.user));
        if (e.data.ok) closeModal();
      })
      .catch(catchMessageServer)
      .finally(() => {
        captchaRef.current?.reset?.();
      });
  };

  const props = {
    fields: fields,
    onRegister: onRegister,
    closeModal: closeModal,
    captchaRef: captchaRef,
  };

  const modals = {
    [ENUM_TYPE_MODALS.old]: {
      element: <RegisterBanner1 {...props} />,
      className: "variant1",
    },
    [ENUM_TYPE_MODALS.new]: {
      element: <RegisterBanner2 {...props} />,
      className: "variant2",
    },
    [ENUM_TYPE_MODALS.app]: {
      element: <RegisterBannerApp {...props} />,
      className: "variant-app",
    },
  };

  const isNew = useMemo(() => Math.random() * 2 > 1, [isOpen]);
  let typeModal = isNew ? ENUM_TYPE_MODALS.new : ENUM_TYPE_MODALS.old;
  if (isAppModal) typeModal = ENUM_TYPE_MODALS.app;

  const currentModal = modals[typeModal];
  const classModal = currentModal.className;

  return (
    <Modal
      ariaHideApp={false}
      className={`banner-register ${t("currentLang")} ${classModal}`}
      isOpen={isOpen}
      onRequestClose={(e) => {
        if (e.key) closeModal();
      }}
    >
      {currentModal.element}
    </Modal>
  );
}
