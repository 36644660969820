import React, { useRef } from "react";
import useDeviceSize from "../../../../../hooks/useDeviceSize";
import { useTranslation } from "react-i18next";
import { useEventListener } from "usehooks-ts";
import { setZoom } from "../../../../../store/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { setElemHeight } from "../../../../../utils/utils";

export default function DrugSize({ videoRef, canvasRef }) {
  const dispatch = useDispatch();
  const { isMobile, width } = useDeviceSize();
  const { t } = useTranslation();

  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);

  const isDragRef = useRef();

  const initMaxHeight = () => {
    if (canvasRef?.current?.style?.height) canvasRef.current.style.height = "100%";
  };

  const makeScale = async (difference) => {
    console.log(difference);

    const canvas = canvasRef.current;
    const video = videoRef.current;

    const setZeroTransform = () => {
      if (window.zoomist?.transform?.translateX) window.zoomist.transform.translateX = 0;
      if (window.zoomist?.transform?.translateY) window.zoomist.transform.translateY = 0;
    };

    const setScale = (z) => {
      if (window.zoomist?.transform?.scale) {
        window.zoomist.transform.scale = z;
        dispatch(setZoom(z));
      }
    };

    if (difference > 0) {
      while (video.clientHeight * window.scale < canvas.clientHeight) {
        window.scale += difference * 0.005;
      }
    }
    if (difference < 0 && window.scale > 1) {
      while (video.clientHeight * window.scale > canvas.clientHeight) {
        window.scale += difference * 0.005;
        setZeroTransform();
      }
    }
    setScale(window.scale);
  };

  const onMouseMove = (e) => {
    if (!isDragRef.current) return;
    e.preventDefault();
    e.stopPropagation();
    if (!window.scale) window.scale = 1;

    dispatchEvent(new CustomEvent("drugSize"));
    const canvas = canvasRef.current;
    // const saveScrollY = window.scrollY;

    const topCanvas = isFullScreen ? 0 : canvas.getBoundingClientRect().top;
    const touch = e.touches[0];

    const bottomPos = touch.clientY - topCanvas;
    const bottomBorder = window.innerHeight * 0.6;

    if (bottomPos < 200) return;
    if (bottomPos > bottomBorder) return;

    const difference = touch.clientY - canvas.getBoundingClientRect().bottom;

    makeScale(difference);
    setElemHeight(canvas, bottomPos);

    if (isFullScreen) {
      const chatBroadcaster = document.querySelector("#chat-broadcaster");
      if (chatBroadcaster) {
        const newHeight = window.innerHeight - bottomPos;
        chatBroadcaster.style.maxHeight = newHeight + "px";
      }
    }
  };

  useEventListener("resize", initMaxHeight);
  useEventListener("touchmove", onMouseMove, undefined, { passive: false });

  return (
    isMobile &&
    width && (
      <button
        onTouchStart={() => {
          isDragRef.current = true;
        }}
        onTouchEnd={() => (isDragRef.current = false)}
        className="controls__drag"
      >
        {t("Drag & Resize")}
      </button>
    )
  );
}
