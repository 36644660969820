import React, { useRef, useState } from "react";
import { useEventListener } from "usehooks-ts";

export default function Draggable({ className, style, children, id }) {
  const [isDragging, setIsDragging] = useState(false);
  const [initialOffset, setInitialOffset] = useState([0, 0]);
  const [currentOffset, setCurrentOffset] = useState([0, 0]);

  const containerRef = useRef();

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setInitialOffset([e.clientX - currentOffset[0], e.clientY - currentOffset[1]]);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    const offsetX = e.clientX - initialOffset[0];
    const offsetY = e.clientY - initialOffset[1];
    setCurrentOffset([offsetX, offsetY]);
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    const touch = e.touches[0];
    setIsDragging(true);
    setInitialOffset([touch.clientX - currentOffset[0], touch.clientY - currentOffset[1]]);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const touch = e.touches[0];
    const offsetX = touch.clientX - initialOffset[0];
    const offsetY = touch.clientY - initialOffset[1];
    setCurrentOffset([offsetX, offsetY]);
  };

  const handleTouchEnd = () => setIsDragging(false);
  const handleMouseUp = () => setIsDragging(false);

  useEventListener("mousemove", handleMouseMove, undefined, { passive: false });
  useEventListener("touchmove", handleTouchMove, undefined, { passive: false });

  return (
    <div
      id={id}
      ref={containerRef}
      className={className}
      style={{
        ...style,
        transform: `translate(${currentOffset[0]}px, ${currentOffset[1]}px)`,
        cursor: isDragging ? "grabbing" : "grab",
      }}
      onMouseDown={handleMouseDown}
      // onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {children}
    </div>
  );
}
