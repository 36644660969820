import React from "react";
import CusButton from "../../mui/CusButton";
import { useTranslation } from "react-i18next";

export default function PrivateEnd() {
  const { t } = useTranslation();
  return (
    <div className="chat__model__private-end">
      <h2>{t("End of The Private Show?")}</h2>
      <div className="chat__model__private-end__button">
        <CusButton color="red" text={"Continue Show"} />
        <CusButton text={"Finish"} />
      </div>
    </div>
  );
}
