import React, { useState } from "react";
import CusButton from "../../mui/CusButton";
import { ENUM_TAB, setPageActiveTab } from "../../../store/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { scroller } from "react-scroll";
import { API_PROFILE } from "../../../api/profile";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import { TEST_MODE } from "../../common/consts";

export default function ChatTabs() {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.chat.pageActiveTab);
  const broadcasterId = useSelector((state) => state.chat.broadcaster?.id);

  const [chatItems, setChatItems] = useState([]);

  const checkEmptyTab = (id) => {
    if (activeTab === id) dispatch(setPageActiveTab(ENUM_TAB.profile));
  };

  useAsyncEffect(async () => {
    if (!broadcasterId) return;
    let photo = false;
    let snapshot = false;

    await API_PROFILE.photos
      .getPhotoIdList(broadcasterId)
      .then((e) => {
        photo = !!e.data.photo.length;
      })
      .catch((e) => console.error(e));
    await API_PROFILE.photos
      .getSnapshotIdList(broadcasterId)
      .then((e) => {
        snapshot = !!e.data.snapshot.length;
      })
      .catch((e) => console.error(e));

    if (TEST_MODE) photo = true;
    if (TEST_MODE) snapshot = true;

    const items = [
      { text: "Profile", id: ENUM_TAB.profile },
      { text: "Photo", id: ENUM_TAB.photo },
      { text: "Snapshots", id: ENUM_TAB.snapshot },
      // { text: "Video", id: ENUM_TAB.video },
    ];
    if (!photo) {
      delete items[1];
      checkEmptyTab(ENUM_TAB.photo);
    }
    if (!snapshot) {
      delete items[2];
      checkEmptyTab(ENUM_TAB.snapshot);
    }

    setChatItems(items);
  }, [broadcasterId]);

  const onTabClick = (id) => {
    dispatch(setPageActiveTab(id));
    setTimeout(
      () => scroller.scrollTo("section-bottom", { duration: 800, smooth: "easeInOutQuart" }),
      60
    );
  };

  const classTabs = chatItems.length === 4 ? "mobile-grid-tabs" : "";

  return (
    <div className={`chat__tabs ${classTabs}`}>
      {chatItems.map((el, indx) => (
        <CusButton
          className="chat__items__item"
          key={indx}
          color={activeTab === el.id && "active" && "red"}
          onClick={() => onTabClick(el.id)}
          text={el.text}
        />
      ))}
    </div>
  );
}
