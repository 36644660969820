import React, { useEffect, useState } from "react";
import Message from "./Message";
import { getScrollBottom } from "../../../utils/utils";

export default function ChatFreeMessages({
  messages,
  freeMessages,
  setShowNewMes,
  makeScrollBottom,
  maxHeight,
  messagesAll,
}) {
  const [scrollBottomY, setScrollBottom] = useState(0);

  const onScrollMess = () => {
    const scrollBottom = getScrollBottom(freeMessages.current);
    if (scrollBottom < 5) setShowNewMes(false);
    setScrollBottom(scrollBottom);
  };

  useEffect(() => {
    makeScrollBottom(freeMessages.current);
  }, [maxHeight]);

  useEffect(() => {
    const freeMes = freeMessages.current;
    if (!freeMes) return;

    if (scrollBottomY < 100) {
      makeScrollBottom(freeMes);
      return;
    }

    setShowNewMes(true);
  }, [messagesAll, freeMessages]);

  return (
    <div className="chat__free__messages" ref={freeMessages} onScroll={onScrollMess}>
      {messages?.map?.((el, indx) => (
        <Message key={indx} message={el} />
      ))}
    </div>
  );
}
