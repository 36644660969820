import React from "react";
import CusButton from "../mui/CusButton";
import { LOGIN, SIGN_UP } from "../common/consts";
import { useSelector } from "react-redux";
import ProfilePopup from "../user/ProfilePopup";
import BalanceInfo from "../user/BalanceInfo";

// блок кнопки на десктопе для авторизированного или нет
export default function HeaderButton({ setOpenLoginModal }) {
  const id = useSelector((state) => state.user.user.id);

  return (
    <div className="header__buttons">
      {id ? (
        <>
          <BalanceInfo />
          <ProfilePopup />
        </>
      ) : (
        <>
          <CusButton text={"Login"} onClick={() => setOpenLoginModal(LOGIN)} />
          <CusButton
            onClick={() => setOpenLoginModal(SIGN_UP)}
            className="custom-button__red"
            text={"Sign up"}
          />
        </>
      )}
    </div>
  );
}
