import React from "react";
import IconConnectionLost from "../../../images/jsIcons/IconConnectionLost";
import { useTranslation } from "react-i18next";

export default function ConnectionLost() {
  const { t } = useTranslation();

  return (
    <div className="chat__model__connect-lost">
      <IconConnectionLost />
      <h3>{t("Connection lost")}</h3>
    </div>
  );
}
