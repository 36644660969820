import React from "react";

export default function CardFromTable({ dataSource, columns }) {
  return (
    <div className="cus-card__container">
      {dataSource.map((source) => (
        <div key={source.id} className="cus-card">
          <div className="cus-card__first-row">
            <span className="cus-card__full-id">
              <span className="cus-card__full-id__title">ID</span>
              <span className="cus-card__full-id__value">{source.id}</span>
            </span>

            <span className="cus-card__date">{source.date}</span>
          </div>
          <div className="cus-card__content">
            {columns.map(
              (column) =>
                column.dataIndex !== "id" &&
                column.dataIndex !== "date" && (
                  <div key={column.key} className="cus-card__content__row">
                    <span className="cus-card__content__row__title">{column.title}</span>
                    <span className="cus-card__content__row__value">
                      {source[column.dataIndex]}
                    </span>
                  </div>
                )
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
