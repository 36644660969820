import React, { useState } from "react";
import IconArrow from "../../images/jsIcons/IconArrow";
import ImgAvatar, { ENUM_PLUG } from "../mui/ImgAvatar";
import CusButton from "../mui/CusButton";
import IconCross from "../../images/jsIcons/IconCross";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IconArrowTwo from "../../images/jsIcons/IconArrowTwo";
import { scrollToElement } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import NextPrevModel from "../../controls/NextPrevModel";
import useJanus from "../../hooks/useJanus";
import Spinner from "../mui/Spinner";

export default function ChatHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const broadcaster = useSelector((state) => state.chat.broadcaster);
  const broadcasters = useSelector((state) => state.common.broadcastersOnline);
  const broadcastersPayload = useSelector((state) => state.common.broadcastersPayload);
  const maxPage = useSelector((state) => state.common.maxPage);

  const { t } = useTranslation();
  const [disable, setDisable] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const { destroyCurrent } = useJanus({});

  const onNextModel = async () => {
    console.log("broadcasters", broadcasters);

    const nextPrevModel = new NextPrevModel({
      broadcasters,
      broadcaster,
      dispatch,
      navigate,
      setBtnLoading,
      setDisable,
      broadcastersPayload,
      destroyCurrent,
    });

    nextPrevModel.next();
  };

  const onPrevClick = async () => {
    const nextPrevModel = new NextPrevModel({
      broadcasters,
      broadcaster,
      dispatch,
      navigate,
      setBtnLoading,
      setDisable,
      broadcastersPayload,
      destroyCurrent,
    });

    nextPrevModel.prev(maxPage);
  };

  return (
    <>
      <div className="chat-page__header">
        <button disabled={disable} className="chat-page__header__btn-control" onClick={onPrevClick}>
          {btnLoading ? <Spinner /> : <IconArrow className={"rotate90"} />}
        </button>

        <div className="chat__items">
          <div
            className="chat__user chat__items__item"
            onClick={() => scrollToElement("section-bottom")}
          >
            <ImgAvatar
              id={broadcaster.id}
              autoWidthImg
              plug={ENUM_PLUG.rabbit}
              avatarProps={broadcaster.imgSource}
            />
            <span>@{broadcaster.name}</span>
            <IconArrowTwo />
          </div>
        </div>

        <CusButton
          loading={btnLoading}
          disabled={disable}
          color="red"
          className="chat__next"
          onClick={onNextModel}
        >
          <span>{t("Next model")}</span>
          <IconArrow className={"rotate270"} />
        </CusButton>
        <Link to="/" className="chat-page__header__btn-control">
          <IconCross />
        </Link>
      </div>
    </>
  );
}
