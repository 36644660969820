import { useCallback, useEffect } from "react";
import { useInterval } from "usehooks-ts";
import { ENUM_STATUS_WINDOW, setLoadingBroadcaster } from "../store/chatSlice";
import { useDispatch } from "react-redux";
import JanusState from "../controls/janus/site/JanusState";
import { ENUM_READY_STATE } from "../store/enums";

export default function useCheckCamera({ videoRef, setNoCamera }) {
  const dispatch = useDispatch();

  const checkPause = useCallback(() => {
    const isLive = JanusState.readyState === ENUM_READY_STATE.live;
    const paused = videoRef.current?.paused;
    JanusState.isPlayVideo = !paused;
    setNoCamera(paused);
    if (!paused && isLive) {
      // console.log("setLoadingBroadcaster", false);
      dispatch(setLoadingBroadcaster(false));
    }
    return paused;
  }, [setNoCamera]);

  const checkNoCamera = useCallback(() => {
    // console.log("camera paused", videoRef.current?.paused);
    if (!videoRef.current) return;
    if (JanusState.statusWindow !== ENUM_STATUS_WINDOW.stream) {
      setNoCamera(false);
      console.log("setLoadingBroadcaster", false);
      dispatch(setLoadingBroadcaster(false));
      JanusState.isPlayVideo = false;
      return;
    }
    const paused = checkPause();
    setNoCamera(paused);
  }, [checkPause]);

  useEffect(() => {
    checkNoCamera();
  }, []);

  useInterval(() => {
    checkNoCamera();
  }, 3000);

  const onPlaying = useCallback(() => {
    console.log("onPlaying");
    checkNoCamera();
    setTimeout(() => checkPause(), 100);
  }, [checkNoCamera]);

  const onPause = useCallback(() => {
    // setNoCamera(true);
    console.log("onPause");
  }, [useCheckCamera]);

  return { onPlaying, onPause };
}
