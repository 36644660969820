import React, { useRef } from "react";
import CusButton from "../../../../mui/CusButton";
import IconFoolScreen from "../../../../../images/jsIcons/IconFoolScreen";
import { useSelector } from "react-redux";
import useDeviceSize, { ENUM_DEVICE_ORIENTATION } from "../../../../../hooks/useDeviceSize";
import { useEventListener } from "usehooks-ts";
import useLoginAction from "../../../../../hooks/useLoginAction";
import { cancelFs, requestFs } from "../../../../../utils/utils";
import ShownControl from "../../../../mui/ShownControl";

export default function FulScreen({ chatWindowRef, videoRef, realVideoRef }) {
  const { isMobile, orientation } = useDeviceSize();
  const { onLoginAction } = useLoginAction();

  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);

  const angeRef = useRef(screen?.orientation?.angle);

  useEventListener("resize", async () => {
    const angle = screen?.orientation?.angle;
    if (isFullScreen && angeRef.current !== angle) await onFoolScreen();
    angeRef.current = angle;
  });

  const onFoolScreen = async () => {
    const chatBroadcaster = document.querySelector("#chat-broadcaster");
    const zoomistContainer = document.querySelector("#zoomist-container");
    chatBroadcaster.style.maxHeight = "100%";
    zoomistContainer.style.height = "auto";

    if (isFullScreen) return cancelFs();

    const mobilePortrait = isMobile && orientation === ENUM_DEVICE_ORIENTATION.portrait;
    const elem = mobilePortrait ? document.querySelector("#chat-content") : chatWindowRef.current;

    await requestFs(elem, realVideoRef?.current);
    if (videoRef?.current?.style?.height) videoRef.current.style.height = "100%";
  };

  return (
    <ShownControl>
      <CusButton
        id={"full-screen"}
        icon={<IconFoolScreen />}
        onClick={() => onLoginAction(onFoolScreen)}
      />
    </ShownControl>
  );
}
