import React from "react";
import { ENUM_CHAT_MODE } from "../../../../store/chatSlice";

import CusButton from "../../../mui/CusButton";
import useJanus from "../../../../hooks/useJanus";
import usePaidAction from "../../../../hooks/usePaidAction";
import { useSelector } from "react-redux";
import { ICON_BY_MODE } from "../../../../store/siteInfoSlice";
import { useTranslation } from "react-i18next";

const MODE_BY_TEXT = {
  [ENUM_CHAT_MODE.free]: "Free Chat",
  [ENUM_CHAT_MODE.paid]: "Group Show",
  [ENUM_CHAT_MODE.view]: "View Chat",
  [ENUM_CHAT_MODE.private]: "Private Chat",
  [ENUM_CHAT_MODE.exclusive]: "Private Exclusive",
  [ENUM_CHAT_MODE.pause]: "Pause",
};

export default function MesSwitched({ message, isJoin, spying }) {
  const { changeChatMode } = useJanus({});
  const { onPaidActionClick } = usePaidAction();
  const { t } = useTranslation();

  const broadcaster = useSelector((state) => state.chat.broadcaster.name);
  const joinPrice = useSelector((state) => state.chat.viewPrice);
  const joinMinutes = useSelector((state) => state.chat.viewTime);
  const JoinTextStart = spying ? "Start spying" : "Join";
  const joinText = `${JoinTextStart} for ${joinPrice} ${t("tk")} / ${joinMinutes} minute`;

  const iconMode = ICON_BY_MODE[message.mode];

  const onJoinClick = () => changeChatMode(message.mode);
  return (
    <>
      <div className={"chat__free__message__change"}>
        <span className="chat__free__message__change__text">
          {iconMode}
          {/* {message.nickname && <span className="nick">@{message.nickname} </span>} */}
          <span className="nick">@{broadcaster} </span>
          <span className="mode">
            {" "}
            {t("started a")} {MODE_BY_TEXT[message.mode]}{" "}
          </span>
          {isJoin && (
            <CusButton
              className="join-button"
              color="red"
              icon={iconMode}
              text={joinText}
              onClick={() => onPaidActionClick(joinPrice, onJoinClick)}
            />
          )}
        </span>
      </div>
    </>
  );
}
