import { Table } from "antd";
import React from "react";

/// CusTable - кастомный компонент для отображения таблицы

export default function CusTable({ dataSource, columns, title }) {
  return (
    <div className="cus-table">
      {!!title && <h2>{title(title)}</h2>}

      <Table
        dataSource={dataSource}
        columns={columns}
        className="cus-table"
        pagination={false}
        bordered={false}
        rowClassName={"cus-table__row"}
      />
    </div>
  );
}
