import React from "react";
import imgLogo from "../../images/common/logo.svg";
import { Link } from "react-router-dom";

export default function Logo({ className }) {
  return (
    <Link to={"/"} className={className}>
      <img className="logo" src={imgLogo} />
    </Link>
  );
}
