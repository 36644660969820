import React, { useMemo, useRef } from "react";
import i18n from "../../i18n";

import { useTranslation } from "react-i18next";
import { getLang } from "../../utils/utils";
import IconArrow from "../../images/jsIcons/IconArrow";
import { useLocalStorage } from "usehooks-ts";
import { API_INFO } from "../../api/info";
import FlagImg from "./FlagImg";
import { setNewSiteInfo } from "../../store/siteInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import { TEST_MODE } from "../common/consts";

export default function ChoseLang({ classNameUl }) {
  const chooseLangRef = useRef(null);
  const languages = useSelector((state) => state.common.languages);

  const chosenLang = getLang();
  const [selectLang, setSelectLang] = useLocalStorage("lang", chosenLang);
  const getSelect = (lang) => (lang === chosenLang ? "select" : "");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cookieLang = useMemo(() => {
    const cookieLang = {};

    languages?.forEach((el) => {
      cookieLang[el.code] = `${el.code}_${el.country}`;
    });

    return cookieLang;
  }, [languages]);

  const changeLang = (lang) => {
    document.cookie =
      encodeURIComponent("language") + "=" + encodeURIComponent(cookieLang[lang]) + ";";
    encodeURIComponent("language") + "=" + cookieLang[lang];
    API_INFO.listSiteCategory()
      .then((e) => {
        dispatch(setNewSiteInfo(e.data.categories));
      })
      .catch((e) => console.log(e))
      .finally(() => {
        i18n.changeLanguage(lang);
        setSelectLang(lang);
      });

    if (TEST_MODE)
      dispatch(
        setNewSiteInfo([
          {
            title: "Пол",
            id: 1,
            url: "gender",
            subcategories: [
              {
                title: "Все модели",
                id: 15,
                url: "all",
              },
              {
                title: "Девушки",
                id: 11,
                url: "girls",
                multiselect: true,
              },
              {
                title: "Парни",
                id: 12,
                url: "guys",
                multiselect: true,
              },
              {
                title: "Трансы",
                id: 16,
                url: "trans",
                multiselect: true,
              },
            ],
          },
          {
            id: 4,
            url: "show",
            subcategories: [
              {
                title: "Вне дома",
                keywords: "Вне дома",
                id: 8,
                url: "outdoors",
                multiselect: true,
              },
              {
                title: "Вибратор",
                keywords: "Вибратор",
                id: 17,
                url: "vibrator",
                multiselect: true,
              },
              {
                title: "Дилдо",
                keywords: "Дилдо",
                id: 18,
                url: "dildo",
                multiselect: true,
              },
              {
                title: "Пары",
                keywords: "Пары",
                id: 19,
                url: "couples",
                multiselect: true,
              },
              {
                title: "Подгядки",
                keywords: "Подгядки",
                id: 20,
                url: "spy-private",
                multiselect: true,
              },
            ],
          },
          {
            title: "Фильтры",
            id: 5,
            url: "filters",
            multiselect: true,
            subcategories: [
              {
                title: "Популярные",
                keywords: "Популярные",
                id: 9,
                url: "popular",
                subcategories: [
                  {
                    title: "Групповой секс",
                    keywords: "Групповой секс",
                    id: 24,
                    url: "group-sex",
                    multiselect: true,
                  },
                  {
                    title: "Большая грудь",
                    keywords: "Большая грудь",
                    id: 25,
                    url: "big-tits",
                    multiselect: true,
                  },
                  {
                    title: "Анал",
                    keywords: "Анал",
                    id: 32,
                    url: "anal",
                    multiselect: true,
                  },
                  {
                    title: "Большая задница",
                    keywords: "Большая задница",
                    id: 33,
                    url: "big-ass",
                    multiselect: true,
                  },
                  {
                    title: "Лесбиянки",
                    keywords: "Лесбиянки",
                    id: 34,
                    url: "lesbians",
                    multiselect: true,
                  },
                  {
                    title: "Беременные",
                    keywords: "Беременные",
                    id: 35,
                    url: "pregnant",
                    multiselect: true,
                  },
                  {
                    title: "Сквирт",
                    keywords: "Сквирт",
                    id: 36,
                    url: "squirt",
                    multiselect: true,
                  },
                  {
                    title: "Жесткий секс",
                    keywords: "Жесткий секс",
                    id: 37,
                    url: "hardcore",
                    multiselect: true,
                  },
                  {
                    title: "Минет",
                    keywords: "Минет",
                    id: 38,
                    url: "blowjob",
                    multiselect: true,
                  },
                  {
                    title: "БДСМ",
                    keywords: "БДСМ",
                    id: 39,
                    url: "bdsm",
                    multiselect: true,
                  },
                ],
              },
              {
                title: "Возраст",
                id: 78,
                url: "age",
                subcategories: [
                  {
                    title: "18-22",
                    keywords: "18-22",
                    id: 79,
                    url: "18-22",
                  },
                  {
                    title: "23-30",
                    keywords: "23-30",
                    id: 80,
                    url: "23-30",
                  },
                  {
                    title: "31-45",
                    keywords: "31-45",
                    id: 81,
                    url: "31-45",
                  },
                  {
                    title: "45+",
                    keywords: "45+",
                    id: 82,
                    url: "46-99",
                  },
                ],
              },
              {
                title: "Регион",
                keywords: "Region",
                id: 10,
                url: "region",
                subcategories: [
                  {
                    title: "Северная Америка",
                    keywords: "Северная Америка",
                    id: 40,
                    url: "usa-uk",
                  },
                  {
                    title: "Европа",
                    keywords: "Европа",
                    id: 41,
                    url: "eu",
                  },
                  {
                    title: "Россия / Украина",
                    keywords: "Россия / Украина",
                    id: 42,
                    url: "cis",
                  },
                  {
                    title: "Латинская Америка",
                    keywords: "Латинская Америка",
                    id: 43,
                    url: "latin",
                  },
                  {
                    title: "Азия",
                    keywords: "Азия",
                    id: 44,
                    url: "asia",
                  },
                  {
                    title: "Другие регионы",
                    keywords: "Другие регионы",
                    id: 45,
                    url: "other",
                  },
                ],
              },
              {
                title: "Язык",
                keywords: "Язык",
                id: 22,
                url: "language",
                multiselect: true,
                subcategories: [
                  {
                    title: "Английский",
                    keywords: "Английский язык",
                    id: 46,
                    url: "en",
                    multiselect: true,
                  },
                  {
                    title: "Французский",
                    keywords: "Французский язык",
                    id: 88,
                    url: "fr",
                    multiselect: true,
                  },
                  {
                    title: "Испанский",
                    keywords: "Испанский язык",
                    id: 89,
                    url: "es",
                    multiselect: true,
                  },
                  {
                    title: "Русский",
                    keywords: "Русский язык",
                    id: 90,
                    url: "ru",
                    multiselect: true,
                  },
                  {
                    title: "Немецкий",
                    keywords: "Немецкий язык",
                    id: 91,
                    url: "de",
                    multiselect: true,
                  },
                  {
                    title: "Итальянский",
                    keywords: "Итальянский язык",
                    id: 92,
                    url: "it",
                    multiselect: true,
                  },
                  {
                    title: "Шведский",
                    keywords: "Шведский язык",
                    id: 93,
                    url: "sw",
                    multiselect: true,
                  },
                  {
                    title: "Португальский",
                    keywords: "Португальский язык",
                    id: 94,
                    url: "pt",
                    multiselect: true,
                  },
                  {
                    title: "Датский",
                    keywords: "Датский язык",
                    id: 95,
                    url: "da",
                    multiselect: true,
                  },
                  {
                    title: "Другие",
                    keywords: "Другие",
                    id: 96,
                    url: "other",
                    multiselect: true,
                  },
                ],
              },
              {
                title: "Внешность",
                keywords: "Appearance",
                id: 23,
                url: "appearance",
                multiselect: true,
                subcategories: [
                  {
                    title: "Тело",
                    keywords: "Тело",
                    id: 47,
                    url: "body",
                    subcategories: [
                      {
                        title: "Стройное",
                        keywords: "Стройное",
                        id: 48,
                        url: "thin",
                        multiselect: true,
                      },
                      {
                        title: "Обычное",
                        keywords: "Обычное",
                        id: 49,
                        url: "regular",
                        multiselect: true,
                      },
                      {
                        title: "Атлетическое",
                        keywords: "Атлетическое",
                        id: 50,
                        url: "athletic",
                        multiselect: true,
                      },
                      {
                        title: "Несколько лишних килограмм",
                        keywords: "Несколько лишних килограмм",
                        id: 51,
                        url: "extra",
                        multiselect: true,
                      },
                      {
                        title: "Большой и высокий",
                        keywords: "Большой и высокий",
                        id: 52,
                        url: "bbw",
                        multiselect: true,
                      },
                    ],
                  },
                  {
                    title: "Грудь",
                    keywords: "Грудь",
                    id: 53,
                    url: "breasts",
                    subcategories: [
                      {
                        title: "Маленькая",
                        keywords: "Маленькая",
                        id: 54,
                        url: "small",
                        multiselect: true,
                      },
                      {
                        title: "Средний размер груди",
                        keywords: "Средняя грудь",
                        id: 55,
                        url: "medium",
                        multiselect: true,
                      },
                      {
                        title: "Большая грудь",
                        keywords: "Большая грудь",
                        id: 56,
                        url: "large",
                        multiselect: true,
                      },
                      {
                        title: "Огромная грудь",
                        keywords: "Огромная грудь",
                        id: 57,
                        url: "huge",
                        multiselect: true,
                      },
                    ],
                  },
                  {
                    title: "Размер задницы",
                    keywords: "Размер задницы",
                    id: 58,
                    url: "butt",
                    subcategories: [
                      {
                        title: "Маленькая задница",
                        keywords: "Маленькая задница",
                        id: 59,
                        url: "small",
                        multiselect: true,
                      },
                      {
                        title: "Средняя",
                        keywords: "Средняя",
                        id: 60,
                        url: "medium",
                        multiselect: true,
                      },
                      {
                        title: "Большая задница",
                        keywords: "Большая задница",
                        id: 61,
                        url: "big",
                        multiselect: true,
                      },
                    ],
                  },
                  {
                    title: "Этничность",
                    keywords: "Этничность",
                    id: 62,
                    url: "ethnicity",
                    subcategories: [
                      {
                        title: "Азиатская",
                        keywords: "Азиатская",
                        id: 63,
                        url: "asia",
                        multiselect: true,
                      },
                      {
                        title: "Белая",
                        keywords: "Белая",
                        id: 64,
                        url: "caucasian",
                        multiselect: true,
                      },
                      {
                        title: "Темненькая",
                        keywords: "Темненькая",
                        id: 65,
                        url: "ebony",
                        multiselect: true,
                      },
                      {
                        title: "Латиноамериканская",
                        keywords: "Латиноамериканская",
                        id: 66,
                        url: "latino",
                        multiselect: true,
                      },
                      {
                        title: "Индийская",
                        keywords: "Индийская",
                        id: 67,
                        url: "indian",
                        multiselect: true,
                      },
                      {
                        title: "Арабская",
                        keywords: "Арабская",
                        id: 68,
                        url: "arab",
                        multiselect: true,
                      },
                    ],
                  },
                  {
                    title: "Цвет волос",
                    keywords: "Цвет волос",
                    id: 69,
                    url: "hair",
                    subcategories: [
                      {
                        title: "Блондинка",
                        keywords: "Блондинка",
                        id: 70,
                        url: "blonde",
                        multiselect: true,
                      },
                      {
                        title: "Брюнетка",
                        keywords: "Брюнетка",
                        id: 71,
                        url: "brunette",
                        multiselect: true,
                      },
                      {
                        title: "Рыжая",
                        keywords: "Рыжая",
                        id: 72,
                        url: "redhead",
                        multiselect: true,
                      },
                      {
                        title: "Лысая",
                        keywords: "Лысая",
                        id: 73,
                        url: "bald",
                        multiselect: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: "Сортировка",
            keywords: "Сортировка",
            id: 21,
            url: "sort-by",
            subcategories: [
              {
                title: "Самые популярные комнаты",
                keywords: "Самые популярные комнаты",
                id: 26,
                url: "popular-chat-rooms",
              },
              {
                title: "Наивысший балл",
                keywords: "Наивысший балл",
                id: 74,
                url: "highest",
              },
              {
                title: "Новые модели",
                keywords: "Новые модели",
                id: 75,
                url: "new-models",
              },
              {
                title: "Новые чат-комнаты",
                keywords: "Новые чат-комнаты",
                id: 76,
                url: "new-rooms",
              },
              {
                title: "Мои избранные",
                keywords: "Мои избранные",
                id: 77,
                url: "favorites",
              },
            ],
          },
        ])
      );
  };

  return (
    <button
      className="chose-lang"
      onMouseOver={() => chooseLangRef.current.classList.remove("close")}
    >
      <span className="lang-item main select">
        <FlagImg flagCode={languages.find((el) => el.code == selectLang)?.flag} />

        <span className="text">
          {languages.find((el) => el.code == selectLang)?.title || ""}
          <IconArrow className="chose-lang__arrow" />
        </span>
      </span>
      <ul ref={chooseLangRef} className={classNameUl}>
        {languages.map((el, indx) => (
          <li
            key={indx}
            onClick={() => {
              changeLang(el.code);
              chooseLangRef.current.classList.add("close");
            }}
            className={`lang-item ${getSelect(el)}`}
          >
            <button>
              <FlagImg flagCode={el.flag} />
              <span>{t(el.title)}</span>
            </button>
          </li>
        ))}
      </ul>
      <div className="chose-lang__plug" />
    </button>
  );
}
