import React from "react";

export default function IconPresent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1140_20337)">
        <path
          d="M17.9217 5.81235C18.4716 5.68197 18.9912 5.95488 19.1757 6.29421C19.2555 6.44141 19.3057 6.6601 19.09 6.86632C18.636 7.30188 17.963 7.44151 17.376 7.22262L15.6953 6.59593C15.6982 6.82964 15.6594 7.06767 15.5729 7.29957C15.4959 7.50611 15.3869 7.69172 15.2545 7.85359L19.2071 9.32745C19.6573 8.92502 19.8907 8.35733 19.8523 7.74303L19.7738 6.48234C19.7642 6.33616 19.7214 6.18737 19.6422 6.04153C19.3655 5.53387 18.6265 5.10072 17.7993 5.29655L15.5311 5.83492C15.6019 5.99716 15.6513 6.16865 15.6764 6.34496L17.9217 5.81235Z"
          fill="white"
        />
        <path
          d="M11.7881 6.5608C11.7941 6.35176 11.8332 6.14011 11.9102 5.93357C11.9967 5.70167 12.1232 5.49632 12.2784 5.32156L10.5987 4.69524C10.0117 4.47635 9.59456 3.92983 9.5353 3.30289C9.50714 3.00611 9.68824 2.87365 9.84515 2.81509C10.0397 2.74207 10.2948 2.74715 10.5406 2.83878C10.7517 2.91752 10.9556 3.05996 11.1081 3.27179L12.4563 5.14438C12.5908 5.02719 12.7407 4.93001 12.9 4.85397L11.5381 2.96198C11.0411 2.27237 10.1989 2.11592 9.65836 2.31883C9.48521 2.38371 9.34331 2.4805 9.23581 2.60176L8.38529 3.46689C7.95369 3.90591 7.75845 4.48785 7.8356 5.08693L11.7881 6.5608Z"
          fill="white"
        />
        <path
          d="M12.3249 6.76062L14.718 7.653C14.8728 7.50771 14.9976 7.32577 15.0766 7.11397C15.3509 6.3784 14.9749 5.55533 14.2394 5.28104C13.5038 5.00676 12.6807 5.38272 12.4065 6.11829C12.3277 6.33055 12.3031 6.5495 12.3249 6.76062Z"
          fill="white"
        />
        <path
          d="M5.04788 5.80269L4.43924 7.4349C4.25634 7.92538 4.4425 8.46456 4.85709 8.74635L11.0397 11.0518L12.4163 7.36014L6.48205 5.14731C5.90624 4.9326 5.26271 5.22656 5.04788 5.80269Z"
          fill="white"
        />
        <path
          d="M1.89758 16.6822C1.61249 17.4467 2.0025 18.3005 2.76705 18.5856L7.56321 20.3741L10.8545 11.5476L4.67188 9.24219L1.89758 16.6822Z"
          fill="white"
        />
        <path
          d="M20.1914 10.2595L14.2578 8.04688L12.8814 11.7382L19.063 14.0433C19.5612 14.1018 20.0546 13.8161 20.2375 13.3256L20.8461 11.6934C21.0612 11.1177 20.7672 10.4742 20.1914 10.2595Z"
          fill="white"
        />
        <path
          d="M9.40402 21.0608L14.1992 22.8489C14.9637 23.134 15.8176 22.744 16.1027 21.9794L18.8769 14.5394L12.6953 12.2344L9.40402 21.0608Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1140_20337">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(6.33984 0.707031) rotate(20.45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
