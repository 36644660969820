import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import CloseModal from "../mui/CloseModal";
import IconDepositCheck from "../../images/jsIcons/IconDepositCheck";
import ModalHeader from "../mui/ModalHeader";
import CusButton from "../mui/CusButton";
import { API_PAYMENT } from "../../api/payment";
import LoaderContainer from "../mui/LoaderContainer";
import { useTranslation } from "react-i18next";
import { TEST_MODE } from "./consts";
// import balanceCoin from "../../images/mui/tokens.svg";
import LifetimePremium from "../mui/LifetimePremium";
import { useSelector } from "react-redux";
import { testProviders } from "../../testConst";

export default function DepositModal({ isOpen, closeModal }) {
  const { premium } = useSelector((state) => state.user.user);

  const isPremium = useMemo(() => !premium, [premium]);
  const premiumClass = isPremium ? "premium-item" : "";

  const [providers, setProviders] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectProviders, setSelectProviders] = useState(providers?.[0]?.id);
  const { t } = useTranslation();

  const packageTokens = useMemo(
    () => providers.find((el) => el.id == selectProviders)?.packages.filter((el) => el.enabled),
    [providers, selectProviders]
  );

  const [selectTokens, setSelectTokens] = useState(packageTokens?.[0]?.id);

  const onRefillClick = () => {
    API_PAYMENT.buy(selectTokens)
      .then((e) => {
        if (e.data.url) window.location.href = e.data.url;
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    setLoader(true);

    if (TEST_MODE) {
      const providers = testProviders.filter((el) => el.enabled);

      setProviders(providers);
      setSelectProviders(providers?.[0]?.id);
    }

    API_PAYMENT.providers()
      .then((e) => {
        const providers = e.data.providers.filter((el) => el.enabled);

        setProviders(providers);
        setSelectProviders(providers?.[0]?.id);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    setSelectTokens(packageTokens?.[0]?.id);
  }, [selectProviders, packageTokens]);

  return (
    <Modal
      ariaHideApp={false}
      className={"deposit-modal"}
      isOpen={isOpen}
      onRequestClose={(e) => {
        if (e.key) closeModal();
      }}
    >
      <ModalHeader icon={<IconDepositCheck />} title={t("Make a deposit")} />
      <CloseModal close={closeModal} />
      <LoaderContainer loader={loader}>
        {isPremium && (
          <div className="premium">
            <div className="premium__info">
              <h3>
                {t("A deposit of $20.00 gives you")} <LifetimePremium /> <br />
                {/* <span className="bonus-text">
                  {t("status and get bonus ")}
                  <img src={balanceCoin} />
                </span> */}
              </h3>
            </div>
            <div className="premium__banner"></div>
          </div>
        )}

        {providers.length ? (
          <div className="deposit__content">
            <div className="deposit__providers">
              {/* deposit__providers__payments */}
              <div className="deposit__providers__payments">
                <h3 className="deposit__providers__payments__title"> {t("Payment method")}</h3>
                <div className="deposit__providers__payments__content">
                  {providers.map((provider) => (
                    <div
                      onClick={() => {
                        console.log("provider", provider);
                        setSelectProviders(provider.id);
                      }}
                      key={provider.id}
                      className="deposit__providers__card"
                    >
                      <input
                        id={provider.id}
                        checked={provider.id === selectProviders}
                        type="radio"
                        name={"Payment"}
                        // onClick={() => setSelectProviders(provider.id)}
                        onChange={(e) => {
                          if (e.currentTarget.checked) setSelectProviders(provider.id);
                        }}
                      />

                      {provider?.img_tag ? (
                        <span
                          className="deposit__providers__card__img"
                          dangerouslySetInnerHTML={{ __html: provider?.img_tag }}
                        ></span>
                      ) : (
                        <span className="deposit__providers__card__name">{provider.name}</span>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* deposit__providers__token */}
              <div className="deposit__providers__token">
                <h3 className="deposit__providers__token__title">{t("Package of tokens")}</h3>
                <div className="deposit__providers___token__content">
                  {packageTokens.map((token) => (
                    <div
                      key={token.id}
                      className={`deposit__token__card ${premiumClass}`}
                      onClick={() => {
                        setSelectTokens(token.id);
                      }}
                    >
                      <input
                        id={token.id}
                        type="radio"
                        name={"token"}
                        checked={selectTokens === token.id}
                        onChange={(e) => {
                          if (e.currentTarget.checked) setSelectTokens(token.id);
                        }}
                      />
                      {isPremium && <span className="premium-status">{t("Premium Status +")}</span>}
                      <span className="deposit__token__card__value">
                        {token.credits} tokens for {token.cost} {token.currency}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="deposit__info">
              <h3 className="deposit__info__title">
                {t(
                  "Please select the payment system and the packege to purchase, then click the Refill button"
                )}
              </h3>
              <p className="deposit__info__p"> {t("Funds will be credited almost instantly")}</p>
            </div>

            <CusButton onClick={onRefillClick} text={"Refill"} color="red" fullWidth={true} />
          </div>
        ) : (
          <h2 className="deposit__info__plug">
            {t("It is temporarily impossible to top up your balance")}
          </h2>
        )}
      </LoaderContainer>
    </Modal>
  );
}
