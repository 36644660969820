import React from "react";
import { useSelector } from "react-redux";
import { ENUM_STATUS_WINDOW } from "../../../../store/chatSlice";

import FulScreen from "./components/FulScreen";
import Camera from "./components/Camera";
import Live from "./components/Live";
import useDeviceSize from "../../../../hooks/useDeviceSize";
import SendTips from "./components/SendTips";
import PoliticMute from "./components/PoliticMute";

export default function ControlsTop({
  chatWindowRef,
  videoRef,
  politicMute,
  onUnMute,
  setOpenCamera,
  realVideoRef,
}) {
  const status = useSelector((state) => state.chat.statusWindow);
  const available = status === ENUM_STATUS_WINDOW.stream;
  const { isMobile } = useDeviceSize();

  return (
    <>
      <div className="chat__window__controls__top">
        <div className="chat__window__controls__top__left">
          {available && <Live />} {isMobile && <SendTips />}
        </div>
        <div className="chat__window__controls__top__right">
          {available && (
            <>
              <Camera setOpenCamera={setOpenCamera} />
              <FulScreen
                chatWindowRef={chatWindowRef}
                videoRef={videoRef}
                realVideoRef={realVideoRef}
              />
            </>
          )}{" "}
          {isMobile && politicMute && <PoliticMute onUnMute={onUnMute} />}
        </div>
      </div>
    </>
  );
}
