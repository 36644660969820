import React, { useEffect, useRef, useState } from "react";
import HomeContentHeader from "./HomeContentHeader";
import ModelCard from "./ModelCard";
import Pagination from "../mui/Pagination";
import Spinner from "../mui/Spinner";
import { useTranslation } from "react-i18next";

import useGetBroadcasterList from "../../hooks/useGetBroadcasterList";
import useGridDisplay from "../../hooks/useGridDisplay";
import ShowContainer from "../mui/ShowContainer";

export const DELAY_GET_BROADCAST = 10 * 1000;
// const COUNT_PAGE_HOME = 60;
const COUNT_PAGE_HOME = 28;

///HomeContent - основной контент домашний страницы, модели и пагинация
export default function HomeContent({ collapsed, className }) {
  // [x] была б хорошая идея если не перезагрузка фото
  // const broadcasters = useSelector((state) => state.common.broadcasters);

  const homeModelsRef = useRef();
  const scrollPositionRef = useRef();

  const [modelsClass, setModelsClass] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [models, setModels] = useState([]);
  const [loadingModels, setLoadingModels] = useState(true);
  const [isUpdate, setIsUpdate] = useState(1);
  const { t } = useTranslation();

  const handleModelUpdate = (newModels) => {
    scrollPositionRef.current = window.scrollY;
    setModels(newModels);
    setLoadingModels(false);
  };

  // CREATE GRID MODELS
  useGridDisplay({ homeModelsRef, collapsed, setModelsClass, models });

  // BROADCASTER LIST
  useGetBroadcasterList({
    currentPage,
    COUNT_PAGE_HOME,
    DELAY_GET_BROADCAST,
    setLoadingModels,
    handleModelUpdate,
    setTotal,
    setIsUpdate,
  });

  useEffect(() => {
    if (!loadingModels) {
      window.scrollTo(0, scrollPositionRef.current);
    }
  }, [loadingModels]);

  return (
    <div className={`home__content__container ${className} `}>
      <HomeContentHeader />

      {models?.length ? (
        <div className="home__models__containers">
          <div className={`home__models ${modelsClass}`} ref={homeModelsRef}>
            {models.map((el, indx) => (
              <ModelCard card={el} key={indx} isUpdate={isUpdate} />
            ))}
          </div>
        </div>
      ) : (
        <div className="spinner-container">
          {loadingModels ? <Spinner /> : <h3> {t("No models")}</h3>}
        </div>
      )}

      <ShowContainer condition={total / COUNT_PAGE_HOME > 1}>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          MAX_PAGE={Math.ceil(total / COUNT_PAGE_HOME)}
        />
      </ShowContainer>
    </div>
  );
}
