import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/home/Header";
import HomeUnderHeader from "../components/home/HomeUnderHeader";
import HomeFilters from "../components/home/HomeFilters";
import HomeContent from "../components/home/HomeContent";
import Footer from "../components/home/Footer";
import ConfirmAge from "../components/common/ConfirmAge";
import { useLocalStorage } from "usehooks-ts";
import { useSearchParams } from "react-router-dom";
import { errorToast, successToast } from "../components/mui/Toaster";
import { deleteParamsUrl, getCHPYPapamByName } from "../utils/utils";
import useDeviceSize from "../hooks/useDeviceSize";
import { useDispatch, useSelector } from "react-redux";
import { IS_SKIP_INTO } from "../components/common/consts";
import { ENUM_STATUS_WINDOW, setBroadcaster, setStatusWindow } from "../store/chatSlice";
import useLinkParse from "../hooks/useLinkParse";
import ErrorBoundary from "../components/mui/ErrorBoundary";

export default function Home() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [confirmAge, setConfirm] = useLocalStorage("confirmAge", IS_SKIP_INTO);

  const filtersCategory = useSelector((state) => state.common.filter);

  const [collapsed, setCollapsed] = useState(true);
  const [openConfirm, setOpenConfirm] = useState("loading");
  const [currentCategory, setCurrentCategory] = useState();
  const [query] = useSearchParams();
  const errorEmail = query.get("errorEmail");
  const confirmEmail = query.get("confirmEmail");

  useLinkParse({ setOpenConfirm });
  const { isMobile } = useDeviceSize();

  useEffect(() => {
    dispatch(setBroadcaster({}));
    console.log("status loading");
    dispatch(setStatusWindow(ENUM_STATUS_WINDOW.loading));
  }, []);

  useEffect(() => {
    setCollapsed(!filtersCategory.length);
  }, [filtersCategory]);

  useEffect(() => {
    setOpenConfirm(confirmAge);
  }, [confirmAge]);

  useEffect(() => {
    if (confirmEmail && confirmEmail !== "undefined") {
      successToast(confirmEmail);
      deleteParamsUrl("confirmEmail");
      return;
    }

    if (errorEmail && errorEmail !== "undefined") {
      errorToast(errorEmail, { autoClose: false });
      deleteParamsUrl("errorEmail");
      return;
    }
  }, [confirmEmail, errorEmail]);

  useEffect(() => {
    const campaignId = getCHPYPapamByName("campaign_id");
    if (!campaignId) return;

    const campaignOptions = {};
    query?.forEach((el, key) => {
      campaignOptions[key] = el;
    });

    if (!Object.values(campaignOptions).length) return;
    localStorage.setItem("campaignOptions", JSON.stringify(campaignOptions));
  }, [query]);

  function ErrorFallback() {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      {openConfirm && openConfirm !== "loading" && (
        <main className={`home ${t("currentLang")}`}>
          <ErrorBoundary fallbackComponent={ErrorFallback}>
            <Header currentCategory={currentCategory} />
          </ErrorBoundary>
          <ErrorBoundary>
            <HomeUnderHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          </ErrorBoundary>
          <div className="home__content">
            <ErrorBoundary>{!isMobile && <HomeFilters collapsed={collapsed} />}</ErrorBoundary>
            <ErrorBoundary>
              <HomeContent collapsed={collapsed} className={collapsed ? "collapsed" : ""} />
            </ErrorBoundary>
          </div>
          <ErrorBoundary>
            <Footer />
          </ErrorBoundary>
        </main>
      )}

      <ConfirmAge
        setCurrentCategory={setCurrentCategory}
        isOpen={!openConfirm}
        closeModal={() => {
          setConfirm(true);
          setOpenConfirm(true);
        }}
      />
    </>
  );
}
