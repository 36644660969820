import React from "react";

export default function IconConnectionLost() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="153"
      height="152"
      viewBox="0 0 153 152"
      fill="none"
    >
      <g clipPath="url(#clip0_740_11177)">
        <path
          d="M150.245 35.507C130.533 15.7945 104.408 4.98828 76.5016 4.98828C48.5953 4.98828 22.4703 15.7945 2.75781 35.507C-0.210938 38.5945 -0.210938 43.582 2.75781 46.5508C5.84531 49.5195 10.8328 49.5195 13.8016 46.5508C30.4266 29.807 52.7516 20.6633 76.3828 20.6633C100.014 20.6633 122.339 29.807 138.964 46.5508C140.508 48.0945 142.408 48.807 144.545 48.807C146.683 48.807 148.583 47.9758 150.127 46.5508C153.214 43.4633 153.214 38.4758 150.245 35.507Z"
          fill="#CA0C0D"
        />
        <path
          d="M150.245 35.507C130.533 15.7945 104.408 4.98828 76.5016 4.98828C48.5953 4.98828 22.4703 15.7945 2.75781 35.507C-0.210938 38.5945 -0.210938 43.582 2.75781 46.5508C5.84531 49.5195 10.8328 49.5195 13.8016 46.5508C30.4266 29.807 52.7516 20.6633 76.3828 20.6633C100.014 20.6633 122.339 29.807 138.964 46.5508C140.508 48.0945 142.408 48.807 144.545 48.807C146.683 48.807 148.583 47.9758 150.127 46.5508C153.214 43.4633 153.214 38.4758 150.245 35.507Z"
          fill="url(#paint0_linear_740_11177)"
        />
        <path
          d="M24.8438 57.5938C21.875 60.6813 21.875 65.6688 24.8438 68.6375C27.9313 71.6063 32.9187 71.6063 35.8875 68.6375C58.3312 46.3125 94.55 46.3125 116.875 68.6375C118.419 70.1813 120.319 70.8938 122.456 70.8938C124.475 70.8938 126.613 70.1813 128.037 68.6375C131.006 65.55 131.006 60.5625 128.037 57.5938C99.5375 29.0937 53.3438 29.0937 24.8438 57.5938Z"
          fill="#CA0C0D"
        />
        <path
          d="M24.8438 57.5938C21.875 60.6813 21.875 65.6688 24.8438 68.6375C27.9313 71.6063 32.9187 71.6063 35.8875 68.6375C58.3312 46.3125 94.55 46.3125 116.875 68.6375C118.419 70.1813 120.319 70.8938 122.456 70.8938C124.475 70.8938 126.613 70.1813 128.037 68.6375C131.006 65.55 131.006 60.5625 128.037 57.5938C99.5375 29.0937 53.3438 29.0937 24.8438 57.5938Z"
          fill="url(#paint1_linear_740_11177)"
        />
        <path
          d="M76.5008 67.5703C65.457 67.5703 54.8883 71.9641 47.0508 79.8016C44.082 82.8891 44.082 87.8766 47.0508 90.8453C50.1383 93.8141 55.1258 93.8141 58.0945 90.8453C63.082 85.8578 69.4945 83.2453 76.382 83.2453C83.2695 83.2453 89.9195 85.9766 94.6695 90.8453C96.2133 92.3891 98.1133 93.1016 100.251 93.1016C102.151 93.1016 104.288 92.3891 105.832 90.8453C108.801 87.7578 108.801 82.7703 105.832 79.8016C98.1133 71.9641 87.5445 67.5703 76.5008 67.5703Z"
          fill="#CA0C0D"
        />
        <path
          d="M76.5008 67.5703C65.457 67.5703 54.8883 71.9641 47.0508 79.8016C44.082 82.8891 44.082 87.8766 47.0508 90.8453C50.1383 93.8141 55.1258 93.8141 58.0945 90.8453C63.082 85.8578 69.4945 83.2453 76.382 83.2453C83.2695 83.2453 89.9195 85.9766 94.6695 90.8453C96.2133 92.3891 98.1133 93.1016 100.251 93.1016C102.151 93.1016 104.288 92.3891 105.832 90.8453C108.801 87.7578 108.801 82.7703 105.832 79.8016C98.1133 71.9641 87.5445 67.5703 76.5008 67.5703Z"
          fill="url(#paint2_linear_740_11177)"
        />
        <path
          d="M83.7454 97.2578C80.4204 91.9141 72.5829 91.9141 69.3766 97.2578L46.3391 133.952C42.7766 139.77 46.8141 147.014 53.5829 147.014H99.6579C106.308 147.014 110.345 139.533 106.902 133.952L83.7454 97.2578ZM76.5016 141.433C75.0766 141.433 73.7704 140.245 73.7704 138.702C73.7704 137.158 74.9579 135.97 76.5016 135.97C77.9266 135.97 79.2329 137.158 79.2329 138.702C79.2329 140.127 78.0454 141.433 76.5016 141.433ZM78.6391 129.558C78.6391 130.745 77.6891 131.577 76.6204 131.577C75.4329 131.577 74.4829 130.627 74.4829 129.558L73.2954 106.52V106.402C73.2954 104.62 74.7204 103.314 76.6204 103.077C78.4016 103.077 79.9454 104.62 79.7079 106.52L78.6391 129.558Z"
          fill="#CA0C0D"
        />
        <path
          d="M83.7454 97.2578C80.4204 91.9141 72.5829 91.9141 69.3766 97.2578L46.3391 133.952C42.7766 139.77 46.8141 147.014 53.5829 147.014H99.6579C106.308 147.014 110.345 139.533 106.902 133.952L83.7454 97.2578ZM76.5016 141.433C75.0766 141.433 73.7704 140.245 73.7704 138.702C73.7704 137.158 74.9579 135.97 76.5016 135.97C77.9266 135.97 79.2329 137.158 79.2329 138.702C79.2329 140.127 78.0454 141.433 76.5016 141.433ZM78.6391 129.558C78.6391 130.745 77.6891 131.577 76.6204 131.577C75.4329 131.577 74.4829 130.627 74.4829 129.558L73.2954 106.52V106.402C73.2954 104.62 74.7204 103.314 76.6204 103.077C78.4016 103.077 79.9454 104.62 79.7079 106.52L78.6391 129.558Z"
          fill="url(#paint3_linear_740_11177)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_740_11177"
          x1="76.4942"
          y1="4.98828"
          x2="76.4942"
          y2="52.4586"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_740_11177"
          x1="76.4406"
          y1="36.2188"
          x2="76.4406"
          y2="73.7833"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_740_11177"
          x1="76.4414"
          y1="67.5703"
          x2="76.4414"
          y2="95.2292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_740_11177"
          x1="76.5867"
          y1="93.25"
          x2="76.5867"
          y2="151.494"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_740_11177">
          <rect width="152" height="152" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
