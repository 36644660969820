import React from "react";

export default function Icon2circle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
      <path
        d="M17.417 7.83398C18.9358 7.83398 20.167 6.60277 20.167 5.08398C20.167 3.5652 18.9358 2.33398 17.417 2.33398C15.8982 2.33398 14.667 3.5652 14.667 5.08398C14.667 6.60277 15.8982 7.83398 17.417 7.83398Z"
        fill="white"
      />
      <path
        d="M16.3075 9.06132C14.9233 8.69466 13.8049 7.57632 13.4383 6.19216C13.1999 5.31216 13.2549 4.46882 13.5208 3.71716C13.7041 3.20382 13.3924 2.63549 12.8608 2.52549C11.5133 2.24132 10.0558 2.25049 8.53412 2.66299C5.06912 3.63466 2.41995 6.63216 1.92495 10.198C1.08162 16.2663 6.22412 21.4088 12.2924 20.5747C15.8674 20.0797 18.8558 17.4305 19.8183 13.9563C20.24 12.4438 20.2491 10.9863 19.965 9.62966C19.855 9.09799 19.2866 8.79549 18.7733 8.96966C18.0308 9.23549 17.1875 9.29049 16.3075 9.06132Z"
        fill="white"
      />
    </svg>
  );
}
