import React from "react";
import IconCameraOff from "../../../images/jsIcons/IconCameraOff";
import { useTranslation } from "react-i18next";

export default function CameraOff() {
  const { t } = useTranslation();
  return (
    <div className="chat__model__camera-off">
      <IconCameraOff />
      <h3>{t("Camera is turned off")}</h3>
    </div>
  );
}
