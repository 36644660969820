import React from "react";

export default function IconCamera() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
      <path
        d="M44.0217 11.9555H38.8043L35.9239 7.60772C34.837 5.97728 32.9348 4.99902 30.9783 4.99902H19.0217C17.0652 4.99902 15.163 5.97728 14.0761 7.60772L11.1957 11.9555H5.97826C2.66304 11.9555 0 14.6186 0 17.9338V39.0208C0 42.336 2.66304 44.999 5.97826 44.999H44.0217C47.337 44.999 50 42.336 50 39.0208V17.9338C50 14.6186 47.337 11.9555 44.0217 11.9555ZM25 40.1077C17.5543 40.1077 11.5217 34.0751 11.5217 26.6295C11.5217 19.1838 17.5543 13.2055 25 13.2055C32.4457 13.2055 38.4783 19.2382 38.4783 26.6838C38.4783 34.0751 32.4457 40.1077 25 40.1077ZM43.2609 20.3795C43.2065 20.3795 43.1522 20.3795 43.0435 20.3795H40.8696C39.8913 20.3251 39.1304 19.5099 39.1848 18.5316C39.2391 17.6077 39.9456 16.9012 40.8696 16.8468H43.0435C44.0217 16.7925 44.837 17.5534 44.8913 18.5316C44.9456 19.5099 44.2391 20.3251 43.2609 20.3795Z"
        fill="#CAADAD"
      />
      <path
        d="M25 19.1836C20.8696 19.1836 17.5 22.5532 17.5 26.6836C17.5 30.814 20.8696 34.1292 25 34.1292C29.1304 34.1292 32.5 30.7597 32.5 26.6292C32.5 22.4988 29.1304 19.1836 25 19.1836Z"
        fill="#CAADAD"
      />
    </svg>
  );
}
