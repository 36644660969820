import React, { useCallback, useEffect } from "react";
import { useEventListener } from "usehooks-ts";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { useSelector } from "react-redux";

// если разница между значениями когда скрывать/показывать небольшая то нечего не делаем
const DELAY_PX = 10;

export default function ChatBottomFixed({ ChatSendRef, className, ChatSendSendRef, children }) {
  const { isMobile } = useDeviceSize();
  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);

  const scrollTimeout = useCallback(() => {
    const bottomSection = document.getElementsByName("section-bottom")?.[0];
    const stopFixed = bottomSection.offsetTop;
    const sendTop = window.innerHeight + window.scrollY + DELAY_PX;

    const isFixed = sendTop - stopFixed;

    // игнорируем барьер около нескольних px
    if (Math.abs(isFixed) < DELAY_PX) return;

    if (isFixed < 0) {
      ChatSendRef.current?.classList?.add?.("fixed");
    } else {
      ChatSendRef.current?.classList?.remove?.("fixed");
    }
  }, [ChatSendSendRef, ChatSendRef]);

  const ChatSendZeroFixed = () => {
    // if (ChatSendSendRef.current) ChatSendSendRef.current.style.bottom = "0px";
  };

  useEventListener("scroll", scrollTimeout);
  useEventListener("drugSize", scrollTimeout);
  useEventListener("resize", scrollTimeout);

  useEffect(() => {
    if (!isMobile) return;
    ChatSendZeroFixed();
    scrollTimeout();
  }, [isFullScreen, isMobile]);

  useEffect(() => {
    setTimeout(scrollTimeout, 40);
    setTimeout(scrollTimeout, 120);
    setTimeout(scrollTimeout, 500);
  }, []);

  const onSendClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    scrollTimeout();
  };
  return (
    <div className={className} onClick={onSendClick} ref={ChatSendSendRef}>
      {children}
    </div>
  );
}
