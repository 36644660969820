import React from "react";
import { SITE_NAME } from "../common/consts";

export default function ModalHeader({ icon, title }) {
  return (
    <div className="modal__header">
      {icon}
      <h2 className="modal__chapter">{title}</h2>
      <span className="modal__header__or"></span>
      <h2>{SITE_NAME}</h2>
    </div>
  );
}
