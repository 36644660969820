import React from "react";

export default function IconKing() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        d="M15.5834 20.167H6.41675C6.04091 20.167 5.72925 19.8553 5.72925 19.4795C5.72925 19.1037 6.04091 18.792 6.41675 18.792H15.5834C15.9592 18.792 16.2709 19.1037 16.2709 19.4795C16.2709 19.8553 15.9592 20.167 15.5834 20.167Z"
        fill="#FFEF5F"
      />
      <path
        d="M18.6541 5.06012L14.9875 7.68178C14.5016 8.03012 13.805 7.81928 13.5941 7.26012L11.8616 2.64012C11.5683 1.84262 10.4408 1.84262 10.1475 2.64012L8.40579 7.25095C8.19496 7.81928 7.50746 8.03012 7.02162 7.67262L3.35496 5.05095C2.62162 4.53762 1.64996 5.26178 1.95246 6.11428L5.76579 16.7934C5.89412 17.1601 6.24246 17.3984 6.62746 17.3984H15.3633C15.7483 17.3984 16.0966 17.1509 16.225 16.7934L20.0383 6.11428C20.35 5.26178 19.3783 4.53762 18.6541 5.06012ZM13.2916 13.521H8.70829C8.33246 13.521 8.02079 13.2093 8.02079 12.8335C8.02079 12.4576 8.33246 12.146 8.70829 12.146H13.2916C13.6675 12.146 13.9791 12.4576 13.9791 12.8335C13.9791 13.2093 13.6675 13.521 13.2916 13.521Z"
        fill="#FFEF5F"
      />
    </svg>
  );
}
