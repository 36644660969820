import React, { useEffect, useMemo, useState } from "react";

import IconEye from "../../images/jsIcons/IconEye";
import IconLike from "../../images/jsIcons/IconLike";
// import ImgLangFlag from "../mui/ImgLangFlag";

import noImg from "../../images/template/no-img.svg";
import FlagImg from "../mui/FlagImg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ENUM_CHAT_MODE } from "../../store/chatSlice";
import IconSpy from "../../images/jsIcons/IconPrivate";
import IconPaid from "../../images/jsIcons/IconGroup";

import { useNavigate } from "react-router-dom";
import ImageUpdate from "../mui/ImageUpdate";
import ShowContainer from "../mui/ShowContainer";
import { DELAY_GET_BROADCAST } from "./HomeContent";
import useLikeController from "../../hooks/useLikeController";

// ModelCard - карточка модели
export default function ModelCard({ card, style, isUpdate }) {
  //card
  // {
  //   id: Ид карточки,
  //   imgSource: Картинка,
  //   categories: Массив категорий,
  //   isNew: Флаг отображать ли new  в углу ,
  //   name: Имя модели,
  //   lang: Язык модели,
  //   view: Количество просмотров,
  // },

  const { preview, isNew, name, lang, view, chat } = card;
  const { onLikeClick, like } = useLikeController(card.id);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [errorImg, setErrorImg] = useState(false);

  const mode = useMemo(() => chat?.mode, [chat]);
  const imgSource = useMemo(() => chat?.preview || preview, [chat, preview]);

  useEffect(() => {
    if (imgSource) setErrorImg(false);
  }, [imgSource]);

  const isPlug = [ENUM_CHAT_MODE.view, ENUM_CHAT_MODE.paid, ENUM_CHAT_MODE.private].includes(mode);

  const displayToType = {
    [isNew]: t("New"),
    [ENUM_CHAT_MODE.view]: <IconSpy />,
    [ENUM_CHAT_MODE.private]: <IconSpy />,
    [ENUM_CHAT_MODE.paid]: <IconPaid />,
  };

  const onPlugClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/chat/${name}?mode=${mode}`);
  };

  return (
    <>
      <div className={"home__models__card"} style={style}>
        <Link to={`/chat/${name}`}>
          <div className="home__models__card__img">
            <ImageUpdate
              isUpdate={isUpdate}
              path={chat?.preview}
              imgSource={errorImg || imgSource}
              setErrorImg={setErrorImg}
              noImg={noImg}
              time={DELAY_GET_BROADCAST * 1000}
            />

            <img className="blur-img" src={preview} alt="картинка" />

            <ShowContainer condition={isPlug}>
              <div onClick={onPlugClick} className="home__models__card__new">
                {displayToType[mode]}
              </div>
            </ShowContainer>

            {!isPlug && isNew && <div className="home__models__card__new">{t("New")}</div>}
          </div>
        </Link>
        <div className="home__models__card__description">
          <div className="home__models__card__description__left">
            {lang && <FlagImg flagCode={lang} />}

            <span className="home__models__card__name">{name}</span>
          </div>
          <div className="home__models__card__description__right">
            <ShowContainer condition={!!card.online}>
              <span className="home__models__card__view">
                <IconEye />
                <span className="home__models__card__view__text">{view}</span>
              </span>
            </ShowContainer>
            <button
              className={`home__models__card__like ${like ? "like" : ""}`}
              onClick={(e) => {
                e.stopPropagation();
                onLikeClick(card.id);
              }}
            >
              <IconLike />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
