import React from "react";
import { ENUM_ACCESS } from "../../../store/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import IconCrown from "../../../images/jsIcons/IconCrown";
import IconLock from "../../../images/jsIcons/IconLock";
import { useTranslation } from "react-i18next";
import { ENUM_MODALS_VALUE, setModals } from "../../../store/commonSlice";
import usePremiumClick from "../../../hooks/usePremiumClick";

export default function AccessPhoto({ source, getFullPhoto }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onPremiumClick } = usePremiumClick();

  const userId = useSelector((state) => state.user.user.id);
  const premium = useSelector((state) => state.user.user.premium);

  return (
    <div key={source.id} className="chat__photo__content__items">
      {source.access === ENUM_ACCESS.regular && !userId ? (
        <div
          className="only-regular"
          onClick={() => {
            dispatch(setModals({ name: ENUM_MODALS_VALUE.typeLogin, value: true }));
          }}
        >
          <IconLock />
          <h4>{t("Login to view")}</h4>
        </div>
      ) : source.access === ENUM_ACCESS.premium && !premium ? (
        <div
          className="only-premium"
          onClick={() => {
            onPremiumClick();
          }}
        >
          <IconCrown />
          <h4>{t("Only for Premium users")}</h4>
        </div>
      ) : (
        <>
          <img className="chat__photo__content__items__bg" src={source.img} />

          <a data-fancybox="gallery" href={getFullPhoto(source.id)}>
            <img src={source.img} />
          </a>
        </>
      )}
    </div>
  );
}
