import React, { useState } from "react";

import BroadcasterWindow from "./BroadcasterWindow";
import BroadcasterChat from "./BroadcasterChat";
import ChatHeader from "./ChatHeader";
import { useSelector } from "react-redux";
import { ENUM_STATUS_WINDOW } from "../../store/chatSlice";
import Spinner from "../mui/Spinner";
import { getMaxHeight } from "../../utils/utils";
import useDeviceSize from "../../hooks/useDeviceSize";
import ShowContainer from "../mui/ShowContainer";
import ErrorBoundary from "../mui/ErrorBoundary";

export default function ChatTabStream() {
  const isFullscreenChat = useSelector((state) => state.common.controls.isFullscreenChat);
  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);
  const status = useSelector((state) => state.chat.statusWindow);
  const loadingBroadcaster = useSelector((state) => state.chat.loadingBroadcaster);
  const [maxHeight, setMaxHeight] = useState(undefined);
  const { isMobile, width } = useDeviceSize();

  const isLoading = loadingBroadcaster || status === ENUM_STATUS_WINDOW.loading;
  const noChatClass = isFullscreenChat ? "" : "no-chat";
  const fulScreenClass = isFullScreen ? "ful-screen" : "";
  const isHide = isLoading ? "hide" : "";
  const classContent = noChatClass + " " + fulScreenClass + " " + isHide;
  const maxDesktopHeight = width > 950 ? getMaxHeight(isFullScreen, "80svh") : undefined;

  return (
    <>
      <ChatHeader />

      <ShowContainer condition={isLoading}>
        <div className="chat__content__loader">
          <Spinner />
        </div>
      </ShowContainer>

      <div
        id="chat-content"
        style={{
          maxHeight: isMobile ? undefined : maxDesktopHeight,
        }}
        className={`chat__content ${classContent}`}
      >
        <ErrorBoundary>
          <BroadcasterWindow setMaxHeight={setMaxHeight} />
        </ErrorBoundary>
        <ErrorBoundary>
          <BroadcasterChat maxHeight={maxHeight} />
        </ErrorBoundary>
      </div>
    </>
  );
}
