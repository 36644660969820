import { useEffect, useRef } from "react";
import { API_INFO } from "../api/info";
import { setFlags, setLanguages } from "../store/commonSlice";
import { useLocalStorage, useEventListener } from "usehooks-ts";
import { useDispatch, useSelector } from "react-redux";
import { CAMPAIGN_ID, TEST_MODE } from "../components/common/consts";
import { getCHPYPapamByName, getFsStatus, getLang } from "../utils/utils";
import i18n from "../i18n";
import { ENUM_STATUS_WINDOW, setStatusWindow } from "../store/chatSlice";
import useListenerJanusMode from "./useListenerJanusMode";

export default function useCommonHooks() {
  // DOUBTFUL, but ok
  // hook hiding flickering caused by the server
  useListenerJanusMode();

  const chosenLang = getLang();
  const [, setSelectLang] = useLocalStorage("lang", chosenLang);
  const languages = useSelector((state) => state.common.languages);
  const statusWindow = useSelector((state) => state.chat.statusWindow);
  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);

  const oldStateRef = useRef();

  const dispatch = useDispatch();

  useEventListener("offline", () => {
    console.log("status offline");
    dispatch(setStatusWindow(ENUM_STATUS_WINDOW.offline));
  });

  useEventListener("online", () => {
    if (!oldStateRef.current) return;
    console.log("status", oldStateRef.current);
    dispatch(setStatusWindow(oldStateRef.current));
  });

  useEventListener("fullscreenchange", () => {
    console.log(getFsStatus());
    const isFullScreen = !!getFsStatus();
    window.isFullScreen = isFullScreen;
    dispatch(setFlags({ name: "foolScreen", value: isFullScreen }));
  });

  useEventListener("fullscreenerror", (e) => console.error("error fs", e));

  useEffect(() => {
    if (statusWindow === ENUM_STATUS_WINDOW.offline) return;
    oldStateRef.current = statusWindow;
  }, [statusWindow]);

  useEffect(() => {
    API_INFO.listLanguage()
      .then((e) => {
        const language = e.data.languages;
        if (!language) return;
        dispatch(setLanguages(Object.values(language)));
      })
      .catch((e) => console.log(e));

    const language = {
      ca: { title: "Català", flag: "es-ct", code: "ca", country: "ES" },
      de: { title: "Deutsch", flag: "de", code: "de", country: "DE" },
      en: { title: "English", flag: "us", code: "en", country: "US" },
      es: { title: "Español", flag: "es", code: "es", country: "ES" },
      it: { title: "Italiano", flag: "it", code: "it", country: "IT" },
      ru: { title: "Русский", flag: "ru", code: "ru", country: "RU" },
    };

    if (TEST_MODE) dispatch(setLanguages(Object.values(language)));
  }, []);

  useEffect(() => {
    const paramLang = getCHPYPapamByName("lang");

    if (paramLang && languages.length) {
      const currentLang = languages.find((el) => {
        return `${el.code}_${el.country}` === paramLang;
      });

      if (currentLang) {
        const code = currentLang.code;
        setSelectLang(code);
        i18n.changeLanguage(code);
      }
    }
  }, [languages]);

  useEffect(() => {
    // const referrerUserId = getCHPYPapamByName("referrer_user_id");
    const referrerUserId = getCHPYPapamByName("r_id");
    const referrerGroupId = getCHPYPapamByName("referrer_group_id");

    // setItem
    if (CAMPAIGN_ID) localStorage.setItem("campaignId", CAMPAIGN_ID);

    // if`s
    if (referrerUserId) {
      document.cookie =
        encodeURIComponent("referrer_user_id") + "=" + encodeURIComponent(referrerUserId) + ";";
    }
    if (referrerGroupId) {
      document.cookie =
        encodeURIComponent("referrer_group_id") + "=" + encodeURIComponent(referrerGroupId) + ";";
    }
  }, []);

  useEffect(() => {
    if (statusWindow === ENUM_STATUS_WINDOW.stream) return;
    if (isFullScreen) document?.exitFullscreen?.();
  }, [statusWindow, isFullScreen]);
}
