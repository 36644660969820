import React, { useState } from "react";
import CusSelect from "../mui/CusSelect";
import { useDispatch, useSelector } from "react-redux";
import IconProfile from "../../images/jsIcons/IconProfile";
import IconStatistic from "../../images/jsIcons/IconStatistic";
import IconSetting from "../../images/jsIcons/IconSetting";
import IconOut from "../../images/jsIcons/IconOut";
import { API_LOGIN } from "../../api/login";
import { setMainUser } from "../../store/userSlice";
import ProfileCommonModal from "./ProfileCommonModal";
import ImgAvatar from "../mui/ImgAvatar";

export default function ProfilePopup() {
  const nickName = useSelector((state) => state.user?.user?.nickname);
  const dispatch = useDispatch();

  const myAvatar = useSelector((state) => state.user?.user?.avatar);
  const id = useSelector((state) => state.user?.user?.id);

  const [isOpen, setOpen] = useState(false);

  const logout = () => {
    API_LOGIN.logout()
      .then(() => dispatch(setMainUser({})))
      .catch((e) => console.log(e));
  };

  const options = [
    {
      label: "My profile",
      value: "profile",
      icon: <IconProfile />,
      onClick: () => setOpen("general"),
    },
    {
      label: "Statistics",
      value: "statistics",
      icon: <IconStatistic />,
      onClick: () => setOpen("statistic"),
    },
    {
      label: "Setting",
      value: "settings",
      icon: <IconSetting />,
      onClick: () => setOpen("settings"),
    },
    { label: "Logout", value: "logout", icon: <IconOut />, onClick: logout },
  ];

  const UserProfileTitle = () => (
    <span className="profile">
      <ImgAvatar avatarProps={myAvatar} id={id} className="profile__btn" />
      <span className="profile__email">{nickName}</span>
    </span>
  );

  return (
    <>
      <CusSelect
        staticHeader={<UserProfileTitle />}
        className="profile-select"
        placeholder={nickName}
        options={options}
      />

      <ProfileCommonModal isOpen={!!isOpen} setOpen={setOpen} type={isOpen} />
    </>
  );
}
