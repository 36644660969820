import React from "react";
import IconVibrator from "../../../../images/jsIcons/IconVibrator";
import CusButton from "../../../mui/CusButton";
import IconArrow from "../../../../images/jsIcons/IconArrow";
import { ENUM_CHAT_TAB, setChatActiveTab } from "../../../../store/chatSlice";
import { useDispatch } from "react-redux";

export default function MesLovense() {
  const dispatch = useDispatch();

  return (
    <div className="chat__free__message__lovense">
      <IconVibrator /> Interactive toy connected
      <CusButton
        text={"Lovense"}
        endIcon={<IconArrow className="rotate270" />}
        onClick={() => dispatch(setChatActiveTab(ENUM_CHAT_TAB.lovense))}
      />
    </div>
  );
}
