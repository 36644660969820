import React from "react";

export default function IconICircle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M12 1.5C5.925 1.5 1 6.425 1 12.5C1 18.575 5.925 23.5 12 23.5C18.075 23.5 23 18.575 23 12.5C23 6.425 18.075 1.5 12 1.5ZM13.5 18C13.5 18.5525 13.0525 19 12.5 19H11.5C10.9475 19 10.5 18.5525 10.5 18V12C10.5 11.4475 10.9475 11 11.5 11H12.5C13.0525 11 13.5 11.4475 13.5 12V18ZM12 9.5C11.0335 9.5 10.25 8.7165 10.25 7.75C10.25 6.7835 11.0335 6 12 6C12.9665 6 13.75 6.7835 13.75 7.75C13.75 8.7165 12.9665 9.5 12 9.5Z"
        fill="url(#paint0_linear_857_15716)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_857_15716"
          x1="12"
          y1="1.5"
          x2="12"
          y2="25.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
