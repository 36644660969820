import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function FreeSec() {
  const countFirstSec = useSelector(
    (state) => state.chat.settingInfo?.["private-chat"]?.["non-billable-time-sec"]
  );
  const isFreeEnabled = useSelector(
    (state) => state.chat.settingInfo?.["private-chat"]?.["non-billable-time-enabled"]
  );
  const { t } = useTranslation();
  return (
    isFreeEnabled && (
      <span className="chat__model__private__free">
        {/* {t(`First ${countFirstSec} sec`)} */}
        {t("First")} {countFirstSec} {t("sec")}
        <span className="chat__model__private__free__accent"> {t("FREE")}</span>
      </span>
    )
  );
}
