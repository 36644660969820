import React from "react";
import imgStatistic from "../../images/user/statistic-up.png";
import { useTranslation } from "react-i18next";

export default function StatisticEmpty() {
  const { t } = useTranslation();
  return (
    <div className="statistic__empty">
      <div className="statistic__empty__img">
        <div className="statistic__empty__circle" />
        <div className="statistic__empty__circle-gradient" />
        <img src={imgStatistic} />
      </div>
      <p className="statistic__empty__p">
        {t("You do not have any transactions yet, to get statistics, top up your balance")}
      </p>
    </div>
  );
}
