import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePaidAction from "./usePaidAction";
import { ENUM_PAID_ACTION } from "./usePremiumClick";
import { ENUM_STATUS_WINDOW, setStatusWindow } from "../store/chatSlice";
import { useSearchParams } from "react-router-dom";
import { deleteParamsUrl } from "../utils/utils";
import JanusState from "../controls/janus/site/JanusState";

export default function useChangeModeByUrl(changeChatMode) {
  const [query] = useSearchParams();
  const mode = query.get("mode");
  const dispatch = useDispatch();
  const status = useSelector((state) => state.chat.statusWindow);
  const { onPaidActionClick } = usePaidAction();

  const [currentMode, setCurrentMode] = useState(mode);
  const timeoutRef = useRef();
  const available = useMemo(() => status === ENUM_STATUS_WINDOW.stream, [status]);

  const changeModeByUrl = () => {
    if (!currentMode) return;

    const changeMode = async () => {
      const result = await changeChatMode(currentMode);
      console.log("change mode", result);
      if (result) {
        console.log("change mode yes", result);
        console.log("status stream");
        dispatch(setStatusWindow(ENUM_STATUS_WINDOW.stream));
        deleteParamsUrl("mode");
        setCurrentMode(undefined);
        return;
      }
      timeoutRef.current = setTimeout(changeModeByUrl, 300);
    };

    const spyOnGroup =
      status === ENUM_STATUS_WINDOW.group || status === ENUM_STATUS_WINDOW.someonePrivate;
    if (mode !== JanusState.modelMode) return;

    if (spyOnGroup) {
      onPaidActionClick(ENUM_PAID_ACTION.price, changeMode);
    }
  };

  useEffect(() => {
    setCurrentMode(mode);
  }, [mode]);

  useEffect(() => {
    if (!available) {
      clearTimeout(timeoutRef.current);
    }
  }, [available]);

  useEffect(() => {
    return clearTimeout(timeoutRef.current);
  }, [timeoutRef]);

  useEffect(() => {
    changeModeByUrl();
  }, [status, currentMode]);
}
