import React, { useEffect, useState } from "react";
import IconFilters from "../../images/jsIcons/IconFilters";
import IconArrowDown from "../../images/jsIcons/IconArrowDown";
import IconCross from "../../images/jsIcons/IconCross";
import useDeviceSize from "../../hooks/useDeviceSize";
import IconFind from "../../images/jsIcons/IconFind";
import CusFind from "../mui/CusFind";
import SortBySelect from "./SortBySelect";
import { useDispatch, useSelector } from "react-redux";
import { setNickName } from "../../store/commonSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CategoriesCarousel from "./CategoriesCarousel";

export default function HomeUnderHeader({ collapsed, setCollapsed }) {
  const { mobileHeader } = useDeviceSize();
  const navigate = useNavigate();

  const nickname = useSelector((state) => state.common.nickname);
  const categories = useSelector((state) => state.siteInfo.commonSiteInfo.options?.shown);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openMobileFind, setOpenMobileFind] = useState(false);
  const isCollapsedClass = collapsed ? "collapsed" : "";

  useEffect(() => {
    if (!mobileHeader) return;

    setCollapsed(true);
  }, [mobileHeader]);

  return (
    <div className="home__unheader">
      <div className="home__unheader__content">
        {mobileHeader ? (
          <>
            {openMobileFind ? (
              <div className="home__unheader__find" onClick={() => navigate("/")}>
                <CusFind
                  placeholder={"Sort a model by nickname"}
                  value={nickname}
                  onChange={(e) => dispatch(setNickName(e.target.value))}
                />
              </div>
            ) : (
              <CategoriesCarousel className={"home__unheader"} categories={categories} />
            )}

            <button
              title="find"
              className="button-find"
              onClick={() => setOpenMobileFind((prev) => !prev)}
            >
              {openMobileFind ? <IconCross /> : <IconFind />}
            </button>
          </>
        ) : (
          <>
            <button
              className={`home__unheader__filters ${isCollapsedClass}`}
              onClick={() => setCollapsed((prev) => !prev)}
            >
              <div>
                <IconFilters />
                <span>{t("Sorting Priority")}</span>
              </div>
              <IconArrowDown className={!isCollapsedClass ? "rotate180" : ""} />
            </button>
            <CategoriesCarousel className={"home__unheader"} categories={categories} />
            <SortBySelect />
          </>
        )}
      </div>
    </div>
  );
}
