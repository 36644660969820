"use client";

import React, { useEffect } from "react";
import Header from "../components/home/Header";
import Footer from "../components/home/Footer";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChatTabStream from "../components/chat/ChatTabStream";
import ConfirmAge from "../components/common/ConfirmAge";
import { IS_SKIP_INTO, TEST_MODE } from "../components/common/consts";
import { useLocalStorage } from "usehooks-ts";
import UnderChat from "../components/chat/chat/UnderChat";
import { setBroadcasterProfile, setSettingInfo } from "../store/chatSlice";
import LoaderContainer from "../components/mui/LoaderContainer";
import ModalPrivate from "../components/chat/ModalPrivate";
import { ENUM_MODALS_VALUE, setModals } from "../store/commonSlice";
import { testSettingChat } from "../testConst";
import { useInterval } from "usehooks-ts";
import { DELAY_GET_BROADCAST } from "../components/home/HomeContent";
import useUtilsBroadcaster from "../hooks/useUtilsBroadcaster";
import { errorToast } from "../components/mui/Toaster";
import { API_BROADCASTER } from "../api/broadcasters";
import useLeaveModel from "../hooks/useLeaveModel";
import useJanus from "../hooks/useJanus";
import ErrorBoundary from "../components/mui/ErrorBoundary";

export default function CurrentModel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { updateBroadcaster } = useUtilsBroadcaster();
  const { getSetting } = useJanus({});
  useLeaveModel();

  const broadcasterId = useSelector((state) => state.chat.broadcaster.id);
  const broadcasterChatId = useSelector((state) => state.chat.broadcaster?.chat?.id);
  const broadcasterOnline = useSelector((state) => state.chat.broadcaster?.online);
  const isPrivate = useSelector((state) => state.common.modals.private);
  const userId = useSelector((state) => state.user.user.id);

  const [confirmAge, setConfirm] = useLocalStorage("confirmAge", IS_SKIP_INTO);

  const closePrivate = () => dispatch(setModals({ name: ENUM_MODALS_VALUE.private, value: false }));

  const doUpdateBroadcaster = () => {
    updateBroadcaster()
      .then((e) => {
        if (!e.ok) {
          if (TEST_MODE) return;
          navigate("/");
          errorToast(t(e.message));
        }
      })
      .catch(() => {
        if (TEST_MODE) return;
        navigate("/");
        errorToast(t("unknown error"));
      });
  };

  useInterval(() => {
    updateBroadcaster();
  }, DELAY_GET_BROADCAST);

  useEffect(() => {
    doUpdateBroadcaster();
  }, []);

  useEffect(() => {
    if (!broadcasterId) return;
    getSetting();

    if (TEST_MODE) dispatch(setSettingInfo(testSettingChat));
  }, [broadcasterId]);

  useEffect(() => {
    if (!broadcasterId) return;
    API_BROADCASTER.getProfile(broadcasterId)
      .then((e) => {
        const profile = e.data?.profile;
        if (profile) {
          dispatch(setBroadcasterProfile(profile));
        }
      })
      .catch(console.log);
  }, [broadcasterId, userId]);

  return (
    <>
      <LoaderContainer loading={broadcasterOnline ? !broadcasterChatId : !broadcasterId}>
        <main className={`chat ${t("currentLang")}`}>
          <ErrorBoundary>
            <Header />
          </ErrorBoundary>
          <ChatTabStream />
          <ErrorBoundary>
            <UnderChat />
          </ErrorBoundary>
          <ErrorBoundary>
            <Footer />
          </ErrorBoundary>
        </main>
      </LoaderContainer>

      <ConfirmAge
        setCurrentCategory={(e) => console.log(e)}
        isOpen={!confirmAge}
        closeModal={() => setConfirm(true)}
      />

      <ModalPrivate isOpen={isPrivate} closeModal={closePrivate} />
    </>
  );
}
