import { useEffect } from "react";
import Zoomist from "zoomist";
import { MAX_SCALE } from "../components/common/consts";

export default function useInitZoom({ available, divZoomRef }) {
  useEffect(() => {
    setTimeout(async () => {
      if (window.zoomist) return;
      if (!available) return;
      console.log("initzoom");
      window.zoomist = new Zoomist(divZoomRef.current, { maxScale: MAX_SCALE, zoomer: false });
      await window.zoomist.init();
      window.zoomist.on("zoom", (e) => {
        if (e.transform.scale === 1) e.transform.translateY = 0;
      });
    }, 50);
  }, [available]);

  useEffect(() => {
    window.zoomist = null;
  }, []);
}
