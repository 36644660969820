import React from "react";
import ImgAvatar, { ENUM_PLUG } from "../../mui/ImgAvatar";
import ChatTabs from "../tabs/ChatTabs";
import { useSelector } from "react-redux";

export default function ProfileHeader() {
  const broadcaster = useSelector((state) => state.chat.broadcaster);

  return (
    <div className="chat__profile__header">
      <div className="chat__profile__header__bg" />
      <div className="chat__profile__header__info">
        <ImgAvatar
          id={broadcaster.id}
          plug={ENUM_PLUG.rabbit}
          avatarProps={broadcaster.imgSource}
        />
        <div className="chat__profile__header__info__right">
          <h1 className="chat__profile__header__nick">@{broadcaster.name}</h1>
          <div className="chat__profile__header__buttons">
            <ChatTabs />
          </div>
        </div>
      </div>
    </div>
  );
}
