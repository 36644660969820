import React from "react";

export default function IconClick() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_207_33188)">
        <path
          d="M14.1689 5.625C15.7222 5.625 16.9814 4.3658 16.9814 2.8125C16.9814 1.2592 15.7222 0 14.1689 0C12.6156 0 11.3564 1.2592 11.3564 2.8125C11.3564 4.3658 12.6156 5.625 14.1689 5.625Z"
          fill="url(#paint0_linear_207_33188)"
        />
        <path
          d="M21.1875 8.05273C22.7408 8.05273 24 6.79354 24 5.24023C24 3.68693 22.7408 2.42773 21.1875 2.42773C19.6342 2.42773 18.375 3.68693 18.375 5.24023C18.375 6.79354 19.6342 8.05273 21.1875 8.05273Z"
          fill="url(#paint1_linear_207_33188)"
        />
        <path
          d="M6.68262 8.05273C8.23592 8.05273 9.49512 6.79354 9.49512 5.24023C9.49512 3.68693 8.23592 2.42773 6.68262 2.42773C5.12932 2.42773 3.87012 3.68693 3.87012 5.24023C3.87012 6.79354 5.12932 8.05273 6.68262 8.05273Z"
          fill="url(#paint2_linear_207_33188)"
        />
        <path
          d="M2.8125 14.6816C4.3658 14.6816 5.625 13.4224 5.625 11.8691C5.625 10.3158 4.3658 9.05664 2.8125 9.05664C1.2592 9.05664 0 10.3158 0 11.8691C0 13.4224 1.2592 14.6816 2.8125 14.6816Z"
          fill="url(#paint3_linear_207_33188)"
        />
        <path
          d="M22.5935 13.0811C21.8172 13.0811 21.1872 13.7111 21.1872 14.4873V13.5498C21.1872 13.1617 21.0297 12.8101 20.7752 12.5556C20.5207 12.3011 20.1691 12.1436 19.781 12.1436C19.0047 12.1436 18.3747 12.7736 18.3747 13.5498V12.8608C18.3747 12.4726 18.2172 12.1211 17.9627 11.8665C17.7082 11.612 17.3566 11.4545 16.9685 11.4545C16.1941 11.4545 15.5665 12.0798 15.5622 12.8533V8.20279C15.5623 7.43141 14.9398 6.80352 14.1691 6.79701C13.3843 6.78622 12.7483 7.44674 12.7497 8.23138V16.6075L11.0004 14.6997C10.7229 14.3973 10.3436 14.244 9.96349 14.244C9.62364 14.244 9.28333 14.3659 9.01333 14.6134C8.44099 15.1384 8.40255 16.0281 8.92755 16.6004L9.36067 17.0701C10.0943 17.8661 10.6113 18.8406 10.8321 19.9C11.0088 20.747 11.3107 22.5353 11.5657 23.4789C11.6486 23.7859 11.9266 23.9997 12.2449 23.9997H22.3633C22.6727 23.9997 22.946 23.7986 23.036 23.5028C23.448 22.1443 23.9997 18.9372 23.9997 15.67V14.4873C23.9997 14.0992 23.8422 13.7476 23.5877 13.4931C23.3332 13.2386 22.9816 13.0811 22.5935 13.0811Z"
          fill="url(#paint4_linear_207_33188)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_207_33188"
          x1="14.1689"
          y1="0"
          x2="14.1689"
          y2="6.09375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_207_33188"
          x1="21.1875"
          y1="2.42773"
          x2="21.1875"
          y2="8.52148"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_207_33188"
          x1="6.68262"
          y1="2.42773"
          x2="6.68262"
          y2="8.52148"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_207_33188"
          x1="2.8125"
          y1="9.05664"
          x2="2.8125"
          y2="15.1504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_207_33188"
          x1="16.2787"
          y1="6.79687"
          x2="16.2787"
          y2="25.4332"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_207_33188">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
