import React from "react";

import { ENUM_STATUS_WINDOW } from "../../../../store/chatSlice";
import { useSelector } from "react-redux";
import Volume from "./components/Volume";
import useDeviceSize from "../../../../hooks/useDeviceSize";
import PoliticMute from "./components/PoliticMute";

export default function ControlsRight({ audioRef, politicMute, onUnMute }) {
  const status = useSelector((state) => state.chat.statusWindow);
  const available = status === ENUM_STATUS_WINDOW.stream;
  const { isMobile } = useDeviceSize();

  return (
    <div className="chat__window__controls__right">
      <div className="chat__window__controls__right__bottom">
        {!isMobile && politicMute && <PoliticMute onUnMute={onUnMute} />}
        {available && <>{!isMobile && <Volume audioRef={audioRef} />}</>}
      </div>
    </div>
  );
}
