import { API_BROADCASTER } from "../api/broadcasters";
import { errorToast } from "../components/mui/Toaster";
import {
  ENUM_STATUS_WINDOW,
  setBroadcaster,
  setLoadingBroadcaster,
  setStatusWindow,
} from "../store/chatSlice";
import { setBroadcastersOnline, setBroadcastersPayload } from "../store/commonSlice";
import { getBroadcasterByPathname } from "../utils/broadcasters";
import { getLang, sleep } from "../utils/utils";

const TRANSLATE_MES_EN = {
  "No models online yet": "No models online yet",
  "No models online": "No models online",
  "Switching to the next model is not possible": "Switching to the next model is not possible",
};
const TRANSLATE_MES_RU = {
  "No models online yet": "На сайте пока нет моделей",
  "No models online": "Нет моделей онлайн",
  "Switching to the next model is not possible": "Переход на следующую модель невозможен.",
};

const TRANSLATE_MES = {
  en: TRANSLATE_MES_EN,
  ru: TRANSLATE_MES_RU,
};

export default class NextPrevModel {
  broadcasters;
  broadcaster;
  numberModel;
  maxPage;

  #dispatch;
  #navigate;
  #destroyCurrent;
  #setBtnLoading;
  #newPayload;
  #setDisable;
  #page;

  constructor({
    broadcasters,
    broadcaster,
    dispatch,
    navigate,
    setBtnLoading,
    setDisable,
    broadcastersPayload,
    destroyCurrent,
  }) {
    //hide
    this.#dispatch = dispatch;
    this.#navigate = navigate;
    this.#setBtnLoading = setBtnLoading;
    this.#setDisable = setDisable;
    this.#destroyCurrent = destroyCurrent;
    this.#newPayload = broadcastersPayload;
    this.#page = broadcastersPayload?.paging?.page || 0;

    console.log(this.#newPayload);

    // open
    console.log("broadcasters", broadcasters);
    this.broadcasters = broadcasters;
    this.broadcaster = broadcaster;
  }

  #clear() {
    this.#setDisable(false);
    this.#setBtnLoading(false);
  }

  translate(mes) {
    const lang = getLang();

    return TRANSLATE_MES?.[lang]?.[mes] || mes;
  }

  async updateBroadcasterPlus() {
    this.#page++;
    this.broadcasters = await this.#getNewBroadcasterInfo();

    if (!this.broadcasters.length) {
      this.#page = 1;
      this.broadcasters = await this.#getNewBroadcasterInfo();
    }
  }

  async updateBroadcasterMinus() {
    this.#page--;

    if (this.#page === 1) {
      this.#page = this.maxPage;
    }

    this.broadcasters = await this.#getNewBroadcasterInfo();
  }

  async #getId(isPlus = true) {
    this.numberModel = this.broadcasters.findIndex((el) => el.id === this.broadcaster.id);
    console.log("getId", this.numberModel, this.broadcasters);
    await sleep(1000);

    // если только одна в онлайне и сейчас на ней
    if (this.broadcaster.online && this.broadcasters.length == 1) {
      this.broadcasters = await this.#getNewBroadcasterInfo();
      if (this.broadcasters.length == 1) {
        errorToast(this.translate("No models online yet"));
        this.#clear();
        return;
      }
    }

    if (this.numberModel >= 0) {
      return true;
    }

    // если на который сейчас не онлайн
    if (!this.broadcaster.online) {
      this.broadcasters = await this.#getNewBroadcasterInfo();

      if (!this.broadcasters.length) {
        errorToast(this.translate("No models online"));
        this.#clear();
        return;
      }

      await this.#navigatePageByName(this.broadcasters[0].nickname);
      return;
    }

    if (isPlus) {
      this.updateBroadcasterPlus();
    } else {
      this.updateBroadcasterMinus();
    }

    return await this.#getId(isPlus);
  }

  async #getNewBroadcasterInfo() {
    const payload = {
      ...this.#newPayload,
      ...{ paging: { ...this.#newPayload.paging, page: this.#page } },
    };

    const newBrInfo = (await API_BROADCASTER.list(payload)).data.broadcasters;
    const newBroadcasters = [...newBrInfo.data, ...newBrInfo.more].filter((el) => el.online);
    this.#dispatch(setBroadcastersOnline(newBroadcasters));

    return newBroadcasters;
  }

  async #navigatePageByName(name) {
    await this.#destroyCurrent(true);
    await this.#navigate("/chat/" + name);
    this.#setBtnLoading(false);
    const newBroadcaster = await getBroadcasterByPathname();
    this.#dispatch(setBroadcaster(newBroadcaster));

    this.#dispatch(setLoadingBroadcaster(true));
    this.#dispatch(setStatusWindow(ENUM_STATUS_WINDOW.loading));
  }

  async prev(maxPage) {
    this.maxPage = maxPage;
    this.#setBtnLoading(true);
    const res = await this.#getId(false);
    if (!res) return;

    console.log(this.broadcasters, this.numberModel);

    const nextModel = this.broadcasters[this.numberModel - 1];
    if (nextModel?.nickname) {
      await this.#navigatePageByName(nextModel.nickname);
      return;
    }

    await this.updateBroadcasterMinus();
    this.#dispatch(setBroadcastersPayload(this.#newPayload));
    const lastBroadcaster = this.broadcasters.length;
    await this.#navigatePageByName(this.broadcasters[lastBroadcaster - 1].nickname);
  }

  async next() {
    this.#setBtnLoading(true);
    const res = await this.#getId(true);
    if (!res) return;

    console.log(this.broadcasters, this.numberModel);

    const nextModel = this.broadcasters[this.numberModel + 1];

    if (nextModel?.nickname) {
      await this.#navigatePageByName(nextModel.nickname);
      return;
    }

    try {
      await this.updateBroadcasterPlus();
      this.#dispatch(setBroadcastersPayload(this.#newPayload));
      await this.#navigatePageByName(this.broadcasters[0].nickname);
    } catch (e) {
      console.log(e);
      this.#setDisable(true);
      this.#setBtnLoading(false);
      errorToast(this.translate("Switching to the next model is not possible"));
    }
  }
}
