import React, { useState } from "react";
import IconPin from "../../../images/jsIcons/IconPin";
import { useSelector } from "react-redux";

export default function ChatPin({ message }) {
  const { name } = useSelector((state) => state.chat.broadcaster);

  const [hidePin, setHidePin] = useState(false);

  return (
    !!message &&
    !hidePin && (
      <div className="message__pin">
        <div className="message__pin__info">
          <span className="message__pin__nickname">@{name}</span>
          <span className="message__pin__text">{message.title}</span>
        </div>
        <button onClick={() => setHidePin((prev) => !prev)}>
          <IconPin />
        </button>
      </div>
    )
  );
}
