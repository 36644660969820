import React from "react";
import useDeviceSize from "../../hooks/useDeviceSize";
import { Carousel } from "antd";

export default function OnlyMobileСarousel({ children, className = "" }) {
  const { isMobile } = useDeviceSize();

  return isMobile ? (
    <Carousel
      style={{ color: "white" }}
      className={`carousel ${className}`}
      slidesToShow={1}
      // autoplay
      draggable
      dots={true}
      autoplaySpeed={5000}
    >
      {children}
    </Carousel>
  ) : (
    <div className={className}>{children}</div>
  );
}
