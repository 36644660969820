import { useEffect, useState } from "react";
import useDeviceSize from "./useDeviceSize";
import { useEventListener } from "usehooks-ts";

export default function useGridDisplay({ homeModelsRef, collapsed, setModelsClass, models }) {
  const { isMobile } = useDeviceSize();
  const [size, setSize] = useState(null);

  useEventListener("resize", (e) => setSize(e));

  useEffect(() => {
    const homeModels = homeModelsRef.current;
    if (!homeModels) return;
    // const width = homeModels.offsetWidth - 32;
    const width = window.innerWidth - 32;
    // ширина и высота кард в зависимости от того скрыты ли фильтры
    let cardWidth = 248 + 12;
    if (width < 520) cardWidth /= 1.5;

    const isStretched = (width / cardWidth) % 1 > 0.65;
    const countColumn = isStretched ? Math.ceil(width / cardWidth) : Math.floor(width / cardWidth);

    const classForModels = "model-column-" + Math.min(countColumn, 6);
    setModelsClass(classForModels);
  }, [collapsed, size, isMobile, models]);
}
