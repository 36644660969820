import { useCallback } from "react";
import { ENUM_MODALS_VALUE, setModals } from "../store/commonSlice";
import { useDispatch, useSelector } from "react-redux";

export const ENUM_PAID_ACTION = {
  price: "price",
};

// TODO завтра проверить
export default function useLoginAction() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.user.user);

  const onLoginAction = useCallback(
    async (cb) => {
      if (!id) {
        dispatch(setModals({ name: ENUM_MODALS_VALUE.typeLogin, value: true }));
        return;
      }

      await cb();
    },
    [id]
  );

  return { onLoginAction };
}
