import React from "react";

export default function IconArrowTwo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g clipPath="url(#clip0_1056_12019)">
        <path
          d="M0.252866 5.40017C0.588515 5.06453 1.12252 5.06453 1.45821 5.40017L5.99731 9.93928L10.5364 5.40017C10.8721 5.06453 11.4061 5.06453 11.7418 5.40017C12.0774 5.73582 12.0774 6.26983 11.7418 6.60551L6.59996 11.7473C6.26431 12.083 5.7303 12.083 5.39462 11.7473L0.252866 6.60555C-0.0751741 6.2699 -0.0751741 5.73589 0.252866 5.40017Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M0.252866 0.251737C0.588515 -0.0839122 1.12252 -0.0839122 1.45821 0.251737L5.99731 4.79084L10.5364 0.251737C10.872 -0.0839122 11.406 -0.0839122 11.7417 0.251737C12.0774 0.587386 12.0774 1.12139 11.7417 1.45708L6.59996 6.59883C6.26431 6.93448 5.7303 6.93448 5.39462 6.59883L0.252866 1.46472C-0.0751741 1.12904 -0.0751741 0.587419 0.252866 0.251737Z"
          fill="white"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1056_12019">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
