import React from "react";

import { useSelector } from "react-redux";
import { ENUM_STATUS_WINDOW } from "../../../../store/chatSlice";
import Share from "./components/Share";
import Screenshot from "./components/Screenshot";
import Magnifier from "./components/Magnifier";
import useDeviceSize from "../../../../hooks/useDeviceSize";

export default function ControlsLeft({ videoRef }) {
  const status = useSelector((state) => state.chat.statusWindow);
  const available = status === ENUM_STATUS_WINDOW.stream;
  const { isMobile } = useDeviceSize();

  return (
    <div className="chat__window__controls__left">
      {available && (
        <>
          {!isMobile && <Magnifier />}
          <Screenshot videoRef={videoRef} />
          <Share />
        </>
      )}
    </div>
  );
}
