import React, { useEffect } from "react";
import IconGradientRedAllModel from "../../images/jsIcons/IconGradientRedAllModel";
import IconAllModels from "../../images/jsIcons/IconAllModels";
import IconGirls from "../../images/jsIcons/IconGirls";
import IconGays from "../../images/jsIcons/IconGays";
import IconTrans from "../../images/jsIcons/IconTrans";
import { useDispatch, useSelector } from "react-redux";
import { SEX_ALL_MODELS, SEX_GAYS, SEX_GIRLS, SEX_TRANS } from "../../store/siteInfoSlice";
import { setTypeModel } from "../../store/commonSlice";

const IconCenter = {};
IconCenter[SEX_ALL_MODELS] = <IconAllModels className={"svg-center-title"} />;
IconCenter[SEX_GIRLS] = <IconGirls className={"svg-center-title girls"} />;
IconCenter[SEX_GAYS] = <IconGays className={"svg-center-title"} />;
IconCenter[SEX_TRANS] = <IconTrans className={"svg-center-title trans"} />;

// HomeContentHeader - шапка основного контента, надпить All models и градиент
export default function HomeContentHeader() {
  const optionsSex = useSelector((state) => state.siteInfo.commonSiteInfo.options?.sex);
  const modelTypes = useSelector((state) => state.common.typeModel);
  const dispatch = useDispatch();

  useEffect(() => {
    const value = optionsSex.find((el) => el.selected)?.value;
    if (value) dispatch(setTypeModel(value));
  }, [optionsSex]);

  const currentModelType = optionsSex.find((el) => el.id == modelTypes) || optionsSex[0].value;

  return (
    <div className="home__content__header">
      <div className="home__content__header__img__container">
        <IconGradientRedAllModel className={"gradient"} />
        {/* <IconAllModels className={"all-models"} /> */}
        {IconCenter[modelTypes]}
      </div>
      <span className="home__content__header__text">{currentModelType.title}</span>
    </div>
  );
}
