import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import "./social-icons.scss";
import imgFacebook from "./facebook.svg";
import imgOnlyFans from "./OnlyFans.svg";
import imgInstagram from "./instagram.svg";
import imgTwitter from "./twitter.svg";
import usePremiumClick from "../../../../../../hooks/usePremiumClick";

export default function SocialIcons() {
  const { onPremiumClick } = usePremiumClick();

  const social = useSelector((state) => state.chat.broadcasterProfile?.general?.socials) || {};
  const premium = useSelector((state) => state.user.user.premium);

  const socialsKeys = useMemo(() => Object.keys(social).filter((el) => social[el]), [social]);

  const imgSourceBySocial = {
    facebook: imgFacebook,
    instagram: imgInstagram,
    onlyfans: imgOnlyFans,
    x: imgTwitter,
  };

  const getHref = (socialKey) => {
    const value = social[socialKey];
    console.log("value", value);
    if (!value) return "";
    if (typeof value !== "string") return "";

    if (socialKey === "onlyfans") {
      if (value.includes("onlyfans")) return value;
      return "https://onlyfans.com/" + value;
    }
    if (socialKey === "facebook") {
      if (value.includes("facebook")) return value;
      return "https://facebook.com/" + value;
    }
    if (socialKey === "instagram") {
      if (value.includes("instagram")) return value;
      return "https://instagram.com/" + value;
    }
    if (socialKey === "twitter") {
      if (value.includes("twitter")) return value;
      return "https://twitter.com/" + value;
    }

    return value;
  };

  return (
    socialsKeys && (
      <div className="social-icons">
        {socialsKeys.map((socialKey) => (
          <a
            onClick={(e) => {
              if (premium) return;
              e.preventDefault();
              onPremiumClick();
            }}
            key={socialKey}
            className={`social-icons__icon ${socialKey}`}
            href={getHref(socialKey)}
            target="_blank"
            rel="noreferrer"
          >
            <img src={imgSourceBySocial[socialKey]} />
          </a>
        ))}
      </div>
    )
  );
}
