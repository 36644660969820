import React from "react";
import CusButton from "../../../../mui/CusButton";
import IconShare from "../../../../../images/jsIcons/IconShare";
import { useDispatch, useSelector } from "react-redux";
import ShownControl from "../../../../mui/ShownControl";
import { ENUM_MODALS_VALUE, setModals } from "../../../../../store/commonSlice";

export default function Share() {
  const dispatch = useDispatch();
  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);

  return (
    <>
      <ShownControl>
        <CusButton
          id="share-button"
          icon={<IconShare />}
          onClick={async () => {
            if (isFullScreen) await document?.exitFullscreen?.();
            dispatch(setModals({ name: ENUM_MODALS_VALUE.share, value: true }));
          }}
        />
      </ShownControl>
    </>
  );
}
