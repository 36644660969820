import React from "react";

export default function IconChatOne() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M11.333 1.33301H4.66634C2.82634 1.33301 1.33301 2.81967 1.33301 4.65301V8.63967V9.30634C1.33301 11.1397 2.82634 12.6263 4.66634 12.6263H5.66634C5.84634 12.6263 6.08634 12.7463 6.19967 12.893L7.19967 14.2197C7.63967 14.8063 8.35967 14.8063 8.79967 14.2197L9.79967 12.893C9.92634 12.7263 10.1263 12.6263 10.333 12.6263H11.333C13.173 12.6263 14.6663 11.1397 14.6663 9.30634V4.65301C14.6663 2.81967 13.173 1.33301 11.333 1.33301ZM5.33301 7.99967C4.95967 7.99967 4.66634 7.69967 4.66634 7.33301C4.66634 6.96634 4.96634 6.66634 5.33301 6.66634C5.69967 6.66634 5.99967 6.96634 5.99967 7.33301C5.99967 7.69967 5.70634 7.99967 5.33301 7.99967ZM7.99967 7.99967C7.62634 7.99967 7.33301 7.69967 7.33301 7.33301C7.33301 6.96634 7.63301 6.66634 7.99967 6.66634C8.36634 6.66634 8.66634 6.96634 8.66634 7.33301C8.66634 7.69967 8.37301 7.99967 7.99967 7.99967ZM10.6663 7.99967C10.293 7.99967 9.99967 7.69967 9.99967 7.33301C9.99967 6.96634 10.2997 6.66634 10.6663 6.66634C11.033 6.66634 11.333 6.96634 11.333 7.33301C11.333 7.69967 11.0397 7.99967 10.6663 7.99967Z"
        fill="url(#paint0_linear_1027_9056)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1027_9056"
          x1="7.99967"
          y1="1.33301"
          x2="7.99967"
          y2="15.7702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
      </defs>
    </svg>
  );
}
