import React, { useEffect, useMemo, useState } from "react";
import useJanus from "../../../../../hooks/useJanus";
import { useDispatch, useSelector } from "react-redux";
import IconCrown from "../../../../../images/jsIcons/IconCrown";
import IconPremiumCrown from "../../../../../images/jsIcons/IconPremiumCrown";
import IconDiamond from "../../../../../images/jsIcons/IconDiamond";
import IconPrivate from "../../../../../images/jsIcons/IconPrivate";
import { ENUM_CHAT_MODE, ENUM_STATUS_WINDOW } from "../../../../../store/chatSlice";
import CusButton from "../../../../mui/CusButton";
import IconKing from "../../../../../images/jsIcons/IconKing";
import useLoginAction from "../../../../../hooks/useLoginAction";
import { ENUM_MODALS_VALUE, setModals } from "../../../../../store/commonSlice";
import ShownControl from "../../../../mui/ShownControl";
import { useTranslation } from "react-i18next";

export default function PrivateButton({ alwaysShown }) {
  const { getModelMode, stopPrivate } = useJanus({});
  const { onLoginAction } = useLoginAction();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const chatActiveMode = useSelector((state) => state.chat.chatActiveMode);
  const chatInfo = useSelector((state) => state.chat.settingInfo);
  const status = useSelector((state) => state.chat.statusWindow);
  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);

  const modelMode = getModelMode();

  const [availablePrivate, setAvailablePrivate] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const available = useMemo(() => status === ENUM_STATUS_WINDOW.stream, [status]);

  const ICON_BY_MODE = {
    [ENUM_CHAT_MODE.paid]: <IconCrown />,
    [ENUM_CHAT_MODE.private]: <IconPremiumCrown />,
    [ENUM_CHAT_MODE.exclusive]: <IconDiamond />,
    [ENUM_CHAT_MODE.view]: <IconPrivate />,
  };

  const iconButton = ICON_BY_MODE[modelMode];

  const isPrivate =
    chatActiveMode === ENUM_CHAT_MODE.exclusive ||
    chatActiveMode === ENUM_CHAT_MODE.private ||
    chatActiveMode === ENUM_CHAT_MODE.view ||
    chatActiveMode === ENUM_CHAT_MODE.paid;

  const onStopShow = async () => {
    setDisableBtn(true);
    await stopPrivate();
    setDisableBtn(false);
  };

  const onStartPrivate = async () => {
    onLoginAction(() => dispatch(setModals({ name: ENUM_MODALS_VALUE.private, value: true })));
    if (isFullScreen) await document?.exitFullscreen?.();
  };

  useEffect(() => {
    if (!chatActiveMode) return;
    const isAvailable = chatInfo?.[chatActiveMode]?.["allow-client-start-private-chat"];
    setAvailablePrivate(isAvailable);
  }, [chatInfo, chatActiveMode]);

  return (
    <ShownControl isCurrentShown={available} alwaysShown={alwaysShown}>
      {isPrivate ? (
        <CusButton
          icon={iconButton}
          onClick={onStopShow}
          className="controls__buttons__private-stop"
          text={t("Stop Show")}
          disabled={disableBtn}
        />
      ) : availablePrivate ? (
        <CusButton
          onClick={onStartPrivate}
          className="chat__window__private"
          text={"Private show"}
          id={"private-button"}
          icon={<IconKing />}
          color="red"
        />
      ) : (
        // TODO что бы сохранять отступы
        <div className="chat__window__private"></div>
      )}
    </ShownControl>
  );
}
