import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ENUM_CHAT_TAB, setChatActiveTab } from "../../../store/chatSlice";
import CusButton from "../../mui/CusButton";
import IconChatTips from "../../../images/jsIcons/IconChatTips";
import IconChat from "../../../images/jsIcons/IconChat";
// import IconVibrator from "../../../images/jsIcons/IconVibrator";
import { useTranslation } from "react-i18next";
import useDeviceSize from "../../../hooks/useDeviceSize";
import useLoginAction from "../../../hooks/useLoginAction";

export default function ChatHeader({ hideOther, setHideOther }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { onLoginAction } = useLoginAction();

  const chatActiveTab = useSelector((state) => state.chat.chatActiveTab);
  const status = useSelector((state) => state.chat.statusWindow);

  const FREE = ENUM_CHAT_TAB.free;
  const TIPS = ENUM_CHAT_TAB.tips;
  // const LOVENSE = ENUM_CHAT_TAB.lovense;

  const tabs = [
    { id: FREE, icon: <IconChat />, text: "Free chat" },
    { id: TIPS, icon: <IconChatTips />, text: "Tips menu" },
    // { id: LOVENSE, icon: <IconVibrator />, text: "Lovense Toys" },
  ];

  const isActive = (id) => (chatActiveTab === id ? "active" : "");
  const isMobile = useDeviceSize();

  const onChangeTab = (tab) => {
    dispatch(setChatActiveTab(tab.id));
  };

  useEffect(() => {
    dispatch(setChatActiveTab(FREE));
  }, [status]);

  useEffect(() => {
    if (isMobile) {
      dispatch(setChatActiveTab(ENUM_CHAT_TAB.free));
    }
  }, [isMobile]);

  return (
    <div className="chat__header">
      <div className="chat__header__all">
        <div className={"chat__header__all__tabs"}>
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`chat__header__all__item ${tab.id} ${isActive(tab.id)}`}
              onClick={() => onChangeTab(tab)}
            >
              {tab.icon}
              <span className="chat__header__all__item__text">{t(tab.text)}</span>
            </div>
          ))}
        </div>
        {chatActiveTab === FREE && (
          <CusButton
            text={"Only my messages"}
            color={hideOther ? "red" : "black"}
            onClick={() => onLoginAction(() => setHideOther((prev) => !prev))}
          />
        )}
      </div>
    </div>
  );
}
