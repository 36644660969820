import React, { useState } from "react";
import ProfileHeader from "../profile/ProfileHeader";
import { useEffect } from "react";

import { useSelector } from "react-redux";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { API_PROFILE } from "../../../api/profile";
import { API_URL_USER } from "../../../api/main";
import Pagination from "../../mui/Pagination";
import AccessPhoto from "./AccessPhoto";
import Fancybox from "./Fancybox";

const PER_PAGE = 28;

export default function ChatTabSnapshot() {
  const [photos, setPhotos] = useState([]);
  const broadcasterId = useSelector((state) => state.chat.broadcaster.id);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const getFullPhoto = (id) => {
    return `${API_URL_USER}/${broadcasterId}/gallery/snapshot/${id}/view`;
  };

  const getAllPhotos = async () => {
    const gallery = (
      await API_PROFILE.photos.getSnapshotIdList(broadcasterId, currentPage, PER_PAGE)
    ).data;
    const photosId = gallery.snapshot;
    setTotalPage(gallery.count);

    const photos = [];
    for (let i = 0; i < photosId.length; i++) {
      const id = photosId[i].id;
      const photo = `${API_URL_USER}/${broadcasterId}/gallery/snapshot/${id}/preview/180`;

      photos.push({ ...photosId[i], img: photo, id: id });
    }

    if (!photos?.length) return;
    setPhotos(photos);
  };

  useEffect(() => {
    getAllPhotos();
  }, [broadcasterId, currentPage]);

  return (
    <div className="chat__photo">
      <ProfileHeader />
      <Fancybox className="chat__photo__content">
        {photos.map((source) => (
          <AccessPhoto source={source} key={source.id} getFullPhoto={getFullPhoto} />
        ))}
      </Fancybox>

      <Pagination
        MAX_PAGE={Math.ceil(totalPage / PER_PAGE)}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
