import React from "react";
import CusButton from "../../../../mui/CusButton";
import Icon2circle from "../../../../../images/jsIcons/Icon2circle";
import ShownControl from "../../../../mui/ShownControl";

export default function Live() {
  return (
    <ShownControl>
      <CusButton text={"Live"} icon={<Icon2circle />} color="red" className="live" />{" "}
    </ShownControl>
  );
}
