import React from "react";
import { useEventListener } from "usehooks-ts";
import SmileToIndx from "../../mui/smile/SmileToIndx";

export default function ChatSmile({ TextareaRef, open, setOpen, setValue, TextAreaStartRef }) {
  const smiles = new Array(30);
  smiles.fill(1);

  useEventListener("click", () => {
    setOpen(false);
  });

  return (
    open && (
      <div
        className="chat__popup__smiles"
        onClick={(e) => {
          e.stopPropagation();
          TextareaRef?.current?.focus?.();
        }}
      >
        {smiles.map((_, indx) => (
          <div key={indx} className="chat__popup__smiles__smile">
            {
              <SmileToIndx
                indx={indx}
                onClick={(e, evt) => {
                  console.log(e, evt);
                  evt.preventDefault();
                  evt.stopPropagation();
                  TextareaRef?.current?.focus?.();
                  const startNumber = TextAreaStartRef.current;
                  setValue((prev) => prev.slice(0, startNumber) + e + prev.slice(startNumber));
                }}
              />
            }
          </div>
        ))}
      </div>
    )
  );
}
