import React from "react";

export default function SupportContentRu() {
  return (
    <>
      <h1 className="center">Поддержка / Контакты</h1>

      <h2>Нужна помощь?</h2>
      <p>
        Чтобы предоставить вам наилучшую помощь, свяжитесь с нами любым из способов ниже. Мы
        доступны 24 часа в сутки, 7 дней в неделю
        <span>
          Email: <a href="mailto:support@richcams.com">support@richcams.com</a>
        </span>
      </p>

      <h2>Жалобы: </h2>
      <p>
        По всем жалобам обращайтесь по адресу{" "}
        <a href="mailto:support@richcams.com">support@richcams.com</a> Жалобы будут рассмотрены и
        решены в течение семи (7) рабочих дней. Это решение затем будет доведено до сведения истца.
        Апелляции или запросы на любое принятое решение следует снова направлять по адресу{" "}
        <a href="mailto:support@richcams.com">support@richcams.com</a>.
      </p>

      <h2>Апелляции:</h2>
      <p>
        Если вы были изображены в каком-либо контенте и хотите подать апелляцию на удаление такого
        контента, сообщите нам об этом по электронной почте{" "}
        <a href="mailto:support@richcams.com">support@richcams.com</a>. Если возникнут разногласия
        по поводу апелляции, мы позволим разрешить разногласия нейтральной стороне.
      </p>

      <h2>Проблемы с биллингом CCBill:</h2>
      <p>
        Если вы хотите отменить подписку и больше не получать счета, а также по любым вопросам,
        связанным с выставлением счетов, включая возврат средств в каждом конкретном случае,
        свяжитесь с нашим партнером по выставлению счетов, CCBill, по телефону. 1.888.596.9279, или
        напишите по адресу{" "}
        <a href="mailto:consumersupport@ccbill.com">consumersupport@ccbill.com</a>
      </p>
    </>
  );
}
