import React from "react";
import ImgAvatar from "../../mui/ImgAvatar";
import IconLock from "../../../images/jsIcons/IconLock";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function ExclusivePrivate({ id, avatar }) {
  const nickname = useSelector((state) => state.chat.broadcaster.name) || "model";
  const { t } = useTranslation();

  return (
    <div className="chat__model__exclusive-private">
      <div className="chat__model__exclusive-private__avatar">
        <ImgAvatar id={id} avatarProps={avatar} />
        <div className="chat__model__exclusive-private__lock">
          <IconLock />
        </div>
      </div>
      <h3 className="chat__model__exclusive-private__title">
        {t("Your")} @{nickname} {t("is having fun in Exclusive Private")}
      </h3>
      <p className="chat__model__exclusive-private__p">
        {t(
          "When the Exclusive Private ends, you can also invite the model to a Private or Exclusive Private."
        )}
      </p>
    </div>
  );
}
