import React from "react";
import IconPremiumCrown from "../../images/jsIcons/IconPremiumCrown";
import { useTranslation } from "react-i18next";

export default function LifetimePremium() {
  const { t } = useTranslation();

  return (
    <span className="lifetime-premium">
      {t("Lifetime Premium")}
      <span className="lifetime-premium__absolute crown-red">
        <IconPremiumCrown />
      </span>
    </span>
  );
}
