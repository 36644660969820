import axios from "axios";
import React, { useEffect, useState } from "react";
import { useInterval } from "usehooks-ts";

export default function ImageUpdate({
  isUpdate,
  path,
  imgSource,
  setErrorImg,
  noImg,
  time = 45 * 1000,
}) {
  const [source, setSource] = useState(imgSource);

  const getPhoto = () => {
    const data = { method: "GET", responseType: "blob", url: path };
    axios(data)
      .then((e) => {
        if (e.status != 200) return setSource(imgSource);

        const imageUrl = URL.createObjectURL(e.data);
        setSource(imageUrl);
      })
      .catch(() => setSource(imgSource));
  };

  useInterval(() => {
    console.log(isUpdate, path);
    if (!isUpdate) return;
    if (!path) return;

    getPhoto();
  }, time);

  useEffect(() => {
    if (!isUpdate) return setSource(path || imgSource);
    getPhoto();
  }, [isUpdate, path, imgSource]);

  return <img src={source} alt={"изображение"} onError={() => setErrorImg(noImg)} />;
}
