import React from "react";

export default function IconMove() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M4.5 1.5H6V3H4.5V1.5Z" fill="url(#paint0_linear_740_19085)" />
      <path d="M7.5 1.5H9V3H7.5V1.5Z" fill="url(#paint1_linear_740_19085)" />
      <path d="M1.5 10.5H3V12H1.5V10.5Z" fill="url(#paint2_linear_740_19085)" />
      <path d="M10.5 1.5H12V3H10.5V1.5Z" fill="url(#paint3_linear_740_19085)" />
      <path
        d="M15.75 6H12V4.5H10.5V6H6.75C6.3 6 6 6.3 6 6.75V10.5H4.5V12H6V15.75C6 16.2 6.3 16.5 6.75 16.5H15L13.65 14.175L12 15.825V9.75L16.5 12.375V6.75C16.5 6.3 16.2 6 15.75 6Z"
        fill="url(#paint4_linear_740_19085)"
      />
      <path d="M1.5 7.5H3V9H1.5V7.5Z" fill="url(#paint5_linear_740_19085)" />
      <path
        d="M16.5 15.9746C16.5 15.8996 16.5 15.8246 16.5 15.7496V12.9746L15 13.4246L16.5 15.9746Z"
        fill="url(#paint6_linear_740_19085)"
      />
      <path d="M1.5 1.5H3V3H1.5V1.5Z" fill="url(#paint7_linear_740_19085)" />
      <path d="M1.5 4.5H3V6H1.5V4.5Z" fill="url(#paint8_linear_740_19085)" />
      <defs>
        <linearGradient
          id="paint0_linear_740_19085"
          x1="5.25"
          y1="1.5"
          x2="5.25"
          y2="3.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_740_19085"
          x1="8.25"
          y1="1.5"
          x2="8.25"
          y2="3.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_740_19085"
          x1="2.25"
          y1="10.5"
          x2="2.25"
          y2="12.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_740_19085"
          x1="11.25"
          y1="1.5"
          x2="11.25"
          y2="3.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_740_19085"
          x1="10.5"
          y1="4.5"
          x2="10.5"
          y2="17.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_740_19085"
          x1="2.25"
          y1="7.5"
          x2="2.25"
          y2="9.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_740_19085"
          x1="15.75"
          y1="12.9746"
          x2="15.75"
          y2="16.2246"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_740_19085"
          x1="2.25"
          y1="1.5"
          x2="2.25"
          y2="3.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_740_19085"
          x1="2.25"
          y1="4.5"
          x2="2.25"
          y2="6.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
      </defs>
    </svg>
  );
}
