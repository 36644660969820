import React, { useMemo } from "react";
import CloseModal from "../../../../mui/CloseModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ENUM_STATUS_WINDOW } from "../../../../../store/chatSlice";

export default function PoliticMute({ onUnMute }) {
  const { t } = useTranslation();
  const status = useSelector((state) => state.chat.statusWindow);
  const available = useMemo(() => status === ENUM_STATUS_WINDOW.stream, [status]);

  return (
    available && (
      <div className="politic-mute">
        <CloseModal
          close={() => {
            console.log(onUnMute);
            onUnMute();
          }}
        />
        <span>{t("Close this message to unmute.")}</span>
      </div>
    )
  );
}
