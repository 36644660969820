import React from "react";

export default function IconShare() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5867 15.2535C18.1854 15.2535 16.9493 15.9155 16.1418 16.9292L8.65145 13.1311C8.74951 12.7685 8.81999 12.3938 8.81999 11.9994C8.81999 11.5702 8.73628 11.166 8.61948 10.7727L16.0767 6.99214C16.8799 8.05174 18.1479 8.74429 19.5878 8.74429C22.0269 8.74429 24 6.78791 24 4.37161C24.0001 1.9575 22.0269 0 19.5878 0C17.1542 0 15.1789 1.9575 15.1789 4.37156C15.1789 4.76701 15.2495 5.14276 15.3486 5.50651L7.85936 9.30463C7.05073 8.28985 5.81242 7.6257 4.4089 7.6257C1.97197 7.6257 0 9.58426 0 11.9994C0 14.4146 1.97202 16.3721 4.4089 16.3721C5.85098 16.3721 7.11794 15.6774 7.92433 14.6167L15.3783 18.3973C15.2615 18.7895 15.1767 19.1969 15.1767 19.6273C15.1767 22.0425 17.152 24 19.5856 24C22.0247 24 23.9978 22.0425 23.9978 19.6273C23.9989 17.21 22.0258 15.2535 19.5867 15.2535Z"
        fill="url(#paint0_linear_740_12974)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_740_12974"
          x1="12"
          y1="0"
          x2="12"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
      </defs>
    </svg>
  );
}
