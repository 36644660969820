import { useEffect } from "react";
import JanusState from "../controls/janus/site/JanusState";
import { useDispatch } from "react-redux";
import { ENUM_STATUS_WINDOW, setStatusWindow } from "../store/chatSlice";
import { sleep } from "../utils/utils";
import useJanus from "./useJanus";

export default function useLeaveModel() {
  const dispatch = useDispatch();
  const { destroyCurrent } = useJanus({});

  useEffect(() => {
    const leaveCancel = async () => {
      while (JanusState.isAwait) {
        console.log("status loading");
        dispatch(setStatusWindow(ENUM_STATUS_WINDOW.loading));
        console.log(JanusState.isAwait);
        await sleep(300);
      }

      setTimeout(() => {
        JanusState.isLeave = false;
      }, 300);
    };
    leaveCancel();
    return () => {
      console.log("run destroy");
      console.log("status loading");
      dispatch(setStatusWindow(ENUM_STATUS_WINDOW.loading));

      JanusState.isLeave = true;
      destroyCurrent()
        .then((e) => console.log("destroy ok", e))
        .catch((e) => console.log("destroy error", e))
        .finally(() => {
          dispatchEvent(new CustomEvent("destroy.model"));
        });
    };
  }, []);
}
