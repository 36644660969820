import { useEffect } from "react";

export default function useVideoPlay({ videoRef, canvasRef }) {
  useEffect(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const ctx = canvas.getContext("2d");

    const getStart = (vs, vvs) => (vs - vvs) / (vs / vvs) / 2;

    const drawCenteredVideo = () => {
      const proportions = canvas.clientWidth / canvas.clientHeight;
      const isHorizontalVideo = video.videoWidth > video.videoHeight;
      const isHorizontalCanvas = canvas.clientWidth > canvas.clientHeight;

      let videoWidth;
      let videoHeight;
      if (isHorizontalVideo) {
        videoWidth = video.videoWidth;
        videoHeight = videoWidth / proportions;
      } else {
        // vertical
        videoHeight = video.videoHeight;
        videoWidth = video.videoHeight * proportions;
      }

      //isHorizontalCanvas
      if (isHorizontalCanvas) {
        videoHeight = video.videoHeight;
        videoWidth = video.videoHeight * proportions;

        if (videoWidth < video.videoWidth) {
          videoWidth = video.videoWidth;
          videoHeight = videoWidth / proportions;
        }
      }

      const isCenterX = video.videoWidth < videoWidth;
      const isCenterY = video.videoHeight < videoHeight;
      const startX = isCenterX && getStart(videoWidth, video.videoWidth);
      const startY = isCenterY && getStart(videoHeight, video.videoHeight);
      ctx.drawImage(
        video,
        0,
        0,
        videoWidth,
        videoHeight,
        startX,
        startY,
        video.videoWidth,
        video.videoHeight
      );
    };

    const drawFrame = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      drawCenteredVideo();
      window.rsFrame = requestAnimationFrame(drawFrame);
    };

    window.drawFrame = drawFrame;
    video.addEventListener("loadedmetadata", drawFrame);

    return () => {
      cancelAnimationFrame(window.rsFrame);
      video.removeEventListener("loadedmetadata", drawFrame);
    };
  }, [videoRef, canvasRef]);
}
