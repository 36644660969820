import React from "react";

export default function IconMagnifier() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <path
        d="M15.7913 12.491C15.5438 12.7385 15.2964 12.821 14.9664 12.821L8.36671 12.821C7.70675 12.821 7.21177 12.326 7.21177 11.666C7.21177 11.006 7.70675 10.5111 8.36671 10.5111H14.9664C15.6263 10.5111 16.1213 11.006 16.1213 11.666C16.1213 11.996 16.0388 12.2435 15.7913 12.491Z"
        fill="url(#paint0_linear_1170_18356)"
      />
      <path
        d="M11.9333 21.533C6.65325 21.533 2.33325 17.213 2.33325 11.933C2.33325 6.65301 6.65325 2.33301 11.9333 2.33301C17.2133 2.33301 21.5333 6.65301 21.5333 11.933C21.5333 17.213 17.2133 21.533 11.9333 21.533ZM11.9333 4.73301C7.97325 4.73301 4.73325 7.97301 4.73325 11.933C4.73325 15.893 7.97325 19.133 11.9333 19.133C15.8933 19.133 19.1333 15.893 19.1333 11.933C19.1333 7.97301 15.8933 4.73301 11.9333 4.73301Z"
        fill="url(#paint1_linear_1170_18356)"
      />
      <path
        d="M12.7817 16.1753C12.5272 16.4298 12.2726 16.5147 11.9332 16.5147C11.2544 16.5147 10.7453 16.0056 10.7453 15.3267L10.7453 8.53851C10.7453 7.85968 11.2544 7.35057 11.9332 7.35057C12.612 7.35057 13.1211 7.85968 13.1211 8.53851L13.1211 15.3267C13.1211 15.6661 13.0363 15.9207 12.7817 16.1753Z"
        fill="url(#paint2_linear_1170_18356)"
      />
      <path
        d="M25.1332 26.3324C24.7732 26.3324 24.5332 26.2124 24.2932 25.9724L17.0932 18.7724C16.6132 18.2924 16.6132 17.5724 17.0932 17.0924C17.5732 16.6124 18.2932 16.6124 18.7732 17.0924L25.9732 24.2924C26.4532 24.7724 26.4532 25.4924 25.9732 25.9724C25.7332 26.2124 25.4932 26.3324 25.1332 26.3324Z"
        fill="url(#paint3_linear_1170_18356)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1170_18356"
          x1="9.19167"
          y1="9.19114"
          x2="14.5539"
          y2="14.5534"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1170_18356"
          x1="11.9333"
          y1="2.33301"
          x2="11.9333"
          y2="23.133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1170_18356"
          x1="9.38762"
          y1="9.38703"
          x2="14.9031"
          y2="14.9025"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1170_18356"
          x1="21.5332"
          y1="16.7324"
          x2="21.5332"
          y2="27.1324"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
      </defs>
    </svg>
  );
}
