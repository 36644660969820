import { combineReducers, configureStore } from "@reduxjs/toolkit";
import commonSlice from "./commonSlice";
import userSlice from "./userSlice";
import SiteInfoSlice from "./siteInfoSlice";
import chatSlice from "./chatSlice";

const rootReduces = combineReducers({
  common: commonSlice,
  user: userSlice,
  siteInfo: SiteInfoSlice,
  chat: chatSlice,
});

export const store = configureStore({ reducer: rootReduces });
