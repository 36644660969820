import React from "react";

export default function IconMixer() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17.9998 16.7503C17.8398 16.7503 17.6898 16.7003 17.5498 16.6003C17.2198 16.3503 17.1498 15.8803 17.3998 15.5503C18.9698 13.4603 18.9698 10.5403 17.3998 8.45027C17.1498 8.12027 17.2198 7.65027 17.5498 7.40027C17.8798 7.15027 18.3498 7.22027 18.5998 7.55027C20.5598 10.1703 20.5598 13.8303 18.5998 16.4503C18.4498 16.6503 18.2298 16.7503 17.9998 16.7503Z"
        fill="url(#paint0_linear_976_10920)"
      />
      <path
        d="M19.8299 19.2503C19.6699 19.2503 19.5199 19.2003 19.3799 19.1003C19.0499 18.8503 18.9799 18.3803 19.2299 18.0503C21.8999 14.4903 21.8999 9.51027 19.2299 5.95027C18.9799 5.62027 19.0499 5.15027 19.3799 4.90027C19.7099 4.65027 20.1799 4.72027 20.4299 5.05027C23.4999 9.14027 23.4999 14.8603 20.4299 18.9503C20.2899 19.1503 20.0599 19.2503 19.8299 19.2503Z"
        fill="url(#paint1_linear_976_10920)"
      />
      <path
        d="M14.02 3.77972C12.9 3.15972 11.47 3.31972 10.01 4.22972L7.09 6.05972C6.89 6.17972 6.66 6.24972 6.43 6.24972H5.5H5C2.58 6.24972 1.25 7.57972 1.25 9.99972V13.9997C1.25 16.4197 2.58 17.7497 5 17.7497H5.5H6.43C6.66 17.7497 6.89 17.8197 7.09 17.9397L10.01 19.7697C10.89 20.3197 11.75 20.5897 12.55 20.5897C13.07 20.5897 13.57 20.4697 14.02 20.2197C15.13 19.5997 15.75 18.3097 15.75 16.5897V7.40972C15.75 5.68972 15.13 4.39972 14.02 3.77972Z"
        fill="url(#paint2_linear_976_10920)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_976_10920"
          x1="18.6596"
          y1="7.25"
          x2="18.6596"
          y2="17.542"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_976_10920"
          x1="20.906"
          y1="4.75"
          x2="20.906"
          y2="20.4586"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_976_10920"
          x1="8.5"
          y1="3.4082"
          x2="8.5"
          y2="22.0215"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
      </defs>
    </svg>
  );
}
