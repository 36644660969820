import { useDispatch, useSelector } from "react-redux";
import { toDataURL } from "../utils/utils";
import { setSomeoneAvatar } from "../store/userSlice";

export default function useAvatar({ id, url }) {
  const dispatch = useDispatch();
  const someoneAvatar = useSelector((state) => state.user?.someoneAvatar?.[id]);

  const getAvatar = () => {
    if (!someoneAvatar) {
      return url;
    }
    return someoneAvatar;
  };

  const updateAvatar = () => {
    if (!id) return;
    if (!url) return;

    toDataURL(url, (avatar) => {
      dispatch(setSomeoneAvatar({ id, avatar }));
    });
  };

  return { avatar: getAvatar(), updateAvatar };
}
