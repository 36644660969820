import React from "react";
import IconAbout from "../../../images/jsIcons/IconAbout";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function ProfileAbout() {
  const { t } = useTranslation();
  const about = useSelector((state) => state.chat.broadcaster?.profile?.about?.text);

  return (
    about && (
      <div className="chat__profile__about">
        <div className="chat__profile__about__header">
          <IconAbout /> <h3>{t("About me")}</h3>
        </div>
        <p className="chat__profile__about__text">{about}</p>
      </div>
    )
  );
}
