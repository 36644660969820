import React, { useState } from "react";
import IconQuestionCircle from "../../images/jsIcons/IconQuestionCircle";

export default function QuestionPopup({ className, popupText }) {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    popupText && (
      <div
        className="question"
        onMouseEnter={() => setOpenPopup(true)}
        onMouseLeave={() => setOpenPopup(false)}
      >
        <button className={`${className}__question`}>
          <IconQuestionCircle />
        </button>
        {openPopup && <div className="question__popup">{popupText}</div>}
      </div>
    )
  );
}
