import React from "react";

export default function IconGradientRedAllModel(iconProps) {
  return (
    <svg
      {...iconProps}
      width="1268"
      height="54"
      viewBox="0 0 1268 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16_1242)">
        <g filter="url(#filter0_f_16_1242)">
          <rect
            width="573"
            height="16.0354"
            transform="matrix(-1 0 0 1 921 0)"
            fill="#F78181"
            fillOpacity="0.3"
          />
        </g>
        <g opacity="0.5" filter="url(#filter1_f_16_1242)">
          <ellipse
            cx="637.5"
            cy="-5.108"
            rx="10.2162"
            ry="43.4189"
            transform="rotate(90 637.5 -5.108)"
            fill="#ED381F"
          />
        </g>
        <path d="M614 0H138" stroke="url(#paint0_linear_16_1242)" strokeWidth="0.72973" />
        <path d="M614 0H138" stroke="url(#paint1_linear_16_1242)" strokeWidth="0.72973" />
        <path
          d="M397 4.15258e-05L872 0"
          stroke="url(#paint2_linear_16_1242)"
          strokeWidth="0.72973"
        />
        <g filter="url(#filter2_i_16_1242)">
          <path
            d="M615.029 -18H660.971L665 -3.77778L655.5 14H621.5L611 -3.77778L615.029 -18Z"
            fill="#080808"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_16_1242"
          x="301.297"
          y="-46.7027"
          width="666.405"
          height="109.441"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="23.3513" result="effect1_foregroundBlur_16_1242" />
        </filter>
        <filter
          id="filter1_f_16_1242"
          x="564.892"
          y="-44.5134"
          width="145.216"
          height="78.8109"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="14.5946" result="effect1_foregroundBlur_16_1242" />
        </filter>
        <filter
          id="filter2_i_16_1242"
          x="611"
          y="-18"
          width="54"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.72973" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.372549 0 0 0 0 0.423529 0 0 0 0 0.529412 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_16_1242" />
        </filter>
        <linearGradient
          id="paint0_linear_16_1242"
          x1="614"
          y1="1.00488"
          x2="138"
          y2="1.00488"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363D4F" />
          <stop offset="0.0001" stopColor="#5F8BFF" />
          <stop offset="0.372199" stopColor="#82A5FF" stopOpacity="0.627801" />
          <stop offset="1" stopColor="#BDD0FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16_1242"
          x1="614"
          y1="1.00488"
          x2="138"
          y2="1.00488"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363D4F" />
          <stop offset="0.0001" stopColor="#313744" />
          <stop offset="0.494792" stopColor="#2E343F" stopOpacity="0.53" />
          <stop offset="1" stopColor="#3A4251" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_16_1242"
          x1="397"
          y1="-1.00484"
          x2="872"
          y2="-1.00488"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363D4F" />
          <stop offset="0.0001" stopColor="#313744" />
          <stop offset="0.494792" stopColor="#2E343F" stopOpacity="0.53" />
          <stop offset="1" stopColor="#3A4251" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_16_1242">
          <rect width="1268" height="54" rx="5.83784" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
