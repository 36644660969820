import React, { useEffect, useRef } from "react";
import useJanus from "../../../hooks/useJanus";
import { useSelector } from "react-redux";
import { ENUM_CHAT_MODE, ENUM_STATUS_WINDOW } from "../../../store/chatSlice";
import ControlsTop from "../../mui/ControlsTop";
import { useLocalStorage } from "usehooks-ts";
import IconCameraOff2 from "../../../images/jsIcons/IconCameraOff2";
import { useTranslation } from "react-i18next";

export default function MyVideo({ myStream, setMyStream }) {
  const { offerStream } = useJanus({});
  const { t } = useTranslation();

  const [{ videoId }] = useLocalStorage("devices", { videoId: true });

  const status = useSelector((state) => state.chat.statusWindow);
  const chatActiveMode = useSelector((state) => state.chat.chatActiveMode);
  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);

  const videoRef = useRef();

  const onOffClick = () => {
    myStream?.getTracks?.()?.forEach((t) => t?.stop?.());
    setMyStream({});
    offerStream({}, false, false);
  };

  useEffect(() => {
    if (chatActiveMode === ENUM_CHAT_MODE.exclusive && status === ENUM_STATUS_WINDOW.stream) return;

    console.log("effect close", chatActiveMode, status);

    if (myStream?.getTracks?.()) {
      onOffClick();
    }
  }, [status, chatActiveMode, myStream]);

  useEffect(() => {
    if (!myStream?.id) return;
    const video = videoRef.current;
    if (!video) return;
    video.srcObject = myStream;
    video.onloadedmetadata = () => video.play();
  }, [myStream]);

  return (
    <ControlsTop
      id={"my-video"}
      className={"user-video"}
      onClose={() => {
        console.log("click cross");
        onOffClick();
      }}
      style={{ display: !isFullScreen && myStream?.id ? "block" : "none" }}
      controlsStyle={(hide) => ({
        background: hide ? "#191919" : "transparent",
        position: hide ? "static" : "absolute",
      })}
    >
      {videoId ? (
        <video
          muted
          playsInline
          style={{ maxWidth: "45vw", minWidth: "180px" }}
          autoPlay
          width={280}
          ref={videoRef}
        />
      ) : (
        <div className="my-video__no-camera">
          <IconCameraOff2 />
          <p>{t("Only Audio")}</p>
        </div>
      )}
    </ControlsTop>
  );
}
