import React from "react";
import CusButton from "../../../../mui/CusButton";
import IconCamera from "../../../../../images/jsIcons/IconCamera";
import useLoginAction from "../../../../../hooks/useLoginAction";
import ShownControl from "../../../../mui/ShownControl";

export default function Screenshot({ videoRef }) {
  const { onLoginAction } = useLoginAction();

  const onPhotoClick = () => {
    const imgData = videoRef.current.toDataURL();
    var a = document.createElement("a");
    a.href = imgData;
    a.download = "Screenshot.png";
    a.click();
  };
  return (
    <ShownControl>
      <CusButton
        id={"screenshot"}
        icon={<IconCamera />}
        onClick={() => onLoginAction(onPhotoClick)}
      />
    </ShownControl>
  );
}
