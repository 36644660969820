import React, { useState } from "react";
import ChatSend from "./ChatSend";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useJanus from "../../../hooks/useJanus";
import CusButton from "../../mui/CusButton";

import TypeLoginModal from "../../common/TypeLoginModal";
import usePaidAction from "../../../hooks/usePaidAction";
import { errorToast } from "../../mui/Toaster";

export default function ChatTips() {
  const { onSendMessage, postTips } = useJanus({});
  const { onPaidActionClick, onTipsClick } = usePaidAction();
  const { t } = useTranslation();
  const { nickname, balance } = useSelector((state) => state.user.user);
  const tips = useSelector((state) => state.chat.tipsMenu);
  const [chooseBtn, setChooseBtn] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const onTipsSend = (tip) => postTips({ ...tip, nickname: nickname });

  const onBtnClick = (e, tip) => {
    e?.stopPropagation?.();

    const amount = +tip.value;
    if (!amount) return errorToast(t("No amount"));

    onTipsClick(() => {
      onPaidActionClick(amount, () => onTipsSend({ amount: amount, id: tip.id }));
      onSendMessage(tip.text);
    });
  };

  // при оптимизации инкапсулировать
  const onSendMes = (message, amount, onlyBroadcaster) => {
    onPaidActionClick(amount.value, () => {
      if (message) onSendMessage(message, onlyBroadcaster);
      if (amount) onTipsSend(amount);
    });
  };

  return (
    <>
      <div className="chat__tips">
        {!!tips.length && (
          <div className="chat__tips__title">
            <span className="chat__tips__title__actmty">{t("ACTION")}</span>
            <span className="chat__tips__title__tokens">{t("TOKENS")}</span>
          </div>
        )}
        {!!tips.length && (
          <div className="chat__tips__content">
            {tips.map((tip) => (
              <div
                key={tip.id}
                className={`chat__tips__item ${chooseBtn === tip.id ? "relative" : ""}`}
                onMouseEnter={() => setChooseBtn(tip.id)}
                onMouseLeave={() => setChooseBtn(false)}
              >
                <span className="chat__tips__item__actmty">{t(tip.text)}</span>
                {chooseBtn === tip.id ? (
                  <CusButton
                    color="red"
                    text={
                      balance < tip.value
                        ? "Buy tokens"
                        : `${t("Send _ tk", { amount: tip.value })}`
                    }
                    onClick={(e) => onBtnClick(e, tip)}
                  />
                ) : (
                  <span className="chat__tips__item__tokens">{tip.value}</span>
                )}
              </div>
            ))}
          </div>
        )}
        <div className="chat__tips__send">
          <ChatSend sendPlug onSendMes={onSendMes} />
        </div>
      </div>
      <TypeLoginModal isOpen={!!openLoginModal} setOpen={setOpenLoginModal} />
    </>
  );
}
