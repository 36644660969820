import React from "react";

export default function AboutContentEn() {
  return (
    <>
      <h2>About RichCams</h2>
      <p>
        RichCams are a free online community where you can watch amazing amateur models perform
        live. It is 100% free providing instant access to everybody! Our goal is to provide a good
        time for members and models as well
      </p>

      <ul>
        <li>Merchant Name: RichCams</li>
        <li>City: Tallinn</li>
        <li>State/Province: Harju maakond</li>
        <li>Country: Estonia</li>
        <li>
          Contact Info: <a href="mailto:support@richcams.com">support@richcams.com</a>
        </li>
      </ul>
    </>
  );
}
