import React from "react";
import Offline from "./Offline";
import { useSelector } from "react-redux";
import { ENUM_STATUS_WINDOW } from "../../../store/chatSlice";
import ConnectionLost from "./ConnectionLost";
import Over from "./Over";
import CameraOff from "./CameraOff";
import PrivateEnd from "./PrivateEnd";
import Private from "./Private";
import ExclusivePrivate from "./ExclusivePrivate";
import GroupPlug from "./GroupPlug";
import Pause from "./Pause";

export default function UnavailableModal() {
  const avatar = useSelector((state) => state.chat.broadcaster.imgSource) || "";
  const roomId = useSelector((state) => state.chat.broadcaster.id);

  const preview = useSelector((state) => state.chat.broadcaster.preview);
  const status = useSelector((state) => state.chat.statusWindow);

  const componentByStatus = {};

  componentByStatus[ENUM_STATUS_WINDOW.cameraOff] = <CameraOff />;
  componentByStatus[ENUM_STATUS_WINDOW.someoneExclusivePrivate] = (
    <ExclusivePrivate id={roomId} avatar={avatar} />
  );
  componentByStatus[ENUM_STATUS_WINDOW.loading] = <></>;
  componentByStatus[ENUM_STATUS_WINDOW.over] = <Over />;
  componentByStatus[ENUM_STATUS_WINDOW.noConnect] = <ConnectionLost />;
  componentByStatus[ENUM_STATUS_WINDOW.offline] = <Offline id={roomId} avatar={avatar} />;
  componentByStatus[ENUM_STATUS_WINDOW.someonePrivate] = <Private />;
  componentByStatus[ENUM_STATUS_WINDOW.endPrivate] = <PrivateEnd />;
  componentByStatus[ENUM_STATUS_WINDOW.group] = <GroupPlug />;
  componentByStatus[ENUM_STATUS_WINDOW.pause] = <Pause />;

  return (
    <div className="chat__model__offline">
      <img src={preview} className="chat__model__offline__bg" />
      {componentByStatus[status]}
    </div>
  );
}
