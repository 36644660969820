import React from "react";
import ImgAvatar, { ENUM_PLUG } from "../../mui/ImgAvatar";
import IconModelOffline from "../../../images/jsIcons/IconModelOffline";
import { useTranslation } from "react-i18next";

export default function Offline({ id, avatar }) {
  const { t } = useTranslation();

  return (
    <div className="chat__model__offline__center">
      <ImgAvatar
        id={id}
        plug={ENUM_PLUG.rabbit}
        avatarProps={avatar}
        className="chat__model__offline__center__circle"
      />
      <h3 className="chat__model__offline__center__title">
        <IconModelOffline />
        {t("The model is currently offline")}
      </h3>
    </div>
  );
}
