import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import ImgAvatar, { ENUM_PLUG } from "../mui/ImgAvatar";
import CloseModal from "../mui/CloseModal";
import CusButton from "../mui/CusButton";
import IconCrown from "../../images/jsIcons/IconCrown";
import IconDiamond from "../../images/jsIcons/IconDiamond";
import IconCamera from "../../images/jsIcons/IconCamera";
import IconPrivate from "../../images/jsIcons/IconPrivate";
import OnlyMobileСarousel from "../mui/OnlyMobileСarousel";
import useJanus from "../../hooks/useJanus";
import NotifyModal from "../common/NotifyModal";
import { useEventListener } from "usehooks-ts";
import { errorToast, successToast } from "../mui/Toaster";
import { ENUM_MODALS_VALUE, setModals } from "../../store/commonSlice";
import { ENUM_CHAT_MODE } from "../../store/chatSlice";
import CusCheckbox from "../mui/CusCheckbox";
import CameraSetting from "./CameraSetting";
import { useLocalStorage } from "usehooks-ts";
import QuestionPopup from "../mui/QuestionPopup";

const ENUM_CARD_ID = { private: "private", exclusive: "exclusive" };

export default function ModalPrivate({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const { changeChatMode, cancelPrivate, getSetting } = useJanus({});
  const dispatch = useDispatch();

  const balance = useSelector((state) => state.user.user.balance);
  const broadCaster = useSelector((state) => state.chat.broadcaster);
  const privateBilling = useSelector((state) => state.chat.settingInfo["private-chat"]?.billing);
  const exclusiveBilling = useSelector(
    (state) => state.chat.settingInfo["exclusive-chat"]?.billing
  );
  const exclusiveDescribe = useSelector(
    (state) => state.chat.settingInfo["exclusive-chat"]?.["describe-service"]
  );
  const privateDescribe = useSelector(
    (state) => state.chat.settingInfo["private-chat"]?.["describe-service"]
  );

  const [openCamera, setOpenCamera] = useState();

  const getInfoPrivate = (billing) => {
    const min = +(billing?.time?.value / 60).toFixed(1);

    return {
      min: min,
      price: billing?.price?.value * min,
      perPrice: billing?.price?.value,
    };
  };

  const privateInfo = useMemo(() => getInfoPrivate(privateBilling), [privateBilling]);
  const exclusiveInfo = useMemo(() => getInfoPrivate(exclusiveBilling), [exclusiveBilling]);

  const [autostart, setAutostart] = useLocalStorage("autostart", true);
  const [currentId, setCurrentId] = useState(ENUM_CARD_ID.private);
  const [openNotify, setOpenNotify] = useState(false);

  const iconById = {
    [ENUM_CARD_ID.private]: <IconCrown />,
    [ENUM_CARD_ID.exclusive]: <IconDiamond />,
  };

  const cardIdByChatMode = {
    [ENUM_CARD_ID.private]: ENUM_CHAT_MODE.private,
    [ENUM_CARD_ID.exclusive]: ENUM_CHAT_MODE.exclusive,
  };

  useEventListener("response.switch", (e) => {
    closeModal();
    setOpenNotify(false);
    const request = e.detail.request;

    if (request.cancel) return;
    if (request.accepted) successToast(t("The private show has begun."));
    if (request.rejected) errorToast(t("The model rejected the Private Show."));
  });

  useEffect(() => {
    if (isOpen) getSetting();
  }, [isOpen]);

  const onBtnClick = async (id) => {
    const depositAndClose = () => {
      dispatch(setModals({ name: ENUM_MODALS_VALUE.deposit, value: true }));
      closeModal();
    };

    if (id === ENUM_CARD_ID.exclusive) {
      if (balance < privateInfo.price) return depositAndClose();
    }
    if (id === ENUM_CARD_ID.private) {
      if (balance < privateInfo.price) return depositAndClose();
    }

    changeChatMode(cardIdByChatMode[id])
      .then((e) => {
        if (!e) return;

        setCurrentId(id);
        closeModal();
        setOpenNotify(true);
      })
      .catch(errorToast);
  };

  const onCancel = async () => {
    await cancelPrivate().then((e) => {
      console.log(e);
      closeModal();
      setOpenNotify(false);
    });
  };

  const cards = [
    {
      id: ENUM_CARD_ID.exclusive,
      title: "Private Exclusive",
      icon: <IconDiamond />,
      checkbox: "Autostart of my camera and microphone",
      buttonText: `${t("Start")} ${exclusiveInfo.perPrice} ${t("tk")}/${t("min")}`,
      timeText: `${t("Minimum")} ${exclusiveInfo.min} ${t("min")}`,
      popupText:
        exclusiveInfo.min > 1 &&
        t(
          "If you stop the show before minutes are up, you’ll be charged for full minutes. If the model stops the show earlier, you'll only pay for the exact time spent in the show.",
          { min: exclusiveInfo.min }
        ),
      advantage: [
        { icon: <IconCamera />, text: "Video call (Cam2Cam) availavle", color: "red" },
        { icon: <IconPrivate />, text: "Nobody can spy on show", color: "white" },
      ],
      performancesText: "",
      performances: exclusiveDescribe,
    },
    {
      id: ENUM_CARD_ID.private,
      title: "Private",
      icon: <IconCrown />,
      buttonText: `${t("Start")} ${privateInfo.perPrice} ${t("tk")}/${t("min")}`,
      timeText: `${t("Minimum")} ${privateInfo.min} ${t("min")}`,
      popupText: "",
      // advantage: [],
      performancesText: "",
      performances: privateDescribe,
    },
  ];

  return (
    <>
      <Modal
        ariaHideApp={false}
        className={`private-modal ${t("currentLang")}`}
        isOpen={isOpen}
        onRequestClose={closeModal}
      >
        <div className="private-modal__header">
          <h2 className="private-modal__title">{t("Start a Private show with")}</h2>
          <ImgAvatar
            id={broadCaster.id}
            plug={ENUM_PLUG.rabbit}
            avatarProps={broadCaster.imgSource}
          />
          <span className="private-modal__name">@{broadCaster.name}</span>
          <CloseModal close={closeModal} />
        </div>
        <OnlyMobileСarousel className="private-modal__content">
          {cards.map((card) => (
            <div key={card.id} className={`private-modal__card ${card.id}`}>
              <h3>{t(card.title)}</h3>
              <div className="private-modal__card__icon">{card.icon}</div>
              <CusButton onClick={() => onBtnClick(card.id)} color="red" text={card.buttonText} />
              <span className="private-modal__card__time">
                {card.timeText}{" "}
                <QuestionPopup className={"private-modal__card"} popupText={card.popupText} />
              </span>

              <ul className="private-modal__card__advantage">
                {card?.advantage && (
                  <>
                    <li className={"red"}>
                      <IconCamera />
                      <span>{t("Video call (Cam2Cam) availavle")}</span>
                    </li>
                    {card?.checkbox && (
                      <CusCheckbox
                        checked={autostart}
                        onChange={(e) => setAutostart(e.target.checked)}
                        id={"autostart"}
                        title={card?.checkbox}
                      />
                    )}
                    <li className={"white"}>
                      <IconPrivate />
                      <span>{t("Nobody can spy on show")}</span>
                    </li>
                    <button onClick={() => setOpenCamera(true)} className="camera-setting">
                      {t("My camera and microphone")}
                    </button>
                  </>
                )}
              </ul>

              {!!card.performances && (
                <div className="private-modal__card__enjoy">
                  <div className="private-modal__card__enjoy__content">
                    <span className="private-modal__card__enjoy__item">{t(card.performances)}</span>
                  </div>
                </div>
              )}
            </div>
          ))}
        </OnlyMobileСarousel>
        <p className="private-modal__p">
          {t("The model will be able to chat only with you and give you 100% attention.")}
        </p>
      </Modal>
      <NotifyModal
        title="Waiting for the private show to start"
        text="The model must confirm the start of the private show.  Please wait, this won't take long."
        isOpen={openNotify}
        icon={iconById[currentId]}
        cancel={{ text: "Cancel", onClick: onCancel }}
        closeModal={() => setOpenNotify(false)}
      />
      <CameraSetting
        buttonText={"Save"}
        isOpen={openCamera}
        closeModal={() => setOpenCamera(false)}
        // onSaveClick={onSaveClick}
      />
    </>
  );
}
