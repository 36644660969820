import React from "react";
import FooterInstallPlug from "./FooterInstallPlug";
import Logo from "../mui/Logo";
import ChoseLang from "../mui/ChoseLang";

export default function HomeUpperFooter() {
  return (
    <div className="home__upper__footer">
      <div className="home__upper__footer__container">
        <div className="home__upper__footer__content">
          <div
            className="home__upper__footer__content__logo"
            // style={{ color: "#FFF", fontSize: "21.158px", fontStyle: "normal", fontWeight: 700 }}
          >
            <Logo />
          </div>

          {/* <FooterInstallPlug /> */}
          <ChoseLang />
        </div>
        <div className="home__upper__footer__bottom-line" />
      </div>
    </div>
  );
}
