import React, { useEffect, useRef, useState } from "react";
import CusInput from "../mui/CusInput";
import CusDatePicker from "../mui/CusDatePicker";
import IconTg from "../../images/jsIcons/IconTg";
import CusForm from "../mui/CusForm";
import CusButton from "../mui/CusButton";
import { useSelector } from "react-redux";
import { API_PROFILE } from "../../api/profile";
import { API_LOGIN } from "../../api/login";
import { useTranslation } from "react-i18next";
import { errorToast, successToast } from "../mui/Toaster";
import { catchMessageServer } from "../../api/main";
import { TEST_MODE } from "../common/consts";
import SelectCountries from "../mui/select_countries/SelectContries";
import dayjs from "dayjs";
import { subtractYears } from "../../utils/utils";

export default function ContentSetting() {
  const { t } = useTranslation();
  const userState = useSelector((state) => state.user.user);

  const [disabledBtn, setDisabledBtn] = useState(false);
  const [pasError, setPasError] = useState(["", ""]);
  const [user, setUser] = useState(userState);
  const [datePicker, setDatePicker] = useState();
  const [loading, setLoading] = useState(false);
  const [promiseLoader, setPromiseLoader] = useState(false);

  const fieldRef = useRef({});
  const passwordFieldRef = useRef({});

  const writeInRef = (id, e) => {
    const value = e.target.value;
    console.log(id, value);
    if (id === "telegram") {
      if (value === "@") e.target.value = value.replace("@", "");
    }
    fieldRef.current[id] = e.target.value || "";
  };

  useEffect(() => {
    API_PROFILE.personal
      .getInfo()
      .then((e) => {
        const user = e.data.info;
        setUser((prev) => ({ ...user, ...prev }));
        setDatePicker(user.birthday?.split("T")[0]);
      })
      .catch((e) => console.log(e));

    if (TEST_MODE) {
      const user = {
        birthday: "2006-01-13T00:00:00+00:00",
      };
      setUser((prev) => ({ ...user, ...prev }));
      setDatePicker(user.birthday?.split("T")[0]);
    }
  }, []);

  const changePassword = async () => {
    const passwordField = passwordFieldRef.current;

    // если поля пароля пустые то и нет ошибок
    if (!passwordField.previous && !passwordField.password) return;

    // не заполнено поле старого пароля
    if (!passwordField.previous) {
      setPasError(["Enter old password", ""]);
      return;
    }
    // не заполнено поле нового пароля
    if (!passwordField.password) {
      setPasError(["", "Enter password"]);
      return;
    }

    if (passwordField.previous === passwordField.password)
      errorToast(t("The new password is the same as the current one"));

    console.log("passwordField", passwordField);
    const fields = { password: passwordField.password, current: passwordField.previous };

    console.log("fields", fields);

    setLoading(true);
    await API_LOGIN.changePas(fields)
      .then((e) => {
        if (!e.data.ok) {
          errorToast(e.data.message);
        } else {
          successToast(e.data.message);
        }
      })
      .catch((e) => errorToast(e.data.message))
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeEmail = async (email) => {
    console.log("email", email);
    await API_LOGIN.changeEmail({ email: email })
      .then((e) => {
        successToast(e.data.message);
        console.log(e);
      })
      .catch(catchMessageServer);
  };

  const onFormSubmit = async () => {
    setPromiseLoader(true);
    setDisabledBtn(true);
    setLoading(true);

    console.log("fieldRef.current", fieldRef.current);

    await API_PROFILE.personal
      .setInfo(fieldRef.current)
      .then((e) => {
        console.log(e);
        successToast(t("information has been changed"));
      })
      .catch((e) => {
        console.log(e);
        errorToast(t("An error has occurred"));
      })
      .finally(() => {
        setDisabledBtn(false);
        setLoading(false);
      });

    await changePassword();

    if (fieldRef.current.email) {
      await onChangeEmail(fieldRef.current.email);
    }
    setPromiseLoader(false);
  };

  return (
    <CusForm className="setting__content" onSubmit={onFormSubmit}>
      <h4>{t("Personal information")}</h4>
      <div className="setting__common">
        <CusInput
          text={"Nickname"}
          defaultValue={user.nickname}
          onChange={(e) => writeInRef("nickname", e)}
          onKeyUp={(e) => {
            if (e.keyCode !== 8) return;
            writeInRef("telegram", e);
          }}
        />
        <CusInput
          text={"Email"}
          defaultValue={user.email}
          onChange={(e) => writeInRef("email", e)}
          onKeyUp={(e) => {
            if (e.keyCode !== 8) return;
            writeInRef("telegram", e);
          }}
        />

        <SelectCountries
          className="country"
          label="Country"
          defaultValue={user.country}
          placeholder={"Choose the country"}
          onChange={(value) => (fieldRef.current.country = value)}
        />

        <CusInput
          text={"Telegram account"}
          iconJSX={<IconTg />}
          plugText="@"
          placeholder={""}
          defaultValue={user.telegram?.[0] === "@" ? user.telegram.replace("@", "") : user.telegram}
          onChange={(e) => writeInRef("telegram", e)}
          onKeyUp={(e) => {
            if (e.keyCode !== 8) return;
            writeInRef("telegram", e);
          }}
        />
        <CusDatePicker
          label={"Date of Birth"}
          setDate={(value) => {
            fieldRef.current.birthday = value;
            setDatePicker(value);
          }}
          defaultValue={dayjs(subtractYears(18))}
          value={datePicker}
        />
      </div>

      {/* <SettingDocuments fieldRef={fieldRef} /> */}

      {/* setting__password */}
      <div className="setting__password">
        <h5 className="setting__password__title">{t("Change password")}</h5>
        <div className="setting__password__fields">
          <CusInput
            type={"password"}
            dataType={"password"}
            minLength={8}
            placeholder={"Old Password"}
            text={"Old Password"}
            onChange={(e) => (passwordFieldRef.current.previous = e.target.value)}
            errorWarning={pasError[0]}
          />
          <CusInput
            type={"password"}
            dataType={"password"}
            minLength={8}
            placeholder={"New Password"}
            text={"New Password"}
            onChange={(e) => (passwordFieldRef.current.password = e.target.value)}
            errorWarning={pasError[1]}
          />
        </div>
        <CusButton
          loading={loading}
          disabled={disabledBtn}
          type="submit"
          color={"red"}
          text={"Save"}
          fullWidth
          promiseLoader={promiseLoader}
        />
      </div>
    </CusForm>
  );
}
