import React, { useState } from "react";
import CusButton from "../mui/CusButton";
import BannerModalRegister from "../common/BannerModalRegister";
import { useTranslation } from "react-i18next";

export default function MenuForFreeBanner() {
  const [openRegBanner, setOpenRegBanner] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="free-banner">
        <div className="free-banner__content">
          <h4 className="free-banner__title">{t("For free")}</h4>
          <p className="free-banner__p">{t("Register and get 50 tokens")}</p>
          <CusButton
            onClick={() => setOpenRegBanner(true)}
            className="free-banner__button custom-button__red"
            text={"Join Now"}
          />
        </div>
      </div>

      <BannerModalRegister isOpen={openRegBanner} closeModal={() => setOpenRegBanner(false)} />
    </>
  );
}
