import React from "react";

export default function IconModelOffline() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M18.6116 4.31152H11.3866C10.8866 4.31152 10.4866 3.91152 10.4866 3.41152C10.4866 2.91152 10.8866 2.49902 11.3866 2.49902H18.6116C19.1116 2.49902 19.5116 2.89902 19.5116 3.39902C19.5116 3.89902 19.1116 4.31152 18.6116 4.31152Z"
        fill="#161616"
      />
      <path
        d="M18.6116 4.31152H11.3866C10.8866 4.31152 10.4866 3.91152 10.4866 3.41152C10.4866 2.91152 10.8866 2.49902 11.3866 2.49902H18.6116C19.1116 2.49902 19.5116 2.89902 19.5116 3.39902C19.5116 3.89902 19.1116 4.31152 18.6116 4.31152Z"
        fill="url(#paint0_linear_740_10171)"
      />
      <path
        d="M17.4994 24.9645V21.2895C17.4994 19.027 19.0244 17.502 21.2869 17.502H24.9619C25.2494 17.502 25.5244 17.527 25.7869 17.577C25.8119 17.277 25.8369 16.977 25.8369 16.6645C25.8369 10.677 20.9744 5.81445 14.9994 5.81445C9.02436 5.81445 4.16187 10.677 4.16187 16.6645C4.16187 22.6395 9.02436 27.502 14.9994 27.502C16.0619 27.502 17.0744 27.327 18.0494 27.052C17.6994 26.4645 17.4994 25.7645 17.4994 24.9645ZM15.9369 16.252C15.9369 16.7645 15.5119 17.1895 14.9994 17.1895C14.4869 17.1895 14.0619 16.7645 14.0619 16.252V10.002C14.0619 9.48945 14.4869 9.06445 14.9994 9.06445C15.5119 9.06445 15.9369 9.48945 15.9369 10.002V16.252Z"
        fill="#161616"
      />
      <path
        d="M17.4994 24.9645V21.2895C17.4994 19.027 19.0244 17.502 21.2869 17.502H24.9619C25.2494 17.502 25.5244 17.527 25.7869 17.577C25.8119 17.277 25.8369 16.977 25.8369 16.6645C25.8369 10.677 20.9744 5.81445 14.9994 5.81445C9.02436 5.81445 4.16187 10.677 4.16187 16.6645C4.16187 22.6395 9.02436 27.502 14.9994 27.502C16.0619 27.502 17.0744 27.327 18.0494 27.052C17.6994 26.4645 17.4994 25.7645 17.4994 24.9645ZM15.9369 16.252C15.9369 16.7645 15.5119 17.1895 14.9994 17.1895C14.4869 17.1895 14.0619 16.7645 14.0619 16.252V10.002C14.0619 9.48945 14.4869 9.06445 14.9994 9.06445C15.5119 9.06445 15.9369 9.48945 15.9369 10.002V16.252Z"
        fill="url(#paint1_linear_740_10171)"
      />
      <path
        d="M24.9625 18.75H21.3C19.7 18.75 18.75 19.7 18.75 21.2875V24.9625C18.75 26.55 19.7 27.5 21.3 27.5H24.9625C26.55 27.5 27.5 26.55 27.5 24.9625V21.2875C27.5 19.7 26.55 18.75 24.9625 18.75ZM22.4 25.075C22.4 25.475 22.075 25.8 21.6625 25.8C21.2625 25.8 20.9375 25.475 20.9375 25.075V21.175C20.9375 20.775 21.2625 20.45 21.6625 20.45C22.075 20.45 22.4 20.775 22.4 21.175V25.075ZM25.3125 25.075C25.3125 25.475 24.9875 25.8 24.5875 25.8C24.1875 25.8 23.85 25.475 23.85 25.075V21.175C23.85 20.775 24.1875 20.45 24.5875 20.45C24.9875 20.45 25.3125 20.775 25.3125 21.175V25.075Z"
        fill="#161616"
      />
      <path
        d="M24.9625 18.75H21.3C19.7 18.75 18.75 19.7 18.75 21.2875V24.9625C18.75 26.55 19.7 27.5 21.3 27.5H24.9625C26.55 27.5 27.5 26.55 27.5 24.9625V21.2875C27.5 19.7 26.55 18.75 24.9625 18.75ZM22.4 25.075C22.4 25.475 22.075 25.8 21.6625 25.8C21.2625 25.8 20.9375 25.475 20.9375 25.075V21.175C20.9375 20.775 21.2625 20.45 21.6625 20.45C22.075 20.45 22.4 20.775 22.4 21.175V25.075ZM25.3125 25.075C25.3125 25.475 24.9875 25.8 24.5875 25.8C24.1875 25.8 23.85 25.475 23.85 25.075V21.175C23.85 20.775 24.1875 20.45 24.5875 20.45C24.9875 20.45 25.3125 20.775 25.3125 21.175V25.075Z"
        fill="url(#paint2_linear_740_10171)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_740_10171"
          x1="14.9991"
          y1="2.49902"
          x2="14.9991"
          y2="4.46257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_740_10171"
          x1="14.9994"
          y1="5.81445"
          x2="14.9994"
          y2="29.3092"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_740_10171"
          x1="23.125"
          y1="18.75"
          x2="23.125"
          y2="28.2292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
