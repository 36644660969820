import React, { useEffect, useRef, useState } from "react";
// import imgEye from "../../images/icon/eye.svg";
// import imgEyeOff from "../../images/icon/eye-off.svg";
import { useTranslation } from "react-i18next";
import IconEye from "../../images/jsIcons/IconEye";
import IconEyeHide from "../../images/jsIcons/IconEyeHide";
import { useEventListener } from "usehooks-ts";
import IconCopy from "../../images/jsIcons/IconCopy";
import { successToast } from "./Toaster";

export default function CusInput({
  text,
  placeholder,
  type,
  icon,
  grayWarning,
  minLength,
  dataType,
  required,
  name,
  defaultValue,
  iconJSX,
  onChange,
  errorWarning,
  readOnly,
  isCopy,
  plugText,
  onKeyDown,
  onKeyUp,
}) {
  const { t } = useTranslation();
  const [showPas, setShowPas] = useState(true);
  const [error, setError] = useState("");
  const typeInput = type === "password" && showPas ? "text" : type;
  const inputRef = useRef();

  useEventListener("inputError", (e) => {
    const detail = e.detail;
    if (detail.target === inputRef.current) setError(detail.message);
  });

  useEffect(() => {
    setError(errorWarning);
  }, [errorWarning]);

  return (
    <div className={`custom-input  ${error ? "error-container" : ""}`}>
      <span className="custom-input__title">
        {t(text)}
        {required && <span className="required"> *</span>}
      </span>
      <div
        className={`custom-input__container ${error ? "error" : ""} ${
          plugText ? "plug-text-" + plugText.length : ""
        } `}
      >
        {!!icon && <img src={icon} alt="" />}
        <span className="plug-text">{plugText}</span>
        <input
          defaultValue={defaultValue}
          ref={inputRef}
          readOnly={readOnly}
          onChange={(e) => {
            setError("");
            onChange?.(e);
          }}
          data-required={required}
          data-minlength={minLength}
          data-type={dataType}
          type={typeInput}
          name={name}
          placeholder={t(placeholder || text)}
          className={`custom-input__input ${type} ${iconJSX ? "icon" : ""}`}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
        {!!iconJSX && (
          <button type="button" className="password__hide__btn">
            {iconJSX}
          </button>
        )}

        {type === "password" && (
          <button
            type="button"
            className="password__hide__btn"
            onClick={(e) => {
              e.preventDefault();
              setShowPas((prev) => !prev);
            }}
          >
            {showPas ? <IconEye /> : <IconEyeHide />}
          </button>
        )}

        {isCopy && (
          <button
            type="button"
            className="password__hide__btn"
            onClick={() => {
              navigator.clipboard.writeText(defaultValue);
              successToast(t("successfully copied to clipboard"));
            }}
          >
            <IconCopy />
          </button>
        )}
      </div>
      {!!error && <span className="text-error">{t(error)}</span>}
      {grayWarning && <span className="custom-input__gray-warning">{t(grayWarning)}</span>}
    </div>
  );
}
