import React from "react";

import Eye from "./components/Eye";
import Like from "./components/Like";
import SendTips from "./components/SendTips";

import PrivateButton from "./components/PrivateButton";
import ShownControl from "../../../mui/ShownControl";
import SocialIcons from "./components/social_icons/SocialIcons";

export default function ControlsBottom() {
  return (
    <ShownControl>
      <div className="chat__window__controls__bottom">
        <div className="chat__window__controls__bottom__up">
          <SocialIcons />
        </div>
        <Like />
        <Eye />

        <div className="controls__buttons">
          <PrivateButton />
          <SendTips />
        </div>
      </div>
    </ShownControl>
  );
}
