import React from "react";

export default function IconPremiumCrown() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <g clipPath="url(#clip0_1154_15732)">
        <path
          d="M9.69102 0.902267C9.19377 0.902309 8.79105 1.3051 8.79109 1.80234C8.79113 2.29959 9.19393 2.70231 9.69117 2.70227C10.1884 2.70222 10.5911 2.29943 10.5911 1.80219C10.591 1.30495 10.1883 0.902224 9.69102 0.902267Z"
          fill="white"
        />
        <path
          d="M16.8922 15.2996L2.49219 15.3008L2.49234 17.1008L16.8923 17.0996L16.8922 15.2996Z"
          fill="white"
        />
        <path
          d="M16.0322 6.46608C16.5858 6.78104 16.9616 7.36825 16.9616 8.05001C16.9617 9.06027 16.1428 9.87935 15.1325 9.87944C14.4531 9.87949 13.8793 9.49704 13.5642 8.94582C13.9242 8.82881 14.1897 8.50025 14.1897 8.10202C14.1896 7.60478 13.7868 7.20205 13.2896 7.20209C12.7924 7.20214 12.3896 7.60493 12.3897 8.10217C12.3897 8.48243 12.6282 8.80413 12.9635 8.93686C12.7835 8.99761 12.5923 9.03366 12.3898 9.03368C11.3795 9.03377 10.5604 8.21483 10.5603 7.20457C10.5603 6.33832 11.1632 5.61827 11.9687 5.42695L9.69141 2.70236L7.41237 5.42505C8.22015 5.61623 8.82094 6.33618 8.82102 7.20243C8.8211 8.21269 8.00216 9.03177 6.9919 9.03186C6.79164 9.03187 6.59815 8.9959 6.41814 8.93514C6.75339 8.80459 6.99186 8.48285 6.99182 8.10035C6.99178 7.6031 6.58899 7.20038 6.09175 7.20042C5.59451 7.20047 5.19178 7.60326 5.19182 8.1005C5.19186 8.50102 5.4574 8.82724 5.81741 8.9442C5.50246 9.49547 4.92874 9.87802 4.24926 9.87808C3.239 9.87817 2.41992 9.05923 2.41984 8.04897C2.41978 7.3672 2.79775 6.77993 3.34897 6.46488L0.691637 5.40313L2.49233 13.503L16.8923 13.5017L18.6916 5.40159L16.0322 6.46608ZM9.69225 12.6024L7.88762 10.798L9.69194 8.99339L11.4966 10.7977L9.69225 12.6024Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1154_15732">
          <rect width="18" height="18" fill="white" transform="translate(0.691406 0.00195312)" />
        </clipPath>
      </defs>
    </svg>
  );
}
