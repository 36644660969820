import React from "react";
import { LOGIN } from "./consts";
import SignUpModal from "./SignUpModal";
import BannerModalRegister from "./BannerModalRegister";
import { useLocalStorage } from "usehooks-ts";

export default function TypeLoginModal({ isOpen, setOpen }) {
  const [haveAccount] = useLocalStorage("haveAccount", false);

  return haveAccount ? (
    <SignUpModal isOpen={!!isOpen} setOpen={setOpen} type={LOGIN} />
  ) : (
    <BannerModalRegister isOpen={!!isOpen} closeModal={() => setOpen(false)} />
  );
}
