import React from "react";

export default function IconTips() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.333 5.05253C8.38532 5.05253 10.666 3.7188 10.666 2.52624C10.666 1.33378 8.38527 0 5.333 0C2.28073 0 0 1.33378 0 2.52624C0 3.7188 2.28068 5.05253 5.333 5.05253Z"
        fill="#767676"
      />
      <path
        d="M5.333 15.1581C5.77851 15.1581 6.2073 15.1294 6.61517 15.0773C6.27136 14.5889 5.98704 14.0469 5.77404 13.4649C5.62768 13.4703 5.48082 13.4741 5.33295 13.4741C3.58886 13.4741 1.93718 13.0921 0.682284 12.3985C0.435196 12.262 0.207819 12.1153 0 11.96V12.6318C0 13.8243 2.28068 15.1581 5.333 15.1581Z"
        fill="#767676"
      />
      <path
        d="M5.33305 11.7889C5.34869 11.7889 5.36409 11.7887 5.37968 11.7886C5.34844 11.5126 5.33213 11.2314 5.33213 10.9462C5.33213 10.6611 5.34839 10.3801 5.37968 10.1043C5.36409 10.1043 5.34864 10.1049 5.33305 10.1049C3.58896 10.1049 1.93733 9.72291 0.682385 9.02935C0.435247 8.89279 0.207819 8.74607 0 8.59082V9.26265C5.07992e-05 10.4552 2.28073 11.7889 5.33305 11.7889Z"
        fill="#767676"
      />
      <path
        d="M5.33305 8.42121C5.48423 8.42121 5.63337 8.41784 5.78064 8.41144C6.01716 7.77004 6.3404 7.17761 6.7345 6.65221C6.27822 6.7078 5.80929 6.73728 5.33305 6.73728C3.58896 6.73728 1.93733 6.35529 0.682385 5.66173C0.435247 5.52512 0.207819 5.37845 0 5.22314V5.89503C5.07992e-05 7.08754 2.28073 8.42121 5.33305 8.42121Z"
        fill="#767676"
      />
      <path
        d="M7.6193 8.15495C7.28961 8.7028 7.05405 9.32545 6.93818 9.99453C6.88469 10.303 6.85645 10.6212 6.85645 10.9465C6.85645 11.1912 6.8726 11.4317 6.90313 11.6672C6.97816 12.2456 7.14209 12.7925 7.37912 13.2907C7.62783 13.8135 7.95686 14.2826 8.34816 14.6776C9.16136 15.4986 10.2429 16 11.4284 16C13.9493 16 16.0003 13.733 16.0003 10.9465C16.0003 8.16 13.9493 5.89307 11.4284 5.89307C11.1678 5.89307 10.9124 5.91766 10.6635 5.96415C9.3962 6.20105 8.30463 7.01629 7.6193 8.15495Z"
        fill="#767676"
      />
    </svg>
  );
}
