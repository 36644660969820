import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import IconPrivate from "../../../images/jsIcons/IconPrivate";
import CusButton from "../../mui/CusButton";
import IconTg from "../../../images/jsIcons/IconTg";
import { useSelector } from "react-redux";
import ChatSmile from "./ChatSmile";
import { useLocalStorage } from "usehooks-ts";
import useDeviceSize from "../../../hooks/useDeviceSize";
import usePremiumClick from "../../../hooks/usePremiumClick";
import IfThere from "../../mui/IfThere";
import ChatBottomFixed from "./ChatBottomFixed";
import { errorToast, infoToast } from "../../mui/Toaster";
import { ENUM_CHAT_MODE } from "../../../store/chatSlice";
import { TEST_MODE } from "../../common/consts";
import smile from "../../../images/chat/smile.svg";
import usePaidAction from "../../../hooks/usePaidAction";
import TextareaWitchSmile from "../../mui/textarea_witch_smile/TextareaWitchSmile";

export default function ChatSend({ tabFree, onSendMes, sendPlug, placeholder }) {
  const { t } = useTranslation();
  const { isMobile, width, isIos } = useDeviceSize();
  const { onPremiumClick } = usePremiumClick();
  const { onTipsClick } = usePaidAction();

  const tips = useSelector((state) => state.chat.tips);
  const chatActiveMode = useSelector((state) => state.chat.chatActiveMode);
  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);

  const [currentToken, setCurrentToken] = useLocalStorage("lastAmount", tips[0] || { value: 50 });
  const isPrivate = useMemo(
    () => [ENUM_CHAT_MODE.exclusive, ENUM_CHAT_MODE.private].includes(chatActiveMode),
    [chatActiveMode]
  );

  const [value, setValue] = useState("");
  const [openSmilePopup, setOpenSmilePopup] = useState(false);
  const [isAnonymity, setIsAnonymity] = useState(false);

  const TextAreaStartRef = useRef(null);
  const ChatSendRef = useRef(null);
  const TextareaRef = useRef(null);
  const ChatSendSendRef = useRef(null);

  const anonymityClass = isAnonymity ? "anonymity" : "";
  const titleTipsBtn =
    width < 1220 && width > 950
      ? ` ${currentToken.value} ${t("tk")}`
      : `${t("Send")} ${currentToken.value} ${t("tk")}`;

  useEffect(() => {
    setValue(placeholder || "");
  }, [placeholder]);

  useEffect(() => {
    setIsAnonymity(isPrivate);
  }, [isPrivate]);

  const onAnonymityClick = async () => {
    TextareaRef.current.focus();

    if (isPrivate) {
      if (isFullScreen) await document?.exitFullscreen?.();
      errorToast(t("In private, only personal messages are allowed."));
      return;
    }

    onPremiumClick(() => {
      const text = isAnonymity ? "Private messaging disabled." : "Private messaging enabled.";
      setIsAnonymity((prev) => !prev);
      infoToast(t(text));
    });
  };

  // TODO пока реализованно только так что это просто отправка чаевых + отправка сообщения
  const onSend = (e) => {
    e?.stopPropagation?.();
    e?.preventDefault?.();
    TextareaRef.current.focus();

    if (TEST_MODE) return;

    onTipsClick(() => {
      // eslint-disable-next-line no-control-regex
      onSendMes(value.replace(new RegExp("\r?\n", "g"), "<br />"), currentToken, isAnonymity);
      setValue("");
    });
  };

  const handleScrollToTop = () => window.scrollTo(0, 0);

  const isIPhone = useMemo(() => isIos && isMobile, [isIos, isMobile]);

  const onTextAreaFocus = () => {
    if (!isIPhone) return;
    clearTimeout(window.blurTextArea);

    setTimeout(() => {
      handleScrollToTop();
      const root = document.getElementById("root");
      const viewportHeight = visualViewport.height;
      root.style.height = viewportHeight + "px";
      root.style.overflow = "auto";
      root.style.position = "fixed";
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none";
      ChatSendRef.current.classList.add("fixed-ios");
      ChatSendSendRef.current.style.top = `${
        viewportHeight - ChatSendSendRef.current.clientHeight
      }px`;
      ChatSendSendRef.current.style.bottom = "auto";
      TextareaRef.current.focus();
    }, 240);
  };

  const onTextAreaBlur = () => {
    if (!isIPhone) return;

    clearTimeout(window.blurTextArea);
    window.blurTextArea = setTimeout(() => {
      const root = document.getElementById("root");
      root.style.height = "100%";
      root.style.overflow = "auto";
      root.style.position = "static";
      document.body.style.overflow = "auto";
      document.body.style.touchAction = "auto";
      ChatSendRef.current.classList.remove("fixed-ios");
      ChatSendSendRef.current.style.top = "auto";
      ChatSendSendRef.current.style.bottom = "0";

      setTimeout(handleScrollToTop, 40);
    }, 200);
  };

  const curTokenNum = useMemo(() => currentToken.value, [currentToken]);

  const onlyNumber = (num) => {
    const sanitizedNum = num.replace(/[^0-9]/g, "");
    return sanitizedNum.replace(/^0+/, ""); // Remove leading zeros
  };

  return (
    <>
      <div className="chat__send" ref={ChatSendRef}>
        {sendPlug && (
          <div className="chat__send__plug">
            <IfThere something={tips.length > 1}>
              <div className="chat__send__plug__tips">
                {tips.map((el) => (
                  <button
                    key={el.id}
                    onClick={() => setCurrentToken(el)}
                    className={currentToken.value === el.value ? "active" : ""}
                  >
                    {el.value}
                  </button>
                ))}
              </div>
            </IfThere>
            <div className="chat__send__plug__current">
              <label className="chat__send__plug__current__label">{t("Amount of tokens")}</label>
              <input
                step={1}
                min={0}
                className="chat__send__plug__current__value"
                value={curTokenNum}
                onChange={(e) => {
                  const amount = +onlyNumber(e.target.value);
                  setCurrentToken({ value: amount });
                }}
              />
            </div>
          </div>
        )}

        {!tabFree && <label className="chat__send__label">{t("Your comment")}</label>}
        <div
          className="chat__send__send-container"
          onClick={() => {
            TextareaRef?.current?.focus?.();
          }}
        >
          <ChatBottomFixed
            ChatSendSendRef={ChatSendSendRef}
            TextareaRef={TextareaRef}
            ChatSendRef={ChatSendRef}
            className="chat__send__send"
          >
            <button
              id="button-anonymity"
              className={`chat__send__anonymity ${anonymityClass}`}
              onClick={onAnonymityClick}
            >
              <button>
                <IconPrivate />
              </button>
            </button>

            <TextareaWitchSmile
              TextareaRef={TextareaRef}
              rows={1}
              style={{ position: "absolute", opacity: 0 }}
              className="chat__send__write-mes"
              value={value}
              placeholder={t(isMobile ? "Your message" : "Write your message...")}
              onChange={(e) => setValue(e.target.value)}
              onFocus={onTextAreaFocus}
              onBlur={onTextAreaBlur}
              onKeyDown={(e) => {
                if (e.key !== "Enter") return;
                if (!e.ctrlKey) {
                  e.preventDefault();
                  onSend();
                  return;
                }
                setValue((prev) => prev + "\r\n");
                console.log("ctrlKey");
              }}
              onSelectCapture={(e) => {
                TextAreaStartRef.current = e.target.selectionStart;
              }}
            />

            <button
              className="chat__send__btn-smile"
              onClick={(e) => {
                e.stopPropagation();
                TextareaRef.current.focus();
                setOpenSmilePopup((prev) => !prev);
              }}
            >
              <img src={smile} alt="smile" />
              <ChatSmile
                TextareaRef={TextareaRef}
                open={openSmilePopup}
                setOpen={setOpenSmilePopup}
                setValue={setValue}
                TextAreaStartRef={TextAreaStartRef}
              />
            </button>
            {tabFree || isMobile ? (
              <CusButton type="button" color="red" icon={<IconTg />} onClick={onSend} />
            ) : (
              <CusButton color="red" text={titleTipsBtn} className="tips" onClick={onSend} />
            )}
          </ChatBottomFixed>
        </div>
      </div>
    </>
  );
}
