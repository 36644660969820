import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slider } from "@mui/material";
import IconMixedOff from "../../../../../images/jsIcons/IconMixedOff";
import IconMixer from "../../../../../images/jsIcons/IconMixer";
import { setVolume } from "../../../../../store/commonSlice";
import ShownControl from "../../../../mui/ShownControl";

export default function Volume({ audioRef }) {
  const dispatch = useDispatch();
  const volume = useSelector((state) => state.common.controls.volume);

  const [openValue, setOpenValue] = useState(false);
  const [muteValue, setMuteValue] = useState(false);

  const saveVolumeRef = useRef();

  useEffect(() => {
    const curVolume = muteValue ? 0 : volume;
    if (audioRef.current) {
      audioRef.current.volume = curVolume / 100;
    }
  }, [muteValue, volume]);

  useEffect(() => {
    setMuteValue(!volume);
  }, [volume]);

  return (
    <ShownControl>
      <div
        className={`chat__window__controls__mixer-container ${muteValue ? "mute" : ""}`}
        onMouseEnter={() => setOpenValue(true)}
        onMouseLeave={() => setOpenValue(false)}
      >
        <button
          className="chat__window__controls__mixer custom-button"
          onClick={() => {
            if (volume) {
              saveVolumeRef.current = volume;
              dispatch(setVolume(0));
            } else {
              dispatch(setVolume(saveVolumeRef.current || 20));
            }
          }}
        >
          {muteValue ? <IconMixedOff /> : <IconMixer />}
        </button>

        {openValue && (
          <div className="chat__window__controls__value">
            <Slider
              className="custom-vertical-slider"
              orientation="vertical"
              value={volume}
              valueLabelDisplay="off"
              onChange={(e) => dispatch(setVolume(e.target.value))}
            />
          </div>
        )}
      </div>
    </ShownControl>
  );
}
