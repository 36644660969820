import React from "react";
import ImgAvatar from "../../../mui/ImgAvatar";
import { getImgById } from "../../../../utils/utils";
import IconShield from "../../../../images/jsIcons/IconShield";
import useSmile from "../../../../hooks/useSmile";
import ShowContainer from "../../../mui/ShowContainer";

export default function MessageText({ message }) {
  const { replaceEmojiWithSvg } = useSmile();
  const useId = message?.from?.user_id || message?.from?.id;
  const time = message?.created?.split?.("T")?.[1]?.split?.("+")?.[0];
  return (
    message.text && (
      <>
        <div className="chat__free__message__avatar">
          <ImgAvatar id={useId} avatarProps={getImgById(useId)} />
          <ShowContainer condition={message.private}>
            <div className="svg-container">
              <IconShield />
            </div>
          </ShowContainer>
        </div>
        <div className="chat__free__message__content">
          <span className="chat__free__message__content__nick">@{message?.from?.nickname}</span>
          {/* {console.log(message?.text)} {console.log(Smile30())} */}
          <span className="chat__free__message__content__message">
            {replaceEmojiWithSvg(message?.text.replaceAll("\r\n", "<br/>"))}
          </span>
        </div>
        {time && <span className="chat__free__message__time">{time}</span>}
      </>
    )
  );
}
