import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ENUM_CHAT_TAB, ENUM_STATUS_WINDOW } from "../../store/chatSlice";
import ChatFree from "./chat/ChatFree";
import ChatTips from "./chat/ChatTips";
import ChatLovenseToys from "./chat/ChatLovenseToys";
import ChatHeader from "./chat/ChatHeader";
import useDeviceSize from "../../hooks/useDeviceSize";
import ControlsBottomMobile from "./window/controls/ControlsBottomMobile";

export default function BroadcasterChat({ maxHeight }) {
  const chatActiveTab = useSelector((state) => state.chat.chatActiveTab);
  const [hideOther, setHideOther] = useState(false);
  const { isMobile, isSafari } = useDeviceSize();
  const status = useSelector((state) => state.chat.statusWindow);
  const available = useMemo(() => status === ENUM_STATUS_WINDOW.stream, [status]);

  const chatContent = {
    [ENUM_CHAT_TAB.free]: <ChatFree maxHeight={maxHeight} hideOther={hideOther} />,
    [ENUM_CHAT_TAB.tips]: <ChatTips />,
    [ENUM_CHAT_TAB.lovense]: <ChatLovenseToys />,
  };

  const isHide = available ? "" : "hide-chat";

  return (
    <div
      id="chat-broadcaster"
      className={`chat__broadcaster ${isHide}`}
      style={isMobile || isSafari ? undefined : { height: maxHeight, maxHeight: "80svh" }}
    >
      {isMobile ? (
        <ControlsBottomMobile />
      ) : (
        <ChatHeader hideOther={hideOther} setHideOther={setHideOther} />
      )}
      {chatContent[chatActiveTab]}
    </div>
  );
}
