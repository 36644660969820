import React from "react";

export default function IconTimeCircle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
      <path
        d="M9 1.25C7.36831 1.25 5.77325 1.73385 4.41655 2.64038C3.05984 3.5469 2.00242 4.83537 1.378 6.34286C0.753575 7.85035 0.590197 9.50915 0.908525 11.1095C1.22685 12.7098 2.01259 14.1798 3.16637 15.3336C4.32016 16.4874 5.79017 17.2732 7.39051 17.5915C8.99085 17.9098 10.6497 17.7464 12.1571 17.122C13.6646 16.4976 14.9531 15.4402 15.8596 14.0835C16.7661 12.7268 17.25 11.1317 17.25 9.5C17.2474 7.31276 16.3774 5.21584 14.8308 3.66922C13.2842 2.1226 11.1872 1.25258 9 1.25ZM11.7803 12.2803C11.6396 12.4209 11.4489 12.4998 11.25 12.4998C11.0511 12.4998 10.8604 12.4209 10.7198 12.2803L8.46975 10.0303C8.32909 9.88963 8.25005 9.6989 8.25 9.5V5C8.25 4.80109 8.32902 4.61032 8.46967 4.46967C8.61033 4.32902 8.80109 4.25 9 4.25C9.19892 4.25 9.38968 4.32902 9.53033 4.46967C9.67099 4.61032 9.75 4.80109 9.75 5V9.1895L11.7803 11.2198C11.9209 11.3604 11.9998 11.5511 11.9998 11.75C11.9998 11.9489 11.9209 12.1396 11.7803 12.2803Z"
        fill="url(#paint0_linear_207_25859)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_207_25859"
          x1="9"
          y1="1.25"
          x2="9"
          y2="19.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
